import React, { useEffect,useContext, useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { CartContext} from '../../../Context/CartContext';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';


export const ResetPin = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver} = useContext(CartContext); 
       const [ open, setOpen] = useState(false);
       const [ loginDetails, setLoginDetails ] = loginDetailsBag;
       let appUserID = loginDetails.organisations_id;                 
       const [ error, setError] = useState(''); 
       const [ events, setEvents] = useState([]);
       const [ event, setEvent] = useState('');
       const [ eventDetails, setEventDetails] = useState([]);
       const [ pocketIdentifier, setPocketIdentifier] = useState('');
       const [ cellNumber, setCellNumber] = useState('');
       const [ email, setEmail] = useState('');
       const dataDec = dataReceiver;  
       const [ voucherOwnerDetails, setVoucherOwnerDetails] = useState([]);
       const [ showSuccess, setShowSuccess] = useState(false);
       const [ responseMessage, setResponseMessage] = useState('');
       const [ otp, setOtp] = useState('');
       const [ newPin, setNewPin] = useState('');
       let resetResponse = '';               
              

       useEffect(()=> {
          
             const res = axios.post(baseURL+'bridge_evPoc_GetEvents.php' ).then(function (response) {
          
          response.data = dataDec(response.data);        
          setEvents(response.data);

        });

          }, [])
 


const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setEvent(event.target.value);
      let eventID = event.target.value

      for (let i = 0; i < events.length; i++) { 
        let allEventsBagID = events[i].id

           if(eventID === allEventsBagID){

                  setEventDetails(events[i]);

              }
        }
  }



const submitPinActivity = async() =>{
    
      //checkVoucher
  let myCreds = {eventsID:event, cellNumberRef:pocketIdentifier};

      if(!email){

          if(!cellNumber){

            setError('Please consider adding Email or Cell Number to receive your OTP');
            return 0;
          }
      }

      setError('');
    const res = axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php', myCreds ).then(function (response) {
               
               response.data = dataDec(response.data); 
           let responseData = response.data;

            setVoucherOwnerDetails(responseData[0])
        let myResponseContainer = Object.keys(responseData).length;
              
          if(myResponseContainer > 0){

             let data = {
                         cellNumberRef:pocketIdentifier, 
                         cellNumber:cellNumber, 
                         email:email, 
                         eventID:event
                         }

        const res = axios.post(baseURL+'bridge_EvPoc_ResetPocketPin.php', data ).then(function (response) {

            response.data = dataDec(response.data);  
            resetResponse = response.data;            
            setResponseMessage(resetResponse); 

            if(resetResponse.code === '200'){
                  
                alert(resetResponse.description);
             
                setShowSuccess(true);  


            }else{


                setShowSuccess(false);
                alert(resetResponse.description);
            }      
           
               
    });
 }else{


      setError('The Voucher details you entered does not exist for this event.')

 }

    }); 


}





 
const resetPin = async()=>{
     
      let pocketID = voucherOwnerDetails.id
      let oneTimePin = otp;

           let data = {newPin:newPin, 
                       otp:oneTimePin, 
                       pocketID:pocketID, 
                       eventID:event
                       };

             

        const res = axios.post(baseURL+'bridge_EvPoc_UpdatePocketPin.php', data ).then(function (response) {

            response.data = dataDec(response.data); 

            alert(response.data.description);
          
            if(response.data.code == '200'){

                goToDash();
            
            }
          
                               
    });

};



const goToDash = () => history.push({
    
    pathname: '/eventhome'
    
});


const withUnits = async()=>{
     
     

};

const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    

}


const toggleAccordion = () =>{
  

}



return (

      <main className = {classes.content}>
            <div className={classes.toolbar}/>

       <Grid container direction="column" className={classes.root}>
  
                  <Grid item></Grid>

                     <Grid item container spacing={2}>
                      
                         <Grid item xs={12} sm={3} md={3} lg={4}>
                                     
          
                         </Grid>   
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>
                
                  <Grid container spacing={1}>
                   {error?
                     <Grid item xs={12} align="center" >  
                       <Typography variant="body1" align="center" gutterBottom>
                           <strong style={{color: 'red'}}> {error}</strong>
                           
                        </Typography> 
                     </Grid>   
                     :                    
                     <>              
                      {showSuccess ?  
                      <Grid item xs={12} align="center" >
                        <Typography variant="body1" align="center" gutterBottom>
                           <strong> Account Holder Details</strong>
                           
                        </Typography> 
                       </Grid> 
                       :
                       <>
                       <Grid item xs={12} align="center">
                                <Typography align="center" variant="body1">
                                          Voucher PIN 
                                      </Typography>
                                </Grid>
                       </>
                      }
                     </>
                    }    

                  </Grid>
                      <hr/> 
              {/* if reset passed */}
              {showSuccess   ?

              <>     

                <CardContent> 

                       

                         <Typography variant="body1" gutterBottom>
                         Full Name <b>  :  {voucherOwnerDetails.fullName}   </b>          
                          </Typography> 

                          <Typography variant="body1" gutterBottom>
                         Event  <b> :{voucherOwnerDetails.eventName}   </b>          
                          </Typography>


                        {/*<Typography variant="body1" gutterBottom>
                          Cell Number<b>  :  {voucherOwnerDetails.cellNumber}   </b>          
                          </Typography>
                        */}   

                        <Typography variant="body1" gutterBottom>
                         Amount <b> : E{voucherOwnerDetails.currentBalance}   </b>          
                        </Typography>

                        <Typography variant="body1">
                         <b style={{color:'tomato', fontSize:'10px'}} >
                               * An OTP has been sent to the email/ cell number you provided on the previous page, please use the it to set your new password    
                          </b>            
                          </Typography>  


                         <Typography variant="h4" gutterBottom>
                                      <TextField label="New PIN " variant="outlined" type="password" fullWidth size="small"  placeholder="PIN"
                                      onChange={(e)=>setNewPin(e.target.value)} 
                                      />
                        </Typography>


                        <Typography variant="h4" gutterBottom>
                                      <TextField label="OTP" variant="outlined" type="text" fullWidth size="small" placeholder="One Time Pin" 
                                      onChange={(e)=>setOtp(e.target.value)}
                                      />
                        </Typography>     

                    <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}}
                     onClick={()=> resetPin()} >
                  Reset
                </Button>

          </CardContent>
          </>
          :

          <>
          <CardContent>
                       



{/*

                      <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Option" variant="outlined" fullWidth size="small" select
                                  onChange={handleActivityChange}
                                  value={activity}
                                  > 

                                    {activities.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.eventName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>
*/}

                      <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Event" variant="outlined" fullWidth size="small" select
                                  onChange={handleEventChange}
                                  value={event}
                                  > 

                                    {events.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.eventName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                          
                        <Typography variant="h4" gutterBottom>
                                      <TextField label="Voucher" variant="outlined" type="text" fullWidth size="small"  placeholder="Voucher"
                                      onChange={(e)=>setPocketIdentifier(e.target.value)} 
                                      />
                        </Typography>


                        <Typography variant="h4" gutterBottom>
                                      <TextField label="Cell Number" variant="outlined" type="text" fullWidth size="small" placeholder="Cell Number" 
                                      onChange={(e)=>setCellNumber(e.target.value)}
                                      />
                        </Typography>


                        <Typography variant="h4" gutterBottom>
                                      <TextField label="Email" variant="outlined" type="text" fullWidth size="small"  placeholder=" Email" 
                                      onChange={(e)=>setEmail(e.target.value)}
                                      />
                        </Typography> 

                      

                   
      
                <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}}
                onClick={()=> submitPinActivity()}>
                  Submit
                </Button>



            </CardContent>







          </>
         } 

     </Card>

  </Grid>
         
                     <Grid item xs={12} sm={3} md={3} lg={4}>
                           
                              
               
                     </Grid>
                     
                 </Grid>

              </Grid>

 </main>

  );
}

export default ResetPin;