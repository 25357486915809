import React, {useState} from 'react';
import { Grid, Button, TextField, Card, Paper, IconButton, CardContent, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from '../../components/Login/styles';
import { useHistory, Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { ArrowRightAltOutlined, ArrowLeftOutlined, CloudDownloadTwoTone } from '@material-ui/icons';
import MailIcon from '@mui/icons-material/Mail';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; 
import CallIcon from '@material-ui/icons/Call';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@mui/material/Tooltip';


export const RegisterAccountForm = () => {
  
  

  const classes = useStyles(); 
  const history = useHistory();
  const [loginError, setLoginError] = useState(false);
   const [ username, setUsername] = useState('');
  const [ fullName, setFullName] = useState('');
  const [ open, setOpen] = useState(false);
  const [ cellnumber, setCellnumber] = useState('');
  const [ password, setPassword] = useState('');
  const [ passwordConfirm, setPasswordConfirm] = useState('');



  const handleSubmit = () =>{            

        
     }

       
return ( 
     
     <>
 	      {/*showStatic === 'false' ? */}
        <div >
                                
                              <div align='center' style={{color:"red", align: "center"}}>
                                        {loginError}
                              </div>
                              <br/>
                                 
                              <Typography variant="h4" gutterBottom>
                                 <Tooltip title="Your email address will be the unique for every user" placement="top-end">
                                  <TextField label="Email Address" variant="outlined" fullWidth size="small"  placeholder="Enter Email Address"
                                      id="username" name="username"
                                      value={username}
                                      onChange={(e)=>setUsername(e.target.value)}
                                        InputProps={{
                                         startAdornment: (
                                           <InputAdornment position="start">
                                           <MailIcon />
                                           </InputAdornment>
                                          ),
                                       }}
                                  />
                               </Tooltip>
                              </Typography>  


  


                                     <Typography variant="h4" gutterBottom>
                                      <Tooltip title="Username will be used to login" placement="top-end">
                                      <TextField label="Username" variant="outlined"  fullWidth size="small" value={username}
                                      placeholder="Login Username"
                                      InputProps={{
                                        startAdornment: (
                                         <InputAdornment position="start">
                                             <AccountCircleIcon />
                                         </InputAdornment>
                                        ),
                                       }}
                                      />
                                      </Tooltip>
                                      </Typography>

                                    

                                    <Typography variant="h4" gutterBottom>
                                      <Tooltip title="Enter cellnumber to use for transacting" placement="top-end">
                                      <TextField label="Cellnumber" variant="outlined" fullWidth size="small"  placeholder="Enter Mobile Number"
                                      id="value={username}" name="value={username}"
                                      value={cellnumber}
                                      onChange={(e)=>setCellnumber(e.target.value)}
                                       InputProps={{
                                         startAdornment: (
                                           <InputAdornment position="start">
                                           <CallIcon />
                                           </InputAdornment>
                                         ),
                                       }}
                                      />
                                      </Tooltip>
                                      </Typography>
                                      

                                      
                                    
                                   <Typography variant="h4" gutterBottom>
                                    <Tooltip title="Please create a safe password " placement="top-end">
                                    <TextField label="Password" variant="outlined"  fullWidth size="small" placeholder="Password" 
                                      type="password" id="password"
                                      name="password" value={password}
                                      onChange={(e)=>setPassword(e.target.value)}
                                      InputProps={{
                                        startAdornment: (
                                           <InputAdornment position="start">
                                             <LockOpenIcon />
                                           </InputAdornment>
                                           ),
                                      }}                                
                                      />
                                      </Tooltip>
                                  </Typography>  
                                 

                                  <Typography variant="h4" gutterBottom>
                                    <Tooltip title="Confirm password should be the same as password" placement="top-end">
                                    <TextField label="Confirm Password" variant="outlined"  fullWidth size="small" placeholder="Confirm Password" 
                                    type="passwordConfirm" id="passwordConfirm"
                                    name="passwordConfirm" value={passwordConfirm}
                                    onChange={(e)=>setPasswordConfirm(e.target.value)}
                                    InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                             <LockOpenIcon />
                                           </InputAdornment>  
                                          ),
                                       }}                                
                                      />
                                    </Tooltip>
                                   </Typography>

               <Backdrop className={classes.backdrop} open={open}>
                       <Loader/>
               </Backdrop>

               <Tooltip title="This user will be used to Login all other projects besides ePayNet" placement="top-end">                 
                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style ={{margin: '5px', textTransaform:'none', color:'white', backgroundColor:'#155054'}} onClick={()=> handleSubmit()} > 
                    Register 
                    <IconButton style={{color:'white'}} size='small'>
                      <AssignmentTurnedInIcon />
                    </IconButton>
                  </Button>
               </Tooltip>   
    

        </div>

                </> 
       
              
                        
   
               
 )
}

export default RegisterAccountForm;