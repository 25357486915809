import React, { useContext, useEffect, useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import useStyles from "../Login/styles";
import ApprovedRequest from "./BusinessApprovedRequest/ApprovedRequest";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";
import { CartContext } from "../../Context/CartContext";
import Pagination from "../../components/Pagination/Pagination";
import { useHistory } from "react-router-dom";

export const BusinessApprovedRequests = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, dataReceiver, loginDetailsBag } = useContext(CartContext);
  const [search, setSearch] = useState("");
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(24);
  const [requests, setRequests] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedSearch, setSelectedSearch] = useState("");
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const dataDec = dataReceiver;
  const [open, setOpen] = useState(false);
  let usersRight = history.location.userRightState;
  let myItems = {};
  let [searchOptions, setSearchOptions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setOpen(!open);
    getTransactionsStatuses();
    let companyID = loginDetails.organisations_id;
    let data = { ePayNetCompanyID: companyID };

    const res = axios
      .post(
        baseURL + "bridge_iFund_GetMyBiddableApprovedFundingRequests.php",
        data
      )
      .then(function (response) {
        response.data = dataDec(response.data);
        setRequests(response.data);
        setOpen(false);
      });
  }, []);

  if (
    typeof usersRight !== "undefined" &&
    Object.keys(history.location.userRightState).length > 0
  ) {
  }

  const getTransactionsStatuses = async () => {
    const res = axios
      .post(baseURL + "bridge_iFund_GetTransactionStatuses.php")
      .then(function (response) {
        response.data = dataDec(response.data);
        setSearchOptions(response.data);
      });
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let appUsersID = loginDetails.organisations_id;
    let requestStatusID = event.target.value;
    let data = {
      requestStatusID: requestStatusID,
      ePayNetCompanyID: appUsersID,
    };

    setSelectedSearch(event.target.value);

    const res = axios
      .post(baseURL + "bridge_iFund_GetFundeeApprovedFundingRequests.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);

        if (Object.keys(response.data).length > 0) {
          setRequests(response.data);
        } else {
          alert("Nothing to show for selected status");
        }
      });
  };

  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  let currentProducts = requests;
  let filteredProducts = [];

  if (selectedSearch != "") {
    myItems = requests.filter((item) => {
      if (item.requestStatus == selectedSearch) {
        return item;
      }
      //  return item.requestStatus == selectedSearch;
    });

    {
      /*  return 0;

        filteredProducts = requests.filter(product => {
  
          return product.name.toLowerCase().includes(search.toLowerCase());
       
        }); 
      */
    }
  }

  if (myItems.length > 1 || myItems.length !== "") {
    currentProducts = myItems;
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container justify="center" spacing={2}>
        <Grid item container justify="center" xs={12} sm={12} md={4} lg={4}>
          <TextField
            label="Search By Status"
            variant="outlined"
            fullWidth
            size="small"
            select
            onChange={handleCategoryChange}
            value={selectedSearch}
          >
            {searchOptions.map((val) => (
              <MenuItem key={val.id} value={val.id}>
                {val.statusName}
              </MenuItem>
            ))}
          </TextField>
          {currentProducts.length} Results
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={open}>
        <Loader />
      </Backdrop>

      <Grid container spacing={2}>
        {requests.map((product, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={product.id}>
            <ApprovedRequest
              key={product.id}
              product={product}
              usersRight={usersRight}
              loading={open}
            />
          </Grid>
        ))}
      </Grid>

      <div className={classes.toolbar} />
      <Pagination
        productsPerPage={productsPerPage}
        align="center"
        totalProducts={requests.length}
        paginate={paginate}
      />
    </main>
  );
};

export default BusinessApprovedRequests;
