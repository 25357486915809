import React, { useEffect,useState,useContext } from 'react';
import {Card, CardContent, Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles';
import { TextField,InputAdornment } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


export const LenderActivation = () => {
	
 const classes = useStyles(); 
 const history = useHistory(); 
 const { baseURL, dataReceiver } = useContext(CartContext); 
 const [companyCategories, setCompanyCategories]= useState([]);
 const [open, setOpen] = React.useState(false);
 const [lenders, setLenders] = useState([]);
 const [lenderCategory, setLenderCategory] = useState([]);
 const [membershipNumber, setMembershipNumber] = useState('');
 const [borrowerDetails, setBorrowerDetails] = useState({});
 const [selectedLender, setSelectedLender] = useState([]);
 const [ error, setError] = useState('');
 const dataDec  = dataReceiver;
 const [ myCode , setMyCode] = useState('');
 let borrowerDetailsBag = {};
 let selectedLenderCategoryID = '';



 const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setOpen(!open);
        setLenderCategory(event.target.value);
        selectedLenderCategoryID = event.target.value;
    
    const fetchLenders = async()=>{
          
      const res = await axios.get(baseURL+'bridgeGetLenders.php?companyCategoryID='+ selectedLenderCategoryID);

       res.data = dataDec(res.data); 
       setLenders(res.data); 
       setOpen(false);     

      }

      fetchLenders();
    
  };       

  

 useEffect(()=> {

      setOpen(!open);

      const fetchCompanyCategories = async()=>{
          
          const res = await axios.get(baseURL+'bridgeGetLendersCompanyCategories.php');
         
          res.data = dataDec(res.data);  
          setCompanyCategories(res.data);
          setOpen(false);       

      }

      fetchCompanyCategories();
     
   }, [])

    
   const handlerLenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedLender(event.target.value);
    let myLendersID = event.target.value;

    for (let i = 0; i < lenders.length; i++) { 

         let selectedLendersID = lenders[i].id;

       if(myLendersID === selectedLendersID){


              setMyCode(lenders[i].id);  
          }

    }

}

const goToLenderConfirmation = () => history.push({ 
      
      pathname: '/confirmactivation',
        borrowerState: borrowerDetailsBag

});


 function activateLender(){
    
  setOpen(!open);  
    
    
  const ActivateLenders = async()=>{
          
          const res = await axios.get(baseURL+'bridgeActivateLoanMembership.php?code='+ 'XXX'+'&lendersID='+myCode+'&membershipNumber='+membershipNumber);
          
          res.data = dataDec(res.data);

       
        if(res.data.code){

              setError(res.data.response);
              setOpen(false);
              return 0;
        }


            if(res.data.id !== '' ){

                  setBorrowerDetails(res.data);  
                  borrowerDetailsBag = res.data;  
                  goToLenderConfirmation();
                  setOpen(false);
          
              }else{

                       setError(res.data.response);
                       setOpen(false);

               }  

      }
      
      ActivateLenders();
     
 }

 

	return (
		
      <main className = {classes.content}>
            <div className={classes.toolbar}/>
   
  <Grid container direction="column">

               <Grid item></Grid>

                 <Grid item container spacing={4}>
                  
                  <Grid item xs={false} sm={2} md={3} lg={4}/>   
                <Grid item xs={12} sm={8} md={6} lg={4}>  

                 <Card >

                  <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                  </Backdrop>
                                  <div style={{color:"red", align:"center", paddingTop:'15px'}} align='center'>{error}</div>
                  <CardContent>
                  <Typography align="center" variant="body1"><h5>Activate Lender</h5></Typography>

                                
                        <Typography variant="h4" gutterBottom>                
                                 <TextField label="Category" variant="outlined"  fullWidth size="small" select
                                  onChange={handleChange}
                                  value={lenderCategory}
                                  > 
                                    {companyCategories.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.categoryName}
                                   </MenuItem>
                                   ))}
                               </TextField>
                          </Typography> 

                          <Typography variant="h4" gutterBottom>
                                <TextField label="Lenders" variant="outlined"  fullWidth size="small" select 
                                  onChange={handlerLenderChange}
                                  value={selectedLender}                               
                                  InputProps={{ 
                                   startAdornment: ( 
                                   <InputAdornment position="start">
                                   </InputAdornment>
                                  ),
                                 }}>                        
                                    
                                    {lenders.map((option) => (
                                   <MenuItem key={option.id} value =  {option.id}  >
                                      {option.companyName} 
                                   </MenuItem>
                                   ))}
                               </TextField>
                          </Typography>


                                <Typography variant="h4" gutterBottom>
                                      <TextField label="Membership Number" variant="outlined" fullWidth size="small"
                                      placeholder="Membership Number"  
                                        type="text"
                                       id="membershipNumber"
                                       name="membershipNumber"
                                       onChange={(e)=>setMembershipNumber(e.target.value)}
                                                                          
                                      />
                                      </Typography>


                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> activateLender()} > Activate</Button>
                                 
                           </CardContent>
                         </Card>

                        </Grid>
          <Grid item xs={false} sm={2} md={3} lg={4}/> 
                 </Grid>

              </Grid>

  </main>

	);
}

export default LenderActivation;