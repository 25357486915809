//import {CartContext} from '../../Context/CartContext';
import img from '../assets/img.jpg';
import img2 from '../assets/icredit.png';
import img3 from '../assets/letMove.png';

//import loans from '../../assets/testBanner2New.png';

const people = [
  {
    id: 1,
    graphicUrl:img,
    imageUrl:img,
    name: 'ePayNet P2P Funding',
    categoryName: 'Events',
    webUrl:'www.epaynetsz.com',
    description:'The fastest way to fund your business venture by raising money from a large number of people.',  
  },
  {
    id: 2,
    graphicUrl:img2,
    imageUrl:img2,
    name: 'iCredit',
    categoryName: 'Bulk Payments',
    webUrl:'https://icredit.salvtec.com/',
    description:'ePayNet Cashless Events Guest user allow unregistered clients...',
  },
  {
    id: 3,
    graphicUrl:img3,
    imageUrl:img3,
    name: 'Lets Move',
    categoryName: 'Fleet Management',
    webUrl:'https://letsmove.salvtec.com/',
    description:'A vehicle management solution that aims at creating better experience for companies, employees, drivers, finance departments and gas stations.',
  },
];

export default people;
