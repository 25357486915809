import React, {useState, useEffect, useContext} from 'react';
import {Card, CardContent, Button, Typography} from '@material-ui/core';
import useStyles from '../../Login/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FolderIcon from '@mui/icons-material/Folder';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {CartContext} from '../../../Context/CartContext'; 
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';


const History = ({ historys, product, loading, teamMember, userRole}) => {

     const classes = useStyles();
     const history = useHistory();
     const { baseURL, loginDetailsBag, dataReceiver, legalBusinessMemberAccountsBag, userRoleStatusesBag} = useContext(CartContext);
     const dataDec  = dataReceiver; 
     const [ open, setOpen] = useState(false);
     const [ loginDetails, setLoginDetails ] = loginDetailsBag;  
     const [ processedDocAvailable, setprocessedDocAvailable] = useState('');
     const [ isRefreshed, setIsRefreshed] = useState(false);
     let fileData = {}; 
     let responseDetailsBag = {};
     let isTransactionReadyToAuthorize = '';
     let linkUrl = '';       


     
  useEffect(()=> {
      
      isTransactionReadyToAuthorize = historys.fileUrl; 

   }, []) 

const iFrameStyle = {

  fontSize: '15px',
  textAlign: 'center',
  backgroundColor: 'white',
  width:'100%',
  height:'600px'

};

const handleRegistration = () => history.push({
      
     pathname: '/authorization',
     authorizerState:fileData
    
   });

const goToUpload = () => history.push({
  
      pathname:'/businessdocumentprocessing',
      uploadState:fileData

})

const goToAuthorize = () => history.push({
  
      pathname:'/authorization',
      uploadState:responseDetailsBag

})   

const goToViewResponse = (responseStatus) => history.push({
  
      pathname:'/businessactivityresponse',
      responseState:responseStatus

})   
   const handleUpload = () => {
         
        fileData = historys;
        goToUpload();    

   }


  const btnRewind = () =>{

        setOpen(!open);
        setIsRefreshed(!isRefreshed);
        setOpen(false); 

  }

  const handleProcessFile = () =>{


  }

  const handlePreviewProcessed = async()=>{


         setOpen(!open);

         let requestID = historys.id;
         let requestNumber = historys.requestNumber;
         let appUserID = loginDetails.organisations_id; 
         let data = {requestID:requestID,requestNumber:requestNumber, companyID:appUserID} 
         
         data = JSON.stringify(data);


   const res = await axios.post(baseURL+'bridgeFilterBulkPaymentTransaction.php', data ).then(function (response) {

       
       btnRewind();
       response.data = dataDec(response.data);        
       let previewResponse = response.data;
       previewResponse = responseDetailsBag;

     
      if(previewResponse.code == '200'){

          //  console.log(previewResponse);
            setOpen(false); 

      }else{

         // console.log(previewResponse);
          setOpen(false); 

      }

    });   

    }



   const handleAuthorize = async()=>{
        
        setOpen(!open);
        let dataItem = historys;          
        let request = dataItem.id;
        let teamsMember = teamMember.teamMemberID;
        let company = loginDetails.organisations_id;
        let data = {requestID:request, teamMemberID:teamsMember, companyID:company};
            data = JSON.stringify(data);
        
    
   const res = await axios.post(baseURL+'bridgeAuthoriseUnitRechargeRequest.php', data ).then(function (response) {

      response.data = dataDec(response.data);       
      let responseStatus = response.data;



      if(responseStatus.code == '200'){

         setOpen(false);
         goToViewResponse(responseStatus);


      }else{

         responseStatus = responseDetailsBag
         setOpen(false);

      }
  
    });   
   }




   if(loading){
    
        return <h2>Loading..</h2>
    }


return (

     <>

   <Card >
           
      <Typography variant="h6" align="center" gutterBottom>             

       <Stack direction="row" alignItems="center" align="center" gap={4}>
        <span align="center" style={{paddingLeft:'20px', paddingTop:'10px'}}>  {historys.requestNumber} </span>
         
         {historys.fileUrl ?
          <>

           {historys.transactionListUrl !== '' ?

            <>
             
             {isRefreshed ? 

              <a  href={historys.transactionListUrl} onClick={()=> handleProcessFile()} target="_blank" style={{color:'#3f75a2', backgroungColor:'#ff0000'}}> 
                <FileOpenIcon />
             </a> 

             :

             <>
              <a onClick={()=> handlePreviewProcessed()} style={{color:'#3f75a2', backgroungColor:'#ff0000'}}> 
                <CloudDownloadOutlinedIcon />
             </a>

             </>
             }


            </>   
              :

              <>
              </>
             }
           </>

           :
           <>
           </>
          }   

        </Stack>

        </Typography><hr/>
            <CardContent>

                <div className={classes.cardContent}>

                    <Typography variant="body1" gutterBottom>
                       Bank : {historys.bankName}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                     Amount: {historys.amount}    
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      Status: {historys.statusName}  
                    </Typography>


                    <Typography variant="body1" gutterBottom>
                      Reference: {historys.reference} 
                    </Typography>

{/*____________________START INPUTTER UI_________________________________*/}


       {userRole == 'inputter' ?

              <>

                { !historys.fileUrl ?
                      
                   <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style= {{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> handleUpload()}>Upload CSV </Button>
                 
                 :

                 <>

               <a href={historys.fileUrl} style={{color:'#3f75a2', backgroungColor:'#ff0000'}}>   
                   <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style= {{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} >Download & Preview CSV </Button>
               </a> 

                  {/*onClick={()=> handleUpload()} component={Link} to="#"  */}
                

                 </>

                 }
       {/*____________________END INPUTTER UI_________________________________*/}        
 

              </>

                  :





              <>                   
                 

                { historys.fileUrl ?

                    <>

         {/*____________________START AUTHORISER UI_________________________________*/}                

                    <Typography variant="body1" gutterBottom>
                       <a href={historys.fileUrl} style={{color:'#3f75a2', backgroungColor:'#ff0000'}}>
                        Click to download and preview file
                       </a>  
                    </Typography>

   
                    { historys.authorizer == '0' ?
                       
                       <> 
           {/**************START AUTHORISE FILE****************************************/}            

                        <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style= {{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> handleAuthorize()}>Authorize </Button>
                       </>
                      :
                      
                      <>

                     {/*_________________________IS PROCCESSED LIST AVAILABLE____________________________________*/}


          {historys.transactionListUrl !== '' ?

            <>
             
               {isRefreshed ? 

                      <a href={historys.transactionListUrl} target="_blank" style={{color:'#3f75a2', backgroungColor:'#ff0000'}}>       

                           <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style= {{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} >
                               Click Here To Download<FileOpenIcon />
                          </Button>    
                      </a> 

                    :

                     <>
                      
                        <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style= {{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> handlePreviewProcessed()}>
                          Download Processed File   <CloudDownloadOutlinedIcon />
                        </Button>
                      </>
          }
          
            </>   
  
              :

              <>
              </>
       }     



                      </>
                    }    

                    </>    

                    :
                    

                    <>
                      
                      <br/>
                    <Typography variant="body1" gutterBottom align='center'>
                       
                      <span style={{color:'red'}}><strong> CSV file not available </strong></span>
                    </Typography>
                    <br/>
                    </>
                  }





              </>
        }

                </div>
            </CardContent>
                        
        </Card>

    </>   
       
   )
}

export default History;