import React, { useEffect,useContext, useState } from 'react';
import {Card, CardMedia, CardContent, InputAdornment, IconButton, Typography, Grid, Paper, Button, MenuItem} from '@material-ui/core';
import useStyles from '../../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../../Context/CartContext';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";



export const CheckBalance = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver }= useContext(CartContext);
       
       const [ paymentPlatforms, setPaymentPlatforms] = useState([]);
       const [ paymentPlatform, setPaymentPlatform] = useState('');
       const [ events, setEvents] = useState([]);
       const [ eventDetails, setEventDetails] = useState([]);
       const [ event, setEvent] = useState('');
       const [ cellNumberRef, setCellNumberRef] = useState('');
       const [ pinNumber, setPinNumber] = useState('');
       const [ isOnEpaynet, setIsOnEpaynet] = useState(true);
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ voucherSuccess, setVoucherSuccess] = useState(false);
       const [ voucherDetails, setVoucherDetails] = useState({})
       const dataDec  = dataReceiver;
       const [ open, setOpen] = useState(false);
       const [ error, setError] = useState('');
       const [ success, setSuccess] = useState(''); 
      



       useEffect(()=> {
          
          getEvents();

        }, [])
 


  const getEvents = async()=>{
      
       const res = axios.post(baseURL+'bridge_evPoc_GetEvents.php' ).then(function (response) {
          
          response.data = dataDec(response.data);        
          setEvents(response.data);

        });

 } 




  const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setPaymentPlatform(event.target.value);
      let platformID = event.target.value

}


const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setEvent(event.target.value);
      let eventID = event.target.value

      for (let i = 0; i < events.length; i++) { 
        let allEventsBagID = events[i].id

           if(eventID === allEventsBagID){

                  setEventDetails(events[i]);

              }
        }
  }

 
const checkForVoucher = async()=>{
  
      let eventsID = event;
      let cellRef = cellNumberRef;
      let pin = pinNumber  
                  {/*GET THE CREATED POCKET DETAILS*/}
    let myCreds = {eventsID:eventsID, cellNumberRef:cellRef, pocketPin:pin};

    const res = axios.post(baseURL+'bridge_EvPoc_CheckAccountBalanceViaPin.php', myCreds ).then(function (response) {
               

               response.data = dataDec(response.data); 
           let responseData = response.data;
           let mycontainer = Object.keys(responseData).length;
           let responseCode = responseData[0]  
                
              
               if(mycontainer > 0){

                  if(responseData.code === '201'){ 

                     setError(responseData.description);
                     

                    }else{

                          
                    setVoucherDetails(responseData[0]);
                    setError('' );
                    setVoucherSuccess(true);


                    }

               }else{

                    setError('This voucher details are not valid.' );

           }
          

        });    
  }



const goToDashboard = () => history.push({
    
    pathname: '/eventhome'
    
});



  return (

     <main className = {classes.content}>
       <div className={classes.toolbar}/>
       
    <Grid container direction="column">

     
         <Grid item container spacing={1}>
                
                 <Grid item xs={false} sm={3} md={3} lg={4}/> 

                  <Grid item xs={12} sm={6} md={6} lg={4}>
                
                        <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>
                
                                 <Grid container spacing={1}>
                                   <Grid item xs={12} align="center">
                                    {error ?
                                          <span style={{color:'red'}}><b>{error}</b>

                                           
                                          </span>
                                     :

                                        <Typography align="center" variant="body1">
                                           <b> Voucher Balance</b>
                                        </Typography>
                                }
                                    

                                     
                                   </Grid>
                                 </Grid>
                               <hr /> 
                                  
                  {!voucherSuccess ?

                    <CardContent>
                       <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Event" variant="outlined" fullWidth size="small" select
                                  onChange={handleEventChange}
                                  value={event}
                                  > 

                                    {events.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.eventName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                          <Typography variant="h4" gutterBottom>
                                  <TextField label="Voucher Number" variant="outlined" type="text" fullWidth size="small" placeholder="Voucher Number" 
                                  onChange={(e)=>setCellNumberRef(e.target.value)}
                                />
                        </Typography>

                        <Typography variant="h4" gutterBottom>
                                  <TextField label="Voucher PIN" variant="outlined" type="password" fullWidth size="small" placeholder="Voucher PIN Number" 
                                  onChange={(e)=>setPinNumber(e.target.value)}
                                />
                        </Typography>
                  
            {/*CHECK IF VOUCHER EXIIST*/}     
          <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin:'5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> checkForVoucher()}>    
                                 Check Balance
          </Button>        

             </CardContent>

          :
          

          <CardContent>
                          
              <Typography variant="body1" gutterBottom>                
                 Dear <b>{voucherDetails.fullName}</b>, your current balance is :<b>E{voucherDetails.currentBalance}</b> for the event     
                 <b> {voucherDetails.eventName}</b>                   
              </Typography>

                          
                          
                   
            <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin:'5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> goToDashboard()}>    
                            Done
            </Button>        

          </CardContent>
        }       
           
      </Card>

    </Grid>
                
                <Grid item xs={false} sm={3} md={3} lg={4}/>  

                 </Grid>

              </Grid>

  </main>

  );
}

export default CheckBalance;