import React, { useEffect, useContext, useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import axios from 'axios'; 
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


export const ConfirmEsacco = () => {
	
       const classes = useStyles(); 
       const history = useHistory(); 
       const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);  
       const [loginDetails, setLoginDetails] = loginDetailsBag;
       let appUserID = loginDetails.organisations_id;
       const [ open, setOpen] = useState(false);
       const [paymentPlatforms, setPaymentPlatforms] = React.useState([]);
       const [responseStatus, setResponseStatus] = React.useState([]);
       const [selectedPaymentPlatform, setSelectedPaymentPlatform] = React.useState([]);
       const [saccoMemberState, setSaccoMemberState] = React.useState([]);
       const [saccoPaymentInfoState, setSaccoPaymentInfoState] = React.useState([]);
       const [bankCharges, setBankCharges ] = React.useState([]);
       const [bankChargeAmount, setBankChargeAmount ] = React.useState([]);
       const [isWithCharges, setIsWithCharges ] = React.useState(true);
       const dataDec  = dataReceiver;
       let selectedBankChargeAmount = '';
       let selectedPlatform = '';
       let data2 = {};
       let data = {};
       let bankTransactionDetailsBag = {};
       let checkObjType = history.location.saccoMemberState;
       const checkObjType1 = history.location.saccoPaymentInfoState 
       const saccoMemberDetailsBag = history.location.saccoMemberDetailsState;
       const saccoPaymentDetailsBag = history.location.saccoPaymentDetailsState;
  
if(typeof checkObjType !== 'undefined' && Object.keys(history.location.saccoMemberState).length > 0) {
        
          setSaccoMemberState(history.location.saccoMemberDetailsState);
                    
      }


  useEffect(()=> {


      const fetchPaymentPlatform = async()=>{

          setOpen(!open);  
          const res = await axios.get(baseURL+'bridgeGetMobileMoneyVendors.php');
         
          res.data = dataDec(res.data);

          setPaymentPlatforms(res.data);   
          setOpen(false);   

      }

      const fetchBankCharges = async()=>{
          

          }

        fetchPaymentPlatform();
     
   }, [])


const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedPaymentPlatform(event.target.value);
    selectedPlatform = event.target.value;
    
  };  

const account = saccoMemberDetailsBag.accountNumber;

      const min = 100;
      const max = 999;
      const rand =  Math.floor(Math.random() * (max - min + 1) ) + min;
      const randAccountNumber = `${rand}${account}`;


 function d2h(d) {
  return d.toString(16);
}
 function h2d (h) {
    return parseInt(h, 16);
}
 function stringToHex (tmp) {
    let str = '',
        i = 0,
        tmp_len = tmp.length,
        c;
 
    for (; i < tmp_len; i += 1) {
        c = tmp.charCodeAt(i);
        str += d2h(c) + '';
    }
    return str;
}

      const rand3hexAccountNumber = stringToHex(randAccountNumber);
 
const submitPayment = () =>{
        
      setOpen(!open);
      let dt = new Date();
      let seconds = dt.getSeconds();
      let minutes = dt.getMinutes();
      let banksID= saccoMemberDetailsBag.Bank;
      let branchesID= saccoMemberDetailsBag.Branch;
      let accountNumber= rand3hexAccountNumber
      let amount = '';  

      if(bankChargeAmount > amount){

         amount = bankChargeAmount;
      }
      

    const ref = saccoPaymentDetailsBag.reference;
    const payamount = saccoPaymentDetailsBag.amount;
    const saccoCode = saccoMemberDetailsBag[0].saccoCode;
    const accountID = saccoMemberDetailsBag[0].accountID;
    const membershipNumber = saccoMemberDetailsBag[0].membershipNumber;
    const esasccoRecordID = saccoMemberDetailsBag[0].id;
    let selectedPlatform = paymentPlatforms[0].id;

    let data = {saccoCode:saccoCode, companyID:appUserID, amount:payamount, saccoDepositAccount:accountID, reference:ref, localEftVendorID:selectedPlatform, membershipNumber:membershipNumber, esasccoRecordID:esasccoRecordID};


   const res = axios.post(baseURL+'bridgeReceiveEsasccoDepositTransaction.php', data).then(function (response) {


    let cleanData = dataDec(response.data);

     if(cleanData !== ''){
        
         bankTransactionDetailsBag = cleanData ;
         goToPaymentStatus();
         setOpen(false);

        }else{

          alert('Connection error, Please try again later.'); 
          setOpen(false);
     }   
       
  });
}


const goToPaymentStatus = () => history.push({ 

      pathname: '/esaccotransactionstatus',
      bankTransactionState: bankTransactionDetailsBag

});


	return (
  
      <main className = {classes.content}>

       <div className={classes.toolbar}/> 
           <Grid container direction="column">
                  <Grid item></Grid>
                     <Grid item container spacing={4}>
                        <Grid item xs={false} sm={2} md={3} lg={4}/>
                          <Grid item xs={12} sm={8} md={6} lg={4}>
  
                    <Card >
                             <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                             </Backdrop>
                                
                        <CardContent>
                         <Typography align="center" variant="body1">
                               <h5>Confirm Transaction</h5>
                         </Typography>
                                 
                          <Typography variant="body1" gutterBottom>                
                                 
                            Account Holder: <b>{saccoMemberDetailsBag[0].accountHolderName}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Membership #: <b>{saccoMemberDetailsBag[0].membershipNumber}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Account : <b>{saccoMemberDetailsBag[0].accountID}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Account State :{saccoMemberDetailsBag[0].accountState} <b></b>
                          </Typography>

                        <Typography variant="body1" gutterBottom>                
                                 <TextField label="Payment Platform" variant="outlined"  fullWidth    size="small" select
                                  onChange={handleChange}
                                  value={selectedPaymentPlatform}
                                  > 

                                    {paymentPlatforms.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.vendorName}
                                   </MenuItem>
                                   ))}
                               </TextField>
                          </Typography>                          

                <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{margin:'5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> submitPayment()}>Submit</Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
                    <Grid item sm={2} md={3} lg={4}/>
                 </Grid>
              </Grid>
    </main>
	);
}

export default ConfirmEsacco;