import React, { useContext } from "react";
import {
  Drawer as MUIDrawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import StarIcon from "@material-ui/icons/Star";
import SettingsIcon from "@material-ui/icons/Settings";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AddLocationIcon from "@mui/icons-material/AddLocation";

const Drawer = ({ open, setOpen, handleDrawer }) => {
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;

  let itemsList = [
    
    {
      text: "Cashless Events",
      icon: <AddLocationIcon />,
      onClick: "/eventhome",
    },
  ];

  if (loginDetails.rights === "8") {
    itemsList = [
      { text: "Home", icon: <HomeIcon />, onClick: "/Dashboard" },
      {
        text: "My Recipients",
        icon: <PersonAddIcon />,
        onClick: "/addrecipient",
      },
      {
        text: "Cashless Events",
        icon: <AddLocationIcon />,
        onClick: "/eventhome",
      },
      {
        text: "Recharge",
        icon: <CurrencyExchangeIcon />,
        onClick: "/recharge",
      },
      {
        text: "Accounts",
        icon: <SupervisorAccountIcon />,
        onClick: "/UserAccount",
      },
      { text: "History", icon: <AccessTimeIcon />, onClick: "/history" },
      { text: "Favorites", icon: <StarIcon />, onClick: "/Dashboard" },
      {
        text: "Transfer Limit",
        icon: <SettingsIcon />,
        onClick: "/transferlimit",
      },
      {
        text: "ePayNet Activations",
        icon: <AddBusinessIcon />,
        onClick: "/activateepaynetbusiness",
      },
    ];
  }
  return (
    <MUIDrawer anchor="left" open={open} onClose={() => setOpen(false)}>
      <List onClick={() => setOpen(false)}>
        {itemsList.map((item, index) => {
          const { text, icon, onClick } = item;
          return (
            <ListItem button key={text} component={Link} to={onClick}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>
    </MUIDrawer>
  );
};

export default Drawer;
