import React, { useEffect,useContext, useState } from 'react';
import {Card, CardContent,  Typography, Grid,Button, MenuItem} from '@material-ui/core';
import useStyles from '../../../components/Login/styles'; 
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import {CartContext} from '../../../Context/CartContext';
import axios from 'axios';     
import Loader from '../../../components/Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';


export const GuestPocketGenerateView = () => {
  
 
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver }= useContext(CartContext);
       const [ paymentPlatforms, setPaymentPlatforms] = useState([]);
       const [ paymentPlatform, setPaymentPlatform] = useState('');
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ open, setOpen] = useState(false);
       const [ event, setEvent] = useState('');   
       const [ error, setError] = useState('');
       const [ success, setSuccess] = useState(''); 
       const [ amount, setAmount] = useState('');
       let pocketDetails = history.location.createdPocketState; 
       let voucherResponse = history.location.voucherMessageState;                       
       const dataDec  = dataReceiver;
       let responseMessageBag = {};


       useEffect(()=> {
          
          getPaymentPlatforms();

        }, [])
 

  const getPaymentPlatforms = async()=>{
      
       const res = axios.post('https://www.digimagesystem.com/iEventPocket/api/getEpayNetPaymentPlatforms.php' ).then(function (response) {
        
          setPaymentPlatforms(response.data);

        });

 } 


  const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setPaymentPlatform(event.target.value);
      let platformID = event.target.value

}


const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setEvent(event.target.value);
       
  }

 

const rechargeVoucher = async()=>{
        

      let fromAccount = '';
      let data = {};
      let eventsID = pocketDetails[0].events_id;
      let companyID = loginDetails.organisations_id;
      let fullName = pocketDetails[0].fullName;
      let cellNumber = pocketDetails[0].cellNumber;
      let cellRef = pocketDetails[0].cellNumberRef;
      let email = pocketDetails[0].reference;
      
      

      if(loginDetails){

          fromAccount = loginDetails.momoAccountNumber;

      }

      if(fromAccount === '' || fromAccount == undefined){
         
          fromAccount = pocketDetails[0].cellNumber;   
      }  

     
       data = {fullName:fullName, cellNumberRef:cellRef, eventsID:eventsID, ePayNetCompanyID:companyID, amount:amount, localEftVendorID:paymentPlatform, fromAccount:fromAccount, reference:email};


   

      const res = axios.post(baseURL+'bridge_evPoc_ReceiveAttendeeRechargeUnitsTransaction.php', data ).then(function (response) {
            
            response.data = dataDec(response.data);
            responseMessageBag = response.data;

          if(response.data){

              goToComplete()
          }      
              
  });  


}




const createPocket = async()=>{
    
      let eventsID = pocketDetails[0].events_id;
      let companyID = loginDetails.organisations_id;
      let fullName = pocketDetails[0].fullName;
      let cellNumber = pocketDetails[0].cellNumber;
      let cellRef = pocketDetails[0].cellNumberRef
      let fromAccount = loginDetails.momoAccountNumber;

   

      let data = {fullName:fullName, cellNumberRef:cellRef, eventsID:eventsID, ePayNetCompanyID:companyID, amount:amount, localEftVendorID:paymentPlatform, fromAccount:fromAccount, reference:''};


    const res = axios.post(baseURL+'bridge_evPoc_ReceiveAttendeeRechargeUnitsTransaction.php', data ).then(function (response) {
            
        response.data = dataDec(response.data);
        responseMessageBag = response.data;
         
          if(response.data){

              goToComplete()
          }      
              

  });
         
}

const topUpLater = () => history.push({
    
    pathname: '/guesteventhome'
    
});


const goToComplete = () => history.push({
    
    pathname: '/guestpocketrechargestatus',
    responseMessageState:responseMessageBag
    
});


const getCreatedPocketDetails = () =>{
    
                  {/*GET THE CREATED POCKET DETAILS*/}
    let eventsID = pocketDetails.eventsID;
    let cellRef = pocketDetails.cellNumberRef;              

    let myCreds = {eventsID:eventsID, cellNumberRef:cellRef};


    const res = axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php', myCreds ).then(function (response) {

           response.data = dataDec(response.data);  
             
    });

}


 const goToDashboard = () => history.push({
    
    pathname: '/guesteventsdashboard'
    
});



  return (

     <main className = {classes.content}>
       <div className={classes.toolbar}/>
       
    <Grid container direction="column" style= {{margin: '5px'}}>

     
         <Grid item container spacing={1} style={{height: "280px" }}>
                
                 <Grid item xs={false} sm={4} md={4} lg={4}/> 

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                       

                    <Card >

                                 <Grid container spacing={1}>
                                   <Grid item xs={12} align="center">
                                      
                                        <Typography align="center" variant="body1">
                                           { voucherResponse ?
                                               <b style={{color:'#22bb33'}}>  
                                               
                                                   <>{voucherResponse.description}</>
                                                   :
                                                   <>
                                                   </>
                                                   
                                               </b>
                                               :
                                              <>
                                              </>
                                           }
                                        </Typography>
                                      
                                   </Grid>
                                 </Grid>
                               <hr /> 
                           
                       <CardContent> 

                        
                        {/*<Typography variant="body2" gutterBottom>                
                             Fullname:<b> {pocketDetails[0].fullName}</b>
                        </Typography>*/}

   
                        <Typography variant="body2" gutterBottom>                
                             Cell Number:<b>{pocketDetails[0].cellNumber}</b>
                        </Typography>

                        <Typography variant="body2" gutterBottom>                
                             Voucher Number:<b>{/*{pocketDetails[0].cellNumberRef}*/}****</b>
                        </Typography>


                        <Typography variant="body2" gutterBottom>                
                             Event:<b>{pocketDetails[0].eventName}</b>
                        </Typography>


                        <Typography variant="body2" gutterBottom>                
                             Current Balance:<b>E{pocketDetails[0].currentBalance}</b>
                        </Typography>


                        <Typography variant="body1">                
                           <b style={{color:'tomato', fontSize:'8px'}}>
                              <span style={{color:'red'}}>*</span>
                              You can now be able to recharge your voucher from your nearest ePayNet Cashless Events Kiosk, please keep your PIN safe.
                              </b>
                        </Typography>

                        {/*<Typography variant="h4" gutterBottom>
                            <TextField label="Amount" variant="outlined" type="text" fullWidth size="small"  placeholder="Top Up Amount" 
                                onChange={(e)=>setAmount(e.target.value)}
                                      />
                        </Typography>


                        <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Payment Platform" variant="outlined" fullWidth size="small" select
                                  onChange={handleBankChange}
                                  value={paymentPlatform}
                                  > 

                                    {paymentPlatforms.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.description}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>*/}

      
              

                <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> topUpLater()}>
                  Done
                </Button>

          </CardContent>
    
     </Card>


                  </Grid>
               
                 </Grid>

              </Grid>

  </main>

  );
}

export default GuestPocketGenerateView;