import React, { useEffect, useContext, useState } from 'react';
import {Card, Button, IconButton, InputAdornment, CardContent, TextField, Typography, Grid, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles';  
import { useHistory } from 'react-router-dom';
import TransactionHistory from './SavingHistory/TransactionHistory';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios'; 
import Loader from '../Loader/Loader';


   
export const SavingsHistory = () => {
  
 const classes = useStyles(); 
 const history = useHistory(); 
 const [ loading, setLoading] = useState(false); 
 const { baseURL, dataReceiver, savingAmountsBag, interestAmountsBag } = useContext(CartContext); 
 const { loginDetailsBag } = useContext(CartContext);
 const dataDec  = dataReceiver;
 const [ savingsAmount, setSavingsAmount] = savingAmountsBag;
 const [ interestAmount, setInterestAmount] = interestAmountsBag;
 const [ transactionHistory, setTransactionHistory ] = useState([]);
 const [ savingTransactions, setSavingTransactions] = useState([]);
 const [ loginDetails, setLoginDetails ] = loginDetailsBag;
 const [ saccos, setSaccos] = useState([]);
 const [ savingTransaction, setSavingTransaction] = useState([]);
 const [ selectedSacco, setSelectedSacco] = useState('');
 const [ startDate, setStartDate] = useState('');
 const [ endDate, setEndDate] = useState('');
 const [ currentBalance, setMyCurrentBalance] = useState('')
 const [ showSearch, setShowSearch] = useState(true);
 const [ open, setOpen] = useState(false);


 let appUser = loginDetails.organisations_id;
 let username = loginDetails.username;

 let transactionHistoryDetails = {};
 let dt = new Date();
 let seconds = dt.getSeconds();
 let minutes = dt.getMinutes(); 
 let hours = dt.getHours();  
 let year = dt.getFullYear();
 let month = dt.getMonth();
     month = month + 1;
 let day = dt.getDate();

     if(seconds < 10){
        seconds = '0'+seconds;
     }

     if(month < 10){
        month = '0'+month;
     }
     if(day < 10){
        day = '0'+day ;
     }
     if(minutes < 10){
        minutes = '0'+minutes ;
     }
     if(hours < 10){
        hours = '0'+hours ;
     }

 
  let headers = seconds+''+minutes+''+hours+''+day+''+month+''+year+''+username;
  let headerBody = parseInt(appUser) + parseInt(seconds);
  let token = headers+''+headerBody;
  

 useEffect( async()=> {
      

        setOpen(!open);
        fetchMySaccos();

    let data = {startDate:'', endDate:'', logonCompanyID :appUser };

         

         const res = await axios.post(baseURL+'bridgeBringMySavingTransactions.php', data ).then(function (response) {


            //  response.data = dataDec(response.data);
                
              setSavingTransactions(response.data);
              setTransactionHistory(response.data);
              setOpen(false);

           }); 

   }, [])



const fetchMySaccos = async()=>{

      setLoading(true);

      let borrowerCompanyID = appUser;
      let selectedLenderCategoryID = "11";


      const fetchSaccoCompanies = async()=>{
            

       const res = await axios.get(baseURL+'bridgeBringMyLenders.php?borrowerCompanyID='+borrowerCompanyID+'&companyCategory='+selectedLenderCategoryID);
          
                 res.data = dataDec(res.data);

                 
                 setSaccos(res.data);
                 setLoading(false); 
                          
    }
           
      fetchSaccoCompanies();
         
}



const handleSaccoChange  = async(event: React.ChangeEvent<HTMLInputElement>) =>{
  
       setLoading(true);
       let totalSavingsAmount = '';
       setSelectedSacco(event.target.value);
       let selectedSacco = event.target.value;   
       setLoading(!true);

}



const searchNew = async() =>{
      
      setShowSearch(!showSearch);
      
}



const searchStatement = async() =>{
  
      
          setLoading(true);
          setShowSearch(!showSearch);
      let toCompanyID = '';   

      for (let i = 0; i < saccos.length; i++) { 

        let saccoCompaniesID = saccos[i].id;
      
        if(selectedSacco == saccoCompaniesID){

              toCompanyID = saccos[i].companies_id;         

        }

   }

 

    let data = {
    
                fromCompanyID: appUser,
                toCompanyID: toCompanyID,
                startDate:startDate,
                endDate:endDate
              };


    const res = await axios.post(baseURL+'bridgeBringMySavingTransactions.php', data ).then(function (response) {

    
    
     // response.data = dataDec(response.data);
      let totalSavedAmount = '';


     if(response.data !== ''){              


                 setTransactionHistory(response.data);      
                 transactionHistoryDetails = response.data; 

                
                 for (let i = 0; i < transactionHistoryDetails.length; i++) { 
                
                       let savedAmount = transactionHistoryDetails[i].amount;      
                       totalSavedAmount = (Number(totalSavedAmount)  + Number(savedAmount)).toFixed(2);

                       if(transactionHistoryDetails.startDate){

                          setStartDate(transactionHistoryDetails.startDate);
                          setEndDate(transactionHistoryDetails.endDate);
                          setMyCurrentBalance(transactionHistoryDetails.currentBalance)

                        }   

                       

                     }

                 setSavingsAmount(totalSavedAmount)
                 setLoading(false); 
              
               }else {

                    alert('Connection error, Please try again later');
                    setLoading(false);
        }

  });

}


if(loading){

        return  (
 
             <Loader/>

           )
    }

  return (    
   <main className = {classes.content}>
       <div className={classes.toolbar}/>
       
           <Grid container direction="column">

              <Grid item className={classes.sidebar}></Grid>

                 <Grid item container spacing={2}>
                  
                  <Grid item xs={false} sm={3} md={3} lg={4}/>
            
                   <Grid item xs={12} sm={6} md={6} lg={4}>
               
                     <Grid container spacing={2}>
                        <Grid item xs={12} >
                            
                            
                          
            {/*START SEARCH COMPONENT*/}            
              {showSearch ?      
                
                <span >      
                  
                 

              <Grid item xs={12} md={12}> 

                   <Typography variant="body1" gutterBottom >                
                      <TextField label="Select Sacco" variant="outlined" fullWidth size="small" select
                          onChange={handleSaccoChange}
                          value={selectedSacco}
                       > 

                          {saccos.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                          ))}
                   
                      </TextField>
                   </Typography>

                   <Typography variant="body1" gutterBottom>
                      <TextField label="Start Date" variant="outlined" size="small"
                                      placeholder="Search Start Date"  
                                      type="datetime-local"
                                      style={{width:'100%'}}
                                      inputFormat="MM/DD/YYYY"
                                      id="startDate"
                                      name="startDate"
                                      onChange={(e)=>setStartDate(e.target.value)}
                                      />
                   </Typography> 
                  
                   <Typography variant="body1" gutterBottom>
                      <TextField label="End Date" variant="outlined" size="small"
                                      placeholder="Search End Date"  
                                      type="datetime-local"
                                      style={{width:'100%'}}
                                      sx={{ width: 1/2 }}
                                      id="endDate"
                                      name="endDate"
                                      onChange={(e)=>setEndDate(e.target.value)}
                                      />
                   </Typography>             

                     
           </Grid>
           <Grid sm={4} lg={4}/>
           <Button className={classes.button} type="button" variant="contained" fullWidth style ={{margin: '5px', textTransaform:'none', backgroundColor: '#155054', color:'white'}} onClick={()=> searchStatement()} > Search Savings History
               </Button>
        </span> 

           :

           <>
           <Grid container item xs={12} direction="column" align="center">  
               <Typography align="center" variant="body1">
                  <b>Savings History </b>
               </Typography> 

               <Button className={classes.button} type="button" variant="contained" fullWidth style ={{margin: '5px', textTransaform:'none', backgroundColor: '#155054', color:'white'}} onClick={()=> searchNew()} > Make New Search
               </Button>
   
            </Grid>
            <span >    
              Start Date: <strong>{startDate}</strong> <b>-</b> To Date:<strong>{endDate}  </strong> 
            </span>

           </>
          }           

         {/*END SEARCH COMPONENT*/}        

         </Grid>
       </Grid>

                
                </Grid>
          
                <Grid item xs={false} lg={4}/>
      </Grid>

      <Grid container spacing={2}>

           {transactionHistory.map((transaction, index)=> (   

                <Grid item  xs={12} sm={6} md={4} lg={4} key={transaction.id} >
       
                      <Card gutterBottom key={index}>                    
                                <CardContent> 
                               
                                      <TransactionHistory transaction={transaction} />
                                
                                </CardContent>
                       </Card> 
       
                  </Grid> 
           ))}  
      </Grid> 
   
    </Grid>
              
  </main>
  );
}
export default SavingsHistory;