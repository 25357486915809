import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const Invoicing = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { baseURL, dataReceiver } = useContext(CartContext);
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  let appUser = loginDetails.organisations_id;
  const [fullname, setFullname] = React.useState([]);
  const [cellnumber, setCellnumber] = React.useState([]);
  const [emailAddress, setEmailaddress] = React.useState([]);
  const [amount, setAmount] = React.useState([]);
  const [dueDate, setDuedate] = React.useState([]);
  const [installment, setInstallment] = React.useState([]);
  const [reference, setReference] = React.useState([]);
  const [userCredits, setUserCredits] = React.useState({});
  const [open, setOpen] = React.useState(false);
  let responseDetailsBag = [];
  const dataDec = dataReceiver;

  const openDebtors = () =>
    history.push({
      pathname: "/invoicestatus",
      responseState: responseDetailsBag,
    });

  useEffect(() => {
    const fetchUserCredits = async () => {
      setOpen(!open);

      const res = await axios.get(
        baseURL + "bridgeBringUserDebtorCredits.php?companyID=" + appUser
      );

      res.data = dataDec(res.data);
      setUserCredits(res.data);
      setOpen(false);
    };

    fetchUserCredits();
  }, []);

  if (userCredits !== "undefined") {
    for (var balance in userCredits) {
      balance = userCredits[0].balance;
      var unitCharge = userCredits[0].unitCharge;
    }
  }

  const createInvoice = () => {
    setOpen(!open);

    let invoice = {
      companyID: appUser,
      debtorName: fullname,
      cellNumber: cellnumber,
      email: emailAddress,
      totalBalance: amount,
      installment,
      theirNarration: reference,
    };

    invoice = JSON.stringify(invoice);

    const res = axios
      .post(baseURL + "bridgeAddUserDebtor.php", invoice)
      .then(function (response) {
        response.data = dataDec(response.data);

        if (response.data !== "") {
          responseDetailsBag = response.data;
          openDebtors();
          setOpen(false);
        } else {
          alert("Error, try again");
        }
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item className={classes.sidebar}></Grid>

        <Grid item container spacing={4}>
          {/*<Grid item xs={12} sm={4} />
              
                   <Grid item xs={12} sm={4}>
                   */}

          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} align="center">
                <Typography align="center" variant="body1">
                  <b>Create Invoice</b>
                </Typography>
              </Grid>
            </Grid>

            <br />

            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>
              <br />

              <Typography
                variant="body2"
                gutterBottom
                style={{ padding: "10" }}
              >
                <span style={{ padding: "5" }}>
                  Unit Balance: E{balance} <br />
                  Unit Charge: E{unitCharge}
                </span>
              </Typography>

              <CardContent>
                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Debtor's Name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Fullname"
                    type="text"
                    id="fullname"
                    name="fullname"
                    onChange={(e) => setFullname(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Cell Number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Cell Number"
                    type="text"
                    id="cellnumber"
                    name="cellnumber"
                    onChange={(e) => setCellnumber(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Email Address"
                    type="text"
                    id="emailAddress"
                    name="emailAddress"
                    onChange={(e) => setEmailaddress(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Amount"
                    type="text"
                    id="amount"
                    name="amount"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Due Date"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Due Date"
                    type="text"
                    id="dueDate"
                    name="dueDate"
                    onChange={(e) => setDuedate(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Installment"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Installment"
                    type="text"
                    id="installment"
                    name="installment"
                    onChange={(e) => setInstallment(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Reference"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Reference"
                    type="text"
                    id="reference"
                    name="reference"
                    onChange={(e) => setReference(e.target.value)}
                  />
                </Typography>

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => createInvoice()}
                >
                  {" "}
                  Add
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>

        {/*
                    INVOICE LIST BELOW FORM
                 */}

        <Grid item container spacing={2}>
          {/*
                        *SECOND CARD FOR GROUPING CONTACTS/ ADDING GROUPS*
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Card >
                                <br/>
                               <Typography align="center" variant="body1"><h5>Groups</h5></Typography>
                               <CardContent>                               
                              <Typography variant="body1" gutterBottom>                
                                  {groupInvoice.fullName} - {groupInvoice.cellNumber} : E {groupInvoice.amount} <DeleteIcon edge="end" style={{color:'red'}}/>
                                  </Typography>   
                               <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} > Upload Group</Button>                                  
                               </CardContent>
                             </Card>
                          </Grid>
                      */}
        </Grid>
      </Grid>
    </main>
  );
};
export default Invoicing;
