import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";

import global_styles from "../../index.module.css";
import local_styles from '../Loans/LoansLandingPage.module.css'
import styles from "../Dashboard/Dashboard.module.css";
import card_styles from "../../components/ServiceCard/ServiceCard.module.css";
import ServiceCard from "../ServiceCard/ServiceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faMoneyBill } from "@fortawesome/free-solid-svg-icons";

export const LoansLandingPage = () => {
  const history = useHistory();
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const checkObjType = history.location.loginState;

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  const openILoan = () => {
    history.push({
      pathname: "/iloan",
    });
  };
  const masibambisane = () => {
    history.push({
      pathname: "/masibambisane",
    });
  };

  return (
    <>
      <div className={styles.showcase}>
        <div className={styles.username + " " + global_styles.container}>
          <p>Welcome, {loginDetails.fullName}</p>
        </div>

        <div
          className={
            styles.slideshow__container + " " + global_styles.container
          }
        >
          <div className={styles.showcase__slider}>
            <div className={styles.slides}>
              <div className={styles.contents}>
                <h2>Loans</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={global_styles.container+ " " + local_styles.service__cards}
        style={{ margin: "10px auto", display: "flex", gap: "15px" }}
      >
        <ServiceCard
          onClick={() => masibambisane()}
          title={"Masibambisane Loans"}
          icon={
            <FontAwesomeIcon
              icon={faAlignLeft}
              className={card_styles.card__icon}
            />
          }
        />
        <ServiceCard
          onClick={() => openILoan()}
          title={"iLoan DFS"}
          icon={
            <FontAwesomeIcon
              icon={faMoneyBill}
              className={card_styles.card__icon}
            />
          }
        />
      </div>
    </>
  );
};

export default LoansLandingPage;
