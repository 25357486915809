import React, { useEffect,useState,useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles';
import { CardHeader,TextField,InputAdornment } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import SavingHistory from './SavingHistory/SavingHistory';
import global_styles from '../../index.module.css'

export const Saving = () => {
  
 const classes = useStyles(); 
 const history = useHistory(); 
 const { loginDetailsBag, baseURL, dataReceiver, savingAmountsBag, interestAmountsBag, totalSavingsBalanceBag, totalInterestBalanceBag, deviceSizeBag }= useContext(CartContext);
 const [ loginDetails, setLoginDetails] = loginDetailsBag;
 const [ savingsAmount, setSavingsAmount] = savingAmountsBag;
 const [ interestAmount, setInterestAmount] = interestAmountsBag;
 const [ open, setOpen] = useState(false); 
 const [ selectedLender, setSelectedLender] = useState([]);
 const [ lenderCategory, setLenderCategory] = React.useState([]);
 const [ membershipNumber, setMembershipNumber] = useState('');
 const [ responseStatus, setResponseStatus] = useState('');
 const [ amount, setAmount] = useState('');
 const [ reference, setReference ] = useState('');
 const [ borrowerLenders, setBorrowerLenders] = useState([]);
 const [ borrowerApprovedAmount, setBorrowerApprovedAmount] = useState(0.00);
 const [ saccoCompanyDetailsBag, setSaccoCompanyDetailsBag] = useState('');
 const [ transactionHistory, setTransactionHistory] = useState([])
 const [ lenderDetails, setLenderDetails] = useState({});
 let fetchedBorrowerLenders = {};
 let savingDetailsResponse = {};
 const borrowerCompanyID = loginDetails.organisations_id;
 let borrowerMoMoAccount = loginDetails.momoAccountNumber;
 const [ deviceSize, setDeviceSize] = deviceSizeBag;
 const [ windowDimensions, setWindowDimensions] = useState(getWindowDimensions());   
    let screenWidth = windowDimensions.width;
    const [ deviceDimentionW, setDeviceDimentionW] = useState('');
 let selectedLenderDetails = {};
 let saccoMemberDetailsBag = {};
 let saccoPaymentDetailsBag = {};
 let responseDetailsBag = {};
 let mySavingsData = {};

 const dataDec  = dataReceiver;
  



 function getWindowDimensions() {
      
       const { innerWidth: width, innerHeight: height } = window;
                
                return {
                     width,
                     height
                 };
   
  }
      




  useEffect(()=> {

        setOpen(!open);
        const selectedLenderCategoryID = '11';
        

const fetchBorrowerLenders = async()=>{
          
       const res = await axios.get(baseURL+'bridgeBringMyLenders.php?borrowerCompanyID='+borrowerCompanyID+'&companyCategory='+selectedLenderCategoryID);
          
        
           res.data = dataDec(res.data);
           setBorrowerLenders(res.data);
           fetchedBorrowerLenders = res.data;
           setOpen(false);
           
    }
           
      fetchBorrowerLenders();
      getTransactionsHistory();

         setDeviceDimentionW(windowDimensions.width);
         window.addEventListener('resize', handleResize);
         return () => window.removeEventListener('resize', handleResize);
        

      
           
   }, []) 




   function handleResize() {

      setDeviceSize(screenWidth)  
      setWindowDimensions(getWindowDimensions());
      
  }




   const getTransactionsHistory = async() =>{


        let data = {startDate:'', endDate:'', logonCompanyID :borrowerCompanyID };

         const res = await axios.post(baseURL+'bridgeBringMySavingTransactions.php', data ).then(function (response) {


            //  response.data = dataDec(response.data);
              let responseItems = response.data
              responseItems = responseItems.slice(0,8)
              setTransactionHistory(responseItems) 

           }); 



   }


const handlerLenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        

    


      setSelectedLender(event.target.value);
      selectedLenderDetails = event.target.value;
      let fromCompanyID = loginDetails.organisations_id;
      let saccoCompanyID = borrowerLenders[0].companies_id;
      let savingTotal = '';
      let mySavingsContainer = '';
      let borrowersID = '';
      





            for (let i = 0; i < borrowerLenders.length; i++) { 

                    let lenderID = borrowerLenders[i].id
                  
                    if(lenderID == selectedLenderDetails){

                        borrowersID = borrowerLenders[i].companies_id;
                        setLenderDetails(borrowerLenders[i]);

                          setSaccoCompanyDetailsBag(borrowerLenders[i]) 

                    }

              }
       

      let data = {fromCompanyID,toCompanyID:borrowersID};


         const res = axios.post(baseURL+'bridgeGetSaccoMemberSavingTransactions.php',data ).then(function (response) {
      
         response.data = dataDec(response.data);  

         if(response.data !== ''){
                
          
           mySavingsData = response.data;      
          let sumSavings = 0;  
          
               for (let i = 0; i < mySavingsData.length; i++) { 

                         sumSavings+= (+mySavingsData[i].amount);
        
                //       let savingss = mySavingsData[i].currentBalance;
                //       let currSaving = savingss+ currSaving;
                //       setSaccoCompanyDetailsBag(response.data)
                //       savingTotal = mySavingsContainer[0].currentBalance;
                //       mySavingsContainer = response.data;
                //       setSavingsAmount(savingTotal);
                //       setBorrowerApprovedAmount(savingTotal);

                     

                 }     

            setBorrowerApprovedAmount(sumSavings)      
            setSavingsAmount(sumSavings)    
             
          
              }else{

              alert('Error, try again') 
               
           }   
       
  });  

   

       setOpen(false); 
  }
 

 const goToConfirmSaving = () => history.push({ 
      
        pathname: '/confirmsavings',
        saccoMemberDetailsState:saccoMemberDetailsBag,
        saccoPaymentDetailsState:saccoPaymentDetailsBag,
        saccoCompanyDetailsState:saccoCompanyDetailsBag
    
});


   const checkObjType = history.location.loginState;
   
   if (typeof checkObjType  !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
         setLoginDetails(history.location.loginState);
         history.push({pathname: '/', state: {} });
          
      }




 const openHistory = () => history.push({
    
    pathname: '/savingshistory'
    

});     



const  submitSavingDetails = () => {
    
     setOpen(!open);
     let lendersID = lenderDetails.id;
     let lendersCategory = lenderDetails.companyCategories_id;
    

    
   const getSaccoMemberDetails = async()=>{
          


      saccoPaymentDetailsBag = {amount, reference};

     const res = await axios.get(baseURL+'bridgeBringSaccoMemberDetails.php?lenderID='+lendersID+'&membershipNumber='+membershipNumber+'&lenderMemberCompanyID='+borrowerCompanyID);
           
                                      
            saccoMemberDetailsBag = dataDec(res.data);

            if(saccoMemberDetailsBag !== ''){

                goToConfirmSaving();
                setOpen(false);
                  
              
              }else{

                       alert('Error');
                       setOpen(false);

               }  

      }
      
      getSaccoMemberDetails();
 


}

  return (
    
      <main className = {classes.content+ " " + global_styles.container}>
            <div className={classes.toolbar}/>
   
            <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={2}>
                        
                       

                       <Grid item xs={false} sm={false} md={6} lg={4}>





                     {screenWidth > '960' ?
                        <>
                            <Card >
   
                               <CardContent>
                                

                                 <Typography align="center" variant="h6">
                              Savings History
                          </Typography>
                          <hr />
                            
                            {transactionHistory.map((transaction)=> (
                             
                                  <SavingHistory transaction={transaction} />
                              
                             ))} 

                               <Button style ={{ textTransaform:'none', backgroundColor: '#155054', color:'white', width:'93%', margin:'18px', justify:'center'}}  className={classes.button} type="button" variant="contained" onClick={()=> openHistory()} > 
                               View More
                               </Button>
                          

                       </CardContent>
                    </Card>
                    </>
                    :

                    <>

                    <Grid item xs={false} sm={false} md={4} lg={4}>
                    
                    </Grid>
                    </>

                    }


                     </Grid>

                       <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                        </Backdrop>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                 <Card gutterBottom >

                       <CardContent>

                           <CardHeader title="Savings / Yonga"  align="center" />
                                    <span gutterBottom>
                                          You last saved E {borrowerApprovedAmount}
                                    </span> 
                         
                          
                          <Typography variant="h4" gutterBottom>
                                <TextField label="Lenders" variant="outlined"  fullWidth size="small" select 
                                  onChange={handlerLenderChange}
                                  value={selectedLender}                               
                                  >                        
                                    
                                    {borrowerLenders.map((option) => (
                                   <MenuItem key={option.id} value =  {option.id}  >
                                      {option.companyName} 
                                   </MenuItem>
                                   ))}
                                  </TextField>
                                </Typography>

                                <Typography variant="h4" gutterBottom>
                                      <TextField label="Membership Number" variant="outlined" fullWidth size="small"
                                      placeholder="Membership Number"  
                                        type="text"
                                       id="fullName"
                                       name="fullName"
                                       onChange={(e)=>setMembershipNumber(e.target.value)}
                                                                           
                                      />
                                      </Typography>


                                <Typography variant="h4" gutterBottom>
                                      <TextField label="Amount" variant="outlined" fullWidth size="small"
                                      placeholder="Amount"  
                                        type="text"
                                       id="amount"
                                       name="amount"
                                       onChange={(e)=>setAmount(e.target.value)}
                                         
                                      />
                                      </Typography>



                                 <Typography variant="h4" gutterBottom>
                                      <TextField label="Reference" variant="outlined" fullWidth size="small"
                                      placeholder="Reference"  
                                        type="text"
                                       id="amount"
                                       name="amount"
                                       onChange={(e)=>setReference(e.target.value)}
                                         
                                      />
                                      </Typography>     

         <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{margin: '5px', textTransaform:'none', backgroundColor: '#155054', color:'white'}} onClick={()=> submitSavingDetails()} > Submit
          </Button>

          </CardContent>
                         

                      </Card>
                        </Grid>
          
                <Grid item xs={false} sm={false} md={4} lg={4}/>
    </Grid>
  </Grid>
</main>

  );
}

export default Saving;