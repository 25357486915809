import React, { useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { CardHeader} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';


export const LendingStatus = () => {
  
 const classes = useStyles(); 
 const history = useHistory();
 const { loginDetailsBag }= useContext(CartContext);
 const [loginDetails, setLoginDetails] = loginDetailsBag;

 const loanStatusResponse = history.location.loanRequestState; 

 const checkObjType = history.location.loginState;

      if (typeof checkObjType  !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
          setLoginDetails(history.location.loginState);
         history.push({pathname: '/', state: {} });
         

      }



       const submitingComplete = () => history.push({ 
      
             pathname: '/loans'

        });

  return (
    
      <main className = {classes.content}>
            <div className={classes.toolbar}/>
   
            <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                              
                               <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>                       

                       {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/} 
                            <Card >
                                
                               <CardContent>
                
                                  <CardHeader title="Loan Request Status"  align="center" />
                                 
                <Typography variant="body1" gutterBottom>                           
                             Status: <b>{loanStatusResponse.res}</b>
                </Typography>

                                

                                 


                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none'}} onClick={()=> submitingComplete()} > Done</Button>

                                 
                           </CardContent>
                         </Card>

                        </Grid>
          
                    <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>




  </main>

  );
}

export default LendingStatus;