import React, { useEffect,useContext, useState } from 'react';
import {Card, CardContent,  Typography, TextField, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../../Login/styles'; 
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../../Context/CartContext';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';


export const PocketRecharge = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver }= useContext(CartContext);
       const dataDec  = dataReceiver;
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ paymentPlatforms, setPaymentPlatforms] = useState([]);
       const [ paymentPlatform, setPaymentPlatform] = useState('');
       const [ open, setOpen] = useState(false);
       const [ events, setEvents] = useState([]); 
       const [ fullName, setFullname] = useState('');
       const [ contact, setContact] = useState('');
       const [ cellNumberRef, setCellNumberRef] = useState('');
       const [ fromAccount, setFromAccount] = useState('');
       const [ event, setEvent] = useState('');   
       const [ amount, setAmount] = useState('');
       const [ reference, setReference] = useState('');
       const [ pocketIdentifier, setPocketIdentifier] = useState('');
       const [ error, setError] = useState('');  
       const [ useActivity, setUserActivity] = useState('');
       const [ isePayNetActive, setIsePayNetActive] = useState(false);
       const [ userOptions, setUserOptions] = useState([{id:'1', name:'ePayNet'},
                                                      {id:'2', name:'Other'}]);
       const [ selectedUserOption, setSelectedUserOption] = useState('');                                               
       let voucherDetailsBag = {}; 
       let responseDetails = {};   
       let newPocket = {};                  
       const rechargeDetails = history.location.rechargeDetailsState;                        




  useEffect(()=> {
          
          getEvents();
          getPaymentPlatforms();
          
        }, [])
 


  const getEvents = async()=>{
      
       const res = axios.post(baseURL+'bridge_evPoc_GetEvents.php').then(function (response) {
        
          response.data = dataDec(response.data);
          setEvents(response.data);

        });

 } 


  const getPaymentPlatforms = async()=>{
      
       const res = axios.post('https://www.digimagesystem.com/iEventPocket/api/getEpayNetPaymentPlatforms.php' ).then(function (response) {
        
          setPaymentPlatforms(response.data);

        });

 } 



  const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setPaymentPlatform(event.target.value);
      let platformID = event.target.value

}


const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setEvent(event.target.value);
       
  }


 const handleUserOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setSelectedUserOption(event.target.value);
      let selectedOption = event.target.value;

       if(selectedOption === '1'){

          setIsePayNetActive(true);
      
       }else{ 

          setIsePayNetActive(!true);          
      
       }

}


const checkRechargeVoucher = async() =>{
          

     let loggedDetailsLength = Object.keys(loginDetails).length;
             

  if(loggedDetailsLength <= 0){
          
      setError('Please Login on ePayNet to Top Up');

      }else{

         //Select the event, if is ePayNet, get ePayNet Details, else prompt for details 
        getCreatedPocketDetails();  
        
      }     

 }



const goToTransactionStatus = () => history.push({
    
    pathname: '/pocketrechargestatus',
    responseMessageState:responseDetails
    
});
 

const rechargeVoucher = async()=>{
    
      let eventsID = rechargeDetails.eventsID;
      let cellRef = rechargeDetails.cellNumberRef;  
      let companyID = loginDetails.organisations_id


  let data = {fullName:voucherDetailsBag.fullName, cellNumber:voucherDetailsBag.cellNumber, cellNumberRef:pocketIdentifier, eventsID:event, ePayNetCompanyID:companyID, amount:amount, localEftVendorID:paymentPlatform, fromAccount:fromAccount }


    const res = axios.post(baseURL+'bridge_evPoc_ReceiveAttendeeRechargeUnitsTransaction.php', data).then(function (response) {
            

                  response.data = dataDec(response.data);
                  responseDetails = response.data

              if(responseDetails){

                  goToTransactionStatus()

              }
     
       });
         
}



const getPocketDetails = () =>{
        

      let eventsID = rechargeDetails.eventsID;
      let cellRef = rechargeDetails.cellNumberRef;
        
                  {/*GET THE CREATED POCKET DETAILS*/}
    let myCreds = {eventsID:eventsID, cellNumberRef:cellRef};


    const res = axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php', myCreds ).then(function (response) {
               
               response.data = dataDec(response.data); 
           let responseData = response.data;
          
    });

}


const getCreatedPocketDetails = () =>{
    
    {/*GET THE CREATED POCKET DETAILS*/}
    let eventsID = event;
    let cellRef = cellNumberRef;              

    let data = {eventsID:event, cellNumberRef:pocketIdentifier};


    const res = axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php', data ).then(function (response) {

            response.data = dataDec(response.data);  
            newPocket = response.data
            let mycontainer = Object.keys(newPocket).length;
            
           

   if(mycontainer <= 0){

        
          setError('Voucher has not been assigned to anyone on this event, please activate the voucher first');

            return 0;

            }else{
                            
              goToViewPocket();
            
        }
           
    });

}

const goToViewPocket= () => history.push({
    
    pathname: '/pocketgenerateview',
    createdPocketState:newPocket
    
});



 const goToRecharge = () => history.push({
    
    pathname: '/pocketgenerateview',
    createdPocketState:voucherDetailsBag
    
});

 const goToDashboard = () => history.push({
    
    pathname: '/eventsdashboard'
    
});



  return (

     <main className = {classes.content}>
       <div className={classes.toolbar}/>
       
    <Grid container direction="column" style= {{margin: '5px'}}>

     
         <Grid item container spacing={1} style={{height: "280px" }}>
                
                 <Grid item xs={false} sm={4} md={4} lg={4}/> 

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                       

                        <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>
                
                           <Grid container spacing={1}>
                              <Grid item xs={12} align="center">
                                 {error ?
                                  <span style={{color:'red'}}><b>{error}</b></span>
                                  :
                                    <>
                                      <Typography align="center" variant="body1">
                                               <b> Top Up Voucher </b>
                                      </Typography>
                                    </>
                                  }

                                     
                                   </Grid>
                                 </Grid>
                               <hr /> 
                            
                            
        {!useActivity ?             

          <CardContent >
                        <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Event" variant="outlined" fullWidth size="small" select
                                  onChange={handleEventChange}
                                  value={event}
                                  > 

                                    {events.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.eventName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                        <Typography variant="h4" gutterBottom>
                            <TextField label="Voucher Number" variant="outlined" type="text" fullWidth size="small"  placeholder="Voucher Number " 
                                onChange={(e)=>setPocketIdentifier(e.target.value)}
                                      />
                        </Typography>  
                          


                       

            <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> checkRechargeVoucher()}>
                 Submit
                </Button>
          </CardContent >

      :
      

              <CardContent >
                        <Typography variant="body2" gutterBottom>                
                                 <TextField label="Select Event" variant="outlined" fullWidth size="small" select
                                  onChange={handleEventChange}
                                  value={event}
                                  > 

                                    {events.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.description}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

          <Typography variant="h4" gutterBottom>
              <TextField label="Top-Up Amount" variant="outlined" type="text" fullWidth size="small"  value={amount} 
                                
                                      />
          </Typography> 
       
     
        

          <Typography variant="h4" gutterBottom>
              <TextField label="Account Number" variant="outlined" type="text" fullWidth size="small" placeholder="Account" 
              onChange={(e)=>setFromAccount(e.target.value)}           
                                      />
          </Typography> 


          <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Payment Platform" variant="outlined" fullWidth size="small" select
                                  onChange={handleBankChange}
                                  value={paymentPlatform}
                                  > 

                                    {paymentPlatforms.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.description}
                                   </MenuItem>

                                   ))}
                               </TextField>
          </Typography>

     

              <Typography variant="h4" gutterBottom>
                  <TextField label="Voucher" variant="outlined" type="text" fullWidth size="small"  placeholder="Voucher Number"  
                  onChange={(e)=>setPocketIdentifier(e.target.value)} />
            </Typography>

          </CardContent >        

       }   

           </Card>
        </Grid>               
     </Grid>
   </Grid>

  </main>

  );
}

export default PocketRecharge;