import React, { useEffect,useContext,useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem, List} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext'; 
import AddedRecipients from './AddedRecipients';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import Loader from '../Loader/Loader';


export const AddRecipient = () => {
        
       const [loading, setLoading] = useState(false); 
       const [open, setOpen] = useState(false);
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext); 
       const [ loginDetails, setLoginDetails ] = loginDetailsBag;
       let appUserID = loginDetails.organisations_id;
       const [categories, setCategories ] = React.useState([]);
       const [organisations, setOrganisations] = React.useState([]);
       const [selectedCategory, setSelectedCategory ] = React.useState([]);
       const [selectedOrganisation, setSelectedOrganisation ] = React.useState([]);
       const [companyName, setCompanyName ] = React.useState([]);
       const [myRecipients, setMyRecipients] = React.useState([]);
       const dataDec  = dataReceiver; 
       let selectedCategorysID = '';                   
                                      
                        
  useEffect(()=> {
      
    
      setOpen(!open);
      const fetchRecipients = async()=>{
            
            
            const res = await axios.get(baseURL+'bridgeBringCompanyCategories.php?apiUserDetails='+appUserID);
          
          res.data = dataDec(res.data);

      
          if(res.data !== ''){
               
                setCategories(res.data);
               
                setOpen(false);

          }else{

             alert('Connection error, Please try again later');
             setOpen(false);
          
          }
          
      }


    const fetchMyRecipients = async()=>{
          
           const res = await axios.get(baseURL+'bridgeMyCompanyEnrollment.php?logonCompanies_id='+appUserID);
            
            res.data = dataDec(res.data);

         
          if(res.data !== ''){
              
          setMyRecipients(res.data);      
        
 
          }else{

             alert('Connection error, Please try again later');
          }
          
      }


  
      fetchRecipients();
      fetchMyRecipients();
     
   }, [])



  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setOpen(!open);
          setSelectedCategory(event.target.value);
          selectedCategorysID = event.target.value;


        const fetchOrganisation = async()=>{
    
              const res = await axios.get(baseURL+'bridgeSelectedCategoryCompanies.php?companyCategories_id='+selectedCategorysID);
              
              res.data = dataDec(res.data);
              
              setOrganisations(res.data);
              setOpen(false);
       }
        
      fetchOrganisation();

  };

 
const handleOrganisationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      
      setSelectedOrganisation(event.target.value);

    
 };


const AddRecipient = () => {
  
  setOpen(!open);

  const addNewRecipient = async()=>{
    
        const res = await axios.get(baseURL+'bridgeCompanyEnrollment.php?enrollCompanies_id='+selectedOrganisation+'&logonCompanies_id='+appUserID);
        
        res.data = dataDec(res.data);
        
        setMyRecipients(res.data);
        setOpen(false);

      }

      addNewRecipient();
}

  
  return (  
      <main className = {classes.content}>
            <div className={classes.toolbar}/>

           <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
 
                      <Grid item xs={false} sm={2} md={3} lg={4}/>
            
                      <Grid item xs={12} sm={8} md={6} lg={4}>



                            <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                              </Backdrop>


              <CardHeader title="Add recipient"  align="center" />
                    <CardContent>
                      
                    <Typography variant="h4" gutterBottom>                
                                 <TextField label="Select Category" variant="outlined"  fullWidth size="small" select
                                 onChange={handleCategoryChange}
                                 value={selectedCategory} >

                                    {categories.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                          {option.categoryName} 
                                        </MenuItem>
                                    ))}
                               </TextField></Typography>

                    <Typography variant="h4" gutterBottom>                
                                 <TextField label="Select Organisation" variant="outlined"  fullWidth size="small" select
                                 onChange={handleOrganisationChange}
                                 value={selectedOrganisation} >

                                    {organisations.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.companyName} 
                                   </MenuItem>
                                   ))}
                               </TextField></Typography>           
                    
                               
                           <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}}  onClick={()=> AddRecipient()} > Add
                           </Button>
                       </CardContent>
                         </Card>
                             
                         <hr style={{border:'1 black'}}/> 
                             
                      <CardHeader title="Recipients"  align="center" />
                        
                        <List>
                          
                             {myRecipients.map((recipient)=> ( 
                              <Grid item key={recipient.id}> 
                               <Divider style={{border:'1 black'}}/>  
                                  <AddedRecipients recipient={recipient}/>
                                 <Divider style={{border:'1 black'}}/>   
                              </Grid>
                              ))}
                        </List>  
                            
                       
                          

                        </Grid>
          
                    <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>







  </main>

  );
}

export default AddRecipient;