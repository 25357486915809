import React, { useEffect,useContext, useState } from 'react';
import {Card, CardContent,InputAdornment, Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../../Context/CartContext';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';



export const PocketGenerate = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver }= useContext(CartContext);
       const [ isActive, setIsActive] = useState(false);
       const [ isOnEpaynet, setIsOnEpaynet] = useState(true);
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const dataDec  = dataReceiver;
       const [ open, setOpen] = useState(false);
       const [ events, setEvents] = useState([]); 
       const [ fullName, setFullname] = useState('');
       const [ contact, setContact] = useState('');
       const [ cellNumberRef, setCellNumberRef] = useState('');
       const [ eventDetails, setEventDetails] = useState([]);
       const [ event, setEvent] = useState('');   
       const [ error, setError] = useState('');
       const [ success, setSuccess] = useState(''); 
       const [ reference, setReference] = useState('');
       const [ pocketCreated, setPocketCreated] = useState(''); 
       const [ amount, setAmount] = useState('');
       const [ values, setValues] = useState({password: "", showPassword: false});
       let newPocket = {};                     
       let voucherResponse = {};
       const [ pocketExist, setPocketExist] = useState(false);
       const [ pocketDetails, setPocketDetails] = useState({}) ;
       const [ isFocused, setIsFocused] = useState(true);
     



       useEffect(()=> {
          
          getEvents();
     
          }, [])
 


  const getEvents = async()=>{
      
       const res = axios.post(baseURL+'bridge_evPoc_GetEvents.php' ).then(function (response) {
          
          response.data = dataDec(response.data);        
          setEvents(response.data);

        });

 } 





const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setEvent(event.target.value);
      let eventID = event.target.value

      for (let i = 0; i < events.length; i++) { 
        let allEventsBagID = events[i].id

           if(eventID === allEventsBagID){

                  setEventDetails(events[i]);

              }
        }
  }

 
const checkForVoucher = async()=>{
  
  let data = {eventsID:event, cellNumberRef:cellNumberRef, event:eventDetails.eventName}
         
           /*CHECK IF POCKET EXIST */
           
  const res = await axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php',data ).then(function (response) {
        
                response.data = dataDec(response.data);

                let myDetails = response.data;
                let mycontainer = Object.keys(myDetails).length;

              if(mycontainer > 0){
                  

                setError('This Voucher Referencer has already been used for this event');      
                         
               }else{

                setPocketDetails(data)
              // if voucher don't exist go to create new add event + cellRef On Next form
                  setPocketExist(!false);

            }   
                      
       });  
      
}


const getCreatedPocketDetails = () =>{
    
    {/*GET THE CREATED POCKET DETAILS*/}
    let eventsID = event;
    let cellRef = cellNumberRef;              

    let myCreds = {eventsID:eventsID, cellNumberRef:cellRef};


    const res = axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php', myCreds ).then(function (response) {

            response.data = dataDec(response.data);  
            newPocket = response.data
            goToViewPocket();
               
    });

}



const createPocket = async()=>{
    
      let eventsID = pocketDetails.eventsID;
      let cellRef = pocketDetails.cellNumberRef;
      let companyID = loginDetails.organisations_id;
      
      let data = {fullName:fullName, 
                  cellNumber:contact, 
                  email:reference, 
                  cellNumberRef:cellRef, 
                  eventsID:eventsID, 
                  ePayNetCompanyID:companyID};


         
          /*GENERATE NEW VOUCHER*/

    const res = axios.post(baseURL+'bridge_evPoc_CreateEventAccount.php', data ).then(function (response) {
            
               response.data = dataDec(response.data);
               voucherResponse = response.data                
               setSuccess(response.data.description);
               let responseCode = response.data.code;
                
              if(responseCode === '200'){
       
                              setSuccess(response.data.description);
                              
                              getCreatedPocketDetails();
                              alert(response.data.description);
                              
              }else{  

                setError(response.data.description)

              }     

  });
         
}





 const goToDashboard = () => history.push({
    
    pathname: '/eventsdashboard'
    
});

const goToViewPocket= () => history.push({
    
    pathname: '/pocketgenerateview',
    createdPocketState:newPocket,
    voucherMessageState:voucherResponse
    
});



  return (

     <main className = {classes.content}>
       <div className={classes.toolbar}/>
       
    <Grid container direction="column" style= {{margin: '5px'}}>

     
         <Grid item container spacing={1} style={{height: "280px" }}>
                
                 <Grid item xs={false} sm={4} md={4} lg={4}/> 

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                
                        <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>
                
                                 <Grid container spacing={1}>
                                   <Grid item xs={12} align="center">
                                    {error ?
                              <span style={{color:'red'}}><b>{error}</b>

                               
                              </span>
                                      :

                                      <>

                                        <Typography align="center" variant="body1">
                                           <b> Request Voucher </b>
                                        </Typography>

                                      </>
                                    }

                                     
                                   </Grid>
                                 </Grid>
                               <hr /> 
                             

                {!pocketExist ?               
                    <CardContent>
                       <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Event" variant="outlined" fullWidth size="small" select
                                  onChange={handleEventChange}
                                  value={event}
                                  > 

                                    {events.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.eventName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                          <Typography variant="h4" >
                                  <TextField label="Voucher " variant="outlined" type="text" fullWidth size="small" placeholder="Voucher"
                                
                                  onChange={(e)=>setCellNumberRef(e.target.value)}
                                />
                        </Typography>
           
                    <Typography variant="body1" gutterBottom >                
                       <span >
                          <b style={{color:'red', fontSize:'10px'}} >
                            * eg. Secret Key, Voucher, Pet Name, Nickname
                          </b>
                       </span>
                    </Typography>
               
                   {/*CHECK IF VOUCHER EXIIST*/}     
             <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> checkForVoucher()}>    
                                 Submit Request
             </Button>        

             </CardContent>

              :

              <>
                <CardContent> 

                 <Typography variant="body2" gutterBottom>
                                  Voucher Number <b>:****
                         {/* {pocketDetails.cellNumberRef}*/}</b>
                        </Typography>

   

                        <Typography variant="body2" gutterBottom>
                           Event <b>:{eventDetails.eventName}
                                      </b>
                        </Typography>


                        <Typography variant="h4" gutterBottom>
                                <TextField label="Full Name" variant="outlined" type="text" fullWidth size="small"  placeholder="Full Name" 
                                      onChange={(e)=>setFullname(e.target.value)}
                                      />
                        </Typography>

                          
                        <Typography variant="h4" gutterBottom>
                            <TextField label="Contact" variant="outlined" type="text" fullWidth size="small"  placeholder="Cell Number" 
                                onChange={(e)=>setContact(e.target.value)}
                                      />
                        </Typography>


                        <Typography variant="h4" gutterBottom>
                            <TextField label="Email" variant="outlined" type="text" fullWidth size="small"  placeholder="Email Address" 
                                onChange={(e)=>setReference(e.target.value)}
                                      />
                        </Typography>

      
                <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> createPocket()}>
                  Submit
                </Button>

          </CardContent>
       </>   
    }
     </Card>




                  </Grid>
               
                 </Grid>

              </Grid>

  </main>

  );
}

export default PocketGenerate;