import React, { useEffect,useContext, useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


export const ConfirmCompanyTransaction = () => {
	
       const classes = useStyles(); 
       const history = useHistory();  
       const { baseURL, dataReceiver } = useContext(CartContext);
       const { loginDetailsBag }= useContext(CartContext);
       const [loginDetails, setLoginDetails] = loginDetailsBag;
       const [ open, setOpen] = useState(false);
       const [paymentPlatforms, setPaymentPlatforms] = React.useState([]);
       const [responseStatus, setResponseStatus] = React.useState([]);
       const [selectedPaymentPlatform, setSelectedPaymentPlatform] = React.useState([]);
       let momoAccountNumber = loginDetails.momoAccountNumber;
       let eMaliAccountNumber = loginDetails.eMaliAccountNumber;
       const account = loginDetails.momoAccountNumber;
       let selectedPlatform = '';
       let data2 = {};
       let data = {};
       let bankTransactionDetailsBag = {};
       const dataDec  = dataReceiver;
 
  const beneficiaryMomoDetailsBag = history.location.beneficiaryMomoState;
  

  useEffect(()=> {

        setOpen(!open);

      const fetchPaymentPlatform = async()=>{
          
          const res = await axios.get(baseURL+'bridgeGetMobileMoneyVendors.php');
          
        
          res.data = dataDec(res.data);

          setPaymentPlatforms(res.data);
          setOpen(false);      

      }

      fetchPaymentPlatform();
     
   }, [])


  
const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedPaymentPlatform(event.target.value);
    selectedPlatform = event.target.value;
    
   
  return selectedPlatform;

  };  



      const min = 100;
      const max = 999;
      const rand =  Math.floor(Math.random() * (max - min + 1) ) + min;
      const randAccountNumber = `${rand}${account}`;


 function d2h(d) {
  return d.toString(16);
}
 function h2d (h) {
    return parseInt(h, 16);
}
 function stringToHex (tmp) {
    let str = '',
        i = 0,
        tmp_len = tmp.length,
        c;
 
    for (; i < tmp_len; i += 1) {
        c = tmp.charCodeAt(i);
        str += d2h(c) + '';
    }
    return str;
}

      const rand3hexAccountNumber = stringToHex(randAccountNumber);
 
      const submitPayment = () =>{

      setOpen(!open);  
      let dt = new Date();
      let seconds = dt.getSeconds();
      let minutes = dt.getMinutes();
      let companyCategory_id = beneficiaryMomoDetailsBag.companyCategory;
      let companyCategoryCategories_id = beneficiaryMomoDetailsBag.companyCategoryCategories;
      
      let amount= beneficiaryMomoDetailsBag.amount;
      let reference= beneficiaryMomoDetailsBag.reference;
      let token = minutes+''+momoAccountNumber+''+seconds;
      let appUser = beneficiaryMomoDetailsBag.userKey;
      let paymentPlatform = selectedPaymentPlatform;
      let username = loginDetails.username;
      let toCompanyID = beneficiaryMomoDetailsBag.toCompany;

data2 = { 
      companyCategory_id, companyCategoryCategories_id,
      amount, reference, toCompanyID, token, username, paymentPlatform, 
      companyID:appUser,  eMaliAccountNumber, momoAccountNumber, 
      destination_platform_id:'5'
    }


 data = JSON.stringify(data2);

    const res = axios.post(baseURL+'bridgeReceiveOtherCompaniesPayments.php', data ).then(function (response) {
    

     response.data = dataDec(response.data);

   //  response.data = JSON.parse(atob((response.data).slice(0,-2).split("").reverse().join("") + (response.data).slice(-2)));
     
     setOpen(false); 
     if(response.data !== ''){
        
       bankTransactionDetailsBag = response.data ;
       goToPaymentStatus();
       setOpen(false);

     }else{

          alert('Connection error, Please try again later.'); 
          setOpen(false);
     }   
       
  });
}


const goToPaymentStatus = () => history.push({ 

      pathname: '/banktransactionstatus',
      bankTransactionState: bankTransactionDetailsBag

});


	return (
  
      <main className = {classes.content}>

            <div className={classes.toolbar}/> 
        <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
                         <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                             </Backdrop>
                                
                               <CardContent>
           <Typography align="center" variant="body1">
                 <h5>Confirm Transaction</h5>
           </Typography>
                                 
                          <Typography variant="body1" gutterBottom>                
                                 
                            Daily Available Limit: <b>E{loginDetails.available}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Monthly Available Limit: <b>E{loginDetails.monthlyAvailableLimit}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Recipient :{beneficiaryMomoDetailsBag.CompanyName} <b></b>
                          </Typography>



                      <Typography variant="body1" gutterBottom>                
                                 
                         Reference :{beneficiaryMomoDetailsBag.reference} <b></b>
                      </Typography>

                          

                      <Typography variant="body1" gutterBottom>                
                                 
                         Total Balance:<b>E{beneficiaryMomoDetailsBag.amount} </b>
                      </Typography>

                      <Typography variant="body1" gutterBottom>                
                            <TextField label="Payment Platform" variant="outlined" fullWidth size="small" select
                                 onChange={handleChange}
                                 value={selectedPaymentPlatform}> 

                                    {paymentPlatforms.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.vendorName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>


                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> submitPayment()} > Submit</Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                    <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>
    </main>

	);
}



export default ConfirmCompanyTransaction;