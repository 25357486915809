import React, { useEffect, useState, useContext } from "react";
import useStyles from "../Login/styles";
import { useHistory, Link } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";

import global_styles from "../../index.module.css";
import card_styles from "../../components/ServiceCard/ServiceCard.module.css";
import styles from "../Dashboard/Dashboard.module.css";
import section_styles from "../Masibambisane/Masibambisane.module.css";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeftRotate,
  faArrowsRotate,
  faFileInvoice,
  faHandHoldingHand,
} from "@fortawesome/free-solid-svg-icons";

export const EventHome = () => {
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [open, setOpen] = useState(false);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const dataDec = dataReceiver;
  const [cellNumberRef, setCellNumberRef] = useState("");
  const [events, setEvents] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [event, setEvent] = useState("");
  const [error, setError] = useState("");
  const loggedInUserDetails = history.location.loginState;
  const [notOnEpaynet, setNotOnePaynet] = useState(false);

  let pocketDetailsBag = {};
  let rechargeDetailsBag = {};
  {
    /*THIS WILL BE DEFINED ON LOGIN, CHECK IF USER HAS ACTIVE POCKET*/
  }
  const [hasActivePocket, setHasActivePocket] = useState(false);

  useEffect(() => {
    if (Object.keys(loginDetails).length > 0) {
      //alert(loggedUser);
    } else {
      setNotOnePaynet(true);
      //prompt('');
    }

    setOpen(!open);
  }, []);

  /*
const obj = { a: 1, b: 2, c: 3 };
obj.__proto__.d = 4
for (const key of Object.keys(obj)) {
    console.log(key);
}
// Output:
// a
// b
// c
*/

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEvent(event.target.value);
    let eventID = event.target.value;

    for (let i = 0; i < events.length; i++) {
      let allEventsBagID = events[i].id;

      if (eventID === allEventsBagID) {
        setEventDetails(events[i]);
      }
    }
  };

  const goToCreatePocket = () =>
    history.push({
      pathname: "/pocketgenerate",
    });

  const resetPin = () =>
    history.push({
      pathname: "/resetpin",
    });

  const checkBalance = () =>
    history.push({
      pathname: "/checkbalance",
    });

  const goToRechargePocket = () =>
    history.push({
      pathname: "/pocketrecharge",
      rechargeDetailsState: rechargeDetailsBag,
    });

  const disbursement = () =>
    history.push({
      pathname: "/eventsdashboard",
    });

  const openRollBack = () =>
    history.push({
      pathname: "/rollback",
    });

  const loginToEpaynet = () =>
    history.push({
      pathname: "/",
    });

  const registerOnEpayNet = () =>
    history.push({
      pathname: "/Registration",
    });

  const learnMore = async () => {
    let data = { moduleID: "1" };
    //'http://www.salvtec.co.sz/support/knowledgebase.php?category=',data
    //
    const res = await axios
      .post(baseURL + "bridgeGetModuleWebsiteUrl.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);

        let url = response.data[0].moduleUrl;
        window.open(url, "_blank");
        setOpen(false);
      });
  };

  const createPocket = async () => {
    let data = {
      eventsID: event,
      cellNumberRef: cellNumberRef,
      event: eventDetails.eventName,
    };

    goToCreatePocket();
    pocketDetailsBag = data;

    /*CHECK IF POCKET DOESN'T EXIST 
  const res = await axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php',data ).then(function (response) {
        
                response.data = dataDec(response.data);
                let pocketDetails = response.data;
                let mycontainer = Object.keys(pocketDetails).length;
           
           if(mycontainer > 0){
                setError('This Pocket Identifier has already been used for this event');      
               }else{
                 goToCreatePocket() 
            }

       }); 
     */
  };

  const topUp = async () => {
    let data = {
      eventsID: event,
      cellNumberRef: cellNumberRef,
      event: eventDetails.eventName,
    };
    rechargeDetailsBag = data;
    goToRechargePocket();
  };

  return (
    <>
      <div className={styles.showcase}>
        <div
          className={
            styles.slideshow__container + " " + global_styles.container
          }
        >
          <div className={styles.showcase__slider}>
            <div className={styles.slides}>
              <div className={styles.contents}>
                <h2>Cashless Events</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          section_styles.main__content__area + " " + global_styles.container
        }
      >
        <div className={section_styles.side__nav}>
          {notOnEpaynet ? (
            <>
              <Link to="#" onClick={() => loginToEpaynet()}>
                Login to ePayNet
              </Link>
              <Link to="#" onClick={() => registerOnEpayNet()}>
                Create an ePayNet Account
              </Link>
              <Link onClick={() => learnMore()}>What are Cashless Events?</Link>

              <div className={section_styles.help}>
                <FontAwesomeIcon icon={faQuestionCircle} />
                <Link to="#" onClick={() => learnMore()}>
                  Help
                </Link>
              </div>
            </>
          ) : (
            <>
              <Link to="#" onClick={() => learnMore()}>
                Help
              </Link>
            </>
          )}
        </div>
        <div className={section_styles.service__cards}>
          {error ? <p style={{ textAlign: "center" }}>{error}</p> : <></>}
          <ServiceCard
            style={{ width: "30%" }}
            onClick={() => createPocket()}
            title={"Create a Voucher"}
            icon={
              <FontAwesomeIcon
                icon={faPlayCircle}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            style={{ width: "30%" }}
            onClick={() => topUp()}
            title={"Top Up"}
            icon={
              <FontAwesomeIcon
                icon={faHandHoldingHand}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            style={{ width: "30%" }}
            onClick={() => disbursement()}
            title={"Use Existing Voucher"}
            icon={
              <FontAwesomeIcon
                icon={faFileInvoice}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            style={{ width: "30%" }}
            onClick={() => openRollBack()}
            title={"Roll Back"}
            icon={
              <FontAwesomeIcon
                icon={faArrowLeftRotate}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            style={{ width: "30%" }}
            onClick={() => resetPin()}
            title={"Reset / Update Voucher PIN"}
            icon={
              <FontAwesomeIcon
                icon={faArrowsRotate}
                className={card_styles.card__icon}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default EventHome;
