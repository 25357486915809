import img  from '../../assets/img.jpg'; 
import img2 from '../../assets/P2PFunding.png'; 
import img3 from '../../assets/lead.jpg'; 
import FacebookIcon from '@mui/icons-material/Facebook';



const people = [
  {
    id: 1,
    graphicUrl:img,
    imageUrl:img,
    name: 'ePayNet P2P Funding',
    categoryName: 'Events',
    webUrl:'http://localhost:3006/homepage',
    description:
      'ePayNet P2P Funding allow clients...',  
  },
  {
    id: 2,
    graphicUrl:img2,
    imageUrl:img2,
    name: 'iCredit',
    categoryName: 'Bulk Payments',
    webUrl:'https://icredit.salvtec.com/',
    description:
      'ePayNet Cashless Events Guest user allow unregistered clients...',
  },
  {
    id: 3,
    graphicUrl:img3,
      imageUrl:img3,
    name: 'Lets Move',
    categoryName: 'Fleet Management',
    webUrl:'https://letsmove.salvtec.com/',
    description:
      'ePayNet Cashless Events Guest user allow unregistered clients...',
  },
];

export default people;

export const social = [
  {
    id: 1,
    url: 'https://www.twitter.com',
    icon: <FacebookIcon />,
  },
  {
    id: 2,
    url: 'https://www.twitter.com',
    icon: <FacebookIcon />,
  },
  {
    id: 3,
    url: 'https://www.twitter.com',
    icon: <FacebookIcon />,
  },
  {
    id: 4,
    url: 'https://www.twitter.com',
    icon: <FacebookIcon />,
  },
];
 