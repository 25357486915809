import React, { useContext } from "react";
import { Card, CardContent, Typography, Grid, Button } from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";

import global_styles from "../../index.module.css";
import local_styles from '../Loans/LoansLandingPage.module.css'
import styles from "../Dashboard/Dashboard.module.css";
import card_styles from "../../components/ServiceCard/ServiceCard.module.css";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faHandHoldingHand,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";

export const Invoices = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;

  const checkObjType = history.location.loginState;

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  const createInvoice = () =>
    history.push({
      pathname: "/invoicing",
    });

  const openDebts = () =>
    history.push({
      pathname: "/debts",
    });

  const openMyDebts = () =>
    history.push({
      pathname: "/shareddebts",
    });

  const openSharing = () =>
    history.push({
      pathname: "/invoicestatement",
    });

  return (
    <main className={classes.content}>
      <div>
        <div className={styles.showcase}>
          <div className={styles.username + " " + global_styles.container}>
            <p>Welcome, {loginDetails.fullName}</p>
          </div>

          <div
            className={
              styles.slideshow__container + " " + global_styles.container
            }
          >
            <div className={styles.showcase__slider}>
              <div className={styles.slides}>
                <div className={styles.contents}>
                  <h2>Invoice Manager</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={global_styles.container + " " + local_styles.service__cards}
          style={{ margin: "10px auto", display: "flex", gap: "15px" }}
        >
          <ServiceCard
            onClick={() => createInvoice()}
            title={"Invoicing"}
            icon={
              <FontAwesomeIcon
                icon={faReceipt}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            onClick={() => openDebts()}
            title={"Debtors"}
            icon={
              <FontAwesomeIcon
                icon={faPlayCircle}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            onClick={() => openMyDebts()}
            title={"My Debts"}
            icon={
              <FontAwesomeIcon
                icon={faHandHoldingHand}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            onClick={() => openSharing()}
            title={"Statements"}
            icon={
              <FontAwesomeIcon
                icon={faFileInvoice}
                className={card_styles.card__icon}
              />
            }
          />
        </div>
      </div>
    </main>
  );
};

export default Invoices;
