import React, { useEffect,useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../../Login/styles';	
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {LenderActivationStatus} from './LenderActivationStatus';
import {CartContext} from '../../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../../Loader/Loader';



export const ConfirmActivation = () => {
	
 const classes = useStyles(); 
 const history = useHistory();  
 const { baseURL, dataReceiver } = useContext(CartContext); 
 const { loginDetailsBag }= useContext(CartContext);
 const [loginDetails, setLoginDetails] = loginDetailsBag;
 const [open, setOpen] = React.useState(false);
 const [paymentPlatforms, setPaymentPlatforms] = React.useState([]);
 const [responseStatus, setResponseStatus] = React.useState([]);
 const [selectedPaymentPlatform, setSelectedPaymentPlatform] = React.useState([]);
 let activationResponseDetailsBag = [];
 let selectedPlatform = '';
 let response = '';
 let appUserAccountNumber = loginDetails.momoAccountNumber;
 let appUser = loginDetails.organisations_id;
  const dataDec  = dataReceiver;
     

         const borrowerDetailsBag = history.location.borrowerState;        
         let lenderMemberID = borrowerDetailsBag[0].id;

 useEffect(()=> {

      setOpen(!open);

      const fetchPaymentPlatform = async()=>{
          
          const res = await axios.get(baseURL+'bridgeGetMobileMoneyVendors.php');

          res.data = dataDec(res.data);
       
          setPaymentPlatforms(res.data);
          setOpen(false);      

      }

      fetchPaymentPlatform();
     
   }, [])


   const goToLenderConfirmationStatus = () => history.push({ 
      
      pathname: '/lenderactivationstatus',
        activationResponseState: activationResponseDetailsBag

});

const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedPaymentPlatform(event.target.value);
    selectedPlatform = event.target.value;
    
   
  return selectedPlatform;

  };  

 
const confirmActivation = () =>{

  setOpen(!open);
  const submitLenderMemberActivation = async()=>{
          
    const res = await axios.get(baseURL+'bridgeReceiveLenderMemberActivationTransaction.php?appUserCompanyID='+appUser+'&appUserAccountNumber='+appUserAccountNumber+'&localEftVendorID='+selectedPaymentPlatform+'&lenderMemberID='+lenderMemberID);
    
    res.data = dataDec(res.data);
  
      if(res.data !== ''){
          
           setResponseStatus(res.data);
           activationResponseDetailsBag = res.data;
           goToLenderConfirmationStatus();
           setOpen(false);
         

      }else{

          alert('Error');
          setOpen(false);
    } 

}

      submitLenderMemberActivation();
    
}


	return (
  
      <main className = {classes.content}>

            <div className={classes.toolbar}/>

              <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={2}>
                        <Grid item xs={false} sm={3} md={4}/>
            
                        <Grid item xs={12} sm={6} md={4}>
                            <Card >
                                
                                <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                                 </Backdrop>

                               <CardContent>
           <Typography align="center" variant="body1"><h5>Approval</h5></Typography>
                               
                          <Typography variant="body1" gutterBottom>                
                                 
                             Full Name: <b>{borrowerDetailsBag[0].borrowerFullName}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Cell Number: <b>{borrowerDetailsBag[0].cellNumber}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             National ID:<b> {borrowerDetailsBag[0].nationalID}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Membership Number:<b> {borrowerDetailsBag[0].membershipNumber} </b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 <TextField label="Payment Platform" variant="outlined"  fullWidth    size="small" select
                                  onChange={handleChange}
                                  value={selectedPaymentPlatform}
                                  > 

                                    {paymentPlatforms.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.vendorName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>


                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> confirmActivation()} > Activate</Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                    <Grid item xs={false} sm={3} md={4}/>
                 </Grid>

              </Grid>
    </main>

	);
}



export default ConfirmActivation;