import React, { useEffect,useContext, useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Loader from '../Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';


export const ActivateEcommerce = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext); 
       const [open, setOpen] = useState(false);
       const [selectedStatus, setSelectedStatus ] = React.useState([]); 
       const [ loginDetails, setLoginDetails ] = loginDetailsBag;
       let eCommerceStatus = loginDetails.isEcommerceParticipant;
       let appUserID = loginDetails.organisations_id;                 
       const [ activeStatus, setActiveStatus] = React.useState([]);                       
       let selectedConsent = '';
       let status = '';
       const consent = [{id:1, name:'Yes',value:'Y'},
                        {id:2,name:'No',value:'N'}];
       const dataDec  = dataReceiver;                 


              
   const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setSelectedStatus(event.target.value);
          selectedConsent = event.target.value;
       
   }; 

   console.log(eCommerceStatus);

    if(eCommerceStatus === 'Y'){
           
           status = 'Active';
       
          }else if(eCommerceStatus !== 'Y'){

           status = 'Inactive';   

        }

useEffect(()=> {
        
      const fetchActiveStatus = async()=>{
                       
            }

      fetchActiveStatus();
     
   }, [])
 
const updateEcommerceStatus = () => {
      
      setOpen(!open);


      const updateStatus = async()=>{
      
            const res = await axios.get(baseURL+'bridgeOnlineParticiapationStatus.php?isEcommerceParticipant='+selectedStatus+'&logonCompanies_id='+appUserID);
                
          
          res.data = dataDec(res.data);

           setActiveStatus(res.data);
            setOpen(false);

      }

      updateStatus(); 

}

const goToTransactionConfirmation = () => history.push({ 
      
        pathname: '/confirmmomotransaction',
        
});

  return (

      <main className = {classes.content}>
            <div className={classes.toolbar}/>
   
  <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                      {/*
                          <Grid item xs={false} sm={4}/>
                        <Grid item xs={12} sm={4}>
                      */}
                        
                            
                         <Grid item xs={false} sm={2} md={3} lg={4}/>
            
                      <Grid item xs={12} sm={8} md={6} lg={4}>   

                            <Card >

                              <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                              </Backdrop>

              <CardHeader title="Activate e-Commerce"  align="center" />
                    <CardContent>
                  
                  <Typography variant="body2" gutterBottom>                
                      e-Commerce status :<b>{status}</b>
                  </Typography><br></br>
                    
                           
                    <Typography variant="body2" gutterBottom>                
                    Do you want your ePayNet account to be able to make purchases on e-commerce sites?.
                    </Typography><br></br>

                    <Typography variant="h4" gutterBottom>                
                                 <TextField label="Choose Option" variant="outlined"  fullWidth size="small" select
                                 onChange={handleConsentChange}
                                 value={selectedStatus} >

                                    {consent.map((option) => (
                                    <MenuItem key={option.id} value={option.value}>
                                      {option.name} 
                                   </MenuItem>
                                   ))}
                        </TextField></Typography>
                     
                               
                        <Button className={classes.button} type="button" variant="contained" fullWidth style ={{margin: '5px', textTransaform:'none', backgroundColor: '#155054', color:'white'}} onClick={()=> updateEcommerceStatus()} > Update
                        </Button>

                         
                         <br></br><br></br>
           {(activeStatus.description !== '' &&   
            
                  <Typography variant="body2" gutterBottom activeStatus={activeStatus}>                
                        {activeStatus.description}
                  </Typography>
                  
            )}  

            </CardContent>
              <br></br>
                       </Card>

                     </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>

  </main>

  );
}

export default ActivateEcommerce;