import React, { useEffect,useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem, List} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext'; 
import AddedAccounts from './AddedAccounts';
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';



export const UserAccount = () => {
  
       const classes = useStyles();  
       const history = useHistory();
       const { baseURL, dataReceiver } = useContext(CartContext); 
       const [ open, setOpen] = React.useState(false);
       const { loginDetailsBag } = useContext(CartContext);
       const [ loginDetails, setLoginDetails ] = loginDetailsBag;
       let appUserID = loginDetails.organisations_id;
       let momoAccount = loginDetails.momoAccountNumber;
       let eMaliAccount = loginDetails.eMaliAccountNumber;
       const [updatedAccount, setUpdatedAccount] = React.useState([]);
       const [userAccountCategory, setUserAccountCategory ] = React.useState([]);
       const [accountNumber, setAccountNumber] = React.useState([]);
       const [organisations, setOrganisations] = React.useState([]);
       const [vendor, setVendor ] = React.useState([]);
       const [selectedOrganisation, setSelectedOrganisation ] = React.useState([]);
       const [companyName, setCompanyName ] = React.useState([]);
       const [myAccounts, setMyAccounts] = React.useState([]);
       let selectedCategorysID = '';   
       const dataDec  = dataReceiver;

                                      
                        
  useEffect(()=> {
        
      setOpen(!open);

      const fetchEligableVendors = async()=>{
          
            const res = await axios.get(baseURL+'bridgeGetEligibleVendorsToAddAccount.php');
        
            
            res.data = dataDec(res.data);

            if(res.data !== ''){
               
                    setUserAccountCategory(res.data);
                    setOpen(false);

                      }else{

                         alert('Connection error, Please try again later');
                         setOpen(false);
                  
                  }
          
      }


    const fetchMyAccounts = async()=>{
          
           const res = await axios.get(baseURL+'bridgeMyCompanyEnrollment.php?logonCompanies_id='+appUserID);
            
           res.data = dataDec(res.data);
         
          if(res.data !== ''){
              
          setMyAccounts(res.data); 
          setOpen(false);     
        
 
          }else{

             alert('Connection error, Please try again later');
             setOpen(false);
          }
          
      }


  
      fetchEligableVendors();
      fetchMyAccounts();
     
   }, [])



  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setVendor(event.target.value);
          selectedCategorysID = event.target.value;

  };

 
const handleOrganisationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      
      setSelectedOrganisation(event.target.value);

    
 };


const AddUserAccount = () => {
  
  setOpen(!open);
  const addNewAccount = async()=>{
    
    const res = await axios.get(baseURL+'bridgeAddOtherAccount.php?logonCompanies_id='+appUserID+'&accountNumber='+accountNumber+'&localEftVendors_id='+vendor);
       
      
    //    res.data = dataDec(res.data);   

        setOpen(false);
        alert('Your '+accountNumber+' Account will be updated after the approval');
        setMyAccounts(res.data);
        
                
      }

      addNewAccount();
}

const goToTransactionConfirmation = () => history.push({ 
      
        pathname: '/confirmmomotransaction',
        
});



  return (  
      <main className = {classes.content}>
            <div className={classes.toolbar}/>

           <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
                         <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>

              <CardHeader title="Add Account"  align="center" />
                    <CardContent>
                      
                    <Typography variant="h4" gutterBottom>                
                                 <TextField label="Select Account Network" variant="outlined"  fullWidth size="small" select
                                 onChange={handleCategoryChange}
                                 value={vendor} >

                                    {userAccountCategory.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                          {option.vendorName} 
                                        </MenuItem>
                                    ))}
                               </TextField></Typography>

                    <Typography variant="h4" gutterBottom>
                                      <TextField label="Cell Number" variant="outlined" fullWidth size="small"
                                      placeholder="Cell Number"  
                                        type="text"
                                       id="accountNumber"
                                       name="accountNumber"
                                       onChange={(e)=>setAccountNumber(e.target.value)}
                                                                           
                                      />
                                      </Typography>            
                    
                               
                           <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}}  onClick={()=> AddUserAccount()} > Add
                           </Button>
                       </CardContent>
                         </Card>
                             
                         <hr style={{border:'1 black'}}/> 
                             
                      <CardHeader title="Added User Account"  align="center" />
                        
                        <List>
                          
                              <Grid>
                                <Divider style={{border:'1 black'}}/>  
                                  Emali Account : {loginDetails.eMaliAccountNumber} <br></br>
                                <Divider style={{border:'1 black'}}/> 
                                  MoMo Account:   {loginDetails.momoAccountNumber}
                                <Divider style={{border:'1 black'}}/>    
                              </Grid>
                             
                        </List>  
                            
                       
                          

                        </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>







  </main>

  );
}

export default UserAccount;