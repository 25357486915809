import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import useStyles from "../Login/styles";
import Loader from "../Loader/Loader";

// import styles & iconography
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// import Axios client
import axios from "axios";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

export const Balances = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;

  const dataDec = dataReceiver;
  let userID = loginDetails.organisations_id;
  let userAccountNumber = loginDetails.momoAccountNumber;

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [lenderCompanies, setLenderCompanies] = useState([]);
  const [sharesBalances, setSharesBalances] = useState([]);
  const [savingsBalance, setSavingsBalance] = useState([]);
  const [loanBalances, setLoanBalances] = useState([]);
  const [accountBalances, setAccountBalances] = useState([]);

  // capture input fields
  const [lenderCompaniesID, setLenderCompaniesID] = useState("");
  const [membershipNumber, setMembershipNumber] = useState("");
  const [idNumber, setIdNumber] = useState("");

  const [showBalances, setShowBalances] = useState(false);

  let currency = Intl.NumberFormat("en-SZ", {
    style: "currency",
    currency: "SZL",
    //useGrouping: false,
  });

  function hexToUint8Array(hexString) {
    if (hexString.length % 2 !== 0) {
      throw new Error("Invalid hexString");
    }

    let uint8Array = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < hexString.length; i += 2) {
      uint8Array[i / 2] = parseInt(hexString.substr(i, 2), 16);
    }

    return uint8Array;
  }

  const hex2bin = (number) => {
    let binaryData = hexToUint8Array(number);

    let decodedInteger = 0;
    for (let i = 0; i < binaryData.length; i++) {
      decodedInteger = (decodedInteger << 8) + binaryData[i];
    }

    return decodedInteger;
  };

  const getUserBalances = async () => {
    setOpen(!open);

    let payload = {
      lenderCompanies_id: lenderCompaniesID,
      membershipNumber: membershipNumber,
      idNumber: idNumber,
      companies_id: userID,
    };

    try {
      const { data } = await axios.post(
        baseURL + "bridge_hub_getLenderMemberBalances.php",
        JSON.stringify(payload)
      );

      const response = dataDec(data);

      let responseStatus = response?.data?.status;

      if (responseStatus === "200") {
        setLoanBalances(response.data.loanContract);
        setSharesBalances(response.data.sharesResponse);
        setSavingsBalance(response.data.savings);
        setAccountBalances(response.data.transactionalAccount);

        setShowBalances(true);
        setOpen(false);
      } else {
        setErrorMessage(response.message);
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const close = () => {
    setShowBalances(!showBalances);
  };

  useEffect(() => {
    const getLenderID = async () => {
      setOpen(!open);

      let payload = {companies_id: userID}
      try {
        const { data } = await axios.post(
          baseURL + "bridge_hub_getMyActiveLenders.php", JSON.stringify(payload)
        );

        const response = dataDec(data);
        setLenderCompanies(response.response);
      } catch (error) {
        console.log(error);
      } finally {
        setOpen(false);
      }
    };

    getLenderID();
  }, []);

  const checkObjType = history.location.loginState;

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <CardHeader title="View Your Balances" align="center" />

                {showBalances ? (
                  <div
                    style={{
                      padding: "7px 15px",
                      border: "1px solid gray",
                      borderRadius: "3px",
                    }}>
                    <div style={{ margin: "4px 0" }}>
                      <p style={{ fontWeight: "bold" }}>Loan Balances</p>
                      {loanBalances.length > 0 ? (
                        loanBalances.map((loanBalance) => (
                          <p>
                            Loan Number: {loanBalance.loanNumber} <br />
                            <strong>
                              {currency.format(loanBalance.amount)}
                            </strong>
                          </p>
                        ))
                      ) : (
                        <p>{currency.format(loanBalances.length)}</p>
                      )}
                    </div>

                    <div style={{ margin: "4px 0" }}>
                      <p style={{ fontWeight: "bold" }}>Shares Balances</p>
                      {sharesBalances.length > 0 ? (
                        sharesBalances.map((sharesBalance) => (
                          <p
                            style={{ color: "red", align: "center" }}
                            align="center">
                            <strong>{currency.format(sharesBalance.amount)}</strong>
                          </p>
                        ))
                      ) : (
                        <p>{currency.format(sharesBalances.length)}</p>
                      )}
                    </div>

                    <div style={{ margin: "4px 0" }}>
                      <p style={{ fontWeight: "bold" }}>Savings Balance</p>
                      {savingsBalance.length > 0 ? (
                        savingsBalance.map((savingsBalance) => (
                          <p
                            style={{ color: "red", align: "center" }}
                            align="center">
                            <strong>{currency.format(savingsBalance.amount)}</strong>
                          </p>
                        ))
                      ) : (
                        <p>{currency.format(savingsBalance.length)}</p>
                      )}

                      <div style={{ margin: "4px 0" }}>
                        <p style={{ fontWeight: "bold" }}>
                          Transactional Account Balance
                        </p>
                        {accountBalances.length > 0 ? (
                          accountBalances.map((accountBalance) => (
                            <p
                              style={{ color: "red", align: "center" }}
                              align="center">
                              <strong>{currency.format(accountBalance.amount)}</strong>
                            </p>
                          ))
                        ) : (
                          <p>{currency.format(sharesBalances.length)}</p>
                        )}
                      </div>
                    </div>

                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => close()}>
                      {" "}
                      Close
                      <FontAwesomeIcon
                        style={{ marginLeft: "10px" }}
                        icon={faTimes}
                      />
                    </Button>
                  </div>
                ) : (
                  <>
                    {errorMessage && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginBottom: "30px",
                        }}>
                        <b>{errorMessage}</b>
                      </div>
                    )}

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="Select Lender ID"
                        variant="outlined"
                        fullWidth
                        size="small"
                        select
                        onChange={(e) => setLenderCompaniesID(e.target.value)}
                        value={lenderCompaniesID}>
                        {lenderCompanies.length > 0 && lenderCompanies.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.companyName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="Membership Number"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Membership Number"
                        type="text"
                        id="membership_number"
                        name="membership_number"
                        onChange={(e) => setMembershipNumber(e.target.value)}
                      />
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="National ID Number"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="National ID Number"
                        type="text"
                        id="national_id_number"
                        name="national_id_number"
                        onChange={(e) => setIdNumber(e.target.value)}
                      />
                    </Typography>

                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => getUserBalances()}>
                      {open ? (
                        "Processing..."
                      ) : (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Check Balances
                          <FontAwesomeIcon
                            style={{ marginLeft: "10px" }}
                            icon={faArrowRight}
                          />
                        </span>
                      )}
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
      <div className={classes.toolbar} />
    </main>
  );
};

export default Balances;
