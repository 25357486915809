import React, { useContext, useEffect, useState } from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import useStyles from "../Login/styles";
import Request from "./Request/Request";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";
import { CartContext } from "../../Context/CartContext";
import Pagination from "../../components/Pagination/Pagination";
import { useHistory } from "react-router-dom";
import global_styles from '../../index.module.css'

export const Requests = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, dataReceiver } = useContext(CartContext);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(24);
  const [requests, setRequests] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const dataDec = dataReceiver;
  const [open, setOpen] = useState(false);
  let usersRight = history.location.userRightState;

  useEffect(() => {
    window.scrollTo(0, 0);
    setOpen(!open);

    const res = axios
      .post(baseURL + "bridge_iFund_GetBiddableApprovedFundingRequests.php")
      .then(function (response) {
        response.data = dataDec(response.data);
        setRequests(response.data);
        setOpen(false);
      });
  }, []);

  if (
    typeof usersRight !== "undefined" &&
    Object.keys(history.location.userRightState).length > 0
  ) {
  }

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(event.target.value);
  };

  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  let currentProducts = requests.slice(indexOfFirstProduct, indexOfLastProduct);
  let filteredProducts = [];

  if (search != "") {
    filteredProducts = currentProducts.filter((product) => {
      console.log(product);

      return product.name.toLowerCase().includes(search.toLowerCase());
    });
  }

  if (filteredProducts.length > 1) {
    currentProducts = filteredProducts;
  }

  return (
    <main className={classes.content + " " + global_styles.container}>
      <div className={classes.toolbar} />

      {/*<Grid container justify = "center" spacing={2}>
                <Grid item container justify = "center"  xs={12} sm={12} md={4} lg={4}>
                  
                    <TextField label="Search By" variant="outlined" fullWidth size="small" select
                             onChange={handleCategoryChange} value={selectedCategory}> 

                                    {requests.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.category} 
                                   </MenuItem>

                                   ))}
                              </TextField>

                   {requests.length} Results                  

                    </Grid>

                </Grid> */}

      <Backdrop className={classes.backdrop} open={open}>
        <Loader />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}></Grid>
        {requests.map((product, index) => (
          <Grid item xs={12} sm={6} md={6} lg={6} key={product.id}>
            <Request
              key={product.id}
              product={product}
              usersRight={usersRight}
              loading={open}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
      </Grid>

      <div className={classes.toolbar} />
      {/*<Pagination productsPerPage={productsPerPage} align='center' totalProducts ={requests.length} paginate={paginate}/>*/}
    </main>
  );
};

export default Requests;
