import React, { useEffect,useContext, useState } from 'react';
import {Card, CardContent,  Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../../Login/styles'; 
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../../Context/CartContext';
import Loader from '../../Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';


export const PocketRechargeStatus = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver }= useContext(CartContext);
       const dataDec  = dataReceiver;
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ open, setOpen] = useState(false);                     
       const responseDetails = history.location.responseMessageState;                        



 const completeTransaction = () => history.push({
    
    pathname: '/eventhome'
    
});




  return (

     <main className = {classes.content}>
       <div className={classes.toolbar}/>
       
    <Grid container direction="column" style= {{margin: '5px'}}>

     
         <Grid item container spacing={1} style={{height: "280px" }}>
                
                 <Grid item xs={false} sm={4} md={4} lg={4}/> 

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                       


                    <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                    </Backdrop>
                


                <Card>

                  
                    <Typography align="center" variant="body1">
                            <b>Transaction Status </b>
                    </Typography>
                  
                 <hr />                   

                   <CardContent >
                        

                       {responseDetails.description ?

                          <Typography variant="body2" gutterBottom>
                              {responseDetails.description}
                        </Typography>
                        :
                        <Typography variant="body2" gutterBottom>
                              {responseDetails.response}
                        </Typography>
                        }    
                        
      
            <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> completeTransaction()}>
                 Done
                </Button>
          </CardContent >
     </Card>


                  </Grid>
               
                 </Grid>

              </Grid>

  </main>

  );
}

export default PocketRechargeStatus;