import React, {useEffect, useContext } from 'react';
import {Card, Grid, Button} from '@material-ui/core';
import useStyles from '../Login/styles';	
import { CardHeader, } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import { useHistory } from 'react-router-dom';
import ShareContactList from './ShareContactList/ShareContactList';
import AddShareContact from './AddShareContact';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


export const LoanSharing = () => {
	
 const classes = useStyles(); 
 const history = useHistory(); 
 const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext); 
 const [ loginDetails, setLoginDetails] = loginDetailsBag;
 const [open, setOpen] = React.useState(false);
 const [ contactList, setContactList ] = React.useState([]);
 let appUserID = loginDetails.organisations_id;
 let contactListDetailsBag = [];
 const dataDec  = dataReceiver;

useEffect(()=> {

      const fetchMyContactList = async()=>{
            setOpen(!open);
            const res = await axios.get(baseURL+'bridgeBringMyFamilyAndFriends.php?appUserCompanyID='+appUserID);
          
          res.data = dataDec(res.data);
    
          if(res.data !== ''){
              
           contactListDetailsBag = res.data;
           setContactList(res.data);
           setOpen(false);

          }else{

             alert('Connection error, Please try again later.');
             setOpen(false);
          }
          
      }

      fetchMyContactList();
      
     
   }, [])
   

const  openSharing = () => history.push({ 
      
         pathname: '/AddShareContact'

});


	return (

		
      <main className = {classes.content}>
          <div className={classes.toolbar}/>  
               <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
                        <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>  
                            <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>


                         <CardHeader title="Contact List"  align="center" />
                           
                     {contactList.map((contact)=> ( 
                       <span key={contact.id}>
                         <ShareContactList contactDetails={contact} />
                         </span>      
                       ))}   
                           
                          
                 <Grid item xs={12} sm={4}>              
                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none'}} onClick={()=> openSharing()} > Add New</Button>
                  </Grid>
                                                            
                  </Card>


                        </Grid>
          
                   <Grid item xs={false} sm={2} md={3} lg={4}/>   
                 </Grid>

              </Grid>

  </main>

	);
}



export default LoanSharing;