import React, {useContext, useState} from 'react';
import {Card, CardMedia, Dialog, DialogActions, Button, CardContent, CardActions, Typography, IconButton} from '@material-ui/core';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


import useStyles from '../../Login/styles';
import { useHistory } from 'react-router-dom';



 const RollBackTransaction = ({transaction, loading, index}) => {

       const classes = useStyles();
       const history = useHistory();
   


     if(loading){
 
        return <h2>Loading..</h2>
 
    }



    return (
        <Card>
            
            <CardContent>
                <div className={classes.cardContent}>
              
                    <Typography variant="body1" gutterBottom>
                      <strong>  {transaction.transactionNumber}  </strong>
                    </Typography>
                    <hr/>
                    
                    <Typography variant="body1" color="textSecondary">
                     Event : <b>{transaction.eventName}</b>
                    </Typography>

                    <Typography variant="body1" color="textSecondary">
                     Amount : <b>E{transaction.amount}</b>
                    </Typography>

                      
                     <Typography variant="body1" color="textSecondary">
                      To Account :<b>{transaction.toAccount}</b>
                     </Typography>

                     <Typography variant="body1" color="textSecondary">
                      Reference : <b>{transaction.reference}</b>
                     </Typography>

                     <Typography variant="body1" color="textSecondary">
                      Date: <b>{transaction.created_at}</b>
                     </Typography>

                </div>
               
                  </CardContent>
                  
             
        </Card>
    )
}

export default RollBackTransaction;
