import React from "react";
import global_styles from "../../index.module.css";
import footer_styles from "../Footer/Footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={footer_styles.footer__bottom}>
      <div className={global_styles.container}>
        <div className={footer_styles.footer__inner}>
          <Link to="/PrivacyPolicy" className={footer_styles.footer__link}>
            Ts & Cs
          </Link>
          <Link to="/notifications" className={footer_styles.footer__link}>
            Awareness Centre
          </Link>
          <Link to="/about" className={footer_styles.footer__link}>
            About us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
