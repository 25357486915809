import React, {useState, useEffect, useContext} from 'react';
import { Grid, TextField, Card, CardContent, IconButton, Button, Typography } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext'; 
import Loader from '../../components/Loader/Loader';
import InputAdornment from '@material-ui/core/InputAdornment';
import CallIcon from '@material-ui/icons/Call';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOpenSharp from '@material-ui/icons'; 
import useStyles from '../../components/Login/styles';
import { useHistory,Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; 
import axios from 'axios';
import ButtonComponents from './ButtonComponents';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@mui/material/Tooltip';
import LoginAccountForm from '../Forms/LoginAccountForm';
import RegisterAccountForm from '../Forms/RegisterAccountForm';



 function goToTop(){

   window.scrollTo(0, 0); 

}


export const CreateAccount = () => {



  let data ={};  
  const classes = useStyles();
  const history = useHistory();   
  const [ loginDetails, setLoginDetails] = useState({});
  const [ loginError, setLoginError] = useState('');
  const { baseURL, dataReceiver, isActiveBusinessBag, legalBusinessMemberAccountsBag } = useContext(CartContext);
  const [ legalBusinessMemberAccount, setLegalBusinessMemberAccount] = legalBusinessMemberAccountsBag;
  const [ isActiveBusiness, setIsActiveBusiness] = isActiveBusinessBag;
  
  const [ username, setUsername] = useState('');
  const [ fullName, setFullName] = useState('');
  const [ cellnumber, setCellnumber] = useState('');
  const [ password, setPassword] = useState('');
  const [ passwordConfirm, setPasswordConfirm] = useState('');

  const [ loading, setLoading] = useState(false);
  const [ open, setOpen] = useState(false);
  const [ dataContainer, setDataContainer] = useState(data);
  const [ index, setIndex] = useState(0);
  const [ showLoginComponent, setShowLogin] = useState(false);
  const user = {username, fullName};
  const dataDec  = dataReceiver;
  let LoginDetailsBag = [{}];





  useEffect(()=> {


      goToTop();
     /* setTimeout(function(){

             updateBanner();

        }, 35550);
     */   

   }, [])





const goToHomePage = () => history.push({ 

      pathname: '/Dashboard',
      loginState: LoginDetailsBag

});


const goToAuthorize = () => history.push({ 

      pathname: '/authorization',
      loginState: LoginDetailsBag

});



const hitLoginApi = async()=>{
 
  setOpen(!open);
  setLegalBusinessMemberAccount({});
  const password = fullName;
  const min = 100;
  const max = 999;
  const rand =  Math.floor(Math.random() * (max - min + 1) ) + min;
  const randPassword = `${rand}${password}`;

   
 function d2h(d) {
  return d.toString(16);
  }
  function h2d (h) {
      return parseInt(h, 16);
  }
  function stringToHex (tmp) {
    let str = '',
        i = 0,
        tmp_len = tmp.length,
        c;
 
    for (; i < tmp_len; i += 1) {
        c = tmp.charCodeAt(i);
        str += d2h(c) + '';
    }
   
    return str;
}

const rand3hexPassword = stringToHex(randPassword);    
  


{/*
   if(username == ''){
        setLoginError('Username is required');
        setOpen(false);
        return 0;
   }
   if(password == ''){
        setLoginError('Password is required');
        setOpen(false);
        return 0;
   }
//   let data = {username:username, password:rand3hexPassword}
//   data = JSON.stringify(data);
//   let data = {username:username, password:rand3hexPassword}
*/}


  


      let data = { fullName:'Lindo', 
                   email:username, 
                   cellNumber:cellnumber, 
                   password: password
                 };

               data = JSON.stringify(data);

     if(password == passwordConfirm){

        setLoginError('Make sure password and confirmation password are the same.');
        return 0;
     
     }
    
   const res = await axios.post(baseURL+'ePayNetAccount/bridgeRegistration.php', data ).then(function (response) {
      
      
      response.data = dataDec(response.data);
                      console.log(response.data);


      if(response.data.error){

            setLoginError(response.data.error);
      
      }
      
     if(response.data.code === '200'){
                  
                  setLoginDetails(response.data);       
                  LoginDetailsBag = response.data ;     
                  let activeBusiness = LoginDetailsBag.isLegalBusiness;
                  setIsActiveBusiness(activeBusiness);
                  setOpen(false);
                  goToHomePage();
                     
         
             }else{

                  setLoginError(response.data.description);
                  setOpen(false);
       } 
                
  });
         
}


const showLogin = () =>{
  
      setShowLogin(!true);       

}

const showRegister = () =>{
      
      setShowLogin(true);

}

const goToHome = () => history.push({ 

      pathname: '/homepage'

});



const openEpaynetLogin = () => history.push({
  
  pathname: '/'

});


   
const  handleSubmit = () => {
    
     hitLoginApi();

}


if(loading){

        return  (
                     
              <Loader/>
           
           )
    }



return (
    <main className = {classes.content} style={{backgroundColor:"ghostwhite"}}>
            <div className={classes.toolbar} />

   
               <Grid container direction="column" >

                   <Grid item container spacing={1} >
                    
{/*-------------------------------------------------------------------------
                                PAGE SIDE MENU START
-------------------------------------------------------------------------*/}  
       
               <Grid item xs={false} sm={2} md={1} lg={1} />
               <Grid item xs={false} sm={2} md={5} lg={5} >
               {/* 
                <Card style={{backgroundColor:'ghostwhite'}}>                      
                <CardContent >
               */} 
                   <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                      <span>
                            <b> Stage 1. Not registered on ePayNet  </b>
                      </span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                                Go to ePayNet, register, login, activate online check-out and go to Stage 3. NB: If you are already registered, go to Stage 2.
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>



                <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span ><b> Stage 2: Registered ePayNet user </b></span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                               Go to ePayNet, login, click on the ePayNet side menu, select online check-out, select “Yes” and update and then go to Stage 3 to recharge.
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>


                <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span ><b>Stage 3: Registered on ePayNet & activated online check-out </b></span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                                Put the recharge steps through the submit button.
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>  
            {/*
               </CardContent>
               </Card>
            */}

               </Grid>
        
{/*------------------------------------------------------------------------------
                                   PAGE SIDE MENU END
----------------------------------------------------------------------------*/}     
           
{/*------------------------------------------------------------------------------
                                LOGIN FORM START
---------------------------------------------------------------------------------*/}              
                 
                        <Grid item xs={12} sm={8} md={5} lg={5} >
                      <Card style={{backgroundColor:'white'}}>  
                        <CardContent>
              <Grid item container>
                  <Grid item xs={12} align="center">
                 
                  <Button type="button" variant="contained" style ={{margin: '5px', textTransaform:'none', color:'white', backgroundColor:'#155054', width:'20%'}} className={classes.button} onClick={()=> showLogin()}> 
                    Login 
                  </Button>
                    
                  <Button variant="contained" color="inherit" className={classes.button} onClick={()=> showRegister()} style={{width:'20%',color:'white'}}> 
                    Registration
                  </Button>
            
                    

                  </Grid>
              </Grid>
              <hr />

                    {showLoginComponent?

                        <RegisterAccountForm />       

                        :

                      <>

                         <LoginAccountForm /> 
                       
                      </>
   
                      }
   </CardContent>
   </Card>
                            
    </Grid>
{/*------------------------------------------------------------------------------
                                LOGIN FORM END
---------------------------------------------------------------------------------*/}           

                     <Grid item xs={false} sm={2} md={1} lg={1} />
             
                 </Grid>
            
       </Grid>

 </main>
 )    
}

export default CreateAccount;