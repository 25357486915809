import React, { useState, useEffect, useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem, InputAdornment } from '@material-ui/core';
import useStyles from '../Login/styles';
import { CardHeader,TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {CartContext} from '../../Context/CartContext'; 
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import Alert from '../Alert/Alert';

export const Registration = () => {
	   
       const classes = useStyles();
       const history = useHistory();
       const { baseURL, dataReceiver } = useContext(CartContext);
       const [ open, setOpen] = React.useState(false);
       const [ success, setSuccess] = useState(false);
       const [ responseMessage, setResponseMessage] = useState('');
       const [nationalID, setNationalID] = useState('');
       const [sourceOfIncome, setSourceOfIncome] = useState('');
       const [fullName, setFullname]= useState('');
       const [email, setEmail]= useState('');
       const [physicalAddress, setPhysicalAddress]= useState('');
       const [cellNumber, setCellnumber]= useState('');
       const [password, setPassword]= useState('');
       const [employer, setEmployer]= useState('');
       const [incomeAmount, setIncomeAmount]= useState('');
       const [regions, setRegions] = React.useState([]);
       const [towns, setTowns] = React.useState([]);
       const [region, setRegion] = React.useState([]);
       const [town, setTown] = React.useState([]);
       const [passwordConf, setPasswordConfirmation]= useState('');
       const [consentValue, setConsentValue] = React.useState([]);
       let selectedConsent = '';
       const consent = [{id:1, name:'Yes'},
                        {id:2,name:'No'}];

        let emptyResp = {};
        let regionData = {};
        let townsData = {};
        let selectedRegion = '';
        let selectedTown = '';
        const dataDec  = dataReceiver;


 useEffect(()=> {

      setOpen(!open);  
      const fetchRegions = async()=>{
          
          const res = await axios.get(baseURL+'/bridgeGetRegions.php');
                        
          
          res.data = dataDec(res.data);
        
                            

        if(res.data !== ''){

                        setRegions(res.data);      
                        regionData = res.data;
                        setOpen(false);
                        
          }else{

               alert('Connection error, Please try again later.');
               setRegions(emptyResp);
               setOpen(false);
             
          }

      }

      fetchRegions();
     
   }, [])

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setRegion(event.target.value);
        selectedRegion = event.target.value;
        setOpen(!open);

     const fetchTowns = async()=>{
          
           const res = await axios.get(baseURL+'/bridgeGetTowns.php?regionID='+selectedRegion);
            
          
            res.data = dataDec(res.data);

            if(res.data !==''){
                
                setTowns(res.data);      
                townsData = res.data;
                setOpen(false);

            }else{

              alert('An error occured, Please make sure you have a stable connection and try again.');
              setSuccess(true);
         // setResponseMessage('An error occured, Please make sure you have a stable connection and try again.');
              setOpen(false);

            }  
            
      }

      fetchTowns();

   }; 

   const handleTownChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setTown(event.target.value);
          selectedTown = event.target.value;
   
   }; 


const goToLoginPage = () => history.push({
      
     pathname: '/'

});


const openRegistration = () =>{

   hitRegistrationApi();

};


const hitRegistrationApi = async()=>{
      
     setOpen(!open) 
     const userData = {fullName:fullName, identificationNumber:nationalID, cellNumber:cellNumber, town:selectedTown, physicalAddress:physicalAddress, email:email, employer:employer, incomeAmount:incomeAmount, incomeSource:sourceOfIncome,  password:password, passwordConfirm:passwordConf};

 
       let data = userData;    
       data = JSON.stringify(data); 
           
    if(consentValue == '1'){
  
         
        const res = await axios.post(baseURL+'bridgeMobileUserRegistration.php', data )
            .then(function (response) {

            response.data = dataDec(response.data);


        if(response.data.code !== '200'){

            alert(response.data.error);
            setOpen(false);

          //  setResponseMessage(response.data.error);
            window.scrollTo(0, 0);
                 setSuccess(true);
       
            }else if(response.data.code === '200'){
              
               //  alert('Please login using '+response.data.username+' as  username.');
               //  setResponseMessage('Please login using '+response.data.username+' as  username.');
                 setSuccess(true);
                 window.scrollTo(0, 0);
                 goToLoginPage();
                 setOpen(false);
             } 
              
          });

       }else{


        alert('You will not be allowed to register with ePayNet unless you have read and understood ePayNet terms and conditions.');
        setOpen(false);
       

      }    
         
}

const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setConsentValue(event.target.value);
          selectedConsent = event.target.value;
         
  
   }; 


	return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>

       <Grid container direction="column" className={classes.root}>
	
                  <Grid item></Grid>

                     <Grid item container spacing={4}>

                      
                         <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>

                               <CardContent>

                                <CardHeader title="Registration"  align="center" />

                          {/* success  ?
                          
                              <>
                                <Alert messsage={responseMessage.description} code={responseMessage.code}/> 

                              </> 
                                      :  
                              <>
                                      
                              </> 
                    
                          */}


                                  <Typography variant="h4" gutterBottom>
                                     <TextField label="Full Name" name="fullName" variant="outlined" type="text" fullWidth size="small"  placeholder=" John Doe"
                                          onChange={(e)=>setFullname(e.target.value)} 
                                        
                                      />
                                      </Typography>


                                 <Typography variant="h4" gutterBottom>
                                      <TextField label="National ID" variant="outlined" type="text" fullWidth size="small"  placeholder="National ID"
                                       onChange={(e)=>setNationalID(e.target.value)}
                                         
                                      />
                                  </Typography>

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Cell Number" variant="outlined" type="text" fullWidth size="small"  placeholder="Cell Number" 
                                      onChange={(e)=>setCellnumber(e.target.value)}
                                        
                                      />
                          </Typography>

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Email" variant="outlined" type="text" fullWidth size="small"  placeholder="epaynet@gmail.com" 
                                      onChange={(e)=>setEmail(e.target.value)}
                                        
                                      />
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Region" variant="outlined" fullWidth size="small" select
                                  onChange={handleChange}
                                  value={region}
                                  > 

                                    {regions.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.provinceName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Town" variant="outlined" fullWidth size="small" select
                                  onChange={handleTownChange}
                                  value={town}
                                  > 

                                    {towns.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.townName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                                  <Typography variant="h4" gutterBottom>
                                      <TextField label="Physical Address" variant="outlined" type="text" fullWidth size="small"  placeholder=""
                                      onChange={(e)=>setPhysicalAddress(e.target.value)}
                                         
                                      />
                                   </Typography> 

                      <Typography variant="h4" gutterBottom>
                                  <TextField label="Source Of Income" variant="outlined" type="text" fullWidth size="small"  placeholder=""
                                       onChange={(e)=>setSourceOfIncome(e.target.value)}
                                      
                                         
                                      />
                      </Typography>

                      <Typography variant="h4" gutterBottom>
                                 <TextField label="Income Amount" variant="outlined" type="text" fullWidth size="small"  placeholder="500.00"
                                       onChange={(e)=>setIncomeAmount(e.target.value)}
                                      
                                         
                                      />
                      </Typography> 

                      <Typography variant="h4" gutterBottom>
                                <TextField label="Employer" variant="outlined" type="text" fullWidth size="small"  placeholder=""
                                      onChange={(e)=>setEmployer(e.target.value)}
                                         
                                      />
                      </Typography>        

                                   

                      <Typography variant="h4" gutterBottom>
                                 <TextField label="Password" variant="outlined"  fullWidth size="small" type="password"
                                       onChange={(e)=>setPassword(e.target.value)}
                                         
                                      />
                      </Typography>

                      <Typography variant="h4" gutterBottom>
                                <TextField label="Password Confirmation" variant="outlined"  fullWidth size="small" type="password"
                                       onChange={(e)=>setPasswordConfirmation(e.target.value)}
                                         
                                      />
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                          I hereby consent that the information provided above is correct and accurate. I also acknowledge that it is my responsibility to update this information should there be any changes to the above.
                      </Typography>

                      <Typography variant="h4" gutterBottom>                
                               <TextField label="Consent" variant="outlined"  fullWidth size="small" select
                                 onChange={handleConsentChange}
                                 value={consentValue} >

                                    {consent.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.name} 
                                   </MenuItem>
                                   ))}
                      </TextField></Typography><br></br>

           
            		<Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={openRegistration}>Register</Button>

                <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}}onClick={goToLoginPage}>Login</Button> 

            		
				     

      </CardContent>
 </Card>


                        </Grid>
         
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>

 </main>
	);
}



export default Registration;