import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Loader from "../Loader/Loader";
import Backdrop from "@material-ui/core/Backdrop";

export const Notifications = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, dataReceiver } = useContext(CartContext);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  let appUserID = loginDetails.organisations_id;
  const dataDec = dataReceiver;

  useEffect(() => {
    const fetchNotifications = async () => {
      setOpen(!open);
      const res = await axios.get(
        baseURL + "bridgeGetMyMessages.php?logonCompanies_id=" + appUserID
      );

      res.data = dataDec(res.data);
      setNotifications(res.data);
      setOpen(false);
    };

    fetchNotifications();
  }, []);

  const goToTransactionConfirmation = () =>
    history.push({
      pathname: "/confirmmomotransaction",
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Backdrop className={classes.backdrop} open={open}>
        <Loader />
      </Backdrop>

      <Grid item container spacing={2}>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {notifications.map((option) => (
            <Card gutterBottom style={{ padding: "10px", marginTop: 10 }}>
              <Typography align="center" variant="body1">
                <h5>{option.messageName} </h5>
              </Typography>
              <hr></hr>

              <CardContent>{option.description}</CardContent>
            </Card>
          ))}
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          
        </Grid>
      </Grid>
    </main>
  );
};

export default Notifications;
