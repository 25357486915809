import React, { useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";

export const PrivacyPolicy = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
          <Grid item xs={false} sm={2} md={3} lg={2} />
          
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <Card>
              <CardContent>
                <Typography align="center" variant="body1">
                  <h6 align="center">
                    Welcome to ePayNet and thank you for using our platforms
                  </h6>
                </Typography>{" "}
                <br></br>
                <Typography variant="body1" gutterBottom>
                  Before you get started, please read the following terms and
                  conditions. Our platforms and mobile applications available on
                  the Google Play are provided to you by Digimage Proprietary
                  Limited. By registering, using, accessing, viewing or
                  downloading any content or ePayNet you are accepting these
                  Terms and Conditions and you agree to be bound by these Terms
                  and Conditions.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>GENERAL</h6>
                  1.1 This agreement may be altered without notice and your
                  continued use of this App after any such alterations implies
                  your acceptance of the new rules of engagement in this
                  agreement and the terms and conditions thereof. 1.2 This
                  agreement applies exclusively to your access to, and use of,
                  this App and does not alter in any way, the terms or
                  conditions of any other agreement you may have with ePayNet
                  for products, services or otherwise. 1.3 The headings of the
                  clauses in the conditions is provided for convenience and ease
                  of reference only and will not be used to interpret, modify or
                  amplify the terms and conditions. 1.4 No failure or delay by
                  ePayNet to exercise any of its rights will be construed as a
                  waiver of any such rights, whether this is done expressly or
                  implied, nor will it affect the validity of any part of this
                  agreement to take subsequent actions against the User. 1.5
                  ePayNet cannot screen or edit all the content available from
                  the ePayNet App and does not accept any liability for illegal,
                  defamatory or obscene content. Users are encouraged to inform
                  ePayNet of any content that may be offensive or illegal.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Privacy Policy</h6>
                  ePayNet believes strongly in protecting user privacy,
                  therefore, we have put together and follow a policy that
                  recognizes and addresses the User’s needs. Users of this App
                  should refer to our Privacy Policy for information about how
                  ePayNet uses and collects information.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Copyright</h6>
                  Copyright © ePayNet, Eswatini. All rights not expressly
                  granted, are reserved. To obtain permission for the commercial
                  use of any content on this App please contact ePayNet at
                  digimage@salvtec.co.sz.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>LICENSES AND APP ACCESS</h6>
                  5.1 ePayNet grants you a limited license to make use of the
                  App. Such grant does not include, without limitation: (a) any
                  resale of the App or content therein; (b) the collection and
                  use of any listings or descriptions; (c) making derivative
                  uses of the App and its contents; or (d) use of any data
                  mining, robots or similar data gathering and extraction
                  methods. Except as noted above, you are not given any right or
                  license by implication, otherwise in or under any patent,
                  trademark, copyright or proprietary right of ePayNet or any
                  third party.
                  <br></br>
                  5.2 You may not use, frame or utilize framing techniques to
                  enclose any ePayNet trademark, logo or other proprietary
                  information (including the images found at this App, the
                  content of any text or the layout/design of any card or form
                  contained on a page) without ePayNet‘s written consent.
                  Further, you may not use any meta tags or any other “hidden
                  text” utilizing an ePayNet name, trademark or product name
                  without ePayNet‘s written consent.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>User Conduct</h6>
                  6.1 In using this App, and any of the services it offers, you
                  agree:
                  <br />
                  6.1.1 Not to use the Mobile App in such a way that is in
                  violation of any applicable law or regulation;
                  <br />
                  6.1.2 Not to disrupt or interfere with the security of, or
                  otherwise abuse, the App, or any services, system resources,
                  accounts, servers or networks connected to or accessible
                  through the App;
                  <br />
                  6.1.3 Not to violate, or attempt to violate the security of
                  ePayNet. Violations of system or network security may result
                  in civil or criminal liability. ePayNet will investigate
                  occurrences, which may involve such violations and may
                  involve, and cooperate with, law enforcement authorities in
                  prosecuting users who are involved in such violations.
                  <br />
                  6.1.4 Not to disrupt or interfere with any other user’s
                  satisfaction of the App
                  <br />
                  6.1.5 Not to upload, post or otherwise transmit through or on
                  this App any viruses or other harmful, disruptive or
                  destructive files, spam, chain messages, junk mail, SMS or any
                  other type of unsolicited mass email to people or entities who
                  have not agreed to be part of such mailings;
                  <br />
                  6.2 In addition, you agree that you are solely responsible for
                  actions and communications undertaken or transmitted under
                  your account and that you will comply with all applicable
                  local, national and international laws and regulations in this
                  regard.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Indemnification</h6>
                  You shall remain solely liable for the content of any
                  information you upload or transmit to ePayNet. You agree to
                  indemnify and hold harmless ePayNet from any demand, loss or
                  damages (including attorneys’ fees, including incidental and
                  consequential), claim, action, made or incurred by any third
                  party arising out of or relating to your conduct, your
                  violation or failure to comply with the security obligations
                  contained in the terms and conditions, or any typos on the
                  recipient details or your violation of any rights of a third
                  party or unauthorized access to your confidential information.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Submissions</h6>
                  You agree that any materials, including but not limited to
                  suggestions, ideas, plans, notes, drawings, original or
                  creative materials or other information, provided by you in
                  the form of email or submissions to ePayNet, or postings on
                  this App, are non-confidential and shall become the sole
                  property of ePayNet. All intellectual property rights shall be
                  entitled to the unrestricted use of such materials for any
                  purpose, commercial or otherwise, without acknowledgment or
                  compensation to you.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Email Disclaimer</h6>
                  9.1 Every information in all e-mail messages (document and
                  attachments) sent by an employee / our system / a legal member
                  of ePayNet is confidential. The transmitted information is
                  intended only for the person(s) or entity to which it is
                  addressed. If you are not the intended recipient(s) of any
                  e-mail messages (document and attachments) sent by an employee
                  / our system / a legal member of ePayNet, please notify the
                  sender immediately and then delete this email (document and
                  attachments). Do not disclose or make any copies of the
                  contents of this email (document and attachments).
                  <br />
                  9.2 Internet communications cannot be guaranteed to be secure
                  or error-free. Neither ePayNet nor the sender accepts
                  liability for any errors or omissions in the contents of
                  messages which arise as a result of email transmission. Also
                  take note that email can possibly contain viruses and that the
                  recipient is responsible for checking and deleting viruses.
                  <br />
                  9.3 No employee is authorized to conclude a binding agreement
                  on behalf of ePayNet by email without the express written
                  confirmation of a director of ePayNet and such email shall be
                  interpreted as none legally binding.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Acceptance Of Funds</h6>
                  10.1 By registering with ePayNet, you appoint ePayNet as your
                  agent to process payments on your behalf in accordance with
                  your instructions, guided and governed by the terms and
                  restrictions of the terms and conditions. ePayNet agrees to
                  hold your funds separate from its corporate funds, will not
                  use your funds for its operating expenses or any other
                  corporate purposes, and will not voluntarily make funds
                  available to its creditors in the event of bankruptcy or for
                  any other purpose.
                  <br />
                  10.2 You acknowledge that ePayNet is not a bank and the
                  Service you are provided with is a payment processing service
                  rather than a banking service, and ePayNet is not acting as a
                  trustee, fiduciary or escrow with respect to your funds, but
                  is acting only as an agent and custodian.
                  <br />
                  10.3 ePayNet reserves the right to hold funds beyond the
                  normal distribution periods for transactions it deems
                  suspicious or for accounts conducting high transaction volumes
                  to ensure integrity of the funds.
                  <br />
                  10.4 We shall not be responsible for payments made to
                  unintended Recipients or for payments made in incorrect
                  amounts due to the input of incorrect information by Senders.
                  <br />
                  10.5 We shall not be obligated to pay a User for any Payments
                  for which ePayNet has not been fully paid by the Sender’s
                  Mobile Money, credit card issuer or bank, as applicable.
                  <br />
                  10.6 ePayNet reserves the right to require Users who receive
                  payments through the Service and operate with high reversal
                  rates or otherwise present a relatively high risk of losses to
                  choose between closure of their ePayNet account or entering
                  into an additional mitigate agreement for such risks, which
                  may include higher fees.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Credit Cards</h6>
                  11.1 ePayNet reserves the right to charge the user, an issuing
                  fee per transaction for issuing and maintaining the
                  transaction details on its systems. You acknowledge and agree
                  that we may collect and retain the following personal
                  information from and about you:
                  <br />
                  11.2 ePayNet is fully entitled to, at any time, claim back
                  funds from a User which were deemed, or exposed to be, the
                  result of a fraudulent transaction. The User gives ePayNet the
                  right to debit the funds directly from their ePayNet account
                  or by debiting their nominated bank account directly, should
                  the funds in their ePayNet account be insufficient.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>IMPOSED LIMITS AND DELAYS</h6>
                  12.1 ePayNet reserves the right to impose an upper limit on
                  the amount of a single transaction which will be accepted
                  through the Service.
                  <br />
                  12.2 ePayNet reserves the right to impose and regulate certain
                  limits with regards to the flow of funds in the system
                  including funds sent, funds received and funds disbursed.
                  Details of what these limits are will be available on the
                  Mobile App.
                  <br />
                  12.3 ePayNet reserves the right to freeze or suspend any
                  account in the system for an indefinite period if there is
                  suspicion of any illegal activity taking place or for any
                  other reason whatsoever.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Prohibited Transactions</h6>
                  13.1 You agree that you will only use the Service to accept
                  payments for services where payment is due (school fees and
                  utility bills), offering, mobile money to bank transfers and
                  bank to mobile money transfers.
                  <br />
                  13.2 You agree that you will not use the Service to accept
                  payment for illegal products or services, including but not
                  limited to materials that infringe the intellectual property
                  rights of third parties.
                  <br />
                  13.3 You will not use ePayNet App or any of the services
                  offered therein for any unlawful or fraudulent activity. If
                  ePayNet has reason to believe that you may be engaging in or
                  have engaged in fraudulent, unlawful, or improper activity,
                  including but not limited to any violation of any terms and
                  conditions of this Agreement, your access to the Service may
                  be suspended or terminated.
                  <br />
                  13.4 You will fully cooperate with ePayNet to investigate any
                  suspected unlawful, fraudulent or inopportune activity.
                  <br />
                  13.5 You agree not to impersonate an ePayNet User or an
                  ePayNet representative, or to request that an ePayNet customer
                  provide you with their password or other information to access
                  their account.
                  <br />
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Payouts</h6>
                  14.1 Your funds will be transferred electronically to your
                  nominated bank account (Eswatini banks only).
                  <br />
                  14.2 Each Payout may incur a fee.
                  <br />
                  14.3 We shall not be held responsible for incorrect banking
                  details being provided by the User. It is the User’s
                  responsibility to ensure the accuracy of the provided banking
                  details.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Deposits And Balances</h6>
                  15.1 You acknowledge that ePayNet does not hold a
                  deposit-taking license (a banking license) and cannot hold
                  funds on your behalf which are not the proceeds of payments
                  due to you from the payments which ePayNet processed on your
                  behalf.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>DISCLAIMER OF WARRANTIES</h6>
                  17.1 YOU UNDERSTAND AND AGREE THAT THIS APP AND THE
                  INFORMATION, SERVICES, PRODUCTS AND MATERIALS AVAILABLE
                  THROUGH IT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
                  BASIS. YOU EXPRESSLY AGREE THAT USE OF THIS APP IS AT YOUR
                  SOLE RISK.
                  <br />
                  17.2 TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE
                  LAW, EPAYNET AND ITS AFFILIATES DISCLAIM ALL WARRANTIES OF ANY
                  KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
                  ANY WARRANTIES OF TITLE, OR IMPLIED WARRANTIES OF
                  MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NO ORAL
                  OR WRITTEN INFORMATION PROVIDED BY EPAYNET OR ITS AFFILIATES,
                  OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PROVIDERS, MERCHANTS,
                  SPONSORS, LICENSORS OR THE LIKE SHALL CREATE A WARRANTY; NOR
                  SHALL YOU RELY ON ANY SUCH INFORMATION OR ADVICE.
                  <br />
                  17.3 YOU EXPRESSLY AGREE THAT USE OF THIS APP, INCLUDING ALL
                  CONTENT, DATA OR SOFTWARE DISTRIBUTED BY, DOWNLOADED OR
                  ACCESSED FROM OR THROUGH THIS APP, IS AT YOUR SOLE RISK. YOU
                  UNDERSTAND AND AGREE THAT YOU WILL BE SOLELY RESPONSIBLE FOR
                  ANY DAMAGE TO YOUR BUSINESS OR YOUR COMPUTER SYSTEM OR LOSS OF
                  DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH CONTENT, DATA
                  AND/OR SOFTWARE.
                  <br />
                  17.4 YOU ACKNOWLEDGE THAT EPAYNET DOES NOT CONTROL IN ANY
                  RESPECT ANY INFORMATION, PRODUCTS OR SERVICES OFFERED BY THIRD
                  PARTIES ON OR THROUGH THIS APP, EXCEPT AS OTHERWISE AGREED IN
                  WRITING, EPAYNET AND ITS AFFILIATES ASSUME NO RESPONSIBILITY
                  FOR AND MAKE NO WARRANTY OR REPRESENTATION AS TO THE ACCURACY,
                  CURRENCY, COMPLETENESS, RELIABILITY OR USEFULNESS OF CONTENT
                  OR PRODUCTS DISTRIBUTED OR MADE AVAILABLE BY THIRD PARTIES
                  THROUGH THIS APP.
                  <br />
                  17.5 EPAYNET DOES NOT MAKE ANY WARRANTY THAT THIS APP OR ITS
                  CONTENT WILL MEET YOUR REQUIREMENTS, OR THAT THE APP OR
                  CONTENT WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE,
                  OR THAT DEFECTS, IF ANY, WILL BE CORRECTED. EPAYNET DOES NOT
                  REPRESENT OR WARRANT THAT MATERIALS IN THIS APP OR INFORMATION
                  PROVIDED BY EPAYNET VIA EMAIL OR OTHER MEANS, ARE ACCURATE,
                  COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. NOR DOES EPAYNET
                  MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM
                  USE OF EPAYNET OR ITS CONTENT OR AS TO THE ACCURACY,
                  COMPLETENESS OR RELIABILITY OF ANY INFORMATION OBTAINED
                  THROUGH USE OF THE APP.
                  <br />
                  17.6 EPAYNET ASSUMES NO RESPONSIBILITY FOR ANY DAMAGES
                  SUFFERED BY A USER, INCLUDING, BUT NOT LIMITED TO, LOSS OF
                  DATA FROM DELAYS, NONDELIVERIES OF CONTENT OR EMAIL, ERRORS,
                  SYSTEM DOWN TIME, MISDELIVERIES OF CONTENT OR EMAIL, NETWORK
                  OR SYSTEM OUTAGES, FILE CORRUPTION OR SERVICE INTERRUPTIONS
                  CAUSED BY THE NEGLIGENCE OF EPAYNET, ITS AFFILIATES, ITS
                  LICENSORS OR A USER’S OWN ERRORS AND/OR OMISSIONS.
                  <br />
                  17.7 EPAYNET DISCLAIMS ANY WARRANTY OR REPRESENTATION THAT
                  CONFIDENTIALITY OF INFORMATION TRANSMITTED THROUGH THIS WEB
                  APP WILL BE MAINTAINED.
                  <br />
                  17.8 ALL THE INFORMATION APPEARING ON THIS APP IS PROVIDED
                  WITHOUT A REPRESENTATION OR WARRANTY WHATSOEVER, WHETHER
                  EXPRESSED OR IMPLIED, AND EPAYNET DISCLAIMS ANY LIABILITY TO
                  THE USER IN THIS REGARD.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>Limitation Of Liability</h6>
                  18.1 ePayNet has taken reasonable steps as far as is possible,
                  to ensure the accuracy and completeness of the content shown
                  on this App, to ensure that the user doesn’t suffer any loss
                  or damages as a result of the use of this App.
                  <br />
                  18.2 UNDER NO CIRCUMSTANCES, INCLUDING WITHOUT LIMITATION
                  NEGLIGENCE, SHALL EPAYNET OR ITS PARENTS, SUBSIDIARIES,
                  AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS
                  OR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING,
                  TRANSMITTING, OR DISTRIBUTING EPAYNET OR RELATED SERVICES BE
                  LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                  PUNITIVE DAMAGES ARISING FROM OR IN CONNECTION WITH THE USE OR
                  INABILITY TO USE EPAYNET OR ANY CONTENT PROVIDED BY OR THROUGH
                  THIS APP, OR RESULTING FROM UNAUTHORIZED ACCESS TO OR
                  ALTERATION OF YOUR TRANSMISSIONS OR DATA, OR OTHER INFORMATION
                  THAT IS SENT OR RECEIVED, INCLUDING BUT NOT LIMITED TO DAMAGES
                  FOR LOST PROFITS, USE, DATA OR OTHER INTANGIBLES, EVEN IF
                  EPAYNET HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  <br />
                  18.3 EPAYNET SHALL HAVE NO LIABILITY TO YOU IN CONNECTION WITH
                  ANY ACTIONS OR TRANSACTIONS BY AN INDIVIDUAL THAT USES YOUR
                  USER NAME AND PASSWORD WITH YOUR CONSENT OR KNOWLEDGE OR ANY
                  CAUSE OVER WHICH EPAYNET DOES NOT HAVE DIRECT CONTROL,
                  INCLUDING, BUT NOT LIMITED TO, PROBLEMS ATTRIBUTABLE TO
                  COMPUTER HARDWARE OR SOFTWARE (INCLUDING COMPUTER VIRUSES),
                  TELEPHONES (OR OTHER COMMUNICATIONS EQUIPMENT), OR INTERNET
                  SERVICE PROVIDERS.
                  <br />
                  18.4 EPAYNET SHALL HAVE NO LIABILITY TO YOU IN CONNECTION WITH
                  ANY PRODUCT, SERVICE OR OTHERWISE, PURCHASED OR USED AS A
                  RESULT OF THE USE OF THIS APP. IT IS EXPRESSLY UNDERSTOOD THAT
                  THE USER IS AWARE THAT EPAYNET PROVIDES A SERVICE TO SERVICE
                  OFFERER AND SERVICE CONSUMER TOGETHER, AND ACCEPTS NO
                  RESPONSIBILITY FOR THE QUALITY, RELIABILITY, SAFETY, FUNCTION,
                  SUITABILITY OR OTHERWISE, OF A PRODUCT PURCHASED, SERVICE
                  USED, OR OTHERWISE, AS A RESULT OF THE USE OF THIS APP.
                  <br />
                  18.5 ePayNet shall not be liable for any fraud, deception or
                  misrepresentations by Users of the Service in any capacity
                  whatsoever.
                  <br />
                  18.6 In no event shall ePayNet be liable for any act or
                  omission of any third party (such as, for example, any
                  provider of telecommunications services, internet access or
                  computer equipment or software) or for any circumstances
                  beyond our control (such as, for example, a fire, flood, or
                  other natural disaster, war, riot, strike, act of civil or
                  military authority, equipment failure, computer virus, or
                  failure or interruption of electrical, telecommunications or
                  other utility services).
                  <br />
                  18.7 Some jurisdictions do not allow the limitation or
                  exclusion of liability for incidental or consequential
                  damages, in such jurisdictions, liability is limited to the
                  fullest extent permitted by law.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>USER’S LIABILITY TO EPAYNET</h6>
                  In the event that ePayNet takes action against you for breach
                  of these terms of use, you agree to reimburse ePayNet for all
                  legal costs, including tracing fees and collection commission,
                  on a scale as between attorney and own client.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>APPLICABLE LAW</h6>
                  The terms and conditions pertaining to any products or
                  services appearing on this App shall be governed and
                  interpreted in accordance with the laws of the Kingdom of
                  Eswatini. Use of the service offered on this App or pages will
                  constitute the users consent and submission to the
                  jurisdiction of the Eswatini Magistrate court regarding all
                  proceedings, transactions, applications or the like instituted
                  by either party against the other, arising from any of the
                  terms and conditions pertaining to this App.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>TERMINATION</h6>
                  Notwithstanding any of these terms and conditions, ePayNet
                  reserves the right, without notice and in its sole discretion,
                  to terminate your license to use this App, and to block or
                  prevent future access to and use of this App.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>SEVERABILITY</h6>
                  If any provision of this end user agreement is held to be
                  invalid, void or for any reason unenforceable, then that
                  provision shall be deemed severable from this end user
                  agreement and shall not affect the validity and enforceability
                  of any remaining provisions.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>TERMS</h6>
                  If any provision of this end user agreement is held to be
                  invalid, void or for any reason unenforceable, then that
                  provision shall be deemed severable from this end user
                  agreement and shall not affect the validity and enforceability
                  of any remaining provisions.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <h6>TERMS</h6>
                  23.1 The terms “The User” and “You” are used interchangeably
                  and refer to all individuals and/or entities accessing this
                  App for any reason.
                  <br />
                  23.2 The terms “we” and “ePayNet” are used interchangeably and
                  refer to ePayNet itself.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={2} />
        </Grid>
      </Grid>
    </main>
  );
};

export default PrivacyPolicy;
