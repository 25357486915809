import React, { useEffect, useContext, useState } from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";

import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Loader from "../Loader/Loader";

export const InvoiceStatement = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { baseURL, dataReceiver } = useContext(CartContext);
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  let appUser = loginDetails.organisations_id;
  let username = loginDetails.username;
  const [transactionHistory, setTransactionHistory] = React.useState([]);
  let transactionHistoryDetails = {};
  const dataDec = dataReceiver;

  useEffect(() => {
    fetchMyHistory();
  }, []);

  const fetchMyHistory = async () => {
    setLoading(true);

    const res = await axios
      .post(
        baseURL +
          "bridgeGetMyUserDebtorTransactionHistory.php?companyID=" +
          appUser
      )
      .then(function (response) {
        response.data = dataDec(response.data);

        if (response.data !== "") {
          setTransactionHistory(response.data);
          transactionHistoryDetails = response.data;
          setLoading(false);
        } else {
          alert("Connection error, Please try again later");
          setLoading(false);
        }
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item className={classes.sidebar}></Grid>

        <Grid item container spacing={4}>
          {/*
                    <Grid item xs={12} sm={4} />
              
                  <Grid item xs={12} sm={4}>
      
                 */}
          <Grid item xs={false} sm={2} md={3} lg={4} />

          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} align="center">
                <Typography align="center" variant="body1">
                  Transaction History
                </Typography>
              </Grid>
            </Grid>
            {transactionHistory.map((transaction) => (
              <Card
                gutterBottom
                style={{ padding: "10px", marginTop: 10 }}
                key={transaction.id}
              >
                <CardContent>
                  <Typography variant="body2" gutterBottom>
                    <b>
                      {transaction.debtorsName} : {transaction.cellNumber}{" "}
                    </b>
                  </Typography>
                  <hr></hr>

                  <Typography variant="body2" gutterBottom>
                    Transaction #: <b>{transaction.debtNumber} </b>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Using : <b>{transaction.cellNumber} </b>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Reference: <b>{transaction.theirNarration}</b>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Paid an Amount of: <b>E{transaction.totalBalance} </b>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Total Debt Amount:<b> E{transaction.totalBalance}</b>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Date:<b> {transaction.closeDate}</b>
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};
export default InvoiceStatement;
