import React, {useContext, useState} from 'react';
import {Card, CardContent, CardHeader, CardActions, Grid, Button, Typography} from '@material-ui/core';
import useStyles from '../../Login/styles';
import { CartContext } from '../../../Context/CartContext';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';


const ApprovedRequest = ({ loading, product, usersRight}) => {
          
  
     const classes = useStyles();
     const history = useHistory();
     const [ currentPage, setCurrentPage] = useState(1);
     const [ productsPerPage, setProductsPerPage] = useState(24);
     const [ events, setEvents] = useState([]);
     const [ modalIsOpen, setIsOpen] = React.useState(false);
     Modal.setAppElement(document.getElementById('root'));
     let cartItem = {};
     let subtitle;
    


const goToConfirmBid = () => history.push({
    
    pathname: '/confirmbidtransaction',
    requestState:cartItem 
    

});


   const selectBid = () =>{

        openModal();

   }


     function openModal(product) {

    //  setBidDetails(product);
      setIsOpen(true);

  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  
  
  }

  function closeModal() {

    setIsOpen(false);

  }





const previewRequest = () =>{

    cartItem = {id:product.id, name:product.requestNumber, price:product.amount, date:product.maturityDate }
               goToConfirmBid()       
}

      const indexOfLastProduct = currentPage * productsPerPage;
      const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
      let currentProducts = events.slice(indexOfFirstProduct, indexOfLastProduct);


   if(loading){
    
        return <h2>Loading..</h2>
    }



    return (

      <>
        <Card >   
            <CardContent>
                <div className={classes.cardContent}>

                    <Typography variant="body1" gutterBottom>
                     <b> {product.requestNumber}</b>
                    </Typography>
                    <hr/>
                      
                      
                    <Typography variant="body1" gutterBottom>
                     Clients Name: <b>{product.clientName}</b>
                    </Typography>  


                    <Typography variant="body1" gutterBottom>
                     Suppliers Name: <b>{product.supplierName}</b>
                    </Typography> 

                    <Typography variant="body1" gutterBottom>
                     Industry: <b>{product.sectorName}</b>
                    </Typography>  
                   

                    <Typography variant="body1" gutterBottom>
                       Invoice Amount: <b> E{product.invoiceAmount}</b>
                    </Typography>

                    
                    <Typography variant="body1" gutterBottom>
                       Funding Amount: <b> E{product.requestedAmount}</b>
                    </Typography>


                    <Typography variant="body1" gutterBottom>
                     Gross Profit Margin: <b>E{product.netProfitMargin}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                     Total Funding Amount: <b>E{product.totalAmount}</b>
                    </Typography>

                      
                    <Typography variant="body1" gutterBottom>
                     Settled Balance: <b>E{product.settlementBalance}</b>
                    </Typography>
                      
                    
                    <Typography variant="body1" gutterBottom>
                      Status: <b>{product.requestStatus}</b>
                    </Typography>


                    <Typography variant="body1" gutterBottom>
                     Due Date: <b>{product.requestDueDate}</b>
                    </Typography>

                </div>
            </CardContent>
            <CardActions disableSpacing className={classes.cardActions}>

           
        <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=>selectBid()}>
                    <span className={classes.btnTxt}>View Details</span>
        </Button>                  


            
    </CardActions>            
  </Card>







  <>
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
             
              style={{
                    overlay: {
                      position: 'fixed',
                      top: 30,
                      left: 10,
                      right: 10,
                      bottom: 5,
                      zIndex: 1020,
                      backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                      position: 'absolute',
                      top: '40px',
                      left: '40px',
                      right: '40px',
                      bottom: '40px',
                      zIndex: 1020,
                      overflowY: 'auto',
                      border: '1px solid #ccc',
                      background: '#fff',
                      overflow: 'auto',
                      WebkitOverflowScrolling: 'touch',
                      borderRadius: '4px',
                      outline: 'none',
                      padding: '20px'
                    }
                  }}
            >
        


  <Grid container spacing={2}>

      <Grid item xs={12} >
        <Typography align="right" variant="body1">
          <Button type="button" align="right" onClick={closeModal} style={{color:'white', backgroundColor:'tomato', align:'right'}}>
                X
          </Button>
        </Typography>
        <Typography align="center" variant="h6">
                    <b>{product.transactionNumber} </b>
        </Typography>
          </Grid>
        </Grid>  

       <hr /> 

        <CardContent>
             <Typography variant="body1" gutterBottom>
                     <b> {product.requestNumber}</b>
                    </Typography>
                    <hr/>
                      
                      
                    <Typography variant="body1" gutterBottom>
                       Requested Amount: <b> E{product.requestedAmount}</b>
                    </Typography>

                    
                    <Typography variant="body1" gutterBottom>
                       Biddable Amount: <b> E{product.currentBiddableAmount}</b>
                    </Typography>

        </CardContent>

        <Card>

                   <CardContent>

                   </CardContent>
                    

        </Card>
  
      </Modal>
   </>


</>  

  )
}
export default ApprovedRequest;