import React, {useState, useEffect, useMemo, useContext} from 'react';
import { Grid, CardHeader, MenuItem, TextField, Card, CardContent, Button, Typography } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext'; 
import Loader from '../../components/Loader/Loader';
import InputAdornment from '@material-ui/core/InputAdornment';
import CallIcon from '@material-ui/icons/Call';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import useStyles from '../../components/Login/styles';
import { useHistory,Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';

import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from "@mui/icons-material/Search";




export const GuestEventsDashboard = () => {

    const classes = useStyles();
    const history = useHistory();   
    const [ error, setError] = useState('');
    const { baseURL, dataReceiver, loginDetailsBag, tongleCartIconBag} = useContext(CartContext);
    const [ loginDetails, setLoginDetails] = loginDetailsBag;
    const [tongleCartIcon, setTongleCartIcon] = tongleCartIconBag;
    const [isEpaynetCheckout, setIsEpaynetCheckout] = useState(false);
    const [ events, setEvents] = useState([]);
    const [ event, setEvent] = useState('');
    const [ username, setUsername] = useState('');
    const [ fullName, setFullName]= useState(''); 
    const [ loading, setLoading] = useState(false);
    const [ open, setOpen] = useState(false);
    const [ eventStalls, setEventStalls] = useState([]);
    const [ amount, setAmount] = useState('');
    const [ isActiveVoucher, setIsActiveVoucher] = useState(false);
    const [ registeredVoucher, setRegisteredVoucher] = useState({});
    const [ eventStallCategs, setEventStallCategs] = useState([]);
    const [ eventStall, setEventStall] = useState('');
    const [ eventDetails, setEventDetails] = useState('');
    const [ stallDetails, setStallDetails] = useState('');
    const [ pocketIdentifier, setPocketIdentifier] = useState('');
    const [ identifierDetails, setIdentifierDetails] = useState({});
    const [ eventOccupiedStalls, setEventOccupiedStall] = useState([]);
    const [ myOccupiedStall, setMyEventOccupiedStall] = useState('');
    const [ allOccupiedStalls, setAllOccupiedStalls] = useState([]);
    const [ clientDetails, setClientDetails] = useState([]);
    const [ pinNumber, setPinNumber] = useState('');
    const [ reference, setReference] = useState('');
    const dataDec  = dataReceiver;
    let pocketIdentifierDetails = {};
    let responseMessageBag = {};

    const [value, setValue] = useState('');
    let myresponse = '';



  useEffect(()=> {
          
         getEvents(); 
         setTongleCartIcon(!true);

   }, [])


const getEvents = async()=>{
  
  const res = await axios.post(baseURL+'bridge_evPoc_GetEvents.php' ).then(function (response) {
      
      response.data = dataDec(response.data);
      setEvents(response.data);
            
  });
}    


const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setEvent(event.target.value);
      let eventID = event.target.value;

    for (let i = 0; i < events.length; i++) { 
         let allEventsBagID = events[i].id

           if(eventID === allEventsBagID){

                  setEventDetails(events[i]);

              }
        }

      
      let data = {eventID:eventID}

    const res = axios.post(baseURL+'bridge_evPoc_GetEventStallCategories.php',data ).then(function (response) {
      
      response.data = dataDec(response.data);
      setEventStallCategs(response.data);
            
  });
       
}


const handleEventStallChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
            setEventStall(event.target.value);
            let selectedCategoryID = event.target.value;
            let stallID = stallDetails.id;
            let eventID = eventDetails.id;
            setValue('');

       for (let i = 0; i < eventStallCategs.length; i++) {              

         let stallsBagID = eventStallCategs[i].id

           if(selectedCategoryID === stallsBagID){

                  setStallDetails(eventStallCategs[i]);

              }
        }
        

         let data = {eventID:eventID, stallCategoryID:selectedCategoryID}

            
       const res = axios.post(baseURL+'bridge_evPoc_GetEventOccupiedStalls.php',data ).then(function (response) {
      
            response.data = dataDec(response.data);
            setEventOccupiedStall(response.data);
                  
        });  

  }



const handleStallOwnerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
              
            
            setMyEventOccupiedStall(event.id);
            let selectedStall = event.id;


       for (let i = 0; i < eventOccupiedStalls.length; i++) { 
            

           let occupiedStallsBagID = eventOccupiedStalls[i].id

               if(selectedStall === occupiedStallsBagID){

                        setAllOccupiedStalls(eventOccupiedStalls[i]);

                  }
            
            }   
        
       }

const makeStallOrder = async() =>{
      
     
        let data = {eventsID:event, cellNumberRef:pocketIdentifier, event:eventDetails.eventName}
         
           /*CHECK IF POCKET EXIST */
           
  const res = await axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php',data ).then(function (response) {
        
                response.data = dataDec(response.data);
                let myDetails = response.data;   
            let mycontainer = Object.keys(myDetails).length;
              

           if(mycontainer > 0){
                

                 let responseData = response.data[0];  
                 let usersCurrentBalance = responseData.currentBalance
                     pocketIdentifierDetails = responseData
                     setClientDetails(responseData)

                if(usersCurrentBalance <= amount){

                    //VALIDATE AMOUNT BALANCE
                      setError('Your balance is :E'+usersCurrentBalance+' You order :E'+amount+' Please Top-Up');
                      setIsEpaynetCheckout(true);
                      setTongleCartIcon(true);
                      
                     }else{

                        setIsEpaynetCheckout(true); 
                        setTongleCartIcon(true)

                  
                    }  

                  //VOUCHER NOT FOUND        
                   }else{

                        setError('The Voucher you entered does not exist');

                   } 

                              
                      
       });  

}  


const triggerStallOrderPayment = (pocketIdentifierDetails) =>{
      

        let cellRef = clientDetails.cellNumberRef;
        let voucherID = clientDetails.id;
        let ref = reference;
        let amnt = amount;
        let eventIdentity = eventDetails.id;
        let stallIdentity = allOccupiedStalls.id;
        let stallAccount = allOccupiedStalls.momoAccount;
        let companyID = loginDetails.organisations_id;
        let selectedClient = clientDetails.fullName;
        let selectedClientBalance = clientDetails.currentBalance;
             
          if(!companyID){

                setError('You are not an ePayNet user.');
                return 0;
            }

      let data = {cellNumberRef:cellRef, attendeeRechargeUnitsID:voucherID, reference:ref, amount:amnt, eventsID:eventIdentity, occupiedStallID:stallIdentity, toAccount:stallAccount, ePayNetCompanyID:companyID, voucherPinNumber:pinNumber};
  
      const res = axios.post(baseURL+'bridge_evPoc_ReceiveAttendeeStallTransaction.php', data ).then(function (response) {
            
        response.data = dataDec(response.data);
        
        responseMessageBag = response.data;

          if(response.data){

              goToComplete()
          }   
              
  });  

}


const goToComplete = () => history.push({
    
    pathname: '/pocketrechargestatus',
    responseMessageState:responseMessageBag
    
});


const handleSubmit = () => history.push({ 

       pathname: '/buyticket' 

});

const retryLater = () => history.push({ 

       pathname: '/eventhome' 

});


const getEventOppupiedStalls = async() =>{
        
    let data = {eventID:event};
     //   checkVoucher();

    const res = await axios.post(baseURL+'bridge_evPoc_GetEventStallCategories.php', data ).then(function (response) {
      
      response.data = dataDec(response.data); 
      setEventStalls(response.data);
      
  });

}


 
const stallOrderPayment = async() =>{

 let data = {eventsID:event, cellNumberRef:pocketIdentifier};
        

    const res = await axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php', data ).then(function (response) {
      
        response.data = dataDec(response.data);
   
    if(response.data){
            

      let responseSize = Object.keys(response.data).length;
      setIdentifierDetails(response.data[0]);
      pocketIdentifierDetails = response.data[0];



      if(responseSize > 0){

          myresponse = response.data;
          setRegisteredVoucher(myresponse[0]);
          triggerStallOrderPayment(pocketIdentifierDetails);
          setIsActiveVoucher(true);
          setError('');
          

        }else{
                              
           setError('This voucher is not registered for this event');
           setIsActiveVoucher('');
        
        }
      
      }    
       
    });
 }


      const defaultProps = {
                options: eventOccupiedStalls,
                key:eventOccupiedStalls.id,
                getOptionLabel: (option: FilmOptionType, index:FilmOptionType.id) => option.businessName ? option.businessName : "" 
              };       


if(loading){

        return  (
          
           <Loader/>
         
           )
    }

return (
    <main className = {classes.content} style={{backgroundColor:"ghostwhite"}}>
            <div className={classes.toolbar} />

   
         <Grid container direction="column" >

                  <Grid item ></Grid>

             <Grid item container spacing={2}>
           
{/*-------------------------------------------------------------------------
                                PAGE SIDE MENU START
-------------------------------------------------------------------------*/}  
          
             <Grid item xs={false} sm={4} md={4} lg={4} >
                
                
             </Grid>
          
          

        
{/*------------------------------------------------------------------------------
                                   PAGE SIDE MENU END
----------------------------------------------------------------------------*/}     
           
{/*------------------------------------------------------------------------------
                                GET EVENT AVAILABLE STALLS START
---------------------------------------------------------------------------------*/}              
                 
    <Grid item xs={12} sm={4} md={4} lg={4} >                

            <Card >
                 {!isEpaynetCheckout ?            
                   <>        
                           
                      {error ?
                          <Typography style={{color:"red", align:"center"}} align='center'>

                               <b> {error}</b>
                           </Typography>
                             :
                           <Typography align="center" variant="body1">
                                        <b> Stall Payments</b>
                           </Typography>
                      }       
                             
                      <hr />
                  
                   <CardContent>
                         
                      

                                 
                    <Typography variant="body1" gutterBottom>                
                        <TextField label="Select Event" variant="outlined" fullWidth size="small" select
                        onChange={handleEventChange}
                        value={event}> 
                            {events.map((option) => (
                                 <MenuItem key={option.id} value= {option.id} >
                                           {option.eventName}
                                 </MenuItem>
                            ))}
                        </TextField>
                    </Typography>

                    <Typography variant="body1" gutterBottom>                
                        <TextField label="Select Stall Category" variant="outlined" fullWidth size="small" select
                        onChange={handleEventStallChange}
                        value={eventStall}> 
                            {eventStallCategs.map((option) => (
                                 <MenuItem key={option.id} value= {option.id} >
                                           {option.description}
                                 </MenuItem>
                            ))}
                        </TextField>
                    </Typography>


                    <Typography variant="body1" gutterBottom> 
                        <Autocomplete
                           {...defaultProps}
                           value={value}
                          onChange={(event: any, newValue: FilmOptionType | null) => {
                            setValue(newValue);
                            handleStallOwnerChange(newValue)
                          }}
                          renderInput={(params, index) => (
                            <TextField {...params} key={index} label="Select Stall Owner" variant="outlined" fullWidth size="small" />
                          )}
                          />
                    </Typography> 



                        <Typography variant="h4" gutterBottom>
                                <TextField label="Amount" variant="outlined" type="text" fullWidth size="small"  placeholder="Item Amount" 
                                onChange={(e)=>setAmount(e.target.value)}
                                 />
                        </Typography>


                        <Typography variant="h4" gutterBottom>
                                <TextField label="Voucher Number" variant="outlined" type="text" fullWidth size="small"  placeholder="Voucher" 
                                onChange={(e)=>setPocketIdentifier(e.target.value)}
                                />
                    </Typography>


                     {/*<Typography variant="h4" gutterBottom>
                                <TextField label="Reference" variant="outlined" type="text" fullWidth size="small" placeholder="Reference" 
                                onChange={(e)=>setReference(e.target.value)}
                                />
                    </Typography>*/}

                              
              <Backdrop className={classes.backdrop} open={open} >
                         <Loader/>
              </Backdrop>
              
              <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', color:'white', backgroundColor:'#155054'}} onClick={()=> makeStallOrder()} > Submit </Button>
                 
     </CardContent>
     </>

     :

     <>

                    <Typography align="center" variant="body1">
                                               <b> Confirm Transaction</b>
                             </Typography>  
                             <div style={{color:"red", align:"center"}} align='center'>
                               <b>   {error}</b><br/>
                      </div> 
                      <hr />
                  
             <CardContent>                   
                      
                    
                      

                    <Typography variant="body1" gutterBottom>                
                      Full Name <b>: {clientDetails.fullName}</b>
                    </Typography>       

                     <Typography variant="body1" gutterBottom>                
                       Cell Number <b>: {clientDetails.cellNumber}</b>
                    </Typography>

                     <Typography variant="body1" gutterBottom>                
                       Voucher <b>: {/*clientDetails.cellNumberRef*/}*****</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>                
                      Event Name <b>: {eventDetails.eventName}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>                
                       Stall Category<b>: {stallDetails.categoryName}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>                
                      Occupied Stall<b>: {allOccupiedStalls.businessName}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>                
                     Stall Account<b>: {allOccupiedStalls.momoAccount}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>                
                       Amount <b>: E{amount} </b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>                
                      <b> Your Current Balance : E{clientDetails.currentBalance} </b>
                    </Typography>

                    <Typography variant="body1" style={{color:'red', fontSize:'10px'}}>                
                       <span >
                          <b>
                            * This transaction is irreversable
                          </b>
                       </span>
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                                <TextField label="PIN Number" variant="outlined" type="password" fullWidth size="small"  placeholder="Voucher PIN" 
                                onChange={(e)=>setPinNumber(e.target.value)}
                                      />
                    </Typography>


                              
              <Backdrop className={classes.backdrop} open={open} >
                         <Loader/>
              </Backdrop>
           {error ?

               
                
                <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', color:'white', backgroundColor:'#155054'}} onClick={()=> retryLater()} > Try Again Later </Button>  

             

              :

               <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', color:'white', backgroundColor:'#155054'}} onClick={()=> stallOrderPayment()} > Pay </Button>  

           }
                 
     </CardContent>


     </>


}



  </Card>
  
</Grid>
{/*------------------------------------------------------------------------------
                                FIND STALLS FORM END
---------------------------------------------------------------------------------*/}           

                     <Grid item xs={false} sm={4} md={4} lg={4}>
                 
                     </Grid>
                 </Grid>


        {/*<Grid item xs={12} sm={12} md={12} lg={12} >
           
                 <Card >
                        <Typography align="center" variant="body2">
                              <b>  Event Stalls </b>
                        </Typography>  
                      
                      <hr />                  
                   <CardContent>
                      <Grid item container spacing={1} style={{height: "100%" }}>
                        {eventStalls.map((event)=> ( 
                           <Grid item xs={12} sm={4} md={4} lg={4}>
                              <Event event={event} voucher={pocketIdentifier} key={event.id}/>
                           </Grid> 
                         ))}
                      </Grid>     
                   </CardContent>  
                 </Card>

          </Grid>*/}

              </Grid>
      </main>
      )
}

export default GuestEventsDashboard;