import React from "react";
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import useStyles from "../../Login/styles";
import { useHistory } from "react-router-dom";
import ShareIcon from "@material-ui/icons/Share";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../../Loader/Loader";

export const MyLoans = (loans) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const [loanHistory, setLoanHistory] = React.useState([]);
  const [loanToBePaid, setLoanToBePaid] = React.useState([]);
  const [loanToShare, setLoanToShare] = React.useState([]);
  let loanToShareDetailsBag = {};
  let loanToPayDetailsBag = {};

  const selectLoanToPay = () => {
    const loanToPay = {
      id: loans.loans.id,
      name: loans.loans.companyName,
      transactionNumber: loans.loans.transactionNumber,
      amountToPay: loans.loans.totalAmountDue,
      sharedLoan: loans.loans.sharedLoan,
    };

    setLoanToBePaid(loanToBePaid);
    loanToPayDetailsBag = loanToPay;
    goToLoanPayment();
  };

  const goToLoanPayment = () =>
    history.push({
      pathname: "/loanpayment",
      loanToPayState: loanToPayDetailsBag,
    });

  const goToLoanSharing = () =>
    history.push({
      pathname: "/shareLoanContact",
      loanToShareState: loanToShareDetailsBag,
    });

  const openShareLoan = () => {
    const loanToShare = {
      id: loans.loans.id,
      name: loans.loans.companyName,
      transactionNumber: loans.loans.transactionNumber,
      amountToPay: loans.loans.totalAmountDue,
    };

    setLoanToShare(loanToShare);
    loanToShareDetailsBag = loanToShare;

    goToLoanSharing();
  };

  return (
    <main className={classes.content}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            <b>
              {" "}
              {loans.loans.transactionNumber}
              <IconButton
                color="primary"
                edge="start"
                aria-label="share"
                onClick={openShareLoan}
              >
                <ShareIcon style={{marginLeft: '10px'}}/>
              </IconButton>
            </b>

            <Backdrop className={classes.backdrop} open={open}>
              <Loader />
            </Backdrop>
          </Typography>
          <hr></hr>

          <Typography variant="body2" gutterBottom>
            Company:<b> {loans.loans.companyName}</b>
          </Typography>

          <Typography variant="body2" gutterBottom>
            Amount: <b> E{loans.loans.borrowedAmount}</b>
          </Typography>

          <Typography variant="body2" gutterBottom>
            Interest Amount: <b>E{loans.loans.interestAmount}</b>
          </Typography>

          <Typography variant="body2" gutterBottom>
            Due Date: <b>{loans.loans.created_at}</b>
          </Typography>

          <Typography variant="body2" gutterBottom>
            Total Balance Amount: <b> E{loans.loans.totalAmountDue}</b>
          </Typography>

          <Button
            className={classes.button}
            type="button"
            variant="contained"
            fullWidth
            color="primary"
            style={{
              margin: "5px",
              textTransaform: "none",
              backgroundColor: "#155054",
              color: "white",
            }}
            onClick={() => selectLoanToPay()}
          >
            {" "}
            Pay Loan
          </Button>
        </CardContent>
      </Card>
    </main>
  );
};

export default MyLoans;
