import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Loader from "../Loader/Loader";
import Backdrop from "@material-ui/core/Backdrop";

import global_styles from "../../index.module.css";
import styles from "../Dashboard/Dashboard.module.css";
import local_styles from '../Loans/LoansLandingPage.module.css'
import card_styles from "../../components/ServiceCard/ServiceCard.module.css";
import ServiceCard from "../ServiceCard/ServiceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faMoneyBill } from "@fortawesome/free-solid-svg-icons";

export const BusinessMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver, usersFeaturesBag } =
    useContext(CartContext);
  const [open, setOpen] = useState(false);
  const [userFeatureStatuses, setUserFeatureStatuses] = usersFeaturesBag;
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  let appUserID = loginDetails.organisations_id;
  const [activeStatus, setActiveStatus] = useState([]);
  const dataDec = dataReceiver;
  let eCommerceStatus = loginDetails.isLegalBusiness;
  let legalBusinessMemberAccount = loginDetails.isLegalBusiness;
  let p2pFunding = loginDetails.isCrowdFundingParticipant;

  useEffect(() => {}, []);

  const openBulkTransactions = () =>
    history.push({
      pathname: "/businessauthentication",
    });

  const openCrowFunding = () =>
    history.push({
      pathname: "/businessinvestment",
    });

  return (
    <main className={classes.content}>
      <div>
        <div className={styles.showcase}>
          <div className={styles.username + " " + global_styles.container}>
            <p>Welcome, {loginDetails.fullName}</p>
          </div>

          <div
            className={
              styles.slideshow__container + " " + global_styles.container
            }
          >
            <div className={styles.showcase__slider}>
              <div className={styles.slides}>
                <div className={styles.contents}>
                  <h2>Business Account</h2>
                  <p style={{textWrap: 'wrap'}}>Bulk payments at your comfort with ePayNet Business</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={global_styles.container + " " + local_styles.service__cards}
          style={{ margin: "10px auto", display: "flex", gap: "15px" }}
        >
          <ServiceCard
            onClick={() => openCrowFunding()}
            title={"ePayNet P2P Funding"}
            icon={
              <FontAwesomeIcon
                icon={faAlignLeft}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            onClick={() => openBulkTransactions()}
            title={"ePayNet Business"}
            icon={
              <FontAwesomeIcon
                icon={faMoneyBill}
                className={card_styles.card__icon}
              />
            }
          />
        </div>
      </div>
    </main>
  );
};

export default BusinessMenu;
