import React, {useContext, useState} from 'react';
import {Card, CardContent, CardActions, Button, Typography} from '@material-ui/core';
import useStyles from '../../Login/styles';
import { CartContext } from '../../../Context/CartContext';
import { useHistory } from 'react-router-dom';


const Request = ({ loading, product, usersRight}) => {
            

     const classes = useStyles();
     const history = useHistory();
     const [ currentPage, setCurrentPage] = useState(1);
     const [ productsPerPage, setProductsPerPage] = useState(24);
     const [ events, setEvents] = useState([]);
     let cartItem = {};
     let subtitle;



const goToConfirmBid = () => history.push({
    
    pathname: '/confirmbidtransaction',
    requestState:cartItem 
    

});

   const selectBid = () =>{

     cartItem = product;
     goToConfirmBid()

   }


const previewRequest = () =>{

    cartItem = {id:product.id, name:product.requestNumber, price:product.amount, date:product.maturityDate }
               goToConfirmBid()       
}

      const indexOfLastProduct = currentPage * productsPerPage;
      const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
      let currentProducts = events.slice(indexOfFirstProduct, indexOfLastProduct);


   if(loading){
    
        return <h2>Loading..</h2>
    }



    return (

      <>

        <Card >
           
            <CardContent>
                <div className={classes.cardContent}>

                    <Typography variant="body1" gutterBottom>
                     <b> {product.requestNumber}</b>
                    </Typography>
                    <hr/>
                      
        
                    <Typography variant="body1" gutterBottom>
                     Industry: <b>{product.sectorName}</b>
                    </Typography>  
                    

                    {/*<Typography variant="body1" gutterBottom>
                     Industry Sector: <b>{product.industrySectors_id}</b>
                    </Typography>*/} 
        
                    <Typography variant="body1" gutterBottom>
                     Amount: <b>E{product.totalAmount}</b>
                    </Typography>


                    <Typography variant="body1" gutterBottom>
                     Biddable Amount: <b>E{product.currentBiddableAmount}</b>
                    </Typography>    

                    <Typography variant="body1" gutterBottom>
                     Settled Amount: <b>E{product.settlementBalance}</b>
                    </Typography>


                    <Typography variant="body1" gutterBottom>
                     Closing/ Due Date: <b>{product.requestDueDate}</b>
                    </Typography>


                </div>
            </CardContent>
            <CardActions disableSpacing className={classes.cardActions}>

           
        <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=>selectBid()}>
                    <span className={classes.btnTxt}>Make Bid </span>
        </Button>                  


            
    </CardActions>            
  </Card>
</>  

  )
}
export default Request;