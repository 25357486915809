import React, { useEffect,useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles';	
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';

export const LoanPayment = () => {
	
 const classes = useStyles(); 
 const history = useHistory();
 const { loginDetailsBag, baseURL, dataReceiver }= useContext(CartContext);
 const [ loginDetails, setLoginDetails] = loginDetailsBag;
 const [open, setOpen] = React.useState(false);
 const [ paymentPlatforms, setPaymentPlatforms] = React.useState([]);
 const [ selectedPaymentPlatform, setSelectedPaymentPlatform] = React.useState([]);
 const [ amount, setAmount ] = React.useState([]);    
 const loanToPayDetailsBag = history.location.loanToPayState;
 let selectedPaymentPlatformsID = '';
 let responseDetailsBag = {};
 const dataDec  = dataReceiver;


useEffect(()=> {
      

      const fetchPaymentPlatform = async()=>{
          
          setOpen(!open);
          const res = await axios.get(baseURL+'bridgeGetMobileMoneyVendors.php');

          res.data = dataDec(res.data);
          setPaymentPlatforms(res.data); 
          setOpen(false);     

      }

      fetchPaymentPlatform();
     
   }, [])

 
   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedPaymentPlatform(event.target.value);
    selectedPaymentPlatformsID = event.target.value
   
  };


const  submitLoan = () => {
        
        setOpen(!open);
        let companyName = loanToPayDetailsBag.name;
        let lenderMemberTransactionID = loanToPayDetailsBag.id;
        let sharedLoan = loanToPayDetailsBag.sharedLoan;

    const submitLoanPayment = async()=>{

           const res = await axios.get(baseURL+'bridgeBorrowerDebtPayment.php?lenderMemberTransactionID='+lenderMemberTransactionID+'&paymentMethodID='+selectedPaymentPlatform+'&amount='+amount+'&sharedLoan='+sharedLoan);
          
            res.data = dataDec(res.data);

              if(res.data != ''){
                    
                     responseDetailsBag = res.data;
                     goToLoanPaymentStatus();       
                     setOpen(false);
  
                      }else{

                      alert('Connection error, Please try again later');
                      setOpen(false);
                   } 
             }

      submitLoanPayment();
}


const goToLoanPaymentStatus = () => history.push({ 
  
        pathname: '/loanpaymentstatus',
        responseState: responseDetailsBag

});



	return (
      <main className = {classes.content}>
            <div className={classes.toolbar}/>
  <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        
                        <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>  

                       {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/} 
                            <Card >

                               <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                                 </Backdrop>


                          <CardHeader title="Loan Payments"  align="center" />
                               <CardContent>

                                  
                          <Typography variant="h6" gutterBottom>                
                                 
                            Transaction Number :<b>{loanToPayDetailsBag.transactionNumber}</b>
                          </Typography>
                          

                          <Typography variant="h6" gutterBottom>                
                                 
                             Company: <b>{loanToPayDetailsBag.name}</b>
                          </Typography>
                            <Typography variant="h6" gutterBottom>                
                                 
                            Toatal Amount Due: <b>{loanToPayDetailsBag.amountToPay}</b>
                          </Typography>

                          
                           <Typography variant="h4" gutterBottom>
                                      <TextField label="Amount" variant="outlined" fullWidth size="small"
                                      placeholder="Amount To Pay"  
                                        type="text"
                                       id="amount"
                                       name="amount"
                                       onChange={(e)=>setAmount(e.target.value)}
                                                                          
                                      />
                                      </Typography> 
                           
                           <Typography variant="body1" gutterBottom>                
                                 <TextField label="Payment Platform" variant="outlined"  fullWidth    size="small" select
                                  onChange={handleChange}
                                  value={selectedPaymentPlatform}
                                  > 

                                    {paymentPlatforms.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.vendorName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>
                         




                               
                  <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> submitLoan()} > Pay</Button>

                                 

 

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                    <Grid item xs={false} sm={2} md={3} lg={4}/>  
                 </Grid>

              </Grid>




  </main>

	);
}



export default LoanPayment;