import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import useStyles from "../Login/styles";
import Loader from "../Loader/Loader";

// import styles & iconography
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// import Axios client
import axios from "axios";

export const Deposits = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const dataDec = dataReceiver;

  // user account details
  let fetchedBorrowerLenders = {};
  let userID = loginDetails.organisations_id;
  let borrowerMoMoAccount = loginDetails.momoAccountNumber;

  const [lenderCompanies, setLenderCompanies] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  // capture input fields
  const [lenderCompaniesID, setLenderCompaniesID] = useState("");
  const [paymentMethodsID, setPaymentMethodsID] = useState("");
  const [membershipNumber, setMembershipNumber] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [reference, setReference] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [isPendingApproval, setIsPendingApproval] = useState(false);
  const [depositStatusResponse, setDepositStatusResponse] = useState({});

  const [responseMessage, setResponseMessage] = useState("");

  const getLenderID = async () => {
    setOpen(!open);

    let payload = {companies_id: userID}

    try {
        const { data } = await axios.post(
          baseURL + "bridge_hub_getMyActiveLenders.php", JSON.stringify(payload)
        );

      const response = dataDec(data);
      setLenderCompanies(response.response);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const getPaymentMethods = async () => {
    setOpen(!open);

    try {
      const { data } = await axios.get(
        baseURL + "bridgeGetMobileMoneyVendors.php"
      );

      const response = dataDec(data);
      setPaymentMethods(response);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    getPaymentMethods();
    getLenderID();
  }, []);

  const goToDepositStatus = () =>
    history.push({
      pathname: "/depositStatus",
      depositRequestState: depositStatusResponse,
    });

  const checkObjType = history.location.loginState;

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  const makeDeposit = async () => {
    setOpen(!open);

    let payload = {
      lenderCompanies_id: lenderCompaniesID,
      membershipNumber: membershipNumber,
      depositAmount: depositAmount,
      reference: reference,
      paymentMethods_id: paymentMethodsID,
      companies_id: loginDetails.organisations_id,
      cellNumber: loginDetails.momoAccountNumber,
    };

    try {
      const { data } = await axios.post(
        baseURL + "bridge_hub_receiveMemberTransaction.php",
        JSON.stringify(payload)
      );
      const response = dataDec(data);

      let responseStatus = response?.status;

      if (responseStatus === "200") {
        setResponseMessage(response.message);
        setDepositStatusResponse(response); // Update state instead of direct assignment

        setIsPendingApproval(true);
        setOpen(false);
      } else {
        setErrorMessage(response.message);
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <CardHeader
                  title="Deposit into your Transactional Account"
                  align="center"
                />

                {isPendingApproval ? (
                  <>
                    {responseMessage && (
                      <div>
                        <Typography variant="body1" gutterBottom>
                          Status:{" "}
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            {responseMessage}
                          </span>
                        </Typography>
                        Dial <strong>*007#</strong> on your mobile to approve
                        the transation
                      </div>
                    )}
                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => goToDepositStatus()}>
                      Approve & Finish
                      <FontAwesomeIcon
                        style={{ marginLeft: "15px" }}
                        icon={faArrowRight}
                      />
                    </Button>
                  </>
                ) : (
                  <>
                    {errorMessage && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginBottom: "30px",
                        }}>
                        <b>{errorMessage}</b>
                      </div>
                    )}

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="Select Lender ID"
                        variant="outlined"
                        fullWidth
                        size="small"
                        select
                        onChange={(e) => setLenderCompaniesID(e.target.value)}
                        value={lenderCompaniesID}>
                        {lenderCompanies.length > 0 && lenderCompanies.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.companyName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="Membership Number"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Membership Number"
                        type="text"
                        id="membershipNumber"
                        name="membershipNumber"
                        onChange={(e) => setMembershipNumber(e.target.value)}
                      />
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="Deposit Amount"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Deposit Amount"
                        type="text"
                        id="depositAmount"
                        name="depositAmount"
                        onChange={(e) => setDepositAmount(e.target.value)}
                      />
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="Reference"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Reference"
                        type="text"
                        id="reference"
                        name="reference"
                        onChange={(e) => setReference(e.target.value)}
                      />
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="Payment Method"
                        variant="outlined"
                        fullWidth
                        size="small"
                        select
                        onChange={(e) => setPaymentMethodsID(e.target.value)}
                        value={paymentMethodsID}>
                        {paymentMethods.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.vendorName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Typography>

                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => makeDeposit()}>
                      {open ? (
                        "Processing..."
                      ) : (
                        <>
                          Make Deposit
                          <FontAwesomeIcon
                            style={{ marginLeft: "10px" }}
                            icon={faArrowRight}
                          />
                        </>
                      )}
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
      <div className={classes.toolbar} />
    </main>
  );
};

export default Deposits;
