import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
} from "@material-ui/core";
import useStyles from "../Login/styles";

export const About = () => {
  const classes = useStyles();
  let dt = new Date();
  let year = dt.getFullYear();

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />

          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <CardHeader title="About ePayNet" align="center" />
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  <h6 align="center">ePayNet A200322*</h6>
                  <br />
                  <b> Developer:</b> Digimage <br />
                  <b> Legal Status:</b> A private company with limited liability
                  incorporated in the Kingdom of Eswatini.
                  <br />
                  <b> Physical Address:</b> Office #54, 5th Floor Mbandzeni
                  House. Karl Grant Road, Mbabane. Eswatini
                  <br />
                  <b> Telephone Number:</b> +268 2404 0524
                  <br />
                  <b> Cell Number:</b> +268 7823 4777
                  <br />
                  <b> Website:</b>{" "}
                  <a href="https://salvtec.co.sz">https://salvtec.co.sz</a>{" "}
                  <br />{" "}
                  <a href="https://epaynet.salvtec.co.sz">
                    https://epaynet.salvtec.co.sz
                  </a>
                  <br />
                  <b> E-mail:</b>{" "}
                  <a href="mailto:digimage@salvtec.co.sz">
                    digimage@salvtec.co.sz
                  </a>
                  <br />
                  <br />
                  <div align="center" style={{ align: "center" }}>
                    {" "}
                    Digimage © {year}
                  </div>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};
export default About;
