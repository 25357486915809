import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { FundingRequest } from "./FundingRequest/FundingRequest";
import { useHistory } from "react-router-dom";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Loader from "../Loader/Loader";
import Backdrop from "@material-ui/core/Backdrop";
import global_styles from "../../index.module.css";

export const RequestFunding = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver, p2pFundingDetailsBag } =
    useContext(CartContext);
  const [open, setOpen] = useState(false);
  const [p2pFundingDetails, setP2pFundingDetails] = p2pFundingDetailsBag;
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [activeStatus, setActiveStatus] = useState([]);
  const [consentValue, setConsentValue] = useState("");
  const [fullName, setFullName] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [contact, setContact] = useState("");
  const [requiredAmount, setRequiredAmount] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [industry, setIndustry] = useState("");
  const [supplies, setSupplies] = useState("");
  const [supplyName, setSupplyName] = useState("");
  const [selectedFeature, setSelectedFeature] = useState("");
  const [featureName, setFeatureName] = useState("");
  const [industrySectors, setIndustrySectors] = useState([]);
  const [fundingRequests, setFundingRequests] = useState([]);
  const [clientName, setClientName] = useState("");
  const [clientContact, setClientContact] = useState("");
  const [requestDueDate, setRequestDueDate] = useState("");
  const [error, setError] = useState("");
  let appUserID = loginDetails.organisations_id;
  let eCommerceStatus = loginDetails.isLegalBusiness;
  const [suppliesCategory, setSuppliesCategory] = useState([
    { id: "1", name: "Services" },
    { id: "2", name: "Supplies" },
  ]);
  const consent = [
    { id: 1, name: "Yes", value: "T" },
    { id: 2, name: "No", value: "F" },
  ];

  let requestedDetails = {};
  let selectedConsent = "";
  let status = "";
  let featureID = "";
  let featuresName = "";
  let mySelectedFeature = "";
  const dataDec = dataReceiver;

  useEffect(() => {
    setOpen(!open);
    setFullName(loginDetails.fullName);
    setCellNumber(loginDetails.momoAccountNumber);
    getIndustrySectors();
    getMyFundingRequests();
    getAppUnits();
  }, []);

  const getIndustrySectors = async () => {
    const res = axios
      .post(baseURL + "bridge_iFund_GetIndustrySectors.php")
      .then(function (response) {
        response.data = dataDec(response.data);
        setIndustrySectors(response.data);
      });
  };

  const getAppUnits = () => {
    let ePayNetID = loginDetails.organisations_id;
    let loggedInUsersID = loginDetails.companyUID;
    let data = {
      ePayNetCompanyID: ePayNetID,
      ePayNetLoggedInUserID: loggedInUsersID,
    };

    const res = axios
      .post(
        baseURL + "/bridge_iFund_ePayNetCompanyCrowdFundingStatus.php",
        data
      )
      .then(function (response) {
        response.data = dataDec(response.data);
        let crowdfundingData = response.data;
        setP2pFundingDetails(crowdfundingData[0]);
      });
  };

  const handleSupplyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupplies(event.target.value);
  };

  const getMyFundingRequests = async () => {
    let companyID = loginDetails.organisations_id;
    let data = { ePayNetCompanyID: companyID };

    const res = axios
      .post(baseURL + "bridge_iFund_GetMyFundingRequests.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);
        setFundingRequests(response.data);
        setOpen(false);
      });
  };

  const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentValue(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIndustry(event.target.value);
    let mySelectedIndustry = event.target.value;

    for (let i = 0; i < industrySectors.length; i++) {
      let industryID = industrySectors[i].id;

      if (industryID == mySelectedIndustry) {
        let industryName = industrySectors[i].description;
        setIndustryName(industryName);
      }
    }
  };

  const callScrollTop = () => {
    window.scrollTo(0, 0);
  };

  const updateePayNetStatus = async () => {
    let companyID = loginDetails.organisations_id;
    let username = loginDetails.username;
    let action = selectedStatus;
    let data = { companyID: companyID, username: username, action: action };

    const res = await axios
      .post(baseURL + "/bridgeReceiveEpayNetWebAccountAction.php", data)
      .then(function (response) {
        if (response.data.code == 200) {
          setActiveStatus(response.data);
        } else {
          setActiveStatus(response.data);
        }
      });
  };

  const handleProcessRequest = async () => {
    setOpen(!open);
    let fromAccount = loginDetails.momoAccountNumber;
    let companyID = loginDetails.organisations_id;
    let ePayNetLoggedInUserCompanyID = loginDetails.companyUID;

    if (consentValue !== "2") {
      if (clientContact == "") {
        setError("Clients Contact is required");
        setOpen(false);
        callScrollTop();
        return 0;
      }

      if (clientName == "") {
        setError("Clients Name is required");
        setOpen(false);
        callScrollTop();
        return 0;
      }

      if (supplyName == "") {
        setError("Suppliers Name is required");
        setOpen(false);
        callScrollTop();
        return 0;
      }

      if (invoiceAmount == "") {
        setError("Invoice amount is required");
        setOpen(false);
        callScrollTop();
        return 0;
      }

      if (industryName == "") {
        setError("Please select an industry");
        setOpen(false);
        callScrollTop();
        return 0;
      }

      if (requestDueDate == "") {
        setError("Please enter the date which this request will expire");
        setOpen(false);
        callScrollTop();
        return 0;
      }

      const data = {
        requesterFullName: fullName,
        clientsContact: clientContact,
        cellNumber: cellNumber,
        fundingAmount: requiredAmount,
        clientName: clientName,
        supplierName: supplyName,
        invoiceAmount: invoiceAmount,
        industrySectorsID: industry,
        supplies: supplies,
        industryName: industryName,
        fromAccount: fromAccount,
        requestDueDate: requestDueDate,
        ePayNetCompanyID: companyID,
        ePayNetLoggedInUserID: ePayNetLoggedInUserCompanyID,
      };

      const res = axios
        .post(baseURL + "bridge_iFund_FundingRequest.php", data)
        .then(function (response) {
          response.data = dataDec(response.data);

          if (response.data.code !== "201") {
            requestedDetails = response.data;
            setOpen(false);
            goToConfirmRequest();
          } else {
            setError(response.data.error);
            setOpen(false);
            callScrollTop();
            return 0;
          }
        });
    } else {
      setError(
        "Please select YES on the consent to comply with ePayNet fund requesting terms"
      );
      callScrollTop();
      setOpen(false);
    }
  };

  const goToConfirmRequest = () =>
    history.push({
      pathname: "/confirmrequest",
      requestState: requestedDetails,
    });

  const openBulkTransactions = () =>
    history.push({
      pathname: "/businessauthentication",
    });

  const openCrowFunding = () =>
    history.push({
      pathname: "/crowdfunding",
    });

  const openRequests = () =>
    history.push({
      pathname: "/requests",
    });

  const openApprovedRequest = () =>
    history.push({
      pathname: "/businessapprovedrequests",
    });

  const openRecharge = () =>
    history.push({
      pathname: "/recharge",
    });

  return (
    <main className={classes.content + " " + global_styles.container}>
      <div className={classes.toolbar} />

      <Grid container direction="column" className={classes.root}>
        <Grid item></Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardContent>
                <Typography variant="body1" gutterBottom>
                  To make a request you have to click the button below and to
                  buy units for creating a request.
                </Typography>
                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="inherit"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => openRecharge()}
                >
                  Recharge
                </Button>

                <hr />
                <Typography variant="body1" gutterBottom>
                  To preview previously uploaded request and that has been
                  approved click on the button below.
                </Typography>
                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="inherit"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => openApprovedRequest()}
                >
                  View Approved Requests
                </Button>
              </CardContent>
            </Card>

            <Card>
              <CardHeader title="My Requests" align="center" />

              {fundingRequests.map((myRequest) => (
                <FundingRequest myRequest={myRequest} key={myRequest.id} />
              ))}
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              {error ? (
                <div
                  style={{ color: "red", align: "center", paddingTop: "15px" }}
                  align="center"
                >
                  {error}
                </div>
              ) : (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} align="center">
                      <Typography align="center" variant="body1">
                        <b> Welcome, {loginDetails.fullName}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
              <hr />

              <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                  <Typography align="center" variant="body1">
                    <h2>Request Funding</h2>
                  </Typography>
                </Grid>
              </Grid>

              <CardContent>
                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Client Name"
                    variant="outlined"
                    type="text"
                    fullWidth
                    size="small"
                    placeholder="Client Name"
                    onChange={(e) => setClientName(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Client Contact"
                    variant="outlined"
                    type="text"
                    fullWidth
                    size="small"
                    placeholder="Client Contact"
                    onChange={(e) => setClientContact(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Supplier Name"
                    variant="outlined"
                    type="text"
                    fullWidth
                    size="small"
                    placeholder="Supplier Name"
                    onChange={(e) => setSupplyName(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Invoice Amount"
                    variant="outlined"
                    type="text"
                    fullWidth
                    size="small"
                    placeholder="Invoice Amount"
                    onChange={(e) => setInvoiceAmount(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Required Amount"
                    variant="outlined"
                    type="text"
                    fullWidth
                    size="small"
                    placeholder="Needed Amount"
                    onChange={(e) => setRequiredAmount(e.target.value)}
                  />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <TextField
                    label="Due Date"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="date"
                    placeholder="Request Closing Date"
                    id="requestDueDate"
                    name="requestDueDate"
                    value={requestDueDate}
                    onChange={(e) => setRequestDueDate(e.target.value)}
                  />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <TextField
                    label="Select Industry"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleChange}
                    value={industry}
                  >
                    {industrySectors.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.sectorName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <TextField
                    label="Select Supplies"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleSupplyChange}
                    value={supplies}
                  >
                    {suppliesCategory.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  I hereby consent that the information provided above is
                  correct and accurate. I also acknowledge that it is my
                  responsibility to update this information should there be any
                  changes to the above.
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Consent"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleConsentChange}
                    value={consentValue}
                  >
                    {consent.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
                <br></br>

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="inherit"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => handleProcessRequest()}
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
};

export default RequestFunding;
