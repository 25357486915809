import React,{useState} from 'react';
import {Card,  CardContent, IconButton, Typography, Button} from '@material-ui/core';
import useStyles from '../../Login/styles'; 
import { useHistory } from 'react-router-dom';
import ShareIcon from '@material-ui/icons/Share';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../../Loader/Loader';

   
export const ServiceItem = (loans) => {
        


       const classes = useStyles();  
       const history = useHistory();
       const [open, setOpen] = useState(false); 
       
       const [ loanHistory, setLoanHistory ] = useState([]);
       const [ loanToBePaid, setLoanToBePaid ] = useState([]);
       const [ loanToShare, setLoanToShare ] = useState([]);
       let loanToShareDetailsBag = {};
       let loanToPayDetailsBag = {};
 
 const selectLoanToPay = () =>{
   
       const loanToPay = {id:loans.loans.id, name:loans.loans.companyName, transactionNumber:loans.loans.transactionNumber,amountToPay:loans.loans.totalAmountDue,sharedLoan:loans.loans.sharedLoan }
   
          setLoanToBePaid(loanToBePaid);  
          loanToPayDetailsBag = loanToPay;
          goToLoanPayment();

} 

const goToLoanPayment = () => history.push({ 
  
        pathname: '/loanpayment',
        loanToPayState: loanToPayDetailsBag

});

const goToLoanSharing = () => history.push({ 
  
        pathname: '/shareLoanContact',
        loanToShareState: loanToShareDetailsBag

});

const openShareLoan = () =>{
    
      const loanToShare = {id:loans.loans.id, name:loans.loans.companyName, transactionNumber:loans.loans.transactionNumber,amountToPay:loans.loans.totalAmountDue }
   
      setLoanToShare(loanToShare);  
      loanToShareDetailsBag = loanToShare;

      goToLoanSharing();
  
} 

  return (
  
         <Card>
           <CardContent>                             
                  <Typography variant="body2" gutterBottom>                
                                 
                     <b> 
                      {loans.loans.transactionNumber} 
                     </b>
                     <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                     </Backdrop>

                  </Typography>
                           <hr></hr> 

                          <Typography variant="body2" gutterBottom>                
                             Package Name:<b> {loans.loans.packageName}</b>
                          </Typography>

                           <Typography variant="body2" gutterBottom> 
                             DSTV Number:<b> {loans.loans.dstvNumber}</b>
                          </Typography>


                          <Typography variant="body2" gutterBottom> 
                             From Account:<b> {loans.loans.fromAccount}</b>
                          </Typography>


                          <Typography variant="body2" gutterBottom>                 
                             Amount: <b>E{loans.loans.amount}</b> 
                          </Typography>

                          
                          <Typography variant="body2" gutterBottom> 
                             Payment Date: <b>{loans.loans.created_at}</b>
                          </Typography> 

                          
                          <Typography variant="body2" gutterBottom> 
                            Transaction Status:<b> {loans.loans.transactionStatus}</b>
                          </Typography>
           
                          
                         </CardContent>
             </Card>

  );
}

export default ServiceItem;