import React from "react";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import useStyles from "../../Login/styles";
import { useHistory, useLocation } from "react-router-dom";

export const TransactionHistory = ({ transaction }) => {
  const classes = useStyles();
  const history = useHistory();
  const [proofOfPayment, setProofOfPayment] = React.useState([]);
  let proofOfPaymentDetailsBag = {};
  const location = useLocation();

  const sendProofOfPayment = () => {
    const proofOfPaymentData = {
      transactionNumber: transaction.transactionNumber,
      amount: transaction.amount,
      toAccount: transaction.toAccount,
      fromAccount: transaction.fromAccount,
      reference: transaction.ref,
      status: transaction.transactionStatusName,
      date: transaction.created_at,
    };

    setProofOfPayment(proofOfPaymentData);
    proofOfPaymentDetailsBag = proofOfPaymentData;
    goToProofOfPayment(proofOfPaymentDetailsBag);
  };

  const goToProofOfPayment = (proofOfPaymentDetailsBag) =>
    history.push({
      pathname: "/proofofpayment",
      proofOfPaymentState: proofOfPaymentDetailsBag,
    });

  return (
    <>
      <Typography variant="body2" gutterBottom>
        <b>{transaction.transactionNumber} </b>
      </Typography>
      <hr></hr>

      <Typography variant="body2" gutterBottom>
        You Paid an Amount of:<b>E{transaction.amount} </b>
      </Typography>

      {/*
                          <Typography variant="body2" gutterBottom>                
                             Balance Before:<b>E{transaction.balanceBefore} </b>
                          </Typography>

                          <Typography variant="body2" gutterBottom>                
                             Current Balance:<b>E{transaction.currentBalance} </b>
                          </Typography>
                          */}

      <Typography variant="body2" gutterBottom>
        Using:<b>{transaction.fromAccount} </b>
      </Typography>

      <Typography variant="body2" gutterBottom>
        To: <b>{transaction.toAccount} </b>
      </Typography>

      <Typography variant="body2" gutterBottom>
        Reference: <b>{transaction.reference} </b>
      </Typography>

      <Typography variant="body2" gutterBottom>
        Transaction Date: <b>{transaction.created_at} </b>
      </Typography>

      {location.pathname === "/history" && (
        <>
          <Typography variant="body2" gutterBottom>
            Reference: <b>{transaction.ref}</b>
          </Typography>

          <Typography variant="body2" gutterBottom>
            Transaction Status:<b> {transaction.transactionStatusName}</b>
          </Typography>

          <Typography variant="body2" gutterBottom>
            Total Amount:<b> E{transaction.totalAmount}</b>
          </Typography>

          <Typography variant="body2" gutterBottom>
            Date:<b> {transaction.created_at}</b>
          </Typography>

          <Button
            className={classes.button}
            type="button"
            variant="contained"
            fullWidth
            // color="primary"
            style={{ margin: "3px", textTransaform: "none" }}
            onClick={() => sendProofOfPayment()}
          >
            {" "}
            Send Proof Of Payment
          </Button>
        </>
      )}
    </>
  );
};

export default TransactionHistory;
