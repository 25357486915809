import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";

import global_styles from "../../index.module.css";
import local_styles from "../ILoan/iLoanLandingPage.module.css";
import styles from "../Dashboard/Dashboard.module.css";
import card_styles from "../../components/ServiceCard/ServiceCard.module.css";
import ServiceCard from "../ServiceCard/ServiceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePen,
  faMoneyBill,
  faPaperPlane,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";

export const ILoanLandingPage = () => {
  const history = useHistory();
  const { loginDetailsBag } = useContext(CartContext);

  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const checkObjType = history.location.loginState;
const activationStatus = history.location.activationState;

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(checkObjType);
    history.push({ pathname: "/", state: {} });
  }

  const openActivations = () => {
    history.push({
      pathname: "/activations",
    });
  };

  const openDeposits = () => {
    history.push({
      pathname: "/deposits",
    });
  };

  const openBalances = () => {
    history.push({
      pathname: "/balances",
    });
  };

  const openApplications = () => {
    history.push({
      pathname: "/loan-application",
    });
  };

  const openWithdrawals = () => {
    history.push({
      pathname: "/withdrawals",
    });
  };

  const openTransfers = () => {
    history.push({
      pathname: "/transfer",
    });
  };

  const openRepayments = () => {
    history.push({
      pathname: "/repay-loan",
    });
  };

  return (
    <>
      <div className={styles.showcase}>
        <div style={{paddingTop: "20px"}} className={styles.username + " " + global_styles.container}>
          <p>Welcome, {loginDetails.fullName}</p>	
        </div>
	{activationStatus && <p style={{color: '#245054', padding: "5px 10px", background: "#efcd38",}} className={global_styles.container}>{activationStatus}</p>}

        <div
          className={
            styles.slideshow__container + " " + global_styles.container
          }>
          <div className={styles.showcase__slider}>
            <div className={styles.slides}>
              <div className={styles.contents}>
                <h2>iLoan DFS</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={global_styles.container + " " + local_styles.service__cards}
        style={{ margin: "10px auto", display: "flex", gap: "15px" }}>
        <ServiceCard
          onClick={() => openActivations()}
          title={"Activations"}
          icon={
            <FontAwesomeIcon
              icon={faMoneyBill}
              className={card_styles.card__icon}
            />
          }
        />
        <ServiceCard
          onClick={() => openBalances()}
          title={"Check Balances"}
          icon={
            <FontAwesomeIcon
              icon={faMoneyBill}
              className={card_styles.card__icon}
            />
          }
        />
        <ServiceCard
          onClick={() => openDeposits()}
          title={"Make Deposits"}
          icon={
            <FontAwesomeIcon
              icon={faMoneyBill}
              className={card_styles.card__icon}
            />
          }
        />
        {/*<ServiceCard
          onClick={() => openApplications()}
          title={"Apply for a Loan"}
          icon={
            <FontAwesomeIcon
              icon={faFilePen}
              className={card_styles.card__icon}
            />
          }
        />
        <ServiceCard
          onClick={() => openWithdrawals()}
          title={"Withdrawals"}
          icon={
            <FontAwesomeIcon
              icon={faFilePen}
              className={card_styles.card__icon}
            />
          }
        />
         <ServiceCard
          onClick={() => openTransfers()}
          title={"Transfers"}
          icon={
            <FontAwesomeIcon
              icon={faPaperPlane}
              className={card_styles.card__icon}
            />
          }
        />
        <ServiceCard
          onClick={() => openRepayments()}
          title={"Loan Repayment"}
          icon={
            <FontAwesomeIcon
              icon={faReceipt}
              className={card_styles.card__icon}
            />
          }
        /> */}
      </div>
    </>
  );
};

export default ILoanLandingPage;
