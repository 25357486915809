import React, { useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, TextField} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useStyles from '../Login/styles';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


export const AddShareContact = () => {
	
 const classes = useStyles(); 
 const history = useHistory();
 const { baseURL, dataReceiver, loginDetailsBag } = useContext(CartContext); 
 const [loginDetails, setLoginDetails] = loginDetailsBag;
 const [open, setOpen] = React.useState(false);
 const [ fullName, setFullName ] = React.useState([]);
 const [ cellNumber, setCellNumber ] = React.useState([]); 
 let appUserID = loginDetails.organisations_id;
 const dataDec  = dataReceiver;

    
 const  addContact = () => {
    
        setOpen(!open);
      
        const submitShareWithContact = async()=>{

              const res = await axios.get(baseURL+'bridgeAddFamilyAndFriends.php?appUserCompanyID='+appUserID+'&ffUsername='+cellNumber+'&fullName='+fullName);
             
              res.data = dataDec(res.data);

            
              if(res.data !== ''){
                                        
                     goToContactList();  
                     setOpen(false);     

                  }else{

                      alert('Connection error, Please try again later');
                      setOpen(false);

                    } 

          }

      submitShareWithContact();
     
}

const goToContactList = () => history.push({ 
  
        pathname: '/loansharing'

});

	return (
		
           <main className = {classes.content}>
            <div className={classes.toolbar}/>

   
  <Grid container direction="column">

               <Grid item></Grid>

                 <Grid item container spacing={4}>
                     <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>  
            <Card >
                               <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop> 
               <CardContent>

                  
                  <Typography align="center" variant="body1"><h5>Add New Contact</h5></Typography>

                                 
                     
                 <Typography variant="h4" gutterBottom>
                                      <TextField label="Full Name" variant="outlined" fullWidth size="small"
                                      placeholder="Full Name"  
                                        type="text"
                                       id="FullName"
                                       name="FullName"
                                       onChange={(e)=>setFullName(e.target.value)}
                                                                          
                                      />
                                      </Typography>


                  <Typography variant="h4" gutterBottom>
                                      <TextField label="Cell Number" variant="outlined" fullWidth size="small"
                                      placeholder="Cell Number"  
                                        type="text"
                                       id="cellNumber"
                                       name="cellNumber"
                                       onChange={(e)=>setCellNumber(e.target.value)}
                                                                          
                                      />
                                      </Typography>                    
                         


                            


   <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> addContact()} > Add Contact</Button>
                                 
                           </CardContent>
                         </Card>

                        </Grid>
          
                    <Grid item xs={false} sm={2} md={3} lg={4}/>  
                 </Grid>

              </Grid>

  </main>

	);
}



export default AddShareContact;