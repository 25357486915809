import React,{useContext} from 'react';
import { ListItem} from '@material-ui/core';
import {CartContext} from '../../Context/CartContext'; 
import axios from 'axios';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';


export const AddedRecipients = ({recipient}) => {
          
       const { baseURL } = useContext(CartContext); 
       const { loginDetailsBag, dataReceiver } = useContext(CartContext);
       const [ loginDetails, setLoginDetails ] = loginDetailsBag;
       let appUserID = loginDetails.organisations_id;
       const dataDec  = dataReceiver; 
 
 const deleteRecipient = () =>{
    
      let enrolledCompany = recipient.id;
      let companyCategory = recipient.companyCategories_id;

   const removeRecipient = async()=>{
    
         const res = await axios.get(baseURL+'bridgeDeleteEnrolledCompany.php?enrolledCompanies_id='+enrolledCompany+'&companyCategories_id='+companyCategory+'&logonCompanies_id='+appUserID);


         res.data = dataDec(res.data);
          
      }

      removeRecipient();

  } 


	return (
	
  <>
      <ListItem >
          {recipient.companyName}
           
                <DeleteIcon edge="end" />
                    
        </ListItem>
  </>
	);
}

export default AddedRecipients;