import React, { useEffect,useContext, useState } from 'react';
import {Card, CardContent,  Typography, TextField, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../../Login/styles'; 
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../../Context/CartContext';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import RollBackTransaction from '../Events/RollBackTransaction';
import Backdrop from '@material-ui/core/Backdrop';


export const RollBackHistory = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver }= useContext(CartContext);
       const dataDec  = dataReceiver;
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ open, setOpen] = useState(false);
       const [ error, setError] = useState('');
       const [ events, setEvents] = useState([]); 
       const [ event, setEvent] = useState(''); 
       const [ pocketIdentifier, setPocketIdentifier ] = useState('');
       const [ pinNumber, setPinNumber] = useState(''); 
       const [ transactionsList, setTransactionsList] = useState([]);
       const [ isTransactionFound, setIsTransactionFound] = useState(false);
       let currentProducts = {};  
      


  useEffect(()=> {
          
       getEvents();
     
    }, [])
 

   const getEvents = async()=>{
      
       const res = axios.post(baseURL+'bridge_evPoc_GetEvents.php').then(function (response) {
        
          response.data = dataDec(response.data);
          setEvents(response.data);

        });

 } 


const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setEvent(event.target.value);
       
  }



const findHistory = async()=>{
      
       let data = {cellNumberRef:pocketIdentifier, eventID:event, pocketPin:pinNumber};

      const res = axios.post(baseURL+'bridge_EvPoc_BringMyAttendeeCashoutTransactions.php', data ).then(function (response) {
                  

                   response.data = dataDec(response.data);
                   setIsTransactionFound(true); 
                   setTransactionsList(response.data);
          
           });

   }



const goToTransactionStatus= () => history.push({
    
    pathname: '/pocketrechargestatus',
    responseMessageState:"responseDetails"
    
});
 


  return (

    <main className = {classes.content}>
            <div className={classes.toolbar}/>  
           
              <Backdrop className={classes.backdrop} open={open} >
                       <Loader/>
              </Backdrop>
   
          <Grid container direction="column" justify="center">

                 

                     <Grid container justify="center" spacing={1}> 
                
               {isTransactionFound ?
                    <>
                      {transactionsList.map((transaction)=> ( 
                       <Grid item xs={12} sm={6} md={4} lg={4} key={transaction.id}>  
                           <RollBackTransaction transaction={transaction} /> 
                                    <br/>
                       </Grid>
                      ))}
                    </>
                  :
                  
                  <>
                  <Card> 
              <CardContent >
                        <Typography variant="body1" gutterBottom>                
                            <TextField label="Select Event" variant="outlined" fullWidth size="small" select
                                  onChange={handleEventChange}
                                  value={event}
                                  > 

                                    {events.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.eventName}
                                   </MenuItem>

                                   ))}
                            </TextField>
                        </Typography>

                        <Typography variant="h4" gutterBottom>
                            <TextField label="Voucher Number" variant="outlined" type="text" fullWidth size="small"  placeholder="Voucher" 
                            onChange={(e)=>setPocketIdentifier(e.target.value)}
                                      />
                        </Typography> 


                         <Typography variant="h4" gutterBottom>
                            <TextField label="Voucher Pin" variant="outlined" type="text" fullWidth size="small"  placeholder="PIN" 
                            onChange={(e)=>setPinNumber(e.target.value)}
                                      />
                        </Typography>

            <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin:'5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> findHistory()}>
                 Submit
                </Button>
          </CardContent >
           </Card>
          </> 
       }                                   
          
                           
                   </Grid>        
                       
                    <Grid item xs={false} sm={4}/>
           
              </Grid>

  </main>

  );
}

export default RollBackHistory;