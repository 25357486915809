import React from 'react';
import {Card,  CardContent,  Typography, Button} from '@material-ui/core';
import useStyles from '../../Login/styles';	
import { useHistory } from 'react-router-dom';

   
export const SharedLoans = () => {
	
 const classes = useStyles(); 
 const history = useHistory(); 
 const [ loanToBePaid, setLoanToBePaid ] = React.useState([]);
 let loanHistoryDetails = {};
 let loanToPayDetailsBag = {};
 
 const selectLoanToPay = (loans) =>{
   
 const loanToPay = {id:loans.loans.id, name:loans.loans.companyName, transactionNumber:loans.loans.transactionNumber,amountToPay:loans.loans.totalAmountDue,sharedLoan:loans.loans.sharedLoan }
   

          setLoanToBePaid(loanToBePaid);  
          loanToPayDetailsBag = loanToPay;
          goToLoanPayment();

} 

 
const goToLoanPayment = () => history.push({ 
  
        pathname: '/loanpayment',
        loanToPayState: loanToPayDetailsBag

});


	return (

	  <main className = {classes.content}>
            <Card >     
                <CardContent>               
                      <Typography variant="h6" gutterBottom>               
                                <b> {loanHistoryDetails.transactionNumber}</b>
                      </Typography>
                           <hr></hr> 

                      <Typography variant="h6" gutterBottom>                
                             Company:<b> {loanHistoryDetails.companyName}</b>
                      </Typography>
                      
                      <Typography variant="h6" gutterBottom>                           
                            Amount:<b>{loanHistoryDetails.borrowedAmount}</b>
                      </Typography>

                      <Typography variant="h6" gutterBottom>                

                        Interest Amount: <b>{loanHistoryDetails.interestAmount}</b>
                      </Typography>
                    
                      <Typography variant="h6" gutterBottom>                             
                             Due Date: <b>{loanHistoryDetails.created_at}</b>
                      </Typography> 
                      <Typography variant="h6" gutterBottom>                
                                 
                            Total Balance Amount:<b> {loanHistoryDetails.totalAmountDue}</b>
                          </Typography>
           
                               
                           <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> selectLoanToPay()} > Pay Loan</Button>
                 
                           </CardContent>
                         </Card>



  </main>

	);
}



export default SharedLoans;