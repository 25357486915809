import React, {useContext, useEffect, useState} from 'react';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import useStyles from '../Login/styles';
import BusinessCommitment from './BusinessCommitment/BusinessCommitment';  
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import {CartContext} from '../../Context/CartContext';
import Pagination from '../../components/Pagination/Pagination';
import { useHistory } from 'react-router-dom';



export const BusinessCommitments = () => {
  

   const classes = useStyles(); 
   const history = useHistory();
   const { baseURL, dataReceiver, loginDetailsBag }= useContext(CartContext);
   const [ search, setSearch] = useState('');
   const [ loginDetails, setLoginDetails] = loginDetailsBag;
   const [ currentPage, setCurrentPage] = useState(1);
   const [ productsPerPage, setProductsPerPage] = useState(24);
   const [ investments, setInvestments] = useState([])
   const indexOfLastProduct = currentPage * productsPerPage;
   const indexOfFirstProduct = indexOfLastProduct - productsPerPage; 
   const dataDec = dataReceiver;
   const [ open, setOpen] = useState(false);
   const [ categories, setCategories] = useState([]);
   let selectedCategory = '';  
   




 useEffect(()=> {
    
    setOpen(!open);  	
    window.scrollTo(0, 0); 
    let companyID = loginDetails.organisations_id;
    let data ={ePayNetCompanyID:companyID}

    const res = axios.post(baseURL+'bridge_iFund_GetMyBidTransactions.php', data ).then(function (response) {
                    
                   response.data = dataDec(response.data); 
                   setInvestments(response.data);
                   setOpen(false);            				
      		});


     const resp = axios.post(baseURL+'bridge_iFund_GetIndustrySectors.php', data ).then(function (response) {
                    
                   response.data = dataDec(response.data); 
                   setCategories(response.data);
                   setOpen(false);                    
          });      

   }, [])


   	const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    
          console.log(filteredProducts)
   	
   	};


   //Change Page
     const paginate = pageNumber => setCurrentPage(pageNumber);
     let currentProducts = investments;
     let filteredProducts = [];

  
   if(search != ''){

          filteredProducts = currentProducts.filter(product => {
 
          return product.statusName.toLowerCase().includes(search.toLowerCase());
 
        }); 
       }
   
   
  if(filteredProducts.length > 1){
          currentProducts =   filteredProducts;
        }
   


return ( 

   <main className = {classes.content}>
    
         <div className={classes.toolbar}/>

             <Grid container justify = "center" spacing={2}>
                <Grid item container justify = "center"  xs={12} sm={12} md={4} lg={4}>
                  
                    <TextField label="Search By Category" variant="outlined" fullWidth size="small" select
                             onChange={handleCategoryChange} value={selectedCategory}> 

                                    {filteredProducts.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.statusName}
                                   </MenuItem>

                                   ))}

                                   

                              </TextField>

                   {investments.length} Results                  

                    </Grid>

                </Grid>


                <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                </Backdrop>
              
         <Grid container spacing={2}>

           {investments.map((product, index)=> (   
           
            <Grid item  xs={12} sm={6} md={4} lg={4} key={product.id}> 
                      
              <BusinessCommitment key={product.id} product={product} loading={open}/>
              
            </Grid> 
 	       ))}  
         </Grid>
        
    </main>
    )
}

export default BusinessCommitments;