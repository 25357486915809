import React ,{ useContext} from 'react';
import {Card,  CardContent, IconButton, Typography, Button, Grid} from '@material-ui/core';
import useStyles from '../../Login/styles';	
import { useHistory } from 'react-router-dom';
import ShareIcon from '@material-ui/icons/Share';
import Backdrop from '@material-ui/core/Backdrop';
import {CartContext} from '../../../Context/CartContext';
import Loader from '../../Loader/Loader';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

   
export const SharedInvoices = ({invoice}) => {
	 
       const classes = useStyles();  
       const history = useHistory();
       const [open, setOpen] = React.useState(false); 
       const { loginDetailsBag } = useContext(CartContext);
       const [ loginDetails, setLoginDetails ] = loginDetailsBag;
       let appUser = loginDetails.organisations_id;       
       let invoiceToPayDetailsBag = {};
 
  const goToInvoicePayment = () => history.push({ 
  
        pathname: '/userdebtpayment',
        invoiceToPayState: invoiceToPayDetailsBag

});



const submitDebtPayment = () =>{
    

    const invoiceToPay = {id:invoice.id, fromCompaniesID:invoice.fromCompanies_id, debtNumber:invoice.debtNumber, companyID:appUser, amount:invoice.totalBalance, reference:invoice.theirNarration,remainingBalance:invoice.currentBalance, fullname:invoice.debtorsName,localEFTVendor:'8' }
               
          invoiceToPayDetailsBag = invoiceToPay;
          goToInvoicePayment();
        
} 

	return (
	
      <main className = {classes.content}>

              <Accordion style={{padding:'10px', marginTop:10}} key={invoice.id}>
                    <AccordionSummary   expandIcon={<ExpandMoreIcon />} >
                          <Typography >{invoice.debtorsName} : E{invoice.currentBalance}</Typography>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                               <Typography variant="body1" gutterBottom> 
                                  <br/>               
                                    Invoice Number: {invoice.debtNumber} 
                                      <br/>
                                    Cell Number: {invoice.cellNumber} 
                                      <br/>
                                      Total Balance: E{invoice.totalBalance} 
                                    
                                     <br/>
                                     Installment: E{invoice.installment} 
                                    <br/>
                                     Remaining Amount : E{invoice.currentBalance} 
                                     <br/>
                                   Due Date: {invoice.created_at}  
                                   <br/>
                                   Reference: {invoice.theirNarration} 
                                   <br/><br/> 
                                </Typography>
                       
                  </AccordionDetails>


                  <Grid container direction="column" style={{position: 'absolute', padding:'4px',top:'15',paddingRight:'25'}}>
                 
              <div style={{position: 'absolute',bottom:'0',width:'93%', margin:'10'}}>  
                 <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{margin:'3px',textTransaform:'none'}} onClick={()=> submitDebtPayment()}> Cash Deposit</Button>
                
              </div>  
              
              </Grid>


                </Accordion>



  </main>

	);
}



export default SharedInvoices;