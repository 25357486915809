import React, { useEffect, useContext, useState } from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import TransactionHistory from "./TransactionHistory/TransactionHistory";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Loader from "../Loader/Loader";

export const History = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { baseURL, dataReceiver } = useContext(CartContext);
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const dataDec = dataReceiver;
  let appUser = loginDetails.organisations_id;
  let username = loginDetails.username;
  const [transactionHistory, setTransactionHistory] = React.useState([]);
  let transactionHistoryDetails = {};
  let dt = new Date();
  let seconds = dt.getSeconds();
  let minutes = dt.getMinutes();
  let hours = dt.getHours();
  let year = dt.getFullYear();
  let month = dt.getMonth();
  month = month + 1;
  let day = dt.getDate();

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }

  let headers =
    seconds +
    "" +
    minutes +
    "" +
    hours +
    "" +
    day +
    "" +
    month +
    "" +
    year +
    "" +
    username;
  let headerBody = parseInt(appUser) + parseInt(seconds);
  let token = headers + "" + headerBody;
  let data = { token, username };

  useEffect(() => {
    fetchMyHistory();
  }, []);

  const fetchMyHistory = async () => {
    setLoading(true);
    const res = await axios
      .post(baseURL + "bridgeBringMyTransactions.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);

        if (response.data !== "") {
          setTransactionHistory(response.data);
          transactionHistoryDetails = response.data;
          setLoading(false);
        } else {
          alert("Connection error, Please try again later");
          setLoading(false);
        }
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item className={classes.sidebar}></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />

          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} align="center">
                <Typography align="center" variant="body1">
                  Transaction History
                </Typography>
              </Grid>
            </Grid>

            {transactionHistory.map((transaction) => (
              <Card
                gutterBottom
                style={{ padding: "10px", marginTop: 10 }}
                key={transaction.id}
              >
                <CardContent>
                  <TransactionHistory transaction={transaction} />
                </CardContent>
              </Card>
            ))}
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};
export default History;
