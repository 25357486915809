import React, {useState, useContext} from 'react';
import { Grid, Button, TextField, Card, Paper, IconButton, CardContent, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from '../../components/Login/styles';
import { useHistory, Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../../components/Loader/Loader';
import { CartContext } from '../../Context/CartContext';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; 
import CallIcon from '@material-ui/icons/Call';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@mui/material/Tooltip';

 

export const LoginAccountForm = () => {
  
  

  const classes = useStyles(); 
  const history = useHistory();
  const [ loginError, setLoginError] = useState(false);
  const {baseURL, dataReceiver, loginDetailsBag, epaynetServiceAccountBag} = useContext(CartContext);
  const [ ePaynetServiceAccount, setEpaynetServiceAccount] = epaynetServiceAccountBag;
  const [ open, setOpen] = useState(false);
  const [ username, setUsername] = useState('');
  const [ password, setPassword] = useState('');
  const dataDec  = dataReceiver;



  const handleSubmit = () =>{            

      getServiceAccountUser();


     }


     const getServiceAccountUser = async() =>{
      
      let data = {username:username, password:password}

      const res = axios.post(baseURL+'/ePayNetAccount/bridgeLogin.php' ).then(function (response) {
      
                    
                      response.data = dataDec(response.data);
                      let epaynetServiceAccountResponse = response.data;
                      setEpaynetServiceAccount(epaynetServiceAccountResponse)  
                      goToAppHome();
                      // console.log(epaynetServiceAccountResponse)   
                      // alert(epaynetServiceAccountResponse.balance)  
         });   

         
}
 
 const goToAppHome = () => history.push({
    
    pathname: '/homepage'
    
  });

       
return ( 
     
     <>
        <div >
                                
                 <div align='center' style={{color:"red", align: "center"}}>
                       {loginError}
                 </div>
                 <br/>
                                 
                <Typography variant="h4" gutterBottom>
                     <Tooltip title="Use the email address used on registration" placement="top-end">
                     <TextField label="Username" variant="outlined" fullWidth size="small"  placeholder="Enter Username"
                     id="username" name="username" value={username} onChange={(e)=>setUsername(e.target.value)}
                      InputProps={{
                         startAdornment: (
                              <InputAdornment position="start">
                                    <AccountCircleIcon />
                              </InputAdornment>
                           ),
                         }}
                        />
                     </Tooltip>
                </Typography>  

                <Typography variant="h4" gutterBottom>
                      <Tooltip title="Please create a safe password " placement="top-end">
                      <TextField label="Password" variant="outlined"  onChange={(e)=>setPassword(e.target.value)} fullWidth size="small" placeholder="Password" type="password" id="password" name="password" value={password}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                      <LockOpenIcon />
                                </InputAdornment>
                                     ),
                            }}                                
                           />
                      </Tooltip>
                </Typography>  
                                 
                 <Backdrop className={classes.backdrop} open={open}>
                          <Loader/>
                 </Backdrop>

               <Tooltip title="This user will be used to Login all other projects besides ePayNet" placement="top-end">                 
               <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style ={{margin: '5px', textTransaform:'none', color:'white', backgroundColor:'#155054'}} onClick={()=> handleSubmit()} > 
                    Login 
                    <IconButton style={{color:'white'}} size='small'>
                      <LockOpenIcon />
                    </IconButton>
                  </Button>
               </Tooltip>   
    
        </div>

    </> 
               
 )
}

export default LoginAccountForm;