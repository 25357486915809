import React, { useEffect,useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


export const UtilityDetails = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { loginDetailsBag, baseURL, dataReceiver }= useContext(CartContext);
       const [ open, setOpen] = React.useState(false);
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ institutionCategorys, setInstitutionCategorys] = React.useState([]);
       const [ institution, setInstitution] = React.useState([]);
       const [ selectedInstitution, setSelectedInstitution] = React.useState([]);
       const [ selectedInstitutionName, setSelectedInstitutionName] = React.useState([]);
       const [ amount, setAmount ] = React.useState([]);
       const [ reference, setReference ] = React.useState([]);
       const [ institutionDetails, setInstitutionDetails ] = React.useState([]);
       const [ companyName, setCompanyName ] = React.useState([]);
       const [ accountOwner, setAccountOwner ] = React.useState([]);
       const [ accountNumber, setAccountNumber ] = React.useState([]);
       const [ institutionsName, setInstitutionsName] = React.useState([]); 
       const [ selectedOption, setSelectedOption] = React.useState([]); 
       const [ isFavorite, setIsFavorite] = React.useState(false); 
       const [ favoriteAccounts, setFavoriteAccounts] = React.useState([]);
       const [ accountHolder, setAccountHolder] = React.useState([]);
       let favAccountID = '';
       let userSelectedOption = '';  
       let selectedInstitutionsID = '';
       let selectedInstitutionsName = ''; 
       let institutionPaymentDetailsBag = {};
       let appUser = loginDetails.organisations_id;
       let username = loginDetails.username;
       let categoryID = '3';
       let userOption = [{id:'1',name:'Once Off Payment'},{id:'2',name:'Favorites'}];
       const dataDec  = dataReceiver;


     
  useEffect(()=> {

       setOpen(!open); 
      const fetchInstitutionCategories = async()=>{
          
          const res = await axios.get(baseURL+'bridgeGetMyCurrentlySelectedCategoryCompanies.php?selectedCompanyCategories_id='+categoryID+'&logonCompanies_id='+appUser);


            res.data = dataDec(res.data);
         
       
          setInstitutionCategorys(res.data); 
          setOpen(false);     

      }

      fetchInstitutionCategories();
     
   }, [])

 
const handleInstitutionChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedInstitution(event.target.value);
    selectedInstitutionsID = event.target.value;
    setOpen(!open);
    
    const fetchInstitution = async()=>{
    
      const res = await axios.get(baseURL+'bridgeGetMyCurrentlySelectedCategoryCompanies.php?selectedCompanyCategories_id='+categoryID+'&logonCompanies_id='+appUser);

      res.data = dataDec(res.data);
                   
        let companyDetails = institutionCategorys.filter(institutionCategorys => institutionCategorys.id === selectedInstitutionsID);

        let companyName = companyDetails[0].companyName;
        
          setCompanyName(companyName);
          setInstitution(res.data); 
          setOpen(false);     

      }

      fetchInstitution();

  };


const handleInstitutionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {

      setSelectedInstitutionName(event.target.value);
      selectedInstitutionsName = event.target.value;
     
   };


const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {

      setSelectedOption(event.target.value);
      userSelectedOption = event.target.value;
      setOpen(!open);
        if(event.target.value == '2'){
 
             setIsFavorite(true); 
             const fetchFavorites = async()=>{
          
             const res = await axios.get(baseURL+'bridgeGetFavourites.php?appUsers_id='+appUser+'&companies_id='+selectedInstitution);
              

              res.data = dataDec(res.data);           
              
    
                 setFavoriteAccounts(res.data); 
                 setOpen(false);


      }

      fetchFavorites();
 
          }else{

             setIsFavorite(false); 
             setOpen(false);
    
        }
   };  


const handleAccountOwnerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
 
      setAccountHolder(event.target.value);  
      setAccountOwner('saved')
      favAccountID = event.target.value;

       let favorites = favoriteAccounts.filter(favoriteAccounts => favoriteAccounts.id === favAccountID);
       let accountNumber = favorites[0].accountNumber;
       setAccountNumber(accountNumber);  
   
 };  

  
const submitLoan = () => {
   
  
  let dt = new Date();
  let seconds = dt.getSeconds();
  let minutes = dt.getMinutes();
  let hours = dt.getHours();  
  let year = dt.getFullYear();
  let month = dt.getMonth();
      month = month + 1;
  let day = dt.getDate();
   
     if(seconds < 10){
        seconds = '0'+seconds;
     }

     if(month < 10){
        month = '0'+month;
     }
     if(day < 10){
        day = '0'+day ;
     }
     if(minutes < 10){
        minutes = '0'+minutes ;
     }
     if(hours < 10){
        hours = '0'+hours ;
     }
 
        let headers = seconds +''+ minutes +''+hours+''+ day +''+ month +''+ year +''+username;
        let headerBody = parseInt(appUser) + parseInt(seconds);
        let body = headers+''+headerBody;        
   
    let institutionPaymentDetailsBag = { 

              organisation:selectedInstitution, categoryID:categoryID, organisationName:companyName, accountOwner:accountOwner, accountNumber:accountNumber, amount:amount, reference:reference, userKey:body
              
             };

     setInstitutionDetails(institutionPaymentDetailsBag);
     goToTransactionConfirmation(institutionPaymentDetailsBag);
   
}

const goToTransactionConfirmation = (institutionPaymentDetailsBag) => history.push({ 
      
        pathname: '/confirmutilitytransaction',
        institutionState: institutionPaymentDetailsBag

});

  return (
    
    <main className = {classes.content}>
         <div className={classes.toolbar}/>

            <Grid container direction="column">

              <Grid item></Grid>

               <Grid item container spacing={4}>
                    <Grid item xs={false} sm={4}/>
            
                <Grid item xs={12} sm={4}>

                  <Card >
           
                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>
                            
              <CardHeader title="Utility Payment Details"  align="center" />
                    <CardContent>
    
                    <Typography variant="h4" gutterBottom>                
                                 <TextField label="Select Utility" variant="outlined"  fullWidth size="small" select
                                  onChange={handleInstitutionChange}
                                  value={selectedInstitution}
                                  > 

                                    {institutionCategorys.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.companyName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                          <Typography variant="h4" gutterBottom>                
                                 <TextField label="Select Option" variant="outlined"  fullWidth size="small" select
                                  onChange={handleOptionChange}
                                  value={selectedOption}
                                  > 

                                    {userOption.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.name}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                          

                              
                                {isFavorite ? 
                                      
                    <>
                      <Typography variant="h4" gutterBottom>                
                          <TextField label="Select Utility" variant="outlined"  fullWidth size="small" select
                                onChange={handleAccountOwnerChange}
                                value={accountHolder}
                                > 

                                    {favoriteAccounts.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.accountName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                      </Typography>

                      <Typography variant="h4" gutterBottom>
                                      <TextField label="" variant="outlined" fullWidth size="small"
                                      value={accountNumber} 
                                      type="text"
                                      id="accountNumber"
                                      name="accountNumber"
                                    
                                                                           
                                      />
                          </Typography>

                    </>

                                      :
                      <>          
                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Utility Account Owner" variant="outlined" fullWidth size="small"
                                      placeholder="Owners Name"  
                                      type="text"
                                      id="accountOwner"
                                      name="accountOwner"
                                      onChange={(e)=>setAccountOwner(e.target.value)}
                                                                           
                                      />
                          </Typography>

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Utility Account Number" variant="outlined" fullWidth size="small"
                                      placeholder="Owners Account Number"  
                                      type="text"
                                       onChange={(e)=>setAccountNumber(e.target.value)}
                                      id="accountNumber"
                                      name="accountNumber"
                                     
                                                                           
                                      />
                          </Typography>

                      </>    
                                      } 
                
                                                  
                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Amount" variant="outlined" fullWidth size="small"
                                      placeholder="Amount"  
                                        type="text"
                                       id="Amount"
                                       name="Amount"
                                       onChange={(e)=>setAmount(e.target.value)}
                                                                           
                                      />
                          </Typography>

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Reference" variant="outlined" fullWidth size="small"
                                      placeholder="Reference"  
                                        type="text"
                                       id="Reference"
                                       name="Reference"
                                       onChange={(e)=>setReference(e.target.value)}
                                                                           
                                      />
                          </Typography>            

                <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{margin:'5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> submitLoan()} > Pay
                  </Button>
                  
                             </CardContent>
                          </Card>
                        </Grid>
          
                    <Grid item xs={false} sm={4}/>
                 </Grid>

              </Grid>

  </main>

  );
}

export default UtilityDetails;