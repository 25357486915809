import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  TextField,
  Card,
  CardContent,
  IconButton,
  Item,
  Button,
  Typography,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import Loader from "../Loader/Loader";
import InputAdornment from "@material-ui/core/InputAdornment";
import CallIcon from "@material-ui/icons/Call";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import useStyles from "./styles";
import { useHistory, Link } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import axios from "axios";
import { ArrowRight, ArrowLeft } from "@material-ui/icons";
import { Slider } from "../../Layout/Slider/Slider";
import { BannerSlider } from "../../Layout/Slider/BannerSlider";
import data from "./TestData";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import {
  HomeIcon,
  AdjustRounded,
  FacebookOutlined,
  ContactSupportIcon,
  ArrowForwardIosRounded,
  WebOutlined,
} from "@mui/icons-material";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import Tooltip from "@mui/material/Tooltip";

{
  /**/
}
function goToTop() {
  window.scrollTo(0, 0);
}

const Login = () => {
  const [social, setSocial] = useState([
    {
      id: 3,
      url: "https://salvtec.co.sz/",
      icon: <LanguageIcon />,
    },
    {
      id: 1,
      url: "https://www.facebook.com/Digimage.SalvTeC/",
      icon: <FacebookOutlined />,
    },
    {
      id: 2,
      url: "https://twitter.com/digimages?lang=en",
      icon: <TwitterIcon />,
    },
  ]);
  const classes = useStyles();
  const history = useHistory();
  const [loginDetails, setLoginDetails] = useState({});
  const [loginError, setLoginError] = useState("");
  let LoginDetailsBag = [{}];
  const {
    baseURL,
    dataReceiver,
    isActiveBusinessBag,
    legalBusinessMemberAccountsBag,
  } = useContext(CartContext);
  const [legalBusinessMemberAccount, setLegalBusinessMemberAccount] =
    legalBusinessMemberAccountsBag;
  const [isActiveBusiness, setIsActiveBusiness] = isActiveBusinessBag;
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const user = { username, fullName };
  const dataDec = dataReceiver;
  const [dataContainer, setDataContainer] = useState(data);
  const [index, setIndex] = React.useState(0);

  useEffect(() => {
    goToTop();
    setTimeout(function () {
      updateBanner();
    }, 35550);
  }, []);

  const updateBanner = () => {
    let pageName = { pageName: "Login" };

    const res = axios
      .post(baseURL + "/bridgeBringPageGraphics.php", pageName)
      .then(function (response) {
        response.data = dataDec(response.data);
        setDataContainer(response.data);
      });
  };

  const goToHomePage = () =>
    history.push({
      pathname: "/Dashboard",
      loginState: LoginDetailsBag,
    });

  const goToAuthorize = () =>
    history.push({
      pathname: "/authorization",
      loginState: LoginDetailsBag,
    });

  const openRegistration = () =>
    history.push({
      pathname: "/Registration",
    });

  const hitLoginApi = async () => {
    setOpen(!open);
    setLegalBusinessMemberAccount({});
    const password = fullName;
    const min = 100;
    const max = 999;
    const rand = Math.floor(Math.random() * (max - min + 1)) + min;
    const randPassword = `${rand}${password}`;

    function d2h(d) {
      return d.toString(16);
    }
    function h2d(h) {
      return parseInt(h, 16);
    }
    function stringToHex(tmp) {
      let str = "",
        i = 0,
        tmp_len = tmp.length,
        c;

      for (; i < tmp_len; i += 1) {
        c = tmp.charCodeAt(i);
        str += d2h(c) + "";
      }

      return str;
    }

    const rand3hexPassword = stringToHex(randPassword);

    let data = { username: username, password: rand3hexPassword };
    data = JSON.stringify(data);

    if (username == "") {
      setLoginError("Username is required");
      setOpen(false);
      return 0;
    }

    if (password == "") {
      setLoginError("Password is required");
      setOpen(false);
      return 0;
    }

    const res = await axios
      .post(baseURL + "bridgeLogin.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);

        if (response.data.code === "200") {
          setLoginDetails(response.data);
          LoginDetailsBag = response.data;
          let activeBusiness = LoginDetailsBag.isLegalBusiness;
          setIsActiveBusiness(activeBusiness);
          goToHomePage();
          setOpen(false);
        } else {
          setLoginError(response.data.description);
          setOpen(false);
        }
      });
  };

  const setContactSupport = () => {
    let webUrl = "https://www.facebook.com/Digimage.SalvTeC";
    window.open(webUrl);
  };

  const goToHome = () =>
    history.push({
      pathname: "/homepage",
    });

  const handleSubmit = () => {
    hitLoginApi();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <main className={classes.content} style={{ backgroundColor: "ghostwhite" }}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item container spacing={2}>
          {/*-------------------------------------------------------------------------
                                PAGE SIDE MENU START
-------------------------------------------------------------------------*/}

          <Grid item xs={false} sm={2} md={4} lg={4}></Grid>

          {/*------------------------------------------------------------------------------
                                   PAGE SIDE MENU END
----------------------------------------------------------------------------*/}

          {/*------------------------------------------------------------------------------
                                LOGIN FORM START
---------------------------------------------------------------------------------*/}

          <Grid item xs={12} sm={8} md={4} lg={4}>
            <Card>
              <CardContent>
                <div style={{ color: "red", align: "center" }} align="center">
                  {loginError}
                </div>
                <br />

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Enter Mobile Number"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CallIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Password"
                    type="password"
                    id="fullName"
                    name="fullName"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOpenIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Typography>
                <Backdrop className={classes.backdrop} open={open}>
                  <Loader />
                </Backdrop>
                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="inherit"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    color: "white",
                    backgroundColor: "#155054",
                  }}
                  onClick={() => handleSubmit()}>
                  {" "}
                  Login{" "}
                </Button>

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="inherit"
                  gutterBottom
                  style={{
                    margin: "5px",
                    color: "white",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                  }}
                  onClick={() => openRegistration()}>
                  {" "}
                  New to ePayNet? Register{" "}
                </Button>

                <br></br>
                <br></br>
                <Typography variant="body1" gutterBottom>
                  <Button
                    component={Link}
                    to="/PasswordReset"
                    style={{
                      margin: "5px",
                      textTransaform: "none",
                      color: "black",
                    }}>
                    Forgot Password?
                  </Button>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/*------------------------------------------------------------------------------
                                LOGIN FORM END
---------------------------------------------------------------------------------*/}

          <Grid item xs={false} sm={2} md={4} lg={4}></Grid>
        </Grid>

        <Grid
          container
          direction="row"
          style={{ backgroundColor: "#155054", marginTop: "70px" }}>
          <Grid item container sm={false}>
            {dataContainer.map((data, index) => (
              <Grid key={index} item sm={false} md={4} lg={4}>
                <BannerSlider data={data} />
              </Grid>
            ))}

            <Tooltip
              title="View more features to find on ePayNet"
              placement="top-end">
              <button
                className={classes.loginButton + " prev"}
                onClick={() => goToHome()}>
                <AdjustRounded />
              </button>
            </Tooltip>

            <button
              className={classes.loginButton + " next"}
              onClick={() => setContactSupport()}>
              <FacebookOutlined />
            </button>

            <Grid container spacing={2}>
              <Grid item xs={1} className="social-icons">
                <AdjustRounded
                  style={{ color: "white" }}
                  onClick={() => goToHome()}
                />
              </Grid>

              <Grid
                item
                xs={8}
                style={{ display: "flex" }}
                className="social-icons">
                {social.map((item, index) => {
                  const { text, icon, onClick, id, url } = item;

                  return (
                    <ul
                      key={index}
                      style={{ listStyle: "none" }}
                      className="social-icons">
                      <ListItemText key={text}>
                        <a href={url} target="_blank">
                          {icon}
                        </a>
                      </ListItemText>
                    </ul>
                  );
                })}
              </Grid>

              <Grid item xs={1} className="social-icons"></Grid>

              <Grid item xs={2} className="social-icons">
                <ArrowForwardIosRounded
                  style={{ color: "white" }}
                  onClick={() => goToHome()}
                  className="iconButton"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
};

export default Login;
