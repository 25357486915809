import React, {useContext, useState} from 'react';
import {Card, CardMedia, CardContent, CardActions, TextField, Button, InputAdornment, Typography, IconButton} from '@material-ui/core';
import { AddShoppingCart } from '@material-ui/icons';
import useStyles from '../../Login/styles';
import {CartContext} from '../../../Context/CartContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { useHistory, Link } from 'react-router-dom';
//import Modal from 'react-modal';

const BusinessMember = ({ historys, product, loading}) => {

     const classes = useStyles();
     const history = useHistory();


   if(loading){
    
        return <h2>Loading..</h2>
    }


    return (

      <>

        <Card >
           
            <CardContent>
                <div className={classes.cardContent}>

                    <Typography variant="body1" gutterBottom>
                       Name: {historys.username}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                       Cell number: {historys.cellNumber}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                       Email: {historys.email}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                       Role: {historys.rightName}
                    </Typography>                   

                </div>
            </CardContent>
            
        </Card>


  </>   




       
    )
}

export default BusinessMember;
