import React, { useContext, useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import Loader from '../Loader/Loader';
import axios from 'axios';


export const ProofOfPayment = () => {
  
 const classes = useStyles(); 
 const history = useHistory();  
 const { baseURL, dataReceiver, loginDetailsBag } = useContext(CartContext); 
 const [loginDetails, setLoginDetails] = loginDetailsBag;
 const [emailAddress, setEmailAddress] = React.useState([]);
 let appUser = loginDetails.organisations_id;
 let username = loginDetails.username;
 const [ open, setOpen] = useState(false);
 const [loading, setLoading] = useState(false);
 const dataDec  = dataReceiver; 
 let dt = new Date();
 let seconds = dt.getSeconds();
 let minutes = dt.getMinutes();
 let hours = dt.getHours(); 
 let year = dt.getFullYear();
 let month = dt.getMonth();
     month = month + 1;
 let day = dt.getDate();

     if(seconds < 10){
        seconds = '0'+seconds;
     }

     if(month < 10){
        month = '0'+month;
     }
     if(day < 10){
        day = '0'+day ;
     }
     if(minutes < 10){
        minutes = '0'+minutes ;
     }
     if(hours < 10){
        hours = '0'+hours ;
     }

 
  let headers = seconds+''+minutes+''+hours+''+day+''+month+''+year+''+username;
  let headerBody = parseInt(appUser) + parseInt(seconds);
  let token = headers+''+headerBody;
  
   const proofOfPaymentDetailsBag = history.location.proofOfPaymentState;         

  const goToTransactionHistory = () => history.push({
  
        pathname: '/history'
 
 });
 
const sendProof = () =>{

}
 

 const send = async()=>{

        setOpen(!open);
        setLoading(true);
  
       
     let email = emailAddress;
     let transactionNumber = proofOfPaymentDetailsBag.transactionNumber;
     let amount = proofOfPaymentDetailsBag.amount;
     let fromAccount = proofOfPaymentDetailsBag.fromAccount;
     let toAccount = proofOfPaymentDetailsBag.toAccount;
     let ref = proofOfPaymentDetailsBag.reference;
     let status = proofOfPaymentDetailsBag.status;
     let date = proofOfPaymentDetailsBag.date;

 let data = {token, username, email, transactionNumber, amount, fromAccount, toAccount, ref, status, date };
    
   const res = await axios.post(baseURL+'bridgeEmailProofOfPayment.php', data ).then(function (response) {
    
    
      response.data = dataDec(response.data);

    

      if(response.data !== ''){ 
              
          goToTransactionHistory();
          setLoading(false);

      }else{

          goToTransactionHistory();
          setLoading(false);

      }  
       
  });
         
}

if(loading){

        return  (
 
               <Loader/>

           )
    }


  return (
  
      <main className = {classes.content}>

            <div className={classes.toolbar}/>

         <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                                   
                        <Grid item xs={false} sm={2} md={3} lg={4}/>
            
                          <Grid item xs={12} sm={8} md={6} lg={4}>

                            <Card >
                                
                               <CardContent>
           <Typography align="center" variant="body1"><h5>Proof Of Payment</h5></Typography>
                          <Typography variant="h6" gutterBottom>                
                            <b>{proofOfPaymentDetailsBag.transactionNumber}     </b>
                          </Typography>
                           <hr></hr> 
                          <Typography variant="body1" gutterBottom>                
                                 
                             To: <b>{proofOfPaymentDetailsBag.toAccount}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Amount: <b>E{proofOfPaymentDetailsBag.amount}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Date:<b> {proofOfPaymentDetailsBag.date}</b>
                          </Typography>

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Email" variant="outlined" fullWidth size="small"
                                      placeholder="epaynet@gmail.com"  
                                        type="text"
                                       id="email"
                                       name="email"
                                       onChange={(e)=>setEmailAddress(e.target.value)}
                                                                          
                                      />
                                      </Typography>


                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> send()} > Submit</Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                      <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>
    </main>

  );
}



export default ProofOfPayment;