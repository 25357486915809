import React, { useEffect,useContext, useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Loader from '../Loader/Loader';

import Backdrop from '@material-ui/core/Backdrop';


export const BusinessInvestment = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext); 
       const [open, setOpen] = useState(false);
       const [selectedStatus, setSelectedStatus ] = useState([]); 
       const [ loginDetails, setLoginDetails ] = loginDetailsBag;
       let appUserID = loginDetails.organisations_id;                 
       const [ activeStatus, setActiveStatus] = useState([]);                       
       let selectedConsent = '';
       let status = '';
       let featureID = '';
       let featuresName = '';
       const [ activationCategory, setActivationCategory] = useState([{id:'1',name:'ePayNet Business'},
                                                                      {id:'2',name:'Online checkout'},
                                                                      {id:'3',name:'Crowdfunding'}]);
       const [ selectedFeature, setSelectedFeature] = useState(''); 
       const [featureName, setFeatureName] = useState('');    
       let mySelectedFeature = '';                                                          
       const consent = [{id:1, name:'Yes',value:'T'},
                        {id:2,name:'No',value:'F'}];
       const dataDec  = dataReceiver;                 
       let eCommerceStatus = loginDetails.isLegalBusiness;
              
       useEffect(()=> {
        
         
        }, [])
 


   const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setSelectedStatus(event.target.value);
          selectedConsent = event.target.value;
        
   }; 


  

  const handleFeatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setSelectedFeature(event.target.value);
        mySelectedFeature = event.target.value;       

    for (let i = 0; i < activationCategory.length; i++) { 

           featureID = activationCategory[i].id;

          if(featureID == mySelectedFeature){

               featuresName = activationCategory[i].name;
               setFeatureName(featuresName);
    
            }
        }
   }; 



const openRequestFunding = () => history.push({
     
     pathname: '/requestfunding'


});


const openBusinessFunding = () => history.push({
     
     pathname: '/requests',
     userRightState:'funder'

});




const updateePayNetStatus = async()=>{


   let companyID = loginDetails.organisations_id;
   let username = loginDetails.username;
   let action = selectedStatus;
   
   let data = {companyID:companyID, username:username, action:action};


   const res = await axios.post(baseURL+'/bridgeReceiveEpayNetWebAccountAction.php', data ).then(function (response) {

  
       if(response.data.code == 200){

            setActiveStatus(response.data);

          }else{
              
           setActiveStatus(response.data);              
           
        }   
     });
         
}


  return (

     <main className = {classes.content}>
       <div className={classes.toolbar}/>
       
    <Grid container direction="column">

              <Grid item className={classes.sidebar} xs={false} sm={3} md={3} lg={3}></Grid>

                 <Grid item container spacing={2}>
                  <Grid item xs={12} sm={2} md={4} lg={4}>
                   
                  </Grid>
             
                  <Grid item  xs={12} sm={8} md={4} lg={4}>
                        <Card >
                                    
                         
                <CardContent>


                   <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style= {{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> openBusinessFunding()} >  Business Funding </Button>
                  
                  <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style= {{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> openRequestFunding()} > Request Funding </Button>                  

                 



                    
                 </CardContent>  
               </Card>

           </Grid>
          
                    <Grid item xs={false} xs={false} sm={2} md={4} lg={4}/>
                 </Grid>

              </Grid>
  </main>

  );
}

export default BusinessInvestment;