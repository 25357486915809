import React, { useContext } from 'react';
import { Card,  CardContent, CardHeader, Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../../Login/styles';	
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { CartContext } from '../../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../../Loader/Loader';

   
export const ShareLoanContact = () => {
	
 const classes = useStyles(); 
 const history = useHistory();
 const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext); 
 const [open, setOpen] = React.useState(false);
 const [ loginDetails, setLoginDetails ] = loginDetailsBag;
 const [ cellNumber, setCellNumber ] = React.useState([]);
 const [ responseStatus, setResponseStatus ] = React.useState([]);
 let appUser = loginDetails.organisations_id;
 const loanToShareDetailsBag = history.location.loanToShareState;
 const dataDec  = dataReceiver;
 let responseDetailsBag = {};



const ShareLoan = () =>{
   
   setOpen(!open);
   let transactionNumber = loanToShareDetailsBag.transactionNumber;
   
   const submitLoanToShare = async()=>{

         const res = await axios.get(baseURL+'bridgeReceiveDebtSharing.php?transactionNumber='+transactionNumber+'&receiverUsername='+cellNumber+'&appUserCompanyID='+appUser);
          
          res.data = dataDec(res.data);
 
     if(res.data !== ''){
        
           setResponseStatus(res.data);
           responseDetailsBag = res.data;
           goToShareLoanStatus();
           setOpen(false)
                   

      }else{

          alert('Error');
          setOpen(false);
      } 

  

      }

      submitLoanToShare();
    
} 


const goToShareLoanStatus = () => history.push({ 
  
        pathname: '/shareloanstatus',
        responseState: responseDetailsBag


});


	return (

		
       <main className = {classes.content}>

            <div className={classes.toolbar}/>

     
  <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
                        <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card >


                                 <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                                 </Backdrop>
                                
                               <CardContent>

                               <CardHeader title="Share Loan" align="center"/>
                              
                              
                     <Typography variant="body1" gutterBottom>                
                                 
                             Transaction #: <b>{loanToShareDetailsBag.transactionNumber}</b>
                          </Typography>
                    <Typography variant="body1" gutterBottom>                
                                 
                             Company Name: <b>{loanToShareDetailsBag.name}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                            Amount due: <b>E{loanToShareDetailsBag.amountToPay}</b>
                          </Typography>

                          

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Cell Number" variant="outlined" fullWidth size="small"
                                      placeholder="Cell Number"  
                                        type="text"
                                       id="cellNumber"
                                       name="cellNumber"
                                       onChange={(e)=>setCellNumber(e.target.value)}
                                                                          
                                      />
                          </Typography>


                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> ShareLoan()} > Share Loan</Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                   <Grid item xs={false} sm={2} md={3} lg={4}/> 
                 </Grid>

              </Grid>
    </main>

	);
}



export default ShareLoanContact;