import React, { useEffect,useContext, useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader, TextField, InputAdornment } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';

export const UserProfile = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, dataReceiver, loginDetailsBag } = useContext(CartContext);
       const [open, setOpen] = React.useState(false);
       const [userInfo, setUserInfo] = React.useState([]);
       const [username, setUsername] = React.useState([]);
       const [physicalAddress, setPhysicalAddress] = React.useState([]);
       const [emailAddress, setEmailAddress] = React.useState([]);
       const [sourceOfIncome, setSourceOfIncome] = React.useState([]);
       const [incomeAmount , setIncomeAmount] = React.useState([]);
       const [towns, setTowns] = React.useState([]);
       const [selectedTown, setSelectedTown] = React.useState([]);
       const [region, setRegion] = React.useState([]);
       const [regions, setRegions] = React.useState([]);
       const [loginDetails, setLoginDetails] = loginDetailsBag;
       let appUserID = loginDetails.organisations_id;
       const dataDec  = dataReceiver;


useEffect(()=> {
  
   const fetchUsersProfiles = async()=>{
    
         setOpen(!open); 

         const res = await axios.get('https://www.digimagesystem.com/ePayNet2019/apiWeb/getCompanyUpdatableFields.php?companyID='+appUserID);

         setUserInfo(res.data);

         setOpen(false);
 
       }

      fetchUsersProfiles();
     
   }, [])

 

const handlerTownChange = () => {
  
  setSelectedTown('');

} 


const handlerRegionChange = () => {
  
  setRegion('');


}
   

const updateProfile = () => {
  
  alert('Under development')  

}

const goToTransactionConfirmation = () => history.push({ 
      
        pathname: '/confirmmomotransaction',
        
});


  return (

    
      <main className = {classes.content}>
            <div className={classes.toolbar}/>

   
  <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
                         <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card >
              <CardHeader title="Update Profile"  align="center" />
                    <CardContent>
    
                     <Typography variant="h4" gutterBottom>
                                      <TextField label="Username" variant="outlined" fullWidth size="small"
                                      placeholder="Username"
                                      value={username}  
                                      type="text"
                                       id="username"
                                       name="username"
                                       onChange={(e)=>setUsername(e.target.value)}
                                                                          
                                      />
                     </Typography>

                      <Typography variant="h4" gutterBottom>
                                <TextField label="Towns" variant="outlined"  fullWidth size="small" select 
                                  onChange={handlerTownChange}
                                  value={selectedTown} 

                                  InputProps={{ 
                                   startAdornment: ( 
                                   <InputAdornment position="start">
                                   </InputAdornment>
                                  ),
                                 }}>                        
                                    
                                    {towns.map((option) => (
                                   <MenuItem key={option.id} value =  {option.id}  >
                                      {option.companyName} 
                                   </MenuItem>
                                   ))}
                               </TextField>
                          </Typography>

                          <Typography variant="h4" gutterBottom>
                                <TextField label="Regions" variant="outlined"  fullWidth size="small" select 
                                  onChange={handlerRegionChange}
                                  value={selectedTown} 
                                                            
                                  InputProps={{ 
                                   startAdornment: ( 
                                   <InputAdornment position="start">
                                   </InputAdornment>
                                  ),
                                 }}>                        
                                    
                                    {towns.map((option) => (
                                   <MenuItem key={option.id} value =  {option.id}  >
                                      {option.companyName} 
                                   </MenuItem>
                                   ))}
                               </TextField>
                          </Typography>
 

                    <Typography variant="h3" gutterBottom>                
                                
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                                      <TextField label="Physical Address" variant="outlined" fullWidth size="small"
                                      placeholder="Physical Address"
                                      value={physicalAddress}  
                                        type="text"
                                       id="physicalAddress"
                                       name="physicalAddress"
                                       onChange={(e)=>setPhysicalAddress(e.target.value)}
                                                                          
                                      />
                     </Typography>

                     <Typography variant="h4" gutterBottom>
                                      <TextField label="Email Address" variant="outlined" fullWidth size="small"
                                      placeholder="Email Address"
                                      value={emailAddress}  
                                        type="text"
                                       id="emailAddress"
                                       name="emailAddress"
                                       onChange={(e)=>setEmailAddress(e.target.value)}
                                                                          
                                      />
                     </Typography>

                     <Typography variant="h4" gutterBottom>
                                      <TextField label="Source Of Income" variant="outlined" fullWidth size="small"
                                      placeholder="Source Of Income"
                                      value={sourceOfIncome}  
                                        type="text"
                                       id="sourceOfIncome"
                                       name="sourceOfIncome"
                                       onChange={(e)=>setSourceOfIncome(e.target.value)}
                                                                          
                                      />
                     </Typography>

                     <Typography variant="h4" gutterBottom>
                                      <TextField label="Income Amount" variant="outlined" fullWidth size="small"
                                      placeholder="Income Amount"
                                      value={incomeAmount}  
                                        type="text"
                                       id="incomeAmount"
                                       name="incomeAmount"
                                       onChange={(e)=>setIncomeAmount(e.target.value)}
                                                                          
                                      />
                     </Typography>
                    
                               
                  <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> updateProfile()} > Update
                  </Button>

                                 

 

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>




  </main>

  );
}

export default UserProfile;