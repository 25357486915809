import React, { useEffect,useContext,useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles';	
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import axios from 'axios';

export const ServicesPayment = () => {
	
 const classes = useStyles(); 
 const history = useHistory();
 const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
 const [ loginDetails, setLoginDetails] = loginDetailsBag;
 const [ open, setOpen] = useState(false);
 const [ banks, setBanks] = React.useState([]);
 const [ bankCharges, setBankCharges] = React.useState([]);
 const [ branches, setBranches] = React.useState([]);
 const [ selectedBank, setSelectedBank] = React.useState([]);
 const [ selectedBranch, setSelectedBranch] = React.useState([]);
 const [ accountNumber, setAccountNumber ] = React.useState([]);
 const [ amount, setAmount ] = React.useState([]);
 const [ reference, setReference ] = React.useState([]);
 const [ bankDetails, setBankDetails ] = React.useState([]);    
 const loanToPayDetailsBag = history.location.loanToPayState;
 let selectedBanksID = '';
 let selectedBranchsID = '';
 let responseDetailsBag = {};
 let beneficiaryServiceDetailsBag = {};
 let appUser = loginDetails.organisations_id;
 let username = loginDetails.username;
 const dataDec  = dataReceiver;


useEffect(()=> {
      
      setOpen(!open);

      const fetchBanks = async()=>{
          
          
          const res = await axios.get(baseURL+'bridgeMomoToBankPaymentPlatform.php');
         
          res.data = dataDec(res.data);

          setBanks(res.data); 
          setOpen(false);     

      }

      const fetchBankCharges = async()=>{
          
           const res = await axios.get('https://www.digimagesystem.com/ePayNet2019/apiWeb/getPaymentGatewaySwitchingCharges.php?btnName=M2B');

         setBankCharges(res.data); 
          setOpen(false);     
            
      }
      
      fetchBankCharges();  
      fetchBanks();
     
   }, [])

 
   const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedBank(event.target.value);
    selectedBanksID = event.target.value;
    setOpen(!open);
    
    const fetchBranches = async()=>{
          
          const res = await axios.get(baseURL+'bridgeBringBranches.php?banks_id='+ selectedBanksID);
          
          res.data = dataDec(res.data);

          setBranches(res.data); 
          setOpen(false);     

      }

      fetchBranches();

  };

   const handleBranchChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedBranch(event.target.value);
     
    selectedBranchsID = event.target.value;  
   

  };


const submitService = () => {
   
      let dt = new Date();
      let seconds = dt.getSeconds();
      let minutes = dt.getMinutes();
      let hours = dt.getHours();
      let year = dt.getFullYear();
      let month = dt.getMonth();
          month = month + 1;
      let day = dt.getDate();

     if(seconds < 10){
        seconds = '0'+seconds;
     }

     if(month < 10){
        month = '0'+month;
     }
     if(day < 10){
        day = '0'+day ;
     }
     if(minutes < 10){
        minutes = '0'+minutes ;
     }
     if(hours < 10){
        hours = '0'+hours ;
     }

 
        let headers = seconds +''+ minutes +''+hours+''+ day +''+ month +''+ year +''+username;
        let headerBody = parseInt(appUser) + parseInt(seconds);
        let body = headers+''+headerBody;
        let includedCharge = parseFloat(bankCharges) + parseFloat(amount)


   
     let beneficiaryServiceDetailsBag = { Bank:selectedBank, Branch:selectedBranch, accountNumber:accountNumber, amount:amount, reference:reference, userKey:body, includedCharge
          };
     
     setBankDetails(beneficiaryServiceDetailsBag);
     goToTransactionConfirmation(beneficiaryServiceDetailsBag);
   
  }




  const goToTransactionConfirmation = (beneficiaryServiceDetailsBag) => history.push({ 
      
        pathname: '/confirmservice',
        beneficiaryServiceState: beneficiaryServiceDetailsBag

});


	return (
	
      <main className = {classes.content}>
            <div className={classes.toolbar}/>

   
  <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
                         <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                      </Backdrop>

                      
              <CardHeader title="Service Details"  align="center" />
                    <CardContent>
    
                    <Typography variant="h4" gutterBottom>                
                                 <TextField label="Select Service to Pay" variant="outlined"  fullWidth size="small" select
                                  onChange={handleBankChange}
                                  value={selectedBank}
                                  > 

                                    {banks.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.mnemonic}
                                   </MenuItem>

                                   ))}
                               </TextField>
                    </Typography>
                           
                           {/*<Typography variant="h4" gutterBottom>                
                                 <TextField label="Select Payment Platform" variant="outlined"  fullWidth    size="small" select
                                  onChange={handleBranchChange}
                                  value={selectedBranch}
                                  > 

                                    {branches.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.branchName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>*/}
                         
                          {/*<Typography variant="h4" gutterBottom>
                                      <TextField label="Account Number" variant="outlined" fullWidth size="small"
                                      placeholder="Account Number"  
                                        type="text"
                                       id="AccountNumber"
                                       name="AccountNumber"
                                       onChange={(e)=>setAccountNumber(e.target.value)}
                                                                           
                                      />
                                      </Typography>*/}

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Amount" variant="outlined" fullWidth size="small"
                                      placeholder="Amount"  
                                        type="text"
                                       id="Amount"
                                       name="Amount"
                                       onChange={(e)=>setAmount(e.target.value)}
                                                                           
                                      />
                                      </Typography>

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Reference" variant="outlined" fullWidth size="small"
                                      placeholder="Reference"  
                                        type="text"
                                       id="Reference"
                                       name="Reference"
                                       onChange={(e)=>setReference(e.target.value)}
                                                                           
                                      />
                                      </Typography>            



                               
                  <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> submitService()} > Pay
                  </Button>

                                 

 

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>




  </main>

	);
}



export default ServicesPayment;