import React, { useEffect, useState, useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


export const UserVerification = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { loginDetailsBag, accountsTypeBag, baseURL, dataReceiver, legalBusinessMemberAccountsBag, userRoleStatusesBag }= useContext(CartContext);
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ legalBusinessMemberAccount, setLegalBusinessMemberAccount] = legalBusinessMemberAccountsBag;
       const [ userRoleStatus, setUserRoleStatus] = userRoleStatusesBag;
       const [ open, setOpen] = useState('');
       const [ username, setUsername] = useState('');
       const [ password, setPassword] = useState('');
       const [ otp, setOTP] = useState('');
       const [ error, setError] = useState('');
       const dataDec  = dataReceiver;
       let usersRole = {};
       let usersName = legalBusinessMemberAccount.username;
       let accountUsersRole = ''; 
       let isBusinessAccount = ''; 

   {/*if(typeof businessUsersBag !== 'undefined' && Object.keys(history.location.businessUserState).length > 0) {
        
        //  setLoginDetails(history.location.loginState);


     */}

     
  useEffect(()=> {

     
     
   }, [])


  

const handleSubmit = async()=>{


       let teamMemberID = legalBusinessMemberAccount.teamMemberID;
       let companyID = legalBusinessMemberAccount.companyID; 

       let data = { 
                  otp:otp, 
                  teamMemberID:teamMemberID, 
                  companyID:companyID
                 };
             
       data = JSON.stringify(data);

   const res = await axios.post(baseURL+'/bridgeVerifyTeamMemberOTP.php', data ).then(function (response) {

      
      response.data = dataDec(response.data);

     if(response.data.code == '200'){
                    
           if(userRoleStatus == 'authorizer'){                
                
              goToAuthorizer();
         
          }else if(userRoleStatus == 'inputter'){
               
             goToInputter(); 
          
          }else{
                
             setError('Please restart the system and try again.');
    
            }
        
              setOpen(false); 
       
       }else{

          setError(response.data.description);

       }
                
  });

}

  const goToAuthorizer = () => history.push({
    
    pathname: '/businessaccountactivity'
    
}); 


const goToInputter = () => history.push({
    
    pathname: '/businessaccountactivity'
    
}); 

const goToBusinessDashboard = () => history.push({ 

      pathname: '/Dashboard',
      loginState: loginDetails

});
  


  return (
    
    <main className = {classes.content}>
         <div className={classes.toolbar}/>

            <Grid container direction="column">

              <Grid item></Grid>

               <Grid item container spacing={2}>
                    <Grid item xs={false} sm={3} md={3} lg={4}/>
            
                <Grid item xs={12} sm={6} md={4} lg={4}>

                  <Card >
           
                                                       
              <CardHeader title="Confirm" align="center" />
                    <CardContent>
                      
                      {error == '' ?

                        <>
                        <Typography variant="body1" gutterBottom>
                           Hello<b> {usersName}</b>, please check your email for an OTP.
                        </Typography><br/>
                        </>
                      :
                      
                      <>  

                        <Typography variant="body1" gutterBottom>
                          <span style={{color:'red'}}> {error} </span>
                        </Typography>

                      </>  
                      
                      }           
                                     <Typography variant="h4" gutterBottom>
                                      <TextField label="OTP" variant="outlined"  fullWidth size="small"  placeholder="Enter OTP"
                                       id="otp"
                                       name="otp"
                                       value={otp}
                                       onChange={(e)=>setOTP(e.target.value)}
                                        
                                      />
                                      </Typography>




                                
                                      <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                                      </Backdrop>
                               
             <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style ={{margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> handleSubmit()}> Verify </Button>

                          
                  
                             </CardContent>
                          </Card>
                        </Grid>
          
                    <Grid item xs={false} sm={3} md={3} lg={4}/>
                 </Grid>

              </Grid>

  </main>

  );
}

export default UserVerification;