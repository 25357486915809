import React, { useEffect, useContext, useState } from 'react';
import { Card,  CardContent, InputAdornment, Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CartContext} from '../../Context/CartContext';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import DateRangeIcon from '@mui/icons-material/DateRange';
import axios from 'axios'; 


export const ConfirmBidTransaction = () => {
	
       const classes = useStyles(); 
       const history = useHistory(); 
       const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);  
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ open, setOpen] = useState(false);
       const [ responseStatus, setResponseStatus] = useState([]);
       const [ selectedPaymentPlatform, setSelectedPaymentPlatform] = useState([]);
       const [ commitmentAmount, setCommitmentAmount] = useState('');
       const [ bankCharges, setBankCharges ] = useState([]);
       const [ bankChargeAmount, setBankChargeAmount ] = useState([]);
       const [ paymentMethods, setPaymentMethods] = useState([]);
       const [ paymentMethod, setPaymentMethod] = useState('');
       const [ reference, setReference] = useState('');
       const [ settlementDate, setSettlementDate] = useState('');
       const [ consents, setConsents] = useState([{id:'1',name:'Yes'},{id:'2',name:'No'}]);
       const [ consent, setConsent] = useState('');
       const [ standardCharge, setStandardCharge] = useState('');
       const [ error, setError] = useState('');
       const dataDec  = dataReceiver;
       const requestedDetails = history.location.requestState;
       let responseDetails = {};



  useEffect(()=> {

      setOpen(!open); 
      getPaymentMethod();
      getStandardCharge();
     
   }, []) 




  
if(typeof requestedDetails !== 'undefined' && Object.keys(history.location.requestState).length > 0) {
          
                     
      }



  const getPaymentMethod = async()=>{
      
     
       const res = axios.post(baseURL+'bridge_iFund_GetPaymentMethod.php' ).then(function (response) {
        
          response.data = dataDec(response.data);
          setPaymentMethods(response.data);

        });

    }


  const getStandardCharge = async()=>{


       const res = axios.post(baseURL+'bridge_iFund_FunderFixedCharge.php').then(function (response){

          response.data = dataDec(response.data);
          setStandardCharge(response.data);
          setOpen(false);

       });

  } 


  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setPaymentMethod(event.target.value);

   };


    const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setConsent(event.target.value);

   };  



const submitPayment = () =>{
        

      setOpen(!open);  
      let fromAccount = loginDetails.momoAccountNumber;
      let companyID = loginDetails.organisations_id
      let requestID = requestedDetails.id;
      


      if(settlementDate == ''){

         setError('Settlemt Date is required');
         setOpen(false);
         return 0 ; 

      }
      if(commitmentAmount == ''){

         setError('Bid amount is required');
         setOpen(false);
         return 0 ;

      }
      
      if(paymentMethod == ''){

         setError('Please select your payment method.');
         setOpen(false);
         return 0; 

      }


      if(consent == '2' || consent == ''){

         setError('You cannot continue if you have not agreed with the consent.');
         setOpen(false);
         return 0;

      }


     let data = { fromAccountNumber:fromAccount, 
                  pledgeAmount:commitmentAmount, 
                  reference, 
                  approvedFundingRequestID:requestID, 
                  settlementDate:settlementDate, 
                  localEftVendorsID:paymentMethod, 
                  ePayNetCompanyID:companyID
                };

                setError('');   


   const res = axios.post(baseURL+'bridge_iFund_BidderTransaction.php', data ).then(function (response) {
                    
                   response.data = dataDec(response.data); 
                   setOpen(false);
                   responseDetails = response.data; 
                   goToResponse();
                                       
          });

 
}


const goToResponse = () => history.push({ 

      pathname: '/confirmbidresponse',
      responseState:responseDetails

});


	return (
  
      <main className = {classes.content}>

       <div className={classes.toolbar}/> 
           <Grid container direction="column">
                  <Grid item></Grid>
                     <Grid item container spacing={2}>
                        <Grid item xs={false} sm={2} md={3} lg={4}/>
                          <Grid item xs={12} sm={8} md={6} lg={4}>
  
                    <Card >
                             <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                             </Backdrop>
                                
                      <div style={{color:"red", align:"center", paddingTop:'15px'}} align='center'>{error}</div>       
                                
                        <CardContent>
                         <Typography align="center" variant="body1">
                               <h5>Confirm Bid Transaction</h5>
                         </Typography><hr/>
                                 
                          
                           {/*
                              <Typography variant="body1" gutterBottom>                
                                Funding Fee: <b>E{standardCharge.amount}</b>
                              </Typography>
                           */}


                          <Typography variant="body1" gutterBottom>                
                             Request Industry: <b>{requestedDetails.sectorName}</b>
                          </Typography>

                          

                          <Typography variant="body1" gutterBottom>                
                             Total Amount: <b>E{requestedDetails.totalAmount}</b>
                          </Typography>


                          <Typography variant="body1" gutterBottom>                
                             Due Date: <b>{requestedDetails.created_at}</b>
                          </Typography>


                          <Typography variant="body1" gutterBottom>
                                      <TextField label="Bid Amount" variant="outlined" type="text" fullWidth size="small"  placeholder="Bid Amount" 
                                      onChange={(e)=>setCommitmentAmount(e.target.value)}
                                      />
                          </Typography>

                          {/*<Typography variant="body1" gutterBottom>
                                      <TextField label="Settlement Date" variant="outlined" fullWidth size="small" type="date"
                                      id="settlementDate" name="settlementDate"
                                       onChange={(e)=>setSettlementDate(e.target.value)}
                                      />
                          </Typography>*/}



                           <Typography variant="body1" gutterBottom>
                                      <TextField label="Settlement Date" variant="outlined"  fullWidth size="small" type="date" placeholder="Settlement Date"
                                       id="settlementDate"
                                       name="settlementDate"
                                       value={settlementDate}
                                       onChange={(e)=>setSettlementDate(e.target.value)}
                                         
                                      />
                                      </Typography>


                        {/*
                          InputProps={{endAdornment:(<InputAdornment position="end"> 
                                                        <DateRangeIcon /> </InputAdornment>),
                                      }}
                        */}              






                          <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Funding Fee Payment Method" variant="outlined" fullWidth size="small" select
                                  onChange={handleChange}
                                  value={paymentMethod}
                                  > 
                            {paymentMethods.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.bankName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                         <Typography variant="body1" gutterBottom>                
                             I hereby consent that I have read and understood the P2P Funding terms and conditions. I also acknowledge that this transaction will be proccessed at a fee of <b>E{standardCharge.amount}</b>; It is also my responsibility to process the settlement balance within the date and that this transaction is irreversible. 
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Consent Option" variant="outlined" fullWidth size="small" select
                                  onChange={handleConsentChange}
                                  value={consent}
                                  > 
                            {consents.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.name}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography> 


                        
                <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{margin:'5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> submitPayment()}>Submit Reservation Bid</Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
                    <Grid item sm={2} md={3} lg={4}/>
                 </Grid>
              </Grid>
    </main>
	);
}

export default ConfirmBidTransaction;