import {Card,  CardContent,  Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../../Login/styles';	
import { useHistory } from 'react-router-dom';

export const ShareLoanStatus = () => {
	
 const classes = useStyles(); 
 const history = useHistory(); 
 const responseDetailsBag = history.location.responseState;

 const doneActivating = () => history.push({ 
      
      pathname: '/loans'

});


	return (

		
      <main className = {classes.content}>
            <div className={classes.toolbar}/>

   
  <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
                        <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card >
                                
                         <CardContent>
                            <Typography align="center" variant="body1">
                                  <h5>Share Status</h5>
                            </Typography>             
               
                     <Typography variant="body1" gutterBottom>                           
                             Status: <b>{responseDetailsBag.res}</b>
                     </Typography>





                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> doneActivating()} > Done</Button>

                                 

 

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                   <Grid item xs={false} sm={2} md={3} lg={4}/> 
                 </Grid>

              </Grid>




  </main>

	);
}



export default ShareLoanStatus;