import React, {useState,useContext} from 'react';
import { Grid, TextField, Card, CardMedia, Paper, IconButton, CardContent, Button, Typography } from '@material-ui/core';

import loans from '../../assets/cashlessEvent.png';
import thumbs from '../../assets/thumbs.png';
import letsMove from '../../assets/letsMove.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from '../../components/Login/styles';
import { useHistory,Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';
import { ArrowRightAltOutlined, ArrowLeftOutlined } from '@material-ui/icons';
import Chip from '@mui/material/Chip';



export const DialogComponent = ({dataCategories, filterItems}) => {
 

  const classes = useStyles(); 

 

return (
  
 	   <div> 


        {/*<Chip label="all" onClick={()=>filterItems('all')} style={{marginRight:'8px', marginLeft:'8px', color:'#155054'}} variant="outlined"/>*/}

     {dataCategories.map((item, index)=> (
 
          <Chip label={item} onClick={()=>filterItems(item)} key={index} style={{marginRight:'8px', marginLeft:'8px', color:'#155054'}} variant="outlined"/>

      ))}



     </div>    
               
 );
}

export default DialogComponent;