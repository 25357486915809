import React, { useEffect, useState, useContext } from 'react';
import {Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../Login/styles';	
import ServiceItem from './HistoryItem/ServiceItem'
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';   
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
   
export const ServiceHistory = () => {
	
 const classes = useStyles(); 
 const history = useHistory();
 const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext); 
 const [ loginDetails, setLoginDetails ] = loginDetailsBag;
 const [open, setOpen] = useState(false);
 let appUserID = loginDetails.organisations_id;
 const [ loanHistory, setLoanHistory ] = useState([]);
 const [ sharedLoanHistory, setSharedLoanHistory ] = useState([]);
 let loanHistoryDetails = {};
 let sharedLoanHistoryDetails = {};
 const dataDec  = dataReceiver;
 let companysID = loginDetails.organisations_id;



 useEffect(()=> {

        setOpen(!open);
        let data = {companyID:companysID};


        const res = axios.post(baseURL+'/bridge_dstv_GetTransactionHistory.php', data).then(function (response) {
              
                response.data = dataDec(response.data);
                setLoanHistory(response.data);
                setOpen(false);

         });

   }, [])



return (
		
      <main className={classes.content}>
            
             <Backdrop className={classes.backdrop} open={open} >
                     <Loader/>
             </Backdrop>

            <div className={classes.toolbar}/>

            <Typography variant="h6" align="center" gutterBottom>                
                       DSTV Payment History
            </Typography>
    
         <Grid container direction="column">

             <Grid container spacing={1}>
              
                 <Grid item xs={false}/>                         

                  {loanHistory.map((loans)=> ( 
                    <Grid item xs={12} md={3} lg={3} key={loans.id} >
                                          
                      <ServiceItem loans={loans}/>  
                             
                    </Grid>  
                  ))}
              
                 <Grid item xs={false}/>
             </Grid>
         </Grid>

  </main>

	);
}

export default ServiceHistory;