export const slide = [
  {
    heading: "Access to finance, in your hands",
    copy: "Convenience, satisfaction, interoperability, financial inclusion and instant payments.",
    url: "http://salvtec.co.sz/support/knowledgebase.php?category=15",
  },
  {
    heading: "Smart money, with ePayNet",
    copy: "Less time worrying about travelling costs, more time transacting on ePayNet.",
    url: "http://salvtec.co.sz/support/knowledgebase.php?category=15",
  },
  {
    heading: "Transact Bank to Bank with FNB, STD & NED",
    copy: "You can now transfer low-value funds from one bank account to another quickly, with ePayNet.",
    url: "http://salvtec.co.sz/support/knowledgebase.php?article=30",
  },
  {
    heading: "Bank together to create more wealth",
    copy: "Start a new financial year for your Stokvel/SACCO with ePayNet.",
    url: "http://salvtec.co.sz/support/knowledgebase.php?article=31",
  },
  {
    heading: "ePayNet business, for your business needs",
    copy: "Bulk distribution of salaries/dividends to MoMo/eWallet with a single payment, at zero costs.",
    url: "http://salvtec.co.sz/support/knowledgebase.php?article=29",
  },
  {
    heading: "Pay your DStv easily, with ePayNet",
    copy: "Don’t miss out on any of your favorite shows. Pay your DSTV subscriptions easily, with ePayNet.",
    url: "http://salvtec.co.sz/support/knowledgebase.php?article=28",
  },
  {
    heading: "Loan installment deductions from source",
    copy: "Get your company listed on PayEasy to provide your employees with a higher level of access to credit.",
    url: "http://salvtec.co.sz/support/knowledgebase.php?article=27",
  },
  {
    heading: "Buy insurance premiums conveniently",
    copy: "Buy your insurance premiums from the comfort of your home.",
    url: "http://salvtec.co.sz/support/knowledgebase.php?article=26",
  },
];
