import React, { useState, useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../Login/styles';	
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


export const PasswordReset = () => {
	
       const classes = useStyles(); 
       const history = useHistory(); 
       const { baseURL, dataReceiver } = useContext(CartContext); 
       const { loginDetailsBag }= useContext(CartContext);
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ open, setOpen] = useState(false);
       const [ recoveryContact, setRecoveryContact]=useState([]);
       const [ recoveryDetails, setRecoveryDetails] = React.useState([]);
       let recoveryDetailsBag = {}; 
       const [ error, setError] = useState('');
       const dataDec  = dataReceiver;

  
const goToCodeConfirmation = () => history.push({ 
      
        pathname: '/passwordcodeconfirmation',
        passRecoveryState: recoveryDetailsBag,
        recoveryContactDetails:recoveryContact

});

const submitRecoveryAccount = () => {
     
    setOpen(!open);  


    if(recoveryContact == ''){

        setError('Username/ contact is required');
        setOpen(false);
        return 0;

   }


    const submitAccount = async()=>{
          
      const res = await axios.get(baseURL+'bridgePasswordReset.php?contact='+recoveryContact+'&resetMethod='+recoveryContact);
     
      res.data = dataDec(res.data);


            if(res.data.code === '200'){

              setRecoveryDetails(res.data); 
              recoveryDetailsBag = res.data;  
              goToCodeConfirmation();
              setOpen(false);
      
              }else{

                       alert(res.data.description);
                       setOpen(false);

             }  
       }
      
      submitAccount();
     
 }

	return (

		
      <main className = {classes.content}>
            <div className={classes.toolbar}/>
  
  <Grid container direction="column">

               <Grid item></Grid>

                 <Grid item container spacing={4}>
                     {/*<Grid item xs={false} sm={4}/>
            
                      <Grid item xs={12} sm={4}>*/}
                       <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
               <Card >

               <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
               </Backdrop>
                               <div style={{color:"red", align:"center"}} align='center'>{error}</div> 
                  <CardContent>
                  <Typography align="center" variant="h6">Account Recovery</Typography><br></br>

                                <Typography variant="h4" gutterBottom>
                                      <TextField label="Contact" variant="outlined" fullWidth size="small"
                                      placeholder="Contact"  
                                       type="text"
                                       id="resetContact"
                                       name="resetContact"
                                       onChange={(e)=>setRecoveryContact(e.target.value)}
                                                                          
                                      />
                                      </Typography>


                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> submitRecoveryAccount()} > Submit</Button>
                                 
                           </CardContent>
                         </Card>

                        </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>

  </main>

	);
}

export default PasswordReset;