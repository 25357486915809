import React, { useEffect,useContext, useState } from 'react';
import {Card, CardContent, Paper, Box, Typography, TextField, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../../../components/Login/styles'; 
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../../Context/CartContext';
import axios from 'axios';
import Loader from '../../../components/Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';


export const GuestRollBack = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver }= useContext(CartContext);
       const dataDec  = dataReceiver;
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ open, setOpen] = useState(false);
       const [ events, setEvents] = useState([]); 
       const [ fullName, setFullname] = useState('');
       const [ contact, setContact] = useState('');
       const [ cellNumberRef, setCellNumberRef] = useState('');
       const [ fromAccount, setFromAccount] = useState('');
       const [ rollBackAccount, setRollBackAccount] = useState('');
       const [ event, setEvent] = useState('');   
       const [ amount, setAmount] = useState('');
       const [ reference, setReference] = useState('');
       const [ pocketIdentifier, setPocketIdentifier] = useState('');
       const [ error, setError] = useState('');  
       const [ useActivity, setUserActivity] = useState('');
       const [ voucherPIN, setVoucherPIN] = useState('');
       const [ responseMessage, setResponseMessage] = useState({});
       const [ isePayNetActive, setIsePayNetActive] = useState(false);
       const [ rollBackDetails, setRollBackDetails] = useState(false);
       const [ rollBackSuccess, setRollBackSuccess] = useState(false);
       const [ voucherDetails, setVoucherDetails] = useState({});
       let responseDetails = {};
       let voucherDetailsBag = {};
       let newPocket = {};                  



  useEffect(()=> {
          
          getEvents();
    
    }, [])
 


  const getEvents = async()=>{
      
       const res = axios.post(baseURL+'bridge_evPoc_GetEvents.php').then(function (response) {
        
          response.data = dataDec(response.data);
          setEvents(response.data);

        });

 } 




const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setEvent(event.target.value);
       
}


const checkRechargeVoucher = async() =>{
  
//Select the event, if is ePayNet, get ePayNet Details, else prompt for details 
    getCreatedPocketDetails();

}


 

const submitUpdateVoucher = () =>{
          
          setError('');
      let eventsID = event;
      let pocketIdentifier = voucherDetails.cellNumberRef;
      let companyID = loginDetails.organisations_id;
      let companyAccount = loginDetails.momoAccountNumber;
      

      let data = {
                  eventID:eventsID,
                  cellNumberRef:pocketIdentifier,
                  fromAccount:companyAccount, 
                  amount:amount, 
                  toAccount:rollBackAccount, 
                  pocketPin:voucherPIN, 
                  ePayNetCompanyID:companyID
                 };


    const res = axios.post(baseURL+'bridge_EvPoc_ReceiveAttendeeUnitsCashOut.php', data ).then(function (response) {
               
               response.data = dataDec(response.data);

           let responseData = response.data;

            if(responseData.code === '200'){

                setResponseMessage(responseData);   
                setRollBackSuccess(true);

            }else{

                setError(responseData.description);

            }
          
    });

}



const getCreatedPocketDetails = () =>{
      
    {/*GET THE CREATED POCKET DETAILS*/}
    let eventsID = event;
    let cellRef = cellNumberRef;              

    let data = {eventsID:event, cellNumberRef:pocketIdentifier};

    const res = axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php', data ).then(function (response) {

            response.data = dataDec(response.data);  
            newPocket = response.data
            let mycontainer = Object.keys(newPocket).length;
                

    if(mycontainer <= 0){

       setError('Voucher has not been assigned to anyone please activate the voucher first');
       return 0;

      }else{
                
                setError('');  
                setVoucherDetails(newPocket[0]);
                setRollBackDetails(true); 
            
         }
     });

 }

const completeRollBack = () => history.push({
    
    pathname: '/eventhome'
    
});



  return (

     <main className = {classes.content}>
       <div className={classes.toolbar}/>
       
    <Grid container direction="column">

     
         <Grid item container spacing={1}>
                
                 <Grid item xs={false} sm={3} md={3} lg={4}/> 

                  <Grid item xs={12} sm={6} md={6} lg={4}>
                       

                        <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>
                
                           <Grid container spacing={1}>
                              <Grid item xs={12} align="center">
                                 {error ?
                                  <span style={{color:'red'}}><b>{error}</b></span>
                                  :
                                    <>
                             <Typography align="center" variant="body1">
                                  <b> Roll Back Funds </b>                            
                             </Typography>
                                    </>
                                  }

                                     
                                   </Grid>
                                 </Grid>
                               <hr /> 
                            

{rollBackSuccess ?
      
      <>
      <span style={{width:'96%', }}>
            <Typography variant="body1" gutterBottom style={{padding:'10px'}}>
              <b>  {responseMessage.description} </b>
            </Typography>

            <Button className={classes.loginButton} type="button" variant="contained" style={{margin:'5px', textTransaform:'none', width:'96%', backgroundColor:'#155054', color:'white'}} onClick={()=> completeRollBack()}>
                       Done
            </Button> 
      </span>          
      
                </>
                :
                <>
                  {!rollBackDetails ?
                  <CardContent >
                        <Typography variant="body1" gutterBottom>                
                            <TextField label="Select Event" variant="outlined" fullWidth size="small" select
                                  onChange={handleEventChange}
                                  value={event}
                                  > 

                                    {events.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.eventName}
                                   </MenuItem>

                                   ))}
                            </TextField>
                        </Typography>

                        <Typography variant="h4" gutterBottom>
                            <TextField label="Voucher Number" variant="outlined" type="text" fullWidth size="small"  placeholder="Voucher" 
                            onChange={(e)=>setPocketIdentifier(e.target.value)}
                                      />
                        </Typography> 


                         <Typography variant="h4" gutterBottom>
                            <TextField label="Amount" variant="outlined" type="text" fullWidth size="small"  placeholder="Reversal Amount" 
                            onChange={(e)=>setAmount(e.target.value)}
                                      />
                        </Typography>


                         <Typography variant="h4" gutterBottom>
                            <TextField label="Cell Number" variant="outlined" type="text" fullWidth size="small"  placeholder="Reversal Account" 
                            onChange={(e)=>setRollBackAccount(e.target.value)}
                                      />
                        </Typography> 
                       

            <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin:'5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> checkRechargeVoucher()}>
                 Submit
                </Button>
          </CardContent >

          :

          <CardContent>

                        <Typography variant="body2" gutterBottom>                
                             Fullname:<b>{voucherDetails.fullName}</b>
                        </Typography>

                        <Typography variant="body2" gutterBottom>                
                             Cell Number:<b>{voucherDetails.cellNumber}</b>
                        </Typography>

                  <Typography variant="body2" gutterBottom>                
                    Voucher Number:<b>{/*{voucherDetails.cellNumberRef}*/}****</b>
                  </Typography>

   
                        <Typography variant="body2" gutterBottom>                
                             Event:<b>{voucherDetails.eventName}</b>
                        </Typography>


                        <Typography variant="body2" gutterBottom>                
                             Your Current Balance:<b>E{voucherDetails.currentBalance}</b>
                        </Typography>

                        <Typography variant="body1" gutterBottom>                
                           <b> Reversal Amount: E{amount}</b>
                        </Typography>

                        <Typography variant="body1" gutterBottom>                
                           <b> Reverse To: {rollBackAccount}</b>
                        </Typography>


                        <Typography variant="h4" gutterBottom>
                            <TextField label="Voucher PIN" variant="outlined" type="password" fullWidth size="small"  placeholder="PIN Number" 
                            onChange={(e)=>setVoucherPIN(e.target.value)}
                                      />
                        </Typography>
 

                   <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> submitUpdateVoucher()}>
                 Submit
                </Button>       

          </CardContent>
             
      }

    </>

  }

                   </Card>

             </Grid>
                

            <Grid item xs={false} sm={3} md={3} lg={4}/>     
                 </Grid>

              </Grid>

  </main>

  );
}

export default GuestRollBack;