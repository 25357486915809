import React, { useEffect, useState, useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


export const Authorization = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { loginDetailsBag, accountsTypeBag, baseURL, dataReceiver, legalBusinessMemberAccountsBag }= useContext(CartContext);
       const [ legalBusinessMemberAccount, setLegalBusinessMemberAccount] = legalBusinessMemberAccountsBag;
       const [loginDetails, setLoginDetails] = loginDetailsBag;
       const [open, setOpen] = useState('');
       const [ username, setUsername] = useState('');
       const [ password, setPassword] = useState('');
       const dataDec  = dataReceiver;
       const checkObjType = history.location.loginState;
       const checkObjType1 = history.location.authorizerState;
       let businessUsersBag = {};



  if(typeof checkObjType !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
          setLoginDetails(history.location.loginState);

      }

    if(typeof checkObjType1 !== 'undefined' && Object.keys(history.location.authorizerState).length > 0) {
        
         

      }    


     
  useEffect(()=> {

      
     
   }, [])


  

const handleSubmit = async()=>{
      
      
      let companyID = loginDetails.organisations_id;
      let data = {companyID:companyID, username:username, password:password}

      data = JSON.stringify(data);
    
   const res = await axios.post(baseURL+'/bridgeLegalBusinesTeamMemberLogin.php', data ).then(function (response) {

         
          response.data = dataDec(response.data);       
                  
          console.log(response.data)        

     if(response.data.code === '200'){
     
       businessUsersBag = response.data;   
       setOpen(false);   
       goToVerify();

       
       }else{


       alert(response.data.desceription);

       }
                
  });

}

 
  const goToVerify = () => history.push({ 

      pathname: '/userverification',
      businessUserState:businessUsersBag

});

  


  return (
    
    <main className = {classes.content}>
         <div className={classes.toolbar}/>

            <Grid container direction="column">

              <Grid item></Grid>

               <Grid item container spacing={4}>
                    <Grid item xs={false} sm={3} md={4} />
            
                <Grid item xs={12} sm={6} md={4} lg={4} align='center'>

                  <Card >
           
                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>
                            
              <CardHeader title="Verification"  align="center" />
                    <CardContent>
    
                                                         
                                 
                                     <Typography variant="h4" gutterBottom>
                                      <TextField label="Username" variant="outlined"  fullWidth size="small"  placeholder="Enter Username"
                                       id="username"
                                       name="username"
                                       value={username}
                                       onChange={(e)=>setUsername(e.target.value)}
                                        
                                      />
                                      </Typography>




                                <Typography variant="h4" gutterBottom>
                                      <TextField label="Password" variant="outlined"  fullWidth size="small"  placeholder="Password" 
                                        type="password"
                                       id="password"
                                       name="password"
                                       value={password}
                                       onChange={(e)=>setPassword(e.target.value)}  

                                      />
                                      </Typography>
                                      <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                                      </Backdrop>
                               
             <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> handleSubmit()} > Submit </Button>

                          
                  
                             </CardContent>
                          </Card>
                        </Grid>
          
                    <Grid item xs={false} sm={3} md={4}/>
                 </Grid>

              </Grid>

  </main>

  );
}

export default Authorization;