import React, {useState, useEffect, useContext} from 'react';
import {Card,  CardContent,  Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../Login/styles';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';



export const BusinessDocumentProcessing = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, dataReceiver, loginDetailsBag, accountsTypeBag, legalBusinessMemberAccountsBag, userRoleStatusesBag }= useContext(CartContext);
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ legalBusinessMemberAccount, setLegalBusinessMemberAccount] = legalBusinessMemberAccountsBag; 
       const [ uploadFile, setUploadFile] = useState({});
       const [ userRoleStatus, setUserRoleStatus] = userRoleStatusesBag;
       const [ accountsType, setAccountsType] = accountsTypeBag;
       const [ responseMessage, setResponseMessage] = useState('');
       const checkObjType = history.location.loginState;
       const uploadFileDetails = history.location.uploadState;
       const dataDec  = dataReceiver;
       let myMessage = {};
       let fileDetails = {};
       let isBusinessAccount = '';
       let userAccountType = '';
       let accountUsersRole = '';
       let errorCode = '';



         useEffect(()=> {

       
         }, [])

 
  // handle file upload
  const handleFileUpload = e => {

    setResponseMessage(''); 
    const file = e.target.files[0];
    setUploadFile(file)    
    
  }



 if(typeof checkObjType !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
       setLoginDetails(checkObjType);
       let isBusinessAccount = checkObjType.isLegalBusiness;

       if(isBusinessAccount == 'T'){

          userAccountType = 'businessAccount';
          setAccountsType(userAccountType)

       }else if(isBusinessAccount == 'F'){

          userAccountType = 'personalAccount';
          setAccountsType(userAccountType)
       }
       
  }




const proofOfPayMentUpload = async()=>{


   let formdata = new FormData();
   let amount = uploadFileDetails.amount;
   let requestID = uploadFileDetails.id;
   let requestNumber = uploadFileDetails.requestNumber;

   formdata.append('file', uploadFile);
   formdata.append('amount', amount);
   formdata.append('requestID', requestID);
   formdata.append('requestNumber', requestNumber);
   

   const res = await axios.post(baseURL+'/bridgeReceiveRechargeUnitCsvFile.php', formdata ).then(function (response) {


       if(response.data[0].code == '200'){

            errorCode = response.data[0].code;
            myMessage = response.data[0];
            goToViewStatus();
            
            
       }else{

            response.data[0] = dataDec(response.data[0]); 
            errorCode = response.data[0].code;
            setResponseMessage(response.data[0].description);
           
        }   
     });
         
}

const goToViewStatus = () => history.push({
    
    pathname: '/businessactivityresponse',
    responseState:myMessage
    
}); 


  
return (
    
  <main className = {classes.content}>
       <div className={classes.toolbar}/>
       
    <Grid container direction="column">

              <Grid item className={classes.sidebar} xs={false} sm={3} md={3} lg={3}></Grid>

                 <Grid item container spacing={4}>
                  <Grid item xs={12} sm={2} md={3} lg={4}/>
             
                  <Grid item  xs={12} sm={8} md={6} lg={4}>
                            <Card >
                            
                       {/*style={ errorCode != 200 ? { color:'red'} : {color:'green'} }*/}     
           <Grid container spacing={2}>
                <Grid item xs={12} align="center">            
                       { responseMessage == '' ?
                          <>
                          
                          <Typography align="center" variant="body1"><b>Select CSV File for {uploadFileDetails.requestNumber}</b></Typography>   

                          </>
                          :

                          <>
                            <div align='center' style={{color:'red'}}>{responseMessage}</div>
                          </>
                        }    

                </Grid>
           </Grid>   
     
                <hr></hr>
                <CardContent>


                  <Typography align="center" variant="body1" gutterBottom>

                  <input
                    type="file"
                    accept=".csv,.xlsx,.xls,.png,.jpeg"
                    onChange={handleFileUpload}
                  />

                  </Typography><br/>


                  {/*<DataTable
                            pagination
                            highlightOnHover
                            columns={columns}
                            data={data}
                          />*/}

{ errorCode === '200' ? 


    <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{margin:'5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> proofOfPayMentUpload()} >Done </Button>


  :

  
  <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{margin:'5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> proofOfPayMentUpload()} >Upload </Button>



  }

                 </CardContent>  
            </Card>
        </Grid>
          
                    <Grid item xs={false} xs={false} sm={2} md={3} lg={3}/>
                 </Grid>
                

              </Grid>
  </main>

  );
}

export default BusinessDocumentProcessing;