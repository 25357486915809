import React, {useEffect, useState, useContext} from 'react';
import {Card, CardHeader, CardContent, TextField, Typography, MenuItem, Grid, Button} from '@material-ui/core';
import useStyles from '../../components/Login/styles';
import { useHistory } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Box from '@mui/material/Box';



export const GuestEventHome = () => {
        
       
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver }= useContext(CartContext);
       const [ open, setOpen] = useState(false);
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const dataDec  = dataReceiver;
       const [ cellNumberRef, setCellNumberRef] = useState('');
       const [ events, setEvents] = useState([]); 
       const [ eventDetails, setEventDetails] = useState([]);
       const [ event, setEvent] = useState('');
       const [ error, setError] = useState('');
       const loggedInUserDetails = history.location.loginState;
       const [ notOnEpaynet, setNotOnePaynet] = useState(false);

       let pocketDetailsBag = {};
       let rechargeDetailsBag = {};
       {/*THIS WILL BE DEFINED ON LOGIN, CHECK IF USER HAS ACTIVE POCKET*/}
       const [ hasActivePocket, setHasActivePocket] = useState(false);             
       


useEffect(()=> {
          
      if(Object.keys(loginDetails).length > 0){

        //alert(loggedUser);

        }else{

            setNotOnePaynet(true);
            //prompt('');

        }

        setOpen(!open);                              

   }, [])



 

/*
const obj = { a: 1, b: 2, c: 3 };
obj.__proto__.d = 4
for (const key of Object.keys(obj)) {
    console.log(key);
}
// Output:
// a
// b
// c
*/



const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setEvent(event.target.value);
      let eventID = event.target.value

      for (let i = 0; i < events.length; i++) { 
        let allEventsBagID = events[i].id

           if(eventID === allEventsBagID){

                  setEventDetails(events[i]);

              }
        }
  }


const goToCreatePocket = () => history.push({
  
  pathname: '/guestpocketgenerate'

});

const resetPin= () => history.push({
  
  pathname: '/guestresetpin'

});



const checkBalance = () => history.push({
  
  pathname: '/guestcheckbalance'

});

const goToRechargePocket = () => history.push({
  
  pathname: '/guestpocketrecharge',
  rechargeDetailsState:rechargeDetailsBag

}); 


const disbursement = () => history.push({
  
  pathname: '/guesteventsdashboard'

});

const openRollBack = () => history.push({
  
  pathname: '/guestrollback'

});





const loginToEpaynet = () => history.push({
  
  pathname: '/'

});


const registerOnEpayNet = () => history.push({
  
  pathname: '/Registration'

});


const learnMore = async() =>{
  
      
          let data = {moduleID:'1'}
        //'http://www.salvtec.co.sz/support/knowledgebase.php?category=',data
        // 
    const res = await axios.post(baseURL+'bridgeGetModuleWebsiteUrl.php', data).then(function (response) {

      response.data = dataDec(response.data);
      
                    let url = response.data[0].moduleUrl
                    window.open(url, "_blank");              
                    setOpen(false);                
          
          });

    }


const createPocket = async() =>{
      
      let data = {eventsID:event, cellNumberRef:cellNumberRef, event:eventDetails.eventName}
        
            goToCreatePocket(); 
            pocketDetailsBag = data;
            
    /*CHECK IF POCKET DOESN'T EXIST 
  const res = await axios.post(baseURL+'bridge_evPoc_GetEventAccountDetails.php',data ).then(function (response) {
        
                response.data = dataDec(response.data);
                let pocketDetails = response.data;
                let mycontainer = Object.keys(pocketDetails).length;
           
           if(mycontainer > 0){
                setError('This Pocket Identifier has already been used for this event');      
               }else{
                 goToCreatePocket() 
            }

       }); 
     */   
      
    }



   const topUp = async() =>{

      let data = {eventsID:event, cellNumberRef:cellNumberRef, event:eventDetails.eventName}
                 rechargeDetailsBag = data;
                 goToRechargePocket();

  }


return (
    
  <main className = {classes.content}>
       <div className={classes.toolbar}/>
        
        <Grid container direction="column">

          <Grid item className={classes.sidebar} xs={false} sm={3} md={3} lg={3}></Grid>

                 <Grid item container spacing={2}>
                  <Grid item xs={12} sm={2} md={4} lg={4}>
                  
                    {notOnEpaynet ?

                      <>
                        <Card>

                     
                       <CardHeader title="Guest User" align="center" /> 
                         
                        <hr />
                            <CardContent>

            

                
                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> loginToEpaynet()}>    
                            Login To ePayNet
                  </Button>

                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> registerOnEpayNet()}>    
                            Register On ePayNet
                  </Button>

                    <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> learnMore()}>    
                           What is ePayNet?
                  </Button>

                   <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> learnMore()}>    
                           What is ePayNet Cashless Events?
                  </Button>
               

              

                            </CardContent>
                      </Card>
                 </>      
               :
               <>
               </>
              }
              
                  </Grid>
             
                    <Grid item  xs={12} sm={8} md={4} lg={4}>
                  
             <Card>

                     {error ?
                             <h5 align="center" style={{color:'red'}}>{error}</h5>
                          :
                         <>
                          <CardHeader title="Cashless Events" align="center" />                         
                         </>
                     }   
                     <hr />

    
                  <CardContent>
                   
             <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> createPocket()}>    
                            Create Voucher
             </Button>

           

                          <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> disbursement()}>    
                                  Use Voucher
                          </Button> 


                          <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> openRollBack()}>    
                              Roll Back
                          </Button>

                          <Button className={classes.loginButton} type="button" variant="contained" fullWidth style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> resetPin()}>    
                                   Reset / Update Voucher PIN
                          </Button>

                         </CardContent>  
                       </Card>
          </Grid>
          
                    <Grid item xs={false} sm={2} md={4} lg={4}/>
                 </Grid>

              </Grid>

  </main>

  );
}

export default GuestEventHome;