import React, {useContext, useState} from 'react';
import {Card, CardMedia, CardContent, CardActions, TextField, Button, InputAdornment, Typography, IconButton} from '@material-ui/core';
import { AddShoppingCart } from '@material-ui/icons';
import Badge from '@mui/material/Badge';
import useStyles from '../../Login/styles';
import {CartContext} from '../../../Context/CartContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { useHistory, Link } from 'react-router-dom';


const BusinessTransaction = ({ historys, product, loading}) => {

     const classes = useStyles();
     const history = useHistory();


   if(loading){
    
        return <h2>Loading..</h2>
    }



return (

      <>

        <Card >
           
            <CardContent>
                <div className={classes.cardContent}>

                    <Typography variant="body1" gutterBottom>
                     <b>  Transaction #: RUR1234567890 </b>
                    </Typography>
                  <hr/>  

                    <Typography variant="body1" gutterBottom>
                       Cell number: {historys.cellNumber}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                       Email: {historys.email}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                       Status:
                        <Badge color={historys.rightName == 'declined' ? 'red' : 'green'} badgeContent=" ">
                          {historys.rightName}    
                        </Badge>
                    </Typography> 

                    <Typography variant="body1" gutterBottom>
                       Date: {historys.rightName}
                    </Typography> 


                     <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{margin:'5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}}>View Transactions </Button>


                </div>
            </CardContent>

        </Card>


  </>   




       
    )
}

export default BusinessTransaction;
