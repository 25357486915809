import React, { useEffect,useContext,useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const ConfirmTransaction = () => {
	
   const classes = useStyles(); 
   const history = useHistory();  
   const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
   const [loginDetails, setLoginDetails] = loginDetailsBag;
   const [open, setOpen] = useState(false);
   const [paymentPlatforms, setPaymentPlatforms] = React.useState([]);
   const [responseStatus, setResponseStatus] = React.useState([]);
   const [selectedPaymentPlatform, setSelectedPaymentPlatform] = React.useState([]);
   const [bankCharges, setBankCharges ] = React.useState([]);
   const [bankChargeAmount, setBankChargeAmount ] = React.useState([]);
   const [isWithCharges, setIsWithCharges ] = React.useState(true);
   let selectedBankChargeAmount = '';
   let selectedPlatform = '';
   let data2 = {};
   let data = {};
   let bankTransactionDetailsBag = {};
   const dataDec  = dataReceiver;
   
   const beneficiaryBankingDetailsBag = history.location.beneficiaryBankingState;
          

         useEffect(()=> {
 
           setOpen(!open);

           const fetchPaymentPlatform = async()=>{
          
           const res = await axios.get(baseURL+'bridgeGetMobileMoneyVendors.php');
            
            res.data = dataDec(res.data);



          setPaymentPlatforms(res.data); 
          setOpen(false);     

      }

         const fetchBankCharges = async()=>{
          
             
       const res = await axios.get('https://www.digimagesystem.com/ePayNet2019/apiWeb/getPaymentGatewaySwitchingCharges.php?btnName=M2B');
          
              setBankCharges(res.data);   

      } 

      fetchBankCharges();
      fetchPaymentPlatform();
     
   }, [])


   const goToLenderConfirmationStatus = () => history.push({ 
      
      
});
  
 const handleBankChargesChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    let selectedBankChargeAmount = bankCharges[0].chargeAmount;    
    setIsWithCharges(!isWithCharges);
    let currentChargeInclusion = isWithCharges;  

        if(currentChargeInclusion == true){

           let amount= beneficiaryBankingDetailsBag.amount;
           let totalBalance = (parseFloat(amount)+parseFloat(selectedBankChargeAmount)).toFixed(2); 

           setBankChargeAmount(totalBalance);
       
        }else{

         let amount = beneficiaryBankingDetailsBag.amount;
         setBankChargeAmount(amount);

        }
 
  };    


const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedPaymentPlatform(event.target.value);
    selectedPlatform = event.target.value;
    
  };  

const account = beneficiaryBankingDetailsBag.accountNumber;

      const min = 100;
      const max = 999;
      const rand =  Math.floor(Math.random() * (max - min + 1) ) + min;
      const randAccountNumber = `${rand}${account}`;

  function d2h(d) {
    return d.toString(16);
 }
  function h2d (h) {
    return parseInt(h, 16);
 }
  function stringToHex (tmp) {
    let str = '',
        i = 0,
        tmp_len = tmp.length,
        c;
 
    for (; i < tmp_len; i += 1) {
        c = tmp.charCodeAt(i);
        str += d2h(c) + '';
    }
    return str;
}

const rand3hexAccountNumber = stringToHex(randAccountNumber);

  const submitPayment = () =>{

        setOpen(!open);
        let dt = new Date();
        let seconds = dt.getSeconds();
        let minutes = dt.getMinutes();
        let banksID= beneficiaryBankingDetailsBag.Bank;
        let branchesID= beneficiaryBankingDetailsBag.Branch;
        let accountNumber= rand3hexAccountNumber
        let amount= beneficiaryBankingDetailsBag.amount;
        let reference= beneficiaryBankingDetailsBag.reference;
        let token = minutes+''+beneficiaryBankingDetailsBag.accountNumber+''+seconds;
        let appUser = beneficiaryBankingDetailsBag.userKey;
        let paymentPlatform = selectedPaymentPlatform;
        let username = loginDetails.username;

        if(bankChargeAmount > amount){

         amount = bankChargeAmount;
      }

//INCLUDED THE BANK CHARGE AMOUNT TO JSON STRING
 
data2 = {
      bankID:banksID, branchCode:branchesID, accountNumber:accountNumber,
      amount, reference, 
      companyID:appUser, paymentPlatform, username,
      destination_platform_id:5, token,
      includingBankChargeAmount:bankChargeAmount
     }

 data = JSON.stringify(data2);

        const res = axios.post(baseURL+'bridgeMobileMoneyToBankTransaction.php', data ).then(function (response) {
       
           response.data = dataDec(response.data);

        
       if(response.data !== ''){
     
          bankTransactionDetailsBag = response.data ;
          setOpen(false);
          goToPaymentStatus();
        
        }else{

          alert('Connection error, Please try again later.');
          setOpen(false); 

     }   
       
  });


}

const goToPaymentStatus = () => history.push({ 

      pathname: '/banktransactionstatus',
      bankTransactionState: bankTransactionDetailsBag

});




	return (
  
      <main className = {classes.content}>

            <div className={classes.toolbar}/> 
        <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
                         <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card >
                                
                      <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                      </Backdrop>
                               
                               <CardContent>
           <Typography align="center" variant="body1">
                 <h5>Confirm Transaction</h5>
           </Typography>
                                 
                          <Typography variant="body1" gutterBottom>                
                                 
                            Daily Available Limit: <b>E{loginDetails.available}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Monthly Available Limit: <b>E{loginDetails.monthlyAvailableLimit}</b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Recipient :{beneficiaryBankingDetailsBag.accountNumber} <b></b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Account: {beneficiaryBankingDetailsBag.accountNumber}<b></b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Amount:E{beneficiaryBankingDetailsBag.amount}
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             Total Balance:<b>E{bankChargeAmount} </b>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 
                             <FormControlLabel
                                control={<Checkbox isWithCharges={isWithCharges} onChange={handleBankChargesChange}/>}
                                label="Include Charges"
                                color="Primary"
                              />
                                                      
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 <TextField label="Payment Platform" variant="outlined"  fullWidth    size="small" select
                                  onChange={handleChange}
                                  value={selectedPaymentPlatform}
                                  > 

                                    {paymentPlatforms.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.vendorName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>


                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> submitPayment()} > Submit</Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>
    </main>

	);
}



export default ConfirmTransaction;