import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Loader from "../Loader/Loader";
import Backdrop from "@material-ui/core/Backdrop";

export const ActivateBusiness = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    baseURL,
    loginDetailsBag,
    dataReceiver,
    legalBusinessMemberAccountsBag,
  } = useContext(CartContext);
  const [open, setOpen] = useState(false);
  const [legalBusinessMemberAccount, setLegalBusinessMemberAccount] =
    legalBusinessMemberAccountsBag;
  const [selectedStatus, setSelectedStatus] = useState("");
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  let eCommerceStatus = loginDetails.isEcommerceParticipant;
  let appUserID = loginDetails.organisations_id;
  const [activeStatus, setActiveStatus] = useState([]);
  const [amount, setAmount] = useState("");
  const [reference, setReference] = useState("");
  const [selectedBankAccount, setSelectedBankAccount] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  let selectedConsent = "";
  let distributorDetailsBag = {};
  const [consents, setConsents] = useState([]);
  const [currentOutboundBroadcastBalance, setCurrentOutboundBroadcastBalance] =
    useState("");
  const dataDec = dataReceiver;
  const [activationCategory, setActivationCategory] = useState([
    { id: "1", name: "ePayNet Business" },
    { id: "2", name: "Online checkout" },
    { id: "3", name: "Crowdfunding" },
  ]);

  const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStatus(event.target.value);
    selectedConsent = event.target.value;

    for (let i = 0; i < consents.length; i++) {
      let bankID = consents[i].id;

      if (selectedConsent === bankID) {
        setSelectedBankAccount(consents[i].accountNumber);
        setSelectedBankName(consents[i].bankName);
      }
    }
  };

  useEffect(() => {
    setOpen(!open);

    const res = axios
      .post(baseURL + "bridgeGetEpayNetBankAccounts.php")
      .then(function (response) {
        response.data = dataDec(response.data);

        let banks = response.data;

        for (let i = 0; i < banks.length; i++) {
          let bankDetails = banks[i];

          setConsents((curr) => [...curr, bankDetails]);
        }

        setOpen(false);
      });

    let data = { comapnyID: loginDetails.id };
    data = JSON.stringify(data);

    const resp = axios
      .post(baseURL + "bridgeGetLegalCompanyOutboundBroadcastBalance.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);
        setCurrentOutboundBroadcastBalance(response.data);
        setOpen(false);
      });
  }, []);

  const updateBusinessAcountStatus = () => {
    setOpen(!open);

    let data = {
      bank: selectedBankName,
      amount: amount,
      bankID: selectedStatus,
      reference: reference,
      teamMemberID: legalBusinessMemberAccount.teamMemberID,
      bankAccount: selectedBankAccount,
    };

    distributorDetailsBag = data;

    goToTransactionConfirmation();
    setOpen(false);
  };

  const goToTransactionConfirmation = () =>
    history.push({
      pathname: "/businessaccountdetails",
      bankerDetailsState: distributorDetailsBag,
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />

          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardHeader title="Update Credit" align="center" />
              <CardContent>
                <Typography variant="body1" gutterBottom>
                  Please add the proof of payment details.
                </Typography>
                <br></br>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Select Your bank"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleConsentChange}
                    value={selectedStatus}
                  >
                    {consents.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.bankName} ({option.accountNumber})
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Amount"
                    type="text"
                    id="Amount"
                    name="Amount"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Reference"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Reference"
                    type="text"
                    id="reference"
                    name="reference"
                    onChange={(e) => setReference(e.target.value)}
                  />
                </Typography>

                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  fullWidth
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => updateBusinessAcountStatus()}
                >
                  {" "}
                  Update
                </Button>

                <br></br>
                <br></br>
                {activeStatus.description !== "" && (
                  <Typography
                    variant="body2"
                    gutterBottom
                    activeStatus={activeStatus}
                  >
                    {activeStatus.description}
                  </Typography>
                )}
              </CardContent>
              <br></br>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default ActivateBusiness;
