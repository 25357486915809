import React, { useEffect,useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import axios from 'axios';

export const GiveDetails = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, dataReceiver, loginDetailsBag } = useContext(CartContext); 
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ open, setOpen] = React.useState(false); 
       const [ institutionCategorys, setInstitutionCategorys] = React.useState([]);
       const [ institution, setInstitution] = React.useState([]);
       const [ selectedInstitution, setSelectedInstitution] = React.useState([]);
       const [ selectedInstitutionName, setSelectedInstitutionName] = React.useState([]);
       const [ amount, setAmount ] = React.useState([]);
       const [ reference, setReference ] = React.useState([]);
       const [ institutionDetails, setInstitutionDetails ] = React.useState([]);
       const [ companyName, setCompanyName ] = React.useState([]);
       const [ reason, setReason ] = React.useState([]);
       const [ offeringReason, setOfferingReason ] = React.useState([]);
       const [institutionsName, setInstitutionsName] = React.useState([]); 
       const dataDec  = dataReceiver;  
       let selectedInstitutionsID = '';
       let selectedInstitutionsName = '';
       let selectedReason = '';
       let responseDetailsBag = {};
       let institutionPaymentDetailsBag = {};
       let appUser = loginDetails.organisations_id;
       let username = loginDetails.username;
     
  useEffect(()=> {

      setOpen(!open);
      const fetchInstitutionCategories = async()=>{
          
          const res = await axios.get(baseURL+'bridgeBringChurchNgoAndIndividual.php?apiUserDetails='+appUser);
         
           
          res.data = dataDec(res.data);
          setInstitutionCategorys(res.data); 
          setOpen(false);     

      }

     

  fetchInstitutionCategories();
     

     
   }, [])


  
   const handleInstitutionChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedInstitution(event.target.value);
    selectedInstitutionsID = event.target.value;
    setOpen(!open);

        const fetchInstitution = async()=>{
          
          const res = await axios.get(baseURL+'bridgeGetMyCurrentlySelectedCategoryCompanies.php?selectedCompanyCategories_id='+selectedInstitutionsID+'&logonCompanies_id='+appUser);
          
           res.data = dataDec(res.data);

          setInstitution(res.data);      
          setOpen(false);

      }

      const fetchOfferingCategories = async()=>{
          
          const res = await axios.get(baseURL+'bridgeBringOfferingCategories.php?companyCategories_id='+selectedInstitutionsID);
          
           res.data = dataDec(res.data);
        
          setOfferingReason(res.data);  
          setOpen(false);    

      }

        fetchOfferingCategories();
        fetchInstitution();


  };



const handleInstitutionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {

      setSelectedInstitutionName(event.target.value);
      selectedInstitutionsName = event.target.value;
      let companyDetails = institution.filter(institution => institution.id === selectedInstitutionsName);
      let companyName = companyDetails[0].companyName;

      setCompanyName(companyName)

  };


const handlePaymentReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {

     
      selectedReason = event.target.value;
      let companyDetails = offeringReason.filter(offeringReason => offeringReason.id === selectedReason);
     
      let paymentReason = companyDetails[0].name;
      setReason(event.target.value); 
          

  };

  
const submitLoan = () => {
  
  let dt = new Date();
  let seconds = dt.getSeconds();
  let minutes = dt.getMinutes();
  let hours = dt.getHours();  
  let year = dt.getFullYear();
  let month = dt.getMonth();
      month = month + 1;
  let day = dt.getDate();
   
     if(seconds < 10){
        seconds = '0'+seconds;
     }

     if(month < 10){
        month = '0'+month;
     }
     if(day < 10){
        day = '0'+day ;
     }
     if(minutes < 10){
        minutes = '0'+minutes ;
     }
     if(hours < 10){
        hours = '0'+hours ;
     }

 
        let headers = seconds +''+ minutes +''+hours+''+ day +''+ month +''+ year +''+username;
        let headerBody = parseInt(appUser) + parseInt(seconds);
        let body = headers+''+headerBody;        
   
        console.log(selectedInstitution);


    let institutionPaymentDetailsBag = { 
                organisationCategory:selectedInstitution, organisation:selectedInstitutionName, reason:reason, organisationName:companyName, paymentReason:reason, amount:amount, reference:reference, userKey:body
              
             };
      

     setInstitutionDetails(institutionPaymentDetailsBag);
     goToTransactionConfirmation(institutionPaymentDetailsBag);
   
}

const goToTransactionConfirmation = (institutionPaymentDetailsBag) => history.push({ 
      
        pathname: '/confirmgivetransaction',
        institutionState: institutionPaymentDetailsBag

});


  return (
    
      <main className = {classes.content}>
            <div className={classes.toolbar}/>
   
        <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                       
                       {/*
                          <Grid item xs={false} sm={4}/>
                          <Grid item xs={12} sm={4}>
                       */}
                        
                          
                          <Grid item xs={false} sm={2} md={3} lg={4}/>
            
                          <Grid item xs={12} sm={8} md={6} lg={4}>

                            <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                              </Backdrop>

              <CardHeader title="Give Payment Details"  align="center" />
                    <CardContent>
    
                    <Typography variant="h4" gutterBottom>                
                                 <TextField label="Select Organisation Category" variant="outlined"  fullWidth size="small" select
                                  onChange={handleInstitutionChange}
                                  value={selectedInstitution}
                                  > 

                                    {institutionCategorys.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.categoryName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                          <Typography variant="h4" gutterBottom>                
                                 <TextField label="Select School" variant="outlined"  fullWidth size="small" select
                                  onChange={handleInstitutionNameChange}
                                  value={selectedInstitutionName}
                                  > 

                                    {institution.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.companyName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                          <Typography variant="h4" gutterBottom>                
                                 <TextField label="Select Payment Reasons" variant="outlined"  fullWidth size="small" select
                                  onChange={handlePaymentReasonChange}
                                  value={reason}
                                  > 

                                    {offeringReason.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.offeringName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>
                                                   
                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Amount" variant="outlined" fullWidth size="small"
                                      placeholder="Amount"  
                                        type="text"
                                       id="Amount"
                                       name="Amount"
                                       onChange={(e)=>setAmount(e.target.value)}
                                                                           
                                      />
                          </Typography>

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Reference" variant="outlined" fullWidth size="small"
                                      placeholder="Reference"  
                                        type="text"
                                       id="Reference"
                                       name="Reference"
                                       onChange={(e)=>setReference(e.target.value)}
                                                                           
                                      />
                          </Typography>            


                <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{ margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> submitLoan()} > Pay
                  </Button>
                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>

  </main>

  );
}

export default GiveDetails;