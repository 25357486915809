import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    color: "#155054",
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  loginButton: {
    backgroundColor: "#155054",
    color: "#155054",
    textTransform: "none",
  },
  disabledButton: {
    backgroundColor: theme.palette.primary || "red",
  },
  button: {
    backgroundColor: "#245054",
    color: "#fff",
    textTransform: "none",
  },
  card: {
    border: "2px solid red",
  },
  eventCard: {
    padding: "0",
    borderRadius: "8px",
    overflow: "hidden",
  },
  ventCard: {
    transition: "0.3s ease all",
  },
  eentCardImg: {
    margin: "0",
    width: "300px",
    height: "224px",
    objectFit: "cover",
    display: "block",
  },
  spinner: {
    align: "center",
    justify: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  advertCard: {
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  advertImage: {
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    width: "100%",
    bordercollapse: "separate",
    borderSpacing: "5px",
    borderCollapse: "separate",
    padding: "0px",
    flexWrap: "nowrap",
  },
  tablebody: {
    borderCollapse: "separate",
    borderSpacing: "6px",
    flexWrap: "nowrap",
  },
  tblrow: {
    borderCollapse: "separate",
    borderSpacing: "6px",
    height: "90px",
    width: "100%",
    flexWrap: "nowrap",
  },
  tbldata: {
    borderCollapse: "separate",
    borderSpacing: "6px",
    padding: "3px",
    border: "solid 2px",
    borderColor: "#155054",
    cellPadding: "13px",
    height: "35%",
    width: "35%",
    backgroundColor: "#e2e4df",
    flexWrap: "nowrap",
  },

  /*iconButton:{

          color:'white',
          marginTop:'10%',
          zoom: '80%',    
       //   backgroundColor:'white',
          marginLeft:'0px',

    },
    */
  listItemIcon: {
    color: "#155054",
    zoom: "200%",
  },
  listItemText: {
    fontSize: "10px",
  },
  listImage: {
    margin: "0px",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  listItemContainer: {
    width: "0% !important",
    height: "50% !important",
    marginLeft: "0%",
    fontSize: "1em",
  },
}));
