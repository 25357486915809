import React, { useState } from "react";
import data from "../components/Login/TestData";

export const CartContext = React.createContext();

let allCategories = ["All", ...new Set(data.map((item) => item.categoryName))];

export const CartProvider = (props) => {
  const [cart, setCart] = useState([]);
  const [cartItem, setCartItem] = useState([]);
  const [tongleCartIcon, setTongleCartIcon] = useState(false);
  const [loginDetails, setLoginDetails] = useState({});
  const [savingsAmount, setSavingsAmount] = useState(0.0);
  const [interestAmount, setInterestAmount] = useState(0.0);
  const [accountsType, setAccountsType] = useState("");
  const [isActiveBusiness, setIsActiveBusiness] = useState("");
  const [legalBusinessMemberAccount, setLegalBusinessMemberAccount] = useState(
    {}
  );
  const [p2pFundingDetails, setP2pFundingDetails] = useState({});
  const [userFeatureStatuses, setUserFeatureStatuses] = useState({});
  const [userRoleStatus, setUserRoleStatus] = useState("");
  const [ePaynetServiceAccount, setEpaynetServiceAccount] = useState({});
  const [deviceSize, setDeviceSize] = useState({});

  const [selectedItemCategoryID, setSelectedItemCategoryID] = useState(0);
  const [userLastInteraction, setUserLastInteraction] = useState("");

  const myBase = "https://www.epaynetsz.com/bridge00001/";
  let totalSavingsAmount = 0.0;
  let totalInterestAmount = 0.0;
  let totalCartBalance = 0.0;
  let p2pFunding = loginDetails.isCrowdFundingParticipant;
  let ePayNetBusiness = loginDetails.isLegalBusiness;
  let onlineParticipant = loginDetails.isEcommerceParticipant;

  const hashData = function (givenData) {
    var res = eval(
      process.env.REACT_APP_INTER_ALGO.split("").reverse().join("")
    );

    return res;
  };

  return (
    <CartContext.Provider
      value={{
        epaynetServiceAccountBag: [
          ePaynetServiceAccount,
          setEpaynetServiceAccount,
        ],
        legalBusinessMemberAccountsBag: [
          legalBusinessMemberAccount,
          setLegalBusinessMemberAccount,
        ],
        userRoleStatusesBag: [userRoleStatus, setUserRoleStatus],
        cartBag: [cart, setCart],
        deviceSizeBag: [deviceSize, setDeviceSize],
        selectedItemCategoryBag: [
          selectedItemCategoryID,
          setSelectedItemCategoryID,
        ],
        userLastInteractionBag: [userLastInteraction, setUserLastInteraction],
        usersFeaturesBag: [userFeatureStatuses, setUserFeatureStatuses],
        p2pFundingDetailsBag: [p2pFundingDetails, setP2pFundingDetails],
        tongleCartIconBag: [tongleCartIcon, setTongleCartIcon],
        cartItemBag: [cartItem, setCartItem],
        isActiveBusinessBag: [isActiveBusiness, setIsActiveBusiness],
        accountsTypeBag: [accountsType, setAccountsType],
        loginDetailsBag: [loginDetails, setLoginDetails],
        savingAmountsBag: [savingsAmount, setSavingsAmount],
        interestAmountsBag: [interestAmount, setInterestAmount],
        totalSavingsBalanceBag: totalSavingsAmount,
        totalInterestBalanceBag: totalInterestAmount,
        cartTotalBalanceBag: totalCartBalance,
        baseURL: myBase,
        dataReceiver: hashData,
      }}>
      {props.children}
    </CartContext.Provider>
  );
};
