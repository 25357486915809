import React, { useContext, useState, useEffect } from "react";
import { History, Menu, PowerSettingsNew } from "@mui/icons-material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
  AppBar,
  Card,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Avatar,
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import useStyles from "./styles";
import { CartContext } from "../../Context/CartContext";
import { Link, useLocation, useHistory } from "react-router-dom";
import Drawer from "../../components/Drawer/Drawer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import CachedIcon from "@mui/icons-material/Cached";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddchartIcon from "@mui/icons-material/Addchart";
import LoginIcon from "@mui/icons-material/Login";
import axios from "axios";
import { Money, Person } from "@material-ui/icons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function goToTop() {
  window.scrollTo(0, 0);
}

const NavBar = () => {
  const classes = useStyles();
  const usehistory = useHistory();
  const location = useLocation();
  const {
    baseURL,
    dataReceiver,
    cartBag,
    savingAmountsBag,
    interestAmountsBag,
    totalSavingsBalanceBag,
    totalInterestBalanceBag,
    legalBusinessMemberAccountsBag,
    p2pFundingDetailsBag,
    loginDetailsBag,
    tongleCartIconBag,
    epaynetServiceAccountBag,
    deviceSizeBag,
  } = useContext(CartContext);

  const [ePaynetServiceAccount, setEpaynetServiceAccount] =
    epaynetServiceAccountBag;
  const [tongleCartIcon, setTongleCartIcon] = tongleCartIconBag;
  const [legalBusinessMemberAccount, setLegalBusinessMemberAccount] =
    legalBusinessMemberAccountsBag;
  const [p2pFundingDetails, setP2pFundingDetails] = p2pFundingDetailsBag;
  const [savingsAmount, setSavingsAmount] = savingAmountsBag;
  const [interestAmount, setInterestAmount] = interestAmountsBag;
  const [cart, setCart] = cartBag;
  const [deviceSize, setDeviceSize] = deviceSizeBag;
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const dataDec = dataReceiver;
  const [open, setOpen] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  let screenWidth = windowDimensions.width;
  const [deviceDimentionW, setDeviceDimentionW] = useState("");

  const [iopen, setiOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [mobileDevice, setMobileDevice] = useState(false);
  const fullScreen = "";

  let systemUsersRole = "";

  let businessActive = Object.keys(legalBusinessMemberAccount).length;

  if (businessActive <= 0) {
    systemUsersRole = "0";
  } else if (businessActive > 0) {
    systemUsersRole = "1";
  }

  const handleDrawer = () => {
    setOpen(true);
  };

  const eventLogout = () => {
    setLoginDetails({});
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  }

  useEffect(() => {
    setDeviceSize(screenWidth);

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    goToTop();
    setDeviceDimentionW(windowDimensions.width);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    setDeviceSize(screenWidth);
    setWindowDimensions(getWindowDimensions());
  }

  const singOnUsingDemo = () => {
    if (ePaynetServiceAccount.balance) {
      getServiceAccountUser();
      handleClose();
    } else {
      alert("Please check your internet connection");
      handleClose();
    }
  };

  const getServiceAccountUser = async () => {
    const res = axios
      .post(baseURL + "/ePayNetAccount/bridgeLogin.php")
      .then(function (response) {
        response.data = dataDec(response.data);
        let epaynetServiceAccountResponse = response.data;
        setEpaynetServiceAccount(epaynetServiceAccountResponse);

        alert(epaynetServiceAccountResponse.balance);
      });
  };

  const signOutAppAccount = () => {
    setEpaynetServiceAccount({});
  };

  const openRegisterAccount = () => {
    goToRegister();
    handleClose();
  };

  const goToRegister = () =>
    usehistory.push({
      pathname: "/createaccount",
    });

  const openMyBids = () =>
    usehistory.push({
      pathname: "/businesscommitments",
    });

  const goToProfile = () => {
    usehistory.push({
      pathname: "/userProfile",
    });
  };

  const openFaq = async (moduleID) => {
    let data = { moduleID: moduleID };

    const res = await axios
      .post(baseURL + "bridgeGetModuleWebsiteUrl.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);

        if (response.data[0].code === "201") {
          alert(response.data.description);
        } else {
          let url = response.data[0].moduleUrl;

          if (url) {
            window.open(url, "_blank");
            setOpen(false);
          } else {
            alert(response.data.description);
          }
        }
      });
  };

  const previousPage = () => {
    window.history.back();
  };

  const handleClickOpen = () => {
    setiOpen(true);
  };

  const handleClose = (value) => {
    setiOpen(false);
    setSelectedValue(value);
  };

  const goToServiceAccount = () =>
    usehistory.push({
      pathname: "",
    });

  const openRechargeAccount = () => {
    goToRechargeAccount();
    handleClose();
  };

  const goToRechargeAccount = () =>
    usehistory.push({
      pathname: "/rechargeservices",
    });

  const logOutNav = () =>
    usehistory.push({
      pathname: "/",
    });

  const logOut = () => {
    setLoginDetails({});
    logOutNav();
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        {location.pathname === "/Dashboard" || location.pathname === "/" ? (
          <>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleDrawer}>
              <Menu className={classes.menuIcon} sx={{ fontSize: "35px" }} />
            </IconButton>
            
            <Typography className={classes.logo} sx={{ flexGrow: 1 }}>
              <Link
                to="/Dashboard"
                style={{ textDecoration: "none", color: "#F4CC2F" }}>
                ePayNet
              </Link>
            </Typography>
          </>
        ) : (
          <>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu">
              <ArrowBackIcon
                style={{ color: "#f6f6f6", padding: "0" }}
                onClick={() => previousPage()}
              />
            </IconButton>
            <Typography className={classes.logo} sx={{ flexGrow: 1 }}>
              <Link
                to="/Dashboard"
                style={{ textDecoration: "none", color: " #F4CC2F" }}>
                ePayNet
              </Link>
            </Typography>
          </>
        )}

        <Drawer setOpen={setOpen} open={open} handleDrawer={handleDrawer} />

        <Box style={{ flexGrow: 1 }} />
        <Box>
          <Dialog onClose={handleClose} open={iopen}>
            <Card>
              {ePaynetServiceAccount.userID !== "" ? (
                <>
                  <span onClick={() => singOnUsingDemo()}>
                    <ListItem button>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <h4
                        align="center"
                        style={{
                          fontSize: "18px",
                          paddingRight: "14px",
                          marginTop: "8px",
                        }}>
                        ePayNet Demo Account
                      </h4>

                      <ArrowForwardIosIcon />
                    </ListItem>

                    <p
                      align="center"
                      onClick={() => singOnUsingDemo()}
                      type="button">
                      demoaccount@epaynetsz.com
                      <br />
                      <p style={{ fontSize: "10px" }}>
                        Sign-On using 'demo' account
                      </p>
                    </p>
                  </span>
                </>
              ) : (
                <>
                  <ListItem button>
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <h4 align="center">{ePaynetServiceAccount.fullName}</h4>
                  </ListItem>
                  <p align="center">{ePaynetServiceAccount.username}</p>
                </>
              )}

              <hr />

              <p align="right" justify="right" style={{ paddingLeft: "14px" }}>
                Points:
                {ePaynetServiceAccount ? (
                  <>
                    <b> 0 </b>
                  </>
                ) : (
                  <b>{ePaynetServiceAccount.balance}</b>
                )}
              </p>

              <List sx={{ pt: 0 }}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <LoginIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Sign-In"
                    onClick={() => openRegisterAccount()}
                  />
                </ListItem>
              </List>

              {!ePaynetServiceAccount ? (
                <List sx={{ pt: 0 }}>
                  <ListItem button>
                    <ListItemAvatar>
                      <Avatar>
                        <PowerSettingsNewIcon className={classes.icon} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Sign-Out"
                      onClick={() => signOutAppAccount()}
                    />
                  </ListItem>
                </List>
              ) : (
                <></>
              )}

              <List sx={{ pt: 0 }}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <AddchartIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Buy Points"
                    onClick={() => openRechargeAccount()}
                  />
                </ListItem>
              </List>
            </Card>
            <div align="center">
              <span
                type="button"
                style={{ fontSize: "15px" }}
                component={Link}
                to="/">
                Terms of Service
              </span>
            </div>
          </Dialog>

          {location.pathname === "/businessaccountactivity" && (
            <>
              {systemUsersRole === "1" ? (
                <IconButton component={Link} to="/businessauthentication">
                  <div
                    style={{
                      margin: "0 10px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Link className={classes.help}>Switch User</Link>
                    <CachedIcon className={classes.icon_help} />
                  </div>
                </IconButton>
              ) : (
                <></>
              )}
            </>
          )}

          {location.pathname === "/homepage" && (
            <>
              {ePaynetServiceAccount ? (
                <IconButton color="inherit" edge="start">
                  <div
                    style={{
                      margin: "0 10px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Link className={classes.help}>
                      E: {ePaynetServiceAccount.balance}
                    </Link>
                    <Money className={classes.icon_help} />
                  </div>
                </IconButton>
              ) : (
                <></>
              )}
            </>
          )}

          {location.pathname === "/businessauthentication" && (
            <>
              <IconButton aria-label="Home" onClick={() => openFaq(1)}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Help</Link>
                  <QuestionMarkIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/activateepaynetbusiness" && (
            <>
              <IconButton aria-label="Home" onClick={() => openFaq(1)}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Help</Link>
                  <QuestionMarkIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/paytocompany" && (
            <>
              <IconButton aria-label="Home" onClick={() => openFaq(8)}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Help</Link>
                  <QuestionMarkIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/invoices" && (
            <>
              <IconButton aria-label="Home" onClick={() => openFaq(8)}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Help</Link>
                  <QuestionMarkIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/esacco" && (
            <>
              <IconButton aria-label="Home" onClick={() => openFaq(6)}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Help</Link>
                  <QuestionMarkIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/insurance" && (
            <>
              <IconButton aria-label="Home" onClick={() => openFaq(9)}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Help</Link>
                  <QuestionMarkIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/businessinvestment" && (
            <>
              <IconButton aria-label="Home" onClick={() => openFaq(3)}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Help</Link>
                  <QuestionMarkIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/services" && (
            <>
              <IconButton component={Link} to="/servicehistory">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>History</Link>
                  <AccessTimeIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/requests" && (
            <>
              <IconButton aria-label="Home" onClick={() => openMyBids()}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>My Bids</Link>
                  <AddBusinessIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/requestfunding" && (
            <>
              <IconButton aria-label="Home" onClick={() => openMyBids()}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <b className={classes.help}>
                    Units Balance: E{" "}
                    {p2pFundingDetails.crowdFundingUnitsBalance}
                  </b>
                  <Money className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/recharge" && (
            <div className={classes.button}>
              <>
                <IconButton aria-label="Home" onClick={() => openMyBids()}>
                  <div
                    style={{
                      margin: "0 10px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Link className={classes.help}>
                      Units Balance: E
                      {p2pFundingDetails.crowdFundingUnitsBalance}
                    </Link>
                    <Money className={classes.icon_help} />
                  </div>
                </IconButton>
              </>
            </div>
          )}

          {location.pathname === "/rollback" && (
            <>
              <IconButton aria-label="Home" onClick={() => openFaq(1)}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Cashed Out History</Link>
                  <AccessTimeIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/pocketrecharge" && (
            <>
              <IconButton aria-label="Home" component={Link} to="/checkbalance">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Check Balance</Link>
                  <DataUsageIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/eventsdashboard" && (
            <>
              {tongleCartIcon ? (
                <>
                  <IconButton aria-label="Home" component={Link} to="/resetpin">
                    <div
                      style={{
                        margin: "0 10px",
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <Link className={classes.help}>Reset PIN</Link>
                      <EnhancedEncryptionIcon className={classes.icon_help} />
                    </div>
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    aria-label="Home"
                    component={Link}
                    to="/checkbalance">
                    <div
                      style={{
                        margin: "0 10px",
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <Link className={classes.help}>Check Balance</Link>
                      <DataUsageIcon className={classes.icon_help} />
                    </div>
                  </IconButton>
                </>
              )}
            </>
          )}

          {location.pathname === "/eventhome" && (
            <>
              <IconButton aria-label="Home">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link to="/checkbalance" className={classes.help}>
                    Check Balance
                  </Link>
                  <DataUsageIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/homepage" && (
            <>
              <IconButton aria-label="Home" component={Link} to="/">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Login</Link>
                  <LoginIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/createaccount" && (
            <>
              <IconButton aria-label="Home" component={Link} to="/">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Login</Link>
                  <LoginIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/guesteventhome" && (
            <>
              {/* <IconButton aria-label="Home" component={Link} to="/">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Link className={classes.help}>Login</Link>
                  <LoginIcon className={classes.icon_help} />
                </div>
              </IconButton> */}
            </>
          )}

          {location.pathname === "/saving" && (
            <>
              {screenWidth < "960" ? (
                <div className={classes.button}>
                  <>
                    <IconButton
                      aria-label="Home"
                      component={Link}
                      to="/savingshistory">
                      <div
                        style={{
                          margin: "0 10px",
                          display: "flex",
                          alignItems: "center",
                        }}>
                        <Link className={classes.help}>Savings History</Link>
                        <History className={classes.icon_help} />
                      </div>
                    </IconButton>
                  </>
                </div>
              ) : (
                <></>
              )}
            </>
          )}

          {location.pathname === "/schooldetails" && (
            <>
              <IconButton aria-label="Home" component={Link} to="/addrecipient">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Add New</Link>
                  <AddIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/givedetails" && (
            <>
              <IconButton aria-label="Home" component={Link} to="/addrecipient">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Add New</Link>
                  <AddIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/utilitydetails" && (
            <>
              <IconButton aria-label="Home" component={Link} to="/addrecipient">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Add New</Link>
                  <AddIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/debts" && (
            <>
              <IconButton
                aria-label="Home"
                component={Link}
                to="/addinvoicecredits">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Add Credit</Link>
                  <AddIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/invoicestatus" && (
            <>
              <IconButton
                aria-label="Home"
                component={Link}
                to="/addinvoicecredits">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Add Credit</Link>
                  <AddIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/invoicing" && (
            <>
              <IconButton
                aria-label="Home"
                component={Link}
                to="/addinvoicecredits">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Add Credit</Link>
                  <AddIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {/*CONDITION FOR CHANGING ICON (show history), Screen size will also determine display */}

          {location.pathname === "/savingsstatement" && (
            <>
              <IconButton
                aria-label="Home"
                component={Link}
                to="/notifications">
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>
                    Savings: E {savingsAmount}
                  </Link>
                  <Money className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          )}

          {location.pathname === "/ePayNetWeb" ? <></> : <></>}

          {(location.pathname === "/" && loginDetails) ||
          location.pathname === "/ePayNetWeb" ||
          location.pathname === "/eventhome" ||
          location.pathname === "/homepage" ||
          location.pathname === "/PasswordReset" ||
          location.pathname === "/Registration" ? (
            <>
              <IconButton aria-label="Home" onClick={() => openFaq(1)}>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Link className={classes.help}>Help</Link>
                  <QuestionMarkIcon className={classes.icon_help} />
                </div>
              </IconButton>
            </>
          ) : (
            <>
              <IconButton>
                <Person
                  className={classes.profile}
                  onClick={() => goToProfile()}
                />
              </IconButton>
              <IconButton>
                <div className={classes.logout} onClick={() => logOut()}>
                  <small>Logout</small>
                  <span>
                    <PowerSettingsNew className={classes.icon} />
                  </span>
                </div>
              </IconButton>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
