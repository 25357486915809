import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import useStyles from "../Login/styles";
import Loader from "../Loader/Loader";

// import styles & iconography
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

// import Axios client
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const Activations = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const dataDec = dataReceiver;

  const [lenderCompanies, setLenderCompanies] = useState([]);

  // capture input fields
  const [lenderCompaniesID, setLenderCompaniesID] = useState();
  const [lenderCode, setLenderCode] = useState("");
  const [idNumber, setIdNumber] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  let activationStatus = {};

  // user account details
  let fetchedBorrowerLenders = {};
  let momoAccountNumber = loginDetails.momoAccountNumber;
  let companiesID = loginDetails.organisations_id;
  let userFullName = loginDetails.fullName;

  console.log(loginDetails);

  const gotToDashboard = () =>
    history.push({
      pathname: "/iLoan",
      activationState: activationStatus,
    });

  const checkObjType = history.location.loginState;

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  const activateLenderMember = async () => {
    setOpen(!open);
    setShowDetails(!showDetails)

    let payload = {
      lenderCompanies_id: lenderCompaniesID,
      lendersCode: lenderCode,
      idNumber: idNumber,
      companies_id: companiesID,
    };

	console.log(payload)

    const { data } = await axios.post(
      baseURL + "/bridge_hub_activateLenderMember.php",
      JSON.stringify(payload)
    );

    const response = dataDec(data);

    activationStatus = response.message;

    let responseStatus = response.status;

    if (responseStatus === "200") {
      gotToDashboard();
      setOpen(false);
    } else {
      setErrorMessage(response.message);
      setOpen(false);
    }
  };

  useEffect(() => {
    const getLenderID = async () => {
      setOpen(!open);
      try {
        const { data } = await axios.get(
          baseURL + "bridge_hub_getLenderCompanies.php"
        );

        const response = dataDec(data);
        setLenderCompanies(response);
      } catch (error) {
        console.log(error);
      } finally {
        setOpen(false);
      }
    };

    getLenderID();

    console.log(lenderCompaniesID);
  }, []);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <CardHeader title="Activate Lending Account" align="center" />

                {showDetails ? (
                  <>
                    <Typography variant="body" gutterBottom>
                      User Activating: <strong>{userFullName}</strong>
                    </Typography> <br />
                    <Typography variant="body" gutterBottom>
                      Selected Lender Code: <strong>{lenderCode}</strong>
                    </Typography> <br />
                    <Typography variant="body" gutterBottom>
                      User's ID Number: <strong>{idNumber}</strong>
                    </Typography> <br />
                    <Typography variant="body" gutterBottom>
                      User's Mobile Number: <strong>{momoAccountNumber}</strong>
                    </Typography> <br />

                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => activateLenderMember()}>
                      Continue to Activate
                      <FontAwesomeIcon
                        style={{ marginLeft: "15px" }}
                        icon={faArrowRight}
                      />
                    </Button>
                    <Link style={{marginTop: '10px', display: 'block'}} align="center" onClick={() => setShowDetails(false)}>
                      <FontAwesomeIcon
                        style={{ marginRight: "15px" }}
                        icon={faArrowLeft}
                      />
                      Go Back
                    </Link>
                  </>
                ) : (
                  <>
                    {errorMessage && (
                      <>
                        <div
                          style={{
                            color: "red",
                            align: "center",
                            marginBottom: "30px",
                          }}
                          align="center">
                          <b>{errorMessage}</b>
                        </div>
                        <br />
                      </>
                    )}

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="Select Lender ID"
                        variant="outlined"
                        fullWidth
                        size="small"
                        select
                        onChange={(e) => setLenderCompaniesID(e.target.value)}
                        value={lenderCompaniesID}>
                        {lenderCompanies.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.companyName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="Lender Code"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Lender Code"
                        type="text"
                        id="lenderCode"
                        name="lenderCode"
                        onChange={(e) => setLenderCode(e.target.value)}
                      />
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                      <TextField
                        label="Customer ID Number"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Customer ID Number"
                        type="text"
                        id="customerNumber"
                        name="customerNumber"
                        onChange={(e) => setIdNumber(e.target.value)}
                      />
                    </Typography>

                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => setShowDetails(true)}>
                      {open ? "Processing..." : "Activate Account"}
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
      <div className={classes.toolbar} />
    </main>
  );
};

export default Activations;
