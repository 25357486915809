import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Button, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import {CartContext} from '../../Context/CartContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';

  
export const Recharge = () => {


      const classes = useStyles();
      const history = useHistory();
      const [open, setOpen] = useState(false); 
      const [ amount, setAmount] = useState('');
      const { baseURL, dataReceiver, loginDetailsBag, p2pFundingDetailsBag}= useContext(CartContext);
      const [ p2pFundingDetails, setP2pFundingDetails] = p2pFundingDetailsBag;
      const [ loginDetails, setLoginDetails] = loginDetailsBag;
      const [ paymentMethods, setPaymentMethods] = useState([]);
      const [ paymentMethod, setPaymentMethod] = useState('');
      const [ reference, setReference] = useState('');
      const [ error, setError] = useState('');
      const baseUrl = baseURL;
      const dataDec = dataReceiver;
      let responseDetailsBag = {};
      


    useEffect(()=> {

      setOpen(!open);
      window.scrollTo(0, 0);
      getPaymentMethod();  

   }, [])


  
  const getPaymentMethod = async()=>{
      
     
       const res = axios.post(baseUrl+'bridge_iFund_GetPaymentMethod.php' ).then(function (response) {
        
          response.data = dataDec(response.data);
          setPaymentMethods(response.data);
           setOpen(false);

        });

 }  



 
const  handleSubmit = () => {

      setOpen(!open);   

   let companiesID = loginDetails.organisations_id;
   let fromAccount = loginDetails.momoAccountNumber;

  
 let data = { amount, 
              reference, 
              ePayNetCompanyID:companiesID, 
              fromAccountNumber:fromAccount, 
              bankID: paymentMethod 
            }
   

     if(amount == ''){

          setError('Amount is required');
          setOpen(false);
          return 0;
     }


    
     
     if(paymentMethod == ''){

          setError('Please select a payment method');
          setOpen(false);
          return 0;
     } 

             const res = axios.post(baseUrl+'bridge_iFund_FundingUnitRequestTransaction.php',data ).then(function (response) {
              
                       response.data = dataDec(response.data);
                       responseDetailsBag = response.data;
                       goToResponse();
                       setOpen(false);
                       updateUnits();
            });
  }

  
     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setPaymentMethod(event.target.value);

   }; 


const updateUnits = async() =>{
        
          
          setOpen(!open);
          let ePayNetID = loginDetails.organisations_id;
          let loggedInUsersID = loginDetails.companyUID;
          let data = { ePayNetCompanyID:ePayNetID, ePayNetLoggedInUserID:loggedInUsersID}
            

       const res = await axios.post(baseURL+'/bridge_iFund_ePayNetCompanyCrowdFundingStatus.php', data ).then(function (response) {

           response.data = dataDec(response.data);
           let eCommerceStatus = response.data[0].isCrowdFundingParticipant;


            let crowdFundingDetails = response.data;
            setP2pFundingDetails(crowdFundingDetails[0]);            
            setOpen(false);

           });
             
}

const goToResponse = () => history.push({ 

      pathname: '/rechargeunitsresponse',
      responseBagState: responseDetailsBag

});   


return (
    <main className = {classes.content}>
       <div className={classes.toolbar}/>
   
    <Grid container justify="right" direction="column">

         <Grid item container spacing={4} justify="center" >
                     
          <Grid item xs={false}  xs={12} sm={12} md ={6} lg={4} >
             <Card>
               <CardContent>
                 
                   <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span ><b> Stage 1. Recharge Account</b></span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                               Enter the amount to recharge your account and pick the payment platform to use for payment.
                               <br/>
                              
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>



                <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span ><b> Stage 2: Request Funding</b></span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                               To request for funding you should have added units and you can now be able to make a request for funding for your business.
                                <b>NB: Please note that you will be charged E100.00 per request.</b> 
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>


                <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span ><b>Stage 3: Review and Approvals </b></span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                                After having made a request the ePayNet Team will have to review your Request and approve it for funding.
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>  
                                </CardContent>
                          </Card>

                        </Grid>
            
                        <Grid item xs={12} sm={12} md ={12} lg={4} >

                      
                          <Card > 
                      
                              <div style={{color:"red", align:"center", paddingTop:'15px'}} align='center'>{error}</div>

                               <CardContent >
                                    
                                  <CardHeader title="ePayNet Recharge"  align="center" />
                                  
                                  
                                      <Typography variant="h4" gutterBottom>
                                      <TextField label="Amount" variant="outlined"  fullWidth size="small"  placeholder="1.00"
                                       id="amount"
                                       name="amount"
                                       value={amount} 
                                       onChange={(e)=>setAmount(e.target.value)}
                                        
                                      />
                                      </Typography>

                                      <Typography variant="h4" gutterBottom>
                                      <TextField label="Reference" variant="outlined"  fullWidth size="small"  placeholder="Reference"
                                       id="reference"
                                       name="reference"
                                       value={reference} 
                                       onChange={(e)=>setReference(e.target.value)}
                                        
                                      />
                                      </Typography>


                              <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Payment Method" variant="outlined" fullWidth size="small" select
                                  onChange={handleChange}
                                  value={paymentMethod}
                                  > 

                                    {paymentMethods.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.bankName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography> 

                                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=>handleSubmit()}>
                                      <span className={classes.btnTxt}>
                                          Submit
                                      </span>    
                                  </Button>

                                  <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                                  </Backdrop>
                                  
                           </CardContent>
                         </Card>

                        </Grid>
          
                    <Grid item xs={false}  sm={4}/>
                 </Grid>

              </Grid>

  </main>
            

      )
}


export default Recharge;