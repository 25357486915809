import React, {useContext, useState} from 'react';
import {Card, CardContent, Grid, CardHeader, TextField, MenuItem, CardActions, Button, Typography} from '@material-ui/core';
import useStyles from '../../Login/styles';
import { CartContext } from '../../../Context/CartContext';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';




const BusinessCommitment = ({ product, key, usersRight}) => {
        



     const classes = useStyles();
     const history = useHistory();
     const [ currentPage, setCurrentPage] = useState(1);
     const [ productsPerPage, setProductsPerPage] = useState(24);
     const [ events, setEvents] = useState([]);
     const [ categories, setCategories] = useState([]);
     const [ bidDetails, setBidDetails] = useState('');
     const [modalIsOpen, setIsOpen] = React.useState(false);
     Modal.setAppElement(document.getElementById('root'));
     let cartItem = {};
     let subtitle;
     
        //Change Page
     const paginate = pageNumber => setCurrentPage(pageNumber);
   //let currentProducts = events.slice(indexOfFirstProduct, indexOfLastProduct);
     let filteredProducts = [];

const goToConfirmBid = () => history.push({
    
    pathname: '/confirmbidtransaction',
    requestState:cartItem 
    

});

   const selectBid = () =>{

     cartItem = product;
     goToConfirmBid()

   }


   const handleCategoryChange = () =>{



   }


const previewRequest = () =>{

    cartItem = {id:product.id, name:product.requestNumber, price:product.amount, date:product.maturityDate }
               goToConfirmBid()       
}


  function openModal(product) {

      setBidDetails(product);
      setIsOpen(true);

  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  
  
  }

  function closeModal() {

    setIsOpen(false);

  }






      const indexOfLastProduct = currentPage * productsPerPage;
      const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
      let currentProducts = events.slice(indexOfFirstProduct, indexOfLastProduct);


    return (

      <>

        <Card >
          <CardContent>


                <div className={classes.cardContent}>

                    <Typography variant="body1" gutterBottom>
                     <b> {product.transactionNumber}</b>
                    </Typography>
                    <hr/>
                      
                    
                    <Typography variant="body1" gutterBottom>
                    <b> Banking Reference: {product.bankingReference}</b>
                    </Typography> 


                    <Typography variant="body1" gutterBottom>
                     Pledged Amount: <b>E{product.pledgeAmount}</b>
                    </Typography>  
                    
        
                    <Typography variant="body1" gutterBottom>
                     Cell Number: <b>{product.fromAccountNumber}</b>
                    </Typography>


                    <Typography variant="body1" gutterBottom>
                     Bid Status: <b>{product.statusName}</b>
                    </Typography>


                    
                    <Typography variant="body1" gutterBottom>
                     Settlement Date: <b>{product.settlementDate}</b>
                    </Typography>

                </div>
            </CardContent>
            <CardActions disableSpacing className={classes.cardActions}>

           
        <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=>openModal(product.id)}>
                    <span className={classes.btnTxt}>View Bid Details </span>
        </Button>                  


            
    </CardActions>            
  </Card>


  <>
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
             
              style={{
                    overlay: {
                      position: 'fixed',
                      top: 30,
                      left: 10,
                      right: 10,
                      bottom: 5,
                      zIndex: 1020,
                      backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                      position: 'absolute',
                      top: '40px',
                      left: '40px',
                      right: '40px',
                      bottom: '40px',
                      zIndex: 1020,
                      overflowY: 'auto',
                      border: '1px solid #ccc',
                      background: '#fff',
                      overflow: 'auto',
                      WebkitOverflowScrolling: 'touch',
                      borderRadius: '4px',
                      outline: 'none',
                      padding: '20px'
                    }
                  }}
            >
        


  <Grid container spacing={2}>

      <Grid item xs={12} >
        <Typography align="right" variant="body1">
          <Button type="button" align="right" onClick={closeModal} style={{color:'white', backgroundColor:'tomato', align:'right'}}>
                X
          </Button>
        </Typography>
        <Typography align="center" variant="h6">
                    <b>{product.transactionNumber} </b>
        </Typography>
          </Grid>
        </Grid>  

       <hr /> 

        <CardContent>
             <Typography variant="body2" gutterBottom>
                 Pledge Amount:<b>E{product.pledgeAmount}</b>
             </Typography><br/>

             <Typography variant="body2" gutterBottom>
                Bid Contact: <b>{product.fromAccountNumber}</b>
             </Typography><br/>

               <Typography variant="body2" gutterBottom>
                Reference: <b>{product.reference}</b>
             </Typography><br/>

             <Typography variant="body2" gutterBottom>
                Last Bid Date: <b>{product.updated_at}</b>
             </Typography><br/>

        </CardContent>

        {/*<Card>

                <CardHeader align="center" title="Bid Progress" />

                   <CardContent>
                    </CardContent> 

        </Card>*/}
  
      </Modal>
   </>
</>  

   )
 }
export default BusinessCommitment;