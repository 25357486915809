import { makeStyles, fade } from "@material-ui/core/styles";

const drawerWidth = 0;

const colors = {
  color_primary: "#245054",
  color_light: "#f4cc2f",
  light_accent: "#d9d9d9",
  text_dark: "#1e1e1e",
  document_white: "#f4f4f4",
};

export default makeStyles((theme) => ({
  appBar: {
    color: colors.document_white,
    backgroundColor: colors.color_primary,
    minHeight: "60px",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolBar: {
    margin: "auto",
    maxWidth: "80%",
    width: "100%",
    padding: "0",
    "@media(min-width: 320px) and (max-width: 374px)": {
      maxWidth: "90%",
      padding: "0 !important",
      "& button": {
        paddingRight: "5px !important",
      },
    },
    "@media(min-width: 375px) and (max-width: 424px)": {
      maxWidth: "90%",
      padding: "0 !important",
      "& button": {
        paddingRight: "5px !important",
      },
    },
    "@media(min-width: 425px) and (max-width: 559px)": {
      maxWidth: "90%",
      padding: "0",
      "& button": {
        paddingRight: "5px !important",
      },
    },
    "@media(min-width: 560px) and (max-width: 767px)": {
      maxWidth: "85%",
    },
    "@media(min-width: 768px) and (max-width: 979px)": {
      maxWidth: "85%",
    },
    "@media(min-width: 980px) and (max-width: 1023px)": {
      maxWidth: "80%",
    },
    "@media(min-width: 1024px) and (max-width: 1119px)": {
      maxWidth: "80%",
    },
    "@media(min-width: 1120px) and (max-width: 1365px)": {
      maxWidth: "80%",
    },
    "@media(min-width: 1366px)": {
      maxWidth: "80%",
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
    margin: "0",
    padding: "0",
    fontSize: "80px !important",
    transition: "all .2s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
    "@media(min-width: 320px) and (max-width: 374px)": {
      fontSize: "60px !important",
    },
    "@media(min-width: 375px) and (max-width: 424px)": {
      fontSize: "65px !important",
    },
    "@media(min-width: 425px) and (max-width: 559px)": {
      fontSize: "70px !important",
    },
    "@media(min-width: 560px) and (max-width: 767px)": {
      fontSize: "70px !important",
    },
    "@media(min-width: 768px) and (max-width: 979px)": {
      fontSize: "75px !important",
    },
    "@media(min-width: 980px) and (max-width: 1023px)": {
      fontSize: "75px !important",
    },
    "@media(min-width: 1024px) and (max-width: 1119px)": {
      fontSize: "80px !important",
    },
    "@media(min-width: 1120px) and (max-width: 1365px)": {
      fontSize: "80px !important",
    },
    "@media(min-width: 1366px)": {
      fontSize: "80px !important",
    },
  },
  logo: {
    color: colors.color_light,
    fontWeight: "500",
    fontSize: "24px",
    cursor: "pointer",
    "@media(min-width: 320px) and (max-width: 374px)": {
      fontSize: "18px",
    },
    "@media(min-width: 375px) and (max-width: 424px)": {
      fontSize: "18px",
    },
    "@media(min-width: 425px) and (max-width: 559px)": {
      fontSize: "20px",
    },
    "@media(min-width: 560px) and (max-width: 767px)": {
      fontSize: "20px",
    },
    "@media(min-width: 768px) and (max-width: 979px)": {
      fontSize: "22px",
    },
    "@media(min-width: 980px) and (max-width: 1023px)": {
      fontSize: "22px",
    },
    "@media(min-width: 1024px) and (max-width: 1119px)": {
      fontSize: "22px",
    },
    "@media(min-width: 1120px) and (max-width: 1365px)": {
      fontSize: "24px",
    },
    "@media(min-width: 1366px)": {
      fontSize: "24px",
    },
  },
  logout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    color: colors.color_light,
    padding: "4px 10px",
    border: "2px solid " + colors.color_light,
    borderRadius: "5px",
    "@media(min-width: 320px) and (max-width: 374px)": {
      gap: "3px",
      padding: "2px 5px",
      borderRadius: "5px",
      "& span": {
        display: "none",
      },
      "& small": {
        fontSize: "13px",
      },
    },
    "@media(min-width: 375px) and (max-width: 424px)": {
      gap: "3px",
      padding: "2px 5px",
      borderRadius: "5px",
      "& span": {
        display: "none",
      },
      "& small": {
        fontSize: "13px",
      },
    },
    "@media(min-width: 425px) and (max-width: 559px)": {
      gap: "5px",
      padding: "2px 5px",
      borderRadius: "5px",
      "& span": {
        display: "none",
      },
      "& small": {
        fontSize: "13px",
      },
    },
    "@media(min-width: 560px) and (max-width: 767px)": {
      gap: "5px",
      padding: "2px 5px",
    },
    "@media(min-width: 768px) and (max-width: 979px)": {
      gap: "7px",
      padding: "2px 7px",
    },
    "@media(min-width: 980px) and (max-width: 1023px)": {
      gap: "7px",
      padding: "2px 7px",
    },
    "@media(min-width: 1024px) and (max-width: 1119px)": {
      gap: "10px",
      padding: "4px 10px",
    },
    "@media(min-width: 1120px) and (max-width: 1365px)": {
      gap: "10px",
      padding: "4px 10px",
    },
    "@media(min-width: 1366px)": {
      gap: "10px",
      padding: "4px 10px",
    },
  },
  profile: {
    color: colors.color_light,
    padding: "7px",
    border: "2px solid " + colors.color_light,
    borderRadius: "50%",
    transition: "all .3s ease",
    fontSize: "35px !important",
    "@media(min-width: 320px) and (max-width: 374px)": {
      padding: "4px",
      fontSize: "22px !important",
    },
    "@media(min-width: 375px) and (max-width: 424px)": {
      padding: "4px",
      fontSize: "22px !important",
    },
    "@media(min-width: 425px) and (max-width: 559px)": {
      padding: "5px",
      fontSize: "25px !important",
    },
    "@media(min-width: 560px) and (max-width: 767px)": {
      padding: "5px",
      fontSize: "30px !important",
    },
    "@media(min-width: 768px) and (max-width: 979px)": {
      padding: "7px",
      fontSize: "30px !important",
    },
    "@media(min-width: 980px) and (max-width: 1023px)": {
      padding: "7px",
      fontSize: "33px !important",
    },
    "@media(min-width: 1024px) and (max-width: 1119px)": {
      padding: "7px",
      fontSize: "35px !important",
    },
    "@media(min-width: 1120px) and (max-width: 1365px)": {
      padding: "7px",
      fontSize: "35px !important",
    },
    "@media(min-width: 1366px)": {
      padding: "7px",
      fontSize: "35px !important",
    },
  },
  help: {
    color: colors.document_white,
    fontSize: "16px",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
      color: colors.document_white,
    },
    "@media(min-width: 320px) and (max-width: 374px)": {
      fontSize: "10px",
    },
    "@media(min-width: 375px) and (max-width: 424px)": {
      fontSize: "10px",
    },
    "@media(min-width: 425px) and (max-width: 559px)": {
      fontSize: "14px",
    },
    "@media(min-width: 560px) and (max-width: 767px)": {
      fontSize: "14px",
    },
    "@media(min-width: 768px) and (max-width: 979px)": {
      fontSize: "14px",
    },
    "@media(min-width: 980px) and (max-width: 1023px)": {
      fontSize: "16px",
    },
    "@media(min-width: 1024px) and (max-width: 1119px)": {
      fontSize: "16px",
    },
    "@media(min-width: 1120px) and (max-width: 1365px)": {
      fontSize: "16px",
    },
    "@media(min-width: 1366px)": {
      fontSize: "16px",
    },
  },
  icon_help: {
    color: colors.document_white,
    padding: "3px",
    border: "1px solid " + colors.document_white,
    borderRadius: "50%",
    transition: "all .3s ease",
    fontSize: "25px",
    marginLeft: "10px",
    "@media(min-width: 320px) and (max-width: 374px)": {
      fontSize: "14px",
      marginLeft: "4px",
    },
    "@media(min-width: 375px) and (max-width: 424px)": {
      fontSize: "16px",
      marginLeft: "4px",
    },
    "@media(min-width: 425px) and (max-width: 559px)": {
      fontSize: "18px",
      marginLeft: "4px",
    },
    "@media(min-width: 560px) and (max-width: 767px)": {
      fontSize: "20px",
      marginLeft: "5px",
    },
    "@media(min-width: 768px) and (max-width: 979px)": {
      fontSize: "20px",
      marginLeft: "5px",
    },
    "@media(min-width: 980px) and (max-width: 1023px)": {
      fontSize: "22px",
      marginLeft: "7px",
    },
    "@media(min-width: 1024px) and (max-width: 1119px)": {
      fontSize: "22px",
      marginLeft: "7px",
    },
    "@media(min-width: 1120px) and (max-width: 1365px)": {
      fontSize: "25px",
      marginLeft: "10px",
    },
    "@media(min-width: 1366px)": {
      fontSize: "25px",
      marginLeft: "10px",
    },
  },
}));
