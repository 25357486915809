import React, { useEffect, useState,useContext } from 'react';
import {Typography, Grid} from '@material-ui/core';
import useStyles from '../Login/styles';	
import { useHistory } from 'react-router-dom';
import TransactionHistory from '../History/TransactionHistory/TransactionHistory';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios'; 
import Loader from '../Loader/Loader';

   
export const Help = () => {
	
 const classes = useStyles(); 
 const history = useHistory(); 
 const [loading, setLoading] = useState(false); 
 const { baseURL, dataReceiver, loginDetailsBag } = useContext(CartContext); 

 // const {  } = useContext(CartContext);
 const [ loginDetails, setLoginDetails ] = loginDetailsBag;
 let appUser = loginDetails.organisations_id;
 let username = loginDetails.username;
 const [ transactionHistory, setTransactionHistory ] = React.useState([]);
 const dataDec  = dataReceiver;   

 let transactionHistoryDetails = {};
 let dt = new Date();
 let seconds = dt.getSeconds();
 let minutes = dt.getMinutes();
 let hours = dt.getHours();  
 let year = dt.getFullYear();
 let month = dt.getMonth();
     month = month + 1;
 let day = dt.getDate();

     if(seconds < 10){
        seconds = '0'+seconds;
     }

     if(month < 10){
        month = '0'+month;
     }
     if(day < 10){
        day = '0'+day ;
     }
     if(minutes < 10){
        minutes = '0'+minutes ;
     }
     if(hours < 10){
        hours = '0'+hours ;
     }

 
  let headers = seconds+''+minutes+''+hours+''+day+''+month+''+year+''+username;
  let headerBody = parseInt(appUser) + parseInt(seconds);
  let token = headers+''+headerBody;
  let data = {token, username };

 useEffect(()=> {
   

       fetchMyHistory();      
     
   }, [])


const fetchMyHistory = async()=>{
   
  setLoading(true);
  const res = await axios.post(baseURL+'bridgeBringMyTransactions.php', data ).then(function (response) {


    response.data = dataDec(response.data);
    
 // response.data = JSON.parse(atob((response.data).slice(0,-2).split("").reverse().join("") + (response.data).slice(-2)));

  
               if(response.data !== ''){
              
                 setTransactionHistory(response.data);      
                 transactionHistoryDetails = response.data; 
                 setLoading(false); 

                }else {

                    alert('Connection error, Please try again later');
                    setLoading(false);
               } 
       
  });
         
}


if(loading){

        return  (

         
           <Loader/>


           )
    }

    

	return (		
   <main  style={{border:'#155054 solid 1px'}}>{/*className = {classes.content}*/}
            <div className={classes.toolbar}/>
            <Typography variant="h6" align="center" gutterBottom>                                               
                ePayNet User Guide
            </Typography>
   
  <Grid container direction="column" justify="center">

                  <Grid item ></Grid> 
   
                     <Grid container justify="center" >     
                    
                    {/*
                       {transactionHistory.map((transaction)=> (
                          <Grid item xs={12} sm={12} md={6} lg={4} key={transaction.id}>  
                              <TransactionHistory transaction={transaction} />
                          </Grid>
                        ))}
                    */}     
                       
                        
                   </Grid>        
                       
                    <Grid item xs={false}  sm={3}/>
           
  </Grid>
</main>

	);
}
export default Help;