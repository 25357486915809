import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { CardHeader, TextField, InputAdornment } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";

export const Lending = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [open, setOpen] = useState(false);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [selectedLender, setSelectedLender] = useState([]);
  const [lenderCategory, setLenderCategory] = React.useState([]);
  const [membershipNumber, setMembershipNumber] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [amount, setAmount] = useState("");
  let selectedLenderCategoryID = "";
  const [borrowerLenders, setBorrowerLenders] = useState([]);
  const [paymentPlatforms, setPaymentPlatforms] = React.useState([]);
  const [selectedPaymentPlatform, setSelectedPaymentPlatform] = React.useState(
    []
  );
  const [borrowerApprovedAmount, setBorrowerApprovedAmount] =
    React.useState(0.0);
  const [myLenderDetails, setMyLenderDetails] = useState("");
  const dataDec = dataReceiver;
  const [error, setError] = useState("");
  let fetchedBorrowerLenders = {};
  let loanStatusResponse = {};
  let borrowerCompanyID = loginDetails.organisations_id;
  let borrowerMoMoAccount = loginDetails.momoAccountNumber;
  let selectedLenderDetails = {};

  useEffect(() => {
    setOpen(!open);

    const fetchCompanyCategories = async () => {
      const res = await axios.get(
        baseURL + "bridgeGetLendersCompanyCategories.php"
      );

      res.data = dataDec(res.data);

      setCompanyCategories(res.data);
      setOpen(false);
    };

    const fetchPaymentPlatform = async () => {
      const res = await axios.get(baseURL + "bridgeGetMobileMoneyVendors.php");

      res.data = dataDec(res.data);
      setPaymentPlatforms(res.data);
      setOpen(false);
    };

    fetchPaymentPlatform();
    fetchCompanyCategories();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLenderCategory(event.target.value);
    selectedLenderCategoryID = event.target.value;
    setOpen(!open);

    const fetchBorrowerLenders = async () => {
      const res = await axios.get(
        baseURL +
          "bridgeBringMyLenders.php?borrowerCompanyID=" +
          borrowerCompanyID +
          "&companyCategoryID=" +
          selectedLenderCategoryID
      );

      res.data = dataDec(res.data);
      setBorrowerLenders(res.data);
      fetchedBorrowerLenders = res.data;
      setOpen(false);
    };

    fetchBorrowerLenders();
  };

  const handlerLenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLender(event.target.value);
    selectedLenderDetails = event.target.value;
    let lenderDetails = borrowerLenders.filter(
      (borrowerLenders) => borrowerLenders.id == selectedLenderDetails
    );

    let myLendersData = lenderDetails[0];

    setBorrowerApprovedAmount(myLendersData.approvedAmount);
    setMyLenderDetails(myLendersData);
  };

  const handlePaymentPlatformChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedPaymentPlatform(event.target.value);
  };

  const goToLendingStatus = () =>
    history.push({
      pathname: "/lendingstatus",
      loanRequestState: loanStatusResponse,
    });

  const checkObjType = history.location.loginState;
  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  /*
const  submitLoan = () => {    
   setOpen(!open);
  //  console.log(myLenderDetails) 
   const submitLoanRequest = async()=>{
      // const res = await axios.get(baseURL+'bridgeReceiveLoanTransaction.php?amount='+amount+'&localEftVendorID='+selectedPaymentPlatform+'&borrowerAccountNumber='+borrowerMoMoAccount+'&companyCategoryID='+lendersCategory+'&borrowerCompanyID='+borrowerCompanyID+'&lenderID='+lendersID);
               res.data = dataDec(res.data);
            if(res.data !== ''){            
               setResponseStatus(res.data)
               loanStatusResponse = responseStatus ;
               loanStatusResponse = res.data;   
               console.log(loanStatusResponse);
               return 0;
               setOpen(false);
  
         }else{
          alert('Error');
          setOpen(false);
       }  
   }
//    submitLoanRequest();
}
*/

  const submitLoan = () => {
    setOpen(!open);
    let lendersID = myLenderDetails.id;
    let lendersCategory = myLenderDetails.companyCategories_id;

    let data = {
      amount: amount,
      localEftVendorID: selectedPaymentPlatform,
      borrowerAccountNumber: borrowerMoMoAccount,
      companyCategoryID: lendersCategory,
      borrowerCompanyID: borrowerCompanyID,
      lenderID: lendersID,
    };

    const res = axios
      .post(baseURL + "bridgeReceiveLoanTransaction.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);
        let errorCodeMessage = response.data.code;

        if (errorCodeMessage == "201") {
          setError(response.data.res);
          setOpen(false);
          return 0;
        }

        if (errorCodeMessage == "200") {
          setResponseStatus(response.data);
          //  loanStatusResponse = responseStatus ;
          loanStatusResponse = response.data;

          goToLendingStatus();
          setOpen(false);
        } else {
          let errorMessage = response.data.response;
          setError(errorMessage);
          setOpen(false);
        }
      });
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <CardHeader title="Borrow / Boleka" align="center" />

                {error ? (
                  <>
                    <div
                      style={{ color: "red", align: "center" }}
                      align="center">
                      <b>{error}</b>
                    </div>
                    <br />
                  </>
                ) : (
                  <>
                    <span gutterBottom style={{ padding: "5px" }}>
                      You are eligible to borrow E {borrowerApprovedAmount}
                    </span>{" "}
                    <br />
                  </>
                )}

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Category"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleChange}
                    value={lenderCategory}>
                    {companyCategories.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.categoryName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Lenders"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handlerLenderChange}
                    value={selectedLender}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}>
                    {borrowerLenders.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.companyName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Membership Number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Membership Number"
                    type="text"
                    id="fullName"
                    name="fullName"
                    onChange={(e) => setMembershipNumber(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Amount"
                    type="text"
                    id="amount"
                    name="amount"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <TextField
                    label="Payment Platform"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handlePaymentPlatformChange}
                    value={selectedPaymentPlatform}>
                    {paymentPlatforms.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.vendorName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => submitLoan()}>
                  {" "}
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default Lending;
