import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Loader from "../Loader/Loader";
import SharedInvoices from "./Categories/SharedInvoices";
import MyInvoices from "./Categories/MyInvoices";
import Backdrop from "@material-ui/core/Backdrop";
import DeleteIcon from "@material-ui/icons/Delete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const SharedDebts = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, dataReceiver } = useContext(CartContext);
  const [open, setOpen] = useState(false);
  const [debtors, setDebtors] = React.useState([]);
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  let appUserID = loginDetails.organisations_id;
  const [invoice, setInvoice] = React.useState({});
  const [paymentMethod, setPaymentMethod] = React.useState({});
  const dataDec = dataReceiver;

  useEffect(() => {
    const fetchUsersDebts = async () => {
      setOpen(!open);
      const res = await axios.get(
        baseURL + "bridgeUserDebtSharedWithMe.php?companyID=" + appUserID
      );

      res.data = dataDec(res.data);

      setDebtors(res.data);
      setOpen(false);
    };

    const fetchPaymentMethod = async () => {
      const response = await axios.get(
        baseURL + "bridgeGetMobileMoneyVendors.php"
      );
      response.data = dataDec(response.data);
      setPaymentMethod(response.data);
      setOpen(false);
    };

    fetchUsersDebts();
  }, []);

  const goToTransactionConfirmation = () =>
    history.push({
      pathname: "/confirmmomotransaction",
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item className={classes.sidebar}></Grid>

        <Grid item container spacing={4}>
          {/*<Grid item xs={12} sm={4} />
              
                  <Grid item xs={12} sm={4}>
                  */}
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} align="center">
                <Typography align="center" variant="body1">
                  Debts
                </Typography>
              </Grid>
            </Grid>

            <Backdrop className={classes.backdrop} open={open}>
              <Loader />
            </Backdrop>

            {debtors.map((invoice) => (
              <MyInvoices invoice={invoice} />
            ))}
          </Grid>
          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default SharedDebts;
