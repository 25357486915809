import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";

import global_styles from "../../index.module.css";
import styles from "../Dashboard/Dashboard.module.css";
import card_styles from "../../components/ServiceCard/ServiceCard.module.css";
import local_styles from "../LocalPayments/LocalPayments.module.css";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faMoneyBills,
  faRightLeft,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";

export const LocalPayments = () => {
  const history = useHistory();
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;

  const openMobileToBank = () =>
    history.push({
      pathname: "/beneficiarybankingdetails",
    });

  const openMomoToMomo = () =>
    history.push({
      pathname: "/beneficiarymomodetails",
    });

  const openBankToMomo = () =>
    history.push({
      pathname: "/beneficiarysbank",
    });

  const openMomoToATM = () =>
    history.push({
      pathname: "/momotoatm",
    });

  return (
    <>
      <div
        className={styles.showcase}
      >
        <div className={styles.username + " " + global_styles.container}>
          <p>Welcome, {loginDetails.fullName}</p>
        </div>

        <div
          className={
            styles.slideshow__container + " " + global_styles.container
          }
        >
          <div className={styles.showcase__slider}>
            <div className={styles.slides}>
              <div className={styles.contents}>
                <h2>Local Transactions</h2>
                <p className={styles.copy}>
                  Supported banks: Standard Bank, FNB & Nedbank
                </p>
                <a
                  target="_blank"
                  className={styles.link}
                  href="http://salvtec.co.sz/support/knowledgebase.php?category=15"
                >
                  Learn More{" "}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className={styles.icon}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={global_styles.container + " " + local_styles.service__cards}
        style={{ margin: "10px auto"}}
      >
        <ServiceCard
          onClick={() => openMomoToATM()}
          title={"Mobile Money to ATM"}
          copy={"Withdraw cash at your nearest FNB ATM"}
          icon={
            <FontAwesomeIcon
              icon={faMoneyBills}
              className={card_styles.card__icon}
            />
          }
        />
        <ServiceCard
          onClick={() => openMobileToBank()}
          title={"Mobile Money to Bank"}
          copy={
            "Transfer funds from your Mobile Money account to your bank account"
          }
          icon={
            <FontAwesomeIcon
              icon={faRightLeft}
              className={card_styles.card__icon}
            />
          }
        />
        <ServiceCard
          onClick={() => openMomoToMomo()}
          title={"Send to Mobile Money"}
          copy={"Send money to another Mobile Money account"}
          icon={
            <FontAwesomeIcon
              icon={faWallet}
              className={card_styles.card__icon}
            />
          }
        />
        <ServiceCard
          onClick={() => openBankToMomo()}
          title={"Bank to Mobile Money"}
          copy={
            "Transfer funds from your bank account to your Mobile Money account"
          }
          icon={
            <FontAwesomeIcon
              icon={faRightLeft}
              className={card_styles.card__icon}
            />
          }
        />
      </div>
    </>
  );
};

export default LocalPayments;
