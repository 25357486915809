import React, { useEffect,useContext,useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';

export const TransferLimit = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { loginDetailsBag, baseURL, dataReceiver }= useContext(CartContext);
       const [loginDetails, setLoginDetails] = loginDetailsBag;
       const [ open, setOpen] = React.useState(false);
       const [transferLimit,setTransferLimit] = React.useState([]);
       const [limitStatus, setLimitStatus] = React.useState([]);
       let accountLimit = loginDetails.accountLimit;
       let availableLimit = loginDetails.available;
       let appUser = loginDetails.organisations_id;
       const dataDec  = dataReceiver;


useEffect(()=> { 
     
   }, [])

 
const UpdateLimit = () => {
      
    setOpen(!open);  
    const updateLimit = async()=>{
          
          const res = await axios.get(baseURL+'bridgeUpdateBalanceLimit.php?logonCompanies_id='+appUser+'&dailyAccountLimit='+transferLimit);
                
          res.data = dataDec(res.data);

          setLimitStatus(res.data);
          setOpen(false);
      }

      updateLimit();

}

const goToTransactionConfirmation = () => history.push({ 
      
        pathname: '/confirmmomotransaction',
        
});


  return (

        <main className = {classes.content}>
            <div className={classes.toolbar}/>
   
             <Grid container direction="column">

               <Grid item></Grid>

                 <Grid item container spacing={4}>
                     {/*<Grid item xs={false} sm={4}/>
            
                      <Grid item xs={12} sm={4}>
                      */}
                       <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
               <Card >
                          <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>
                                  
                  <CardContent>
                  <Typography align="center" variant="body1"><h5>Update Account Limit</h5></Typography>

                          <Typography variant="body2" gutterBottom>                
                                 
                             Your ePayNet account limit: <b> E {accountLimit}</b>
                          </Typography>

                          <Typography variant="body2" gutterBottom>                
                                 
                             Your ePayNet available limit: <b>E {availableLimit}</b>
                          </Typography>  

                          
                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Transfer Limit" variant="outlined" fullWidth size="small"
                                      placeholder="Transfer Limit Amount"  
                                      type="text"
                                      id="transferLImit"
                                      name="transferLImit"
                                      onChange={(e)=>setTransferLimit(e.target.value)}
                                                                          
                                      />
                                      </Typography>


               <Button className={classes.loginButton} type="button" variant="contained" fullWidth  gutterBottom style ={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> UpdateLimit()} > Update Limit</Button>
                                 
                           <br gutterBottom></br><br></br>
                          
                           <Typography variant="body1" gutterBottom>                
                             Transfer Limit Status: <b>{limitStatus.description}. </b>
                          </Typography>
                         
                           </CardContent>
                         </Card>

                        </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>

  </main>


  );
}

export default TransferLimit;