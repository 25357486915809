import React from 'react';
import { CardContent} from '@material-ui/core';
import { Typography } from '@material-ui/core';	

export const ShareContactList = ({contactDetails}) => {

   
	return (
<>
		
     <CardContent>
                                     
                  <Typography variant="h6" gutterBottom>                               
                           User Name: {contactDetails.fullName}
                  </Typography>
                  <Typography variant="h6" gutterBottom>                
                          Cell Number: {contactDetails.username}
                  </Typography>
                          <hr></hr>
      </CardContent>
 </>                         
	);
}



export default ShareContactList;