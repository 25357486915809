import React from "react";
import card_styles from "../ServiceCard/ServiceCard.module.css";

const ServiceCard = ({ title, icon, copy, style, onClick}) => {
  return (
    <div onClick={onClick} className={card_styles.service__card} style={style}>
      <div className={card_styles.card__link}>
        <h2>{title}</h2>
        <p>{copy}</p>
        <>{icon}</>
      </div>
    </div>
  );
};

export default ServiceCard;
