import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CardHeader, TextField, InputAdornment } from "@material-ui/core";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import loans from "../assets/testBanner2New.png";
import thumbs from "../assets/thumbs.png";
import letsMove from "../assets/letsMove.png";
import { FeatureItem } from "./FeatureList/FeatureItem";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import useStyles from "../components/Login/styles";
import data from "./data";
import ChipCategory from "../components/Chip/ChipCategory";
import { CartContext } from "../Context/CartContext";

function goToTop() {
  window.scrollTo(0, 0);
}

let allCategories = ["All", ...new Set(data.map((item) => item.categoryName))];

export const HomePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    baseURL,
    dataReceiver,
    userLastInteractionBag,
    selectedItemCategoryBag,
  } = useContext(CartContext);
  const [selectedItemCategoryID, setSelectedItemCategoryID] =
    userLastInteractionBag;
  const [userLastInteraction, setUserLastInteraction] = selectedItemCategoryBag;
  const [callServer, setCallServer] = useState("");
  const [dataContainer, setDataContainer] = useState(data);
  const [allCategoryItems, setAllCategoryItems] = useState([]);
  const [dataCategories, setDataCategories] = useState(allCategories);
  const [showStatic, setShowStatic] = useState(false);
  const dataDec = dataReceiver;
  let currentState = userLastInteraction;

  useEffect(() => {
    goToTop();
    setAllCategoryItems(allCategories);

    setTimeout(function () {
      updateCategoryItems();
      getDynamicData();
    }, 15999);

    const res = axios
      .post(baseURL + "/bridgeBringOnlineShelfCategories.php")
      .then(function (response) {
        response.data = dataDec(response.data);
        let categoryItemsContainer = response.data;
        let newCategoryList = categoryItemsContainer.map(
          (item) => item.categoryName
        );

        setDataCategories((curr) => [...curr, ...newCategoryList]);
      });
  }, []);

  const filterItems = (categoryName) => {
    if (categoryName === "All") {
      setShowStatic(true);
      setDataContainer(data);
      return 0;
    }

    setShowStatic(!true);

    const myFilteredCategory = data.filter(
      (categoryItem) => categoryItem.categoryName === categoryName
    );

    let filteredCategoryID = myFilteredCategory.map((item) => item.id);
    setDataContainer(myFilteredCategory);
    let categoryID = filteredCategoryID[0];
    let dataItem = { shelfCategoryID: categoryID };

    const res = axios
      .post(baseURL + "/bridgeBringOnlineShelfSoftwares.php", dataItem)
      .then(function (response) {
        response.data = dataDec(response.data);
      });
  };

  const updateCategoryItems = async () => {
    const res = axios
      .post(baseURL + "/bridgeBringOnlineShelfSoftwares.php")
      .then(function (response) {
        response.data = dataDec(response.data);
        let dynamicCategories = response.data;
        setAllCategoryItems((curr) => [...curr, ...dynamicCategories]);
        setDataContainer((curr) => [...curr, ...dynamicCategories]);
      });
  };

  const getDynamicData = async () => {
    const res = axios
      .post(baseURL + "/bridgeBringOnlineShelfSoftwares.php")
      .then(function (response) {
        response.data = dataDec(response.data);
      });
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container justify="center" spacing={1}>
        <Grid item container justify="center" xs={12} sm={12} md={8} lg={8}>
          <TextField
            label="Search Feature"
            variant="outlined"
            fullWidth
            size="small"
            gutterBottom
          ></TextField>
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2}>
        <Grid item container xs={12} sm={12} md={8} lg={8}>
          <Stack
            direction="row"
            spacing={1}
            style={{
              backgroundColor: "snow",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            <ChipCategory
              dataCategories={dataCategories}
              filterItems={filterItems}
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid container direction="column">
        <Grid item container spacing={2}>
          <Grid item xs={false} sm={2} md={3} lg={3} />

          <Grid item xs={12} sm={8} md={6} lg={6}>
            <FeatureItem
              dataContainer={dataContainer}
              showStatic={showStatic}
            />
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={3} />
        </Grid>
      </Grid>
    </main>
  );
};

export default HomePage;
