import React, { useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";

export const UserDebtPayment = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  let appUser = loginDetails.organisations_id;
  const [open, setOpen] = React.useState(false);
  const { baseURL } = useContext(CartContext);
  const [paymentPlatforms, setPaymentPlatforms] = React.useState([]);
  const [selectedPaymentPlatform, setSelectedPaymentPlatform] = React.useState(
    []
  );
  const [amount, setAmount] = React.useState([]);
  const invoiceToPayDetailsBag = history.location.invoiceToPayState;
  let selectedPaymentPlatformsID = "";
  let responseDetailsBag = {};
  const dataDec = dataReceiver;

  useEffect(() => {
    const fetchPaymentPlatform = async () => {
      setOpen(!open);
      const res = await axios.get(baseURL + "bridgeGetMobileMoneyVendors.php");

      res.data = dataDec(res.data);

      setPaymentPlatforms(res.data);
      setOpen(false);
    };

    fetchPaymentPlatform();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentPlatform(event.target.value);
    selectedPaymentPlatformsID = event.target.value;
  };

  const goToPaymentStatus = () =>
    history.push({
      pathname: "/invoicestatus",
      responseState: responseDetailsBag,
    });

  const submitInvoiceTransaction = () => {
    setOpen(!open);

    let data1 = {
      companyID: appUser,
      amount,
      reference: invoiceToPayDetailsBag.reference,
      paymentMethodID: "8",
      userDebtors_id: invoiceToPayDetailsBag.id,
    };

    data1 = JSON.stringify(data1);

    const res = axios
      .post(baseURL + "bridgeUserDebtCashDeposit.php", data1)
      .then(function (response) {
        response.data = dataDec(response.data);

        if (response.data !== "") {
          setOpen(false);
          responseDetailsBag = response.data;
          goToPaymentStatus();
        } else {
          alert("Error, try again");
        }
      });
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={4} />

          <Grid item xs={12} sm={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardHeader title="Pay Invoice" align="center" />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Transaction Number: {invoiceToPayDetailsBag.debtNumber}
                </Typography>

                <Typography variant="h6" gutterBottom>
                  Payee: {invoiceToPayDetailsBag.fullname}
                </Typography>

                <Typography variant="h6" gutterBottom>
                  Invoice Amount: E{invoiceToPayDetailsBag.amount}
                </Typography>

                <Typography variant="h6" gutterBottom>
                  Remaining Balance: E{invoiceToPayDetailsBag.remainingBalance}
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Amount To Pay"
                    type="text"
                    id="amount"
                    name="amount"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Typography>

                {/*<Typography variant="body1" gutterBottom>                
                                 <TextField label="Payment Platform" variant="outlined"  fullWidth    size="small" select
                                  onChange={handleChange}
                                  value={selectedPaymentPlatform}
                                  > 

                                    {paymentPlatforms.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.vendorName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>*/}

                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => submitInvoiceTransaction()}
                >
                  {" "}
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default UserDebtPayment;
