import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";
import axios from "axios";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export const ConfirmRequest = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  let appUserID = loginDetails.organisations_id;
  const [open, setOpen] = useState(false);
  const dataDec = dataReceiver;
  const requestedDetails = history.location.requestState;

  if (
    typeof requestedDetails !== "undefined" &&
    Object.keys(history.location.requestState).length > 0
  ) {
  }

  const submitPayment = () => {
    doneMakingRequest();
  };

  const doneMakingRequest = () =>
    history.push({
      pathname: "/businessinvestment",
    });

  const myRequests = () =>
    history.push({
      pathname: "/requestfunding",
    });

  const goToPaymentStatus = () =>
    history.push({
      pathname: "/esaccotransactionstatus",
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container direction="column">
        <Grid item></Grid>
        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <Typography align="center" variant="body1">
                  <h5>Confirm Transaction</h5>
                </Typography>
                <hr />

                <Typography variant="body1" gutterBottom>
                  Response: <b>{requestedDetails.description}</b>
                </Typography>

                <br />

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => submitPayment()}
                >
                  Done
                </Button>

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => myRequests()}
                >
                  My Requests List
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default ConfirmRequest;
