 import React, { useContext, useState } from 'react';
import {Card,  CardContent, CardActions, Button} from '@material-ui/core';
import useStyles from '../../Login/styles'; 
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { useHistory } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Loader from '../../Loader/Loader';


export const FundingRequest = ({transaction}) => {
          

       const classes = useStyles(); 
       const history = useHistory();         
        
  
  return (
       
               <Accordion className={classes.accordion} width='100%'>
                          <AccordionSummary expandIcon={<ArrowDropDownOutlinedIcon className={classes.accordionIcon} />} >
                             <b>{transaction.saccoName}</b>
                          </AccordionSummary>
                          <hr/>
                          <AccordionDetails >
                           <span style={{ width:'80%', align:'center'}}> 
                            <p >
                                  
                                  Amount: <b>E{transaction.amount}</b>

                                  <br/>

                                  Transaction #: <b>{transaction.transactionNumber}</b>

                                  <br/>

                                   Payment Account : <b>{transaction.fromAccount}</b>

                                  <br/>

                                  Transacting Date: <b>{transaction.created_at}</b>

                                  <br/>

                                  Reference: <b>{transaction.reference}</b>

                                  <br/>

                                  Status: <b>{transaction.transactionStatusName}</b>
 
                                </p>
                              </span> 
                             
                          </AccordionDetails>
               <CardActions disableSpacing className={classes.cardActions}>
                <Button className={classes.loginButton} type="button" fullWidth variant="contained" color="inherit" style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} >
                 
                    Query Transaction
                </Button>
                </CardActions>
                      </Accordion>  
 
  );
}

export default FundingRequest;