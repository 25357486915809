import React, {useState} from 'react';
import { Grid, TextField, Card, Paper, IconButton, CardContent, Typography } from '@material-ui/core';
import loans from '../../assets/img.jpg';
import thumbs from '../../assets/thumbs.png';
import letsMove from '../../assets/letsMove.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from '../../components/Login/styles';
import { useHistory, Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';
import { ArrowRightAltOutlined, ArrowLeftOutlined, CloudDownloadTwoTone } from '@material-ui/icons';



 

export const FeatureItem = ({dataContainer, showStatic}) => {
  
  

  const classes = useStyles(); 
  const history = useHistory();



  const handleSubmit = (webUrl) =>{            

          if(!webUrl){
            alert('Unable to open application.');
            return 0;
           }
  
              window.open(webUrl);
              return 0;
     }





   const openCashlessEvents= () => history.push({ 

      pathname: '/guesteventsguest'

   });

   const goToCashlessEvents = () => {
              
         openCashlessEvents();
              
         // window.open('guesteventhome');  
  
    } 


       
return ( 
     <>
     <>
 	      {/*showStatic === 'false' ? */}
        <Card>
                 {/*ITEMS LIST CARD*/}     
                 <CardContent align="center">
                    <Grid item container spacing={2} to = "/guesteventhome">
                       
                           <Grid item xs={false} sm={2} md={3} lg={2}>
                               <Paper>
                                 <img alt='cashless' src={loans} className={classes.listImage} /> 
                               </Paper>
                           </Grid>   
                             
                                  <Grid item xs={12} sm={8} md={10} lg={8}>
                                    <Typography align="left">
                                     <b> ePayNet Cashless Events </b>
                                        <div className = {classes.listItemText}>  
                                         
                                          <p>
                                              Adopt a cashless event system for a simpler, safe and smarter way to take payments.
                                              Run your own in-venue micro economy with some great advantages that can be passed on to the customer.
                                              Replace inefficient cash handling with an easy, secure and private cashless payments.
                                          </p>

                                        </div>
                                         
                                    </Typography> 
                                    {/*
                                        <Typography align="right">

                                          <div className = {classes.listItemText}>  
                                             
                                             <CloudDownloadTwoTone />

                                          </div>

                                        </Typography>className={classes.listItemContainer}to={goToCashlessEvents}
                                    */} 
                                  </Grid> 

                      <Grid item xs={false} sm={2} md={2} lg={2} button component={ Link } to = "/guesteventhome" >


                             <IconButton button component={ Link } to = "/guesteventhome">
                                   <ArrowForwardIosIcon className={classes.listItemIcon} />
                             </IconButton>  
                      </Grid>
                  </Grid>          

                </CardContent>
              </Card>

            
        </>    
              <br/>

   {dataContainer.map((data, index)=> (
     <> 
 		    <Card >
                  {/*ITEMS LIST CARD*/}     
                 <CardContent align="center">
                        <Grid item container spacing={4} >
                       
                           <Grid item xs={false} sm={2} md={3} lg={2}>
                               <Paper>
                                 <img alt={data.name} src={data.imageUrl} className={classes.listImage} /> 
                                </Paper>
                               
                           </Grid>   
                             
                                  <Grid item xs={12} sm={8} md={10} lg={8}>
                                    <Typography align="left">
                                     <b> {data.name} </b>
                                       
                                        <div className = {classes.listItemText}>  
                                         
                                         	{data.description}

                                        </div>
                                         
                                    </Typography> 
                                    {/*
                                        <Typography align="right">

                                          <div className = {classes.listItemText}>  
                                             
                                             <CloudDownloadTwoTone />

                                          </div>

                                        </Typography>className={classes.listItemContainer}
                                    */}
                                  </Grid> 

          					  <Grid item xs={false} sm={2} md={2} lg={2} button component={ Link } >
          					         <IconButton onClick={()=> handleSubmit(data.webUrl)}>
          					               <ArrowForwardIosIcon className={classes.listItemIcon} />
          					         </IconButton>  
                      </Grid>
                  </Grid>          

                </CardContent>
              </Card><br />

                </> 
          ))}    

              
         </>               
   
               
 )
}

export default FeatureItem;