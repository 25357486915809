import React, {useState,useContext} from 'react';
import { Grid, TextField, Card, CardMedia, CardContent, Button, Typography } from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';
import CallIcon from '@material-ui/icons/Call';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import useStyles from '../../components/Login/styles';
import { useHistory,Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';
import { ArrowRightAltOutlined, ArrowLeftOutlined } from '@material-ui/icons';


export const BannerSlider = ({data}) => {
  
  const [index, setIndex] = useState(0);

return (
  
  <>  
       
      <CardContent align="center"> 
        <img src={data.graphicUrl} alt={data.name} className="slider-img" />
      </CardContent>

  </>    
               
 )
}

export default BannerSlider;