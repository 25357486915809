import React, { useEffect, useContext } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import useStyles from "../Login/styles";
import SharedLoans from "./LoanCategories/SharedLoans";
import MyLoans from "./LoanCategories/MyLoans";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";

export const LoanHistory = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [open, setOpen] = React.useState(false);
  let appUserID = loginDetails.organisations_id;
  const [loanHistory, setLoanHistory] = React.useState([]);
  const [sharedLoanHistory, setSharedLoanHistory] = React.useState([]);
  let loanHistoryDetails = {};
  let sharedLoanHistoryDetails = {};
  const dataDec = dataReceiver;

  useEffect(() => {
    setOpen(!open);

    const fetchMyLoanHistory = async () => {
      const res = await axios.get(
        baseURL +
          "/bridgeBorrowerActiveDebts.php?borrowerCompanyID=" +
          appUserID
      );

      res.data = dataDec(res.data);

      if (res.data !== "") {
        setLoanHistory(res.data);
        loanHistoryDetails = res.data;
        setOpen(false);
      } else {
        alert("Connection error, Please try again later");
        setOpen(false);
      }
    };

    const fetchSharedLoanHistory = async () => {
      const res = await axios.get(
        baseURL +
          "bridgeBringRecipientSharedDebts.php?borrowerCompanyID=" +
          appUserID
      );

      res.data = dataDec(res.data);

      if (res.data !== "") {
        setSharedLoanHistory(res.data);
        sharedLoanHistoryDetails = res.data;
        setOpen(false);
      } else {
        alert("Connection error, Please try again later");
        setOpen(false);
      }
    };

    fetchMyLoanHistory();
    fetchSharedLoanHistory();
  }, []);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Typography variant="h6" align="center" gutterBottom>
        Loan History
      </Typography>

      <Backdrop className={classes.backdrop} open={open}>
        <Loader />
      </Backdrop>

      <Grid container direction="column" justify="center">
        <Grid item></Grid>

        <Grid container justify="center">
          {loanHistory.map((loans) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={"history" + loans.id}>
              <MyLoans loans={loans} />
            </Grid>
          ))}

          <hr style={{ border: "1 black" }} />
          <Button
            className={classes.loginButton}
            type="button"
            variant="contained"
            fullWidth
            color="secondary"
            style={{
              margin: "5px",
              textTransaform: "none",
              backgroundColor: "lightGrey",
            }}
          >
            {" "}
          </Button>

          <Grid container justify="center">
            {sharedLoanHistory.map((loans) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                key={"history" + loans.id}
              >
                <SharedLoans loans={loans} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={false} sm={3} />
      </Grid>
    </main>
  );
};

export default LoanHistory;
