import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";

export const Services = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loginDetailsBag, dataReceiver, baseURL } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [serviceItems, setServiceItems] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [IDNumber, setIDNumber] = useState([]);
  const [amount, setAmount] = useState(0.0);
  const [serviceCharge, setServiceCharge] = useState(0.0);
  const [reference, setReference] = useState([]);
  const [theirReference, setTheirReference] = useState("");
  const [username, setUsername] = useState("");
  const [schoolDetails, setSchoolDetails] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [totalChargeBalance, setTotalChargeBalance] = useState(0.0);
  const [paymentPlatforms, setSelectedPaymentPlatforms] = useState([]);
  const [payFrom, setPayFrom] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [paymentPlatformName, setPaymentPlatformName] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAccountInput, setShowAccountInput] = useState(true);
  const [accountNotification, setAccountNotification] = useState("");

  const dataDec = dataReceiver;
  let responseDetailsBag = {};
  let institutionPaymentDetailsBag = {};
  let appUser = loginDetails.organisations_id;
  let usersname = loginDetails.username;

  useEffect(() => {
    setError("");
    setOpen(!open);
    setResponseMessage("");

    const res = axios
      .post(baseURL + "/bridge_dstvGetDstvVendors.php")
      .then(function (response) {
        response.data = dataDec(response.data);
        setServiceItems(response.data);

        setOpen(open);
      });

    const response = axios
      .post(baseURL + "/bridgeGetMobileMoneyVendors.php")
      .then(function (response) {
        response.data = dataDec(response.data);
        setSelectedPaymentPlatforms(response.data);
        setOpen(open);
      });

    setOpen(open);
  }, []);

  const handleServiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedService(event.target.value);
    let selectedServicesID = event.target.value;
    setOpen(!open);
    let data = { dstvVendorID: selectedServicesID };

    const res = axios
      .post(baseURL + "/bridge_dstv_GetPackages.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);
        setPackages(response.data);
        setOpen(open);
      });

    let companyDetails = serviceItems.filter(
      (schools) => schools.id === selectedServicesID
    );

    let companyName = companyDetails[0].vendorName;

    setServiceName(companyName);
  };

  const handlePackageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPackage(event.target.value);
    let mySelectedaPackagesID = event.target.value;
    let companyDetails = packages.filter(
      (company) => company.id === mySelectedaPackagesID
    );

    let packageAmount = companyDetails[0].amount;
    let serviceAmount = companyDetails[0].ePayNetCharges;
    let companyName = companyDetails[0].packageName;

    packageAmount = parseInt(packageAmount);
    serviceAmount = parseInt(serviceAmount);
    setAmount(packageAmount);
    setServiceCharge(serviceAmount);
    let totalAmount = (packageAmount + serviceAmount).toFixed(2);
    setTotalChargeBalance(totalAmount);
    setCompanyName(companyName);
  };

  const handlePlatformChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPlatform(event.target.value);
    let selectedPlatoformsID = event.target.value;

    let companyDetails = paymentPlatforms.filter(
      (schools) => schools.id === selectedPlatoformsID
    );
    let companyName = companyDetails[0].vendorName;

    if (companyName === "MoMo") {
      setShowAccountInput(false);
      setAccountNotification(
        "Your ePayNet MoMo account will be used to process this transaction, click on submit to continue."
      );
      let accNum = loginDetails.momoAccountNumber;
      setPayFrom(accNum);
    }
  };

  const submitService = () => {
    setOpen(true);
    let companyDetails = packages.filter(
      (schools) => schools.id === selectedPackage
    );
    let serviceAmount = companyDetails[0].ePayNetCharges;

    let serviceCost = parseFloat(amount);
    let maximumAmount = parseFloat(companyDetails[0].maxAmount);
    let packageName = companyDetails[0].packageName;

    if (serviceCost > maximumAmount) {
      setError(
        packageName +
          " can only process E" +
          maximumAmount.toFixed(2) +
          " or less"
      );
      setOpen(false);
      return 0;
    }

    setError("");

    let packageAmount = parseFloat(serviceCost);
    serviceAmount = parseFloat(serviceAmount);
    setAmount(packageAmount);
    setServiceCharge(serviceAmount);
    let totalAmount = (packageAmount + serviceAmount).toFixed(2);
    setTotalChargeBalance(totalAmount);
    setShowConfirm(true);
    setOpen(false);
  };

  const tryAgain = () => {
    setSelectedPackage("");
    setReference("");
    setAmount(0.0);
    setSelectedPlatform("");
    setServiceCharge(0.0);
    setTotalChargeBalance("");
    setResponseMessage("");
    setShowConfirm(!true);
  };

  const donePaying = async () => {
    setOpen(!open);
    let data = {
      amount: amount,
      localEftVendorsID: selectedPlatform,
      dstvNumber: reference,
      fromAccount: payFrom,
      companyID: loginDetails.organisations_id,
      transactionStatus: "",
      dstvPackagesID: selectedPackage,
      fullName: username,
      theirReference: theirReference,
    };

    const res = await axios
      .post(baseURL + "/bridge_dstvTransaction.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);
        setResponseMessage(response.data);
        alert(response.data.res);
        setOpen(false);
      });
  };

  const goToTransactionConfirmation = (institutionPaymentDetailsBag) =>
    history.push({
      pathname: "/confirmschooltransaction",
      institutionState: institutionPaymentDetailsBag,
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Backdrop className={classes.backdrop} open={open}>
            <Loader />
          </Backdrop>

          <Grid item xs={false} sm={2} md={3} lg={4} />

          <Grid item xs={12} sm={8} md={6} lg={4}>
            {!showConfirm ? (
              <Card>
                <CardHeader title="Service Details" align="center" />
                <div style={{ color: "red" }} align="center">
                  <b>{error}</b>
                </div>

                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    <TextField
                      label="Select Service"
                      select
                      variant="outlined"
                      fullWidth
                      size="small"
                      onChange={handleServiceChange}
                      value={selectedService}>
                      {serviceItems.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.vendorName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Typography>

                  <Typography variant="h4" gutterBottom>
                    <TextField
                      label="Select Service Package"
                      select
                      variant="outlined"
                      fullWidth
                      size="small"
                      onChange={handlePackageChange}
                      value={selectedPackage}>
                      {packages.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.packageName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Typography>

                  <Typography variant="h4" gutterBottom>
                    <TextField
                      label="Amount"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="Amount"
                      type="text"
                      id="Amount"
                      name="Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </Typography>

                  <Typography variant="h4" gutterBottom>
                    <TextField
                      label="Service Fee"
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="text"
                      id="serviceFee"
                      name="ServiceFee"
                      value={serviceCharge.toFixed(2)}
                    />
                  </Typography>

                  <Typography variant="h4" gutterBottom>
                    <TextField
                      label="DSTV Number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="Reference"
                      type="text"
                      id="Reference"
                      name="Reference"
                      onChange={(e) => setReference(e.target.value)}
                    />
                  </Typography>

                  <Button
                    className={classes.button}
                    type="button"
                    variant="contained"
                    fullWidth
                    color="primary"
                    style={{
                      margin: "5px",
                      textTransaform: "none",
                      backgroundColor: "#155054",
                      color: "white",
                    }}
                    onClick={() => submitService()}>
                    {" "}
                    Submit
                  </Button>
                </CardContent>
              </Card>
            ) : (
              <>
                <Card>
                  <CardContent>
                    <Typography align="center" variant="body1">
                      {!responseMessage ? (
                        <h5>Confirm Transaction</h5>
                      ) : (
                        <>
                          {" "}
                          <div>
                            <b>Response:</b>
                            <span style={{ color: "#4BB543" }}>
                              {responseMessage.description}
                            </span>
                          </div>
                        </>
                      )}
                    </Typography>
                    <hr />

                    <Typography variant="body1" gutterBottom>
                      Service: <b>{serviceName}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      Package: <b>{companyName}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      DSTV Number: <b>{reference}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      Service Amount: <b>E{amount.toFixed(2)}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      Service Charge: <b>E{serviceCharge.toFixed(2)}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <b> Total Balance: E{totalChargeBalance}</b>
                    </Typography>

                    {!responseMessage ? (
                      <>
                        <Typography variant="h4" gutterBottom>
                          <TextField
                            label="Select Payment Platform"
                            select
                            variant="outlined"
                            fullWidth
                            size="small"
                            onChange={handlePlatformChange}
                            value={selectedPlatform}>
                            {paymentPlatforms.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.vendorName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Typography>

                        {showAccountInput ? (
                          <Typography variant="h4" gutterBottom>
                            <TextField
                              label="Account Number"
                              variant="outlined"
                              fullWidth
                              size="small"
                              placeholder="Account To Pay From"
                              type="text"
                              id="Account"
                              name="Account"
                              onChange={(e) => setPayFrom(e.target.value)}
                            />
                          </Typography>
                        ) : (
                          <Typography variant="body1" gutterBottom>
                            <p>
                              {" "}
                              <strong>{accountNotification}</strong>
                            </p>
                          </Typography>
                        )}

                        <Button
                          className={classes.button}
                          type="button"
                          variant="contained"
                          fullWidth
                          color="primary"
                          onClick={() => donePaying()}
                          style={{
                            margin: "5px",
                            textTransaform: "none",
                            backgroundColor: "#155054",
                            color: "white",
                          }}>
                          Submit
                        </Button>
                      </>
                    ) : (
                      <h6></h6>
                    )}

                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={() => tryAgain()}
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}>
                      Make New Payment
                    </Button>
                  </CardContent>
                </Card>
              </>
            )}
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default Services;
