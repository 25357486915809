import React, { useContext, useState } from "react";
import { Card, CardContent, CardActions, Button } from "@material-ui/core";
import useStyles from "../../Login/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import { useHistory } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Loader from "../../Loader/Loader";

export const FundingRequest = ({ myRequest }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Accordion className={classes.accordion} width="100%">
      <AccordionSummary
        expandIcon={
          <ArrowDropDownOutlinedIcon className={classes.accordionIcon} />
        }
      >
        <b>{myRequest.requestNumber}</b>
      </AccordionSummary>

      <AccordionDetails>
        <span style={{ width: "80%", align: "center" }}>
          <p>
            Clients Name: <b>{myRequest.clientName}</b>
            <br />
            Suppliers Name: <b>{myRequest.supplierName}</b>
            <br />
            Industry: <b>{myRequest.sectorName}</b>
            <br />
            Invoice Amount: <b>E{myRequest.invoiceAmount}</b>
            <br />
            Funding Amount: <b>E{myRequest.fundingAmount}</b>
            <br />
            Net Profit Margin: <b>E{myRequest.grossBidAmount}</b>
            <br />
            Status: <b>{myRequest.requestStatus}</b>
            <br />
            Due Date: {myRequest.created_at}
            <br />
          </p>
        </span>
      </AccordionDetails>
      <CardActions disableSpacing className={classes.cardActions}>
        <Button
          className={classes.loginButton}
          type="button"
          fullWidth
          variant="contained"
          color="inherit"
          style={{
            margin: "5px",
            textTransaform: "none",
            backgroundColor: "#155054",
            color: "white",
          }}
        >
          <AttachFileIcon />
          Attach Invoice Documents
        </Button>
      </CardActions>
    </Accordion>
  );
};

export default FundingRequest;
