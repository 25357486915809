import React, {useContext, useState} from 'react';
import {Card, CardMedia, Dialog, DialogActions, Button, CardContent, CardActions, Typography, IconButton} from '@material-ui/core';
import { AddShoppingCart, Headset as PlayIcon, Person as ArtistIcon, PausePresentation as PauseIcon, ThumbUpTwoTone as LikeIcon, FavoriteBorderTwoTone as HeartIcon, ThumbDownTwoTone as DislikeIcon } from '@material-ui/icons';
import {AddCircle, RemoveCircle} from '@material-ui/icons';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import {CartContext} from '../../../../Context/CartContext';
import banner from '../../../../assets/tesBannerNew.png';
import useStyles from '../../../Login/styles';
import { useHistory } from 'react-router-dom';



 const Event = ({event, loading, index}) => {

       const classes = useStyles();
       const history = useHistory();
       const [ tongleCartIcon, setTongleCartIcon]= useState(false);
       const [ play, setPlay]= useState(false); 
       const [ ticketVoucher, setTicketVoucer] = useState('');
       const [ voucherSum, setVoucherSum] = useState(0);
       const [open, setOpen] = useState(false);
       const { baseURL, dataReceiver, loginDetailsBag, cartBag } = useContext(CartContext); 
       const [cart, setCart] = cartBag; 
       let btnAddToCartHidden = 'F';
       let sum = 0;



     if(loading){
 
        return <h2>Loading..</h2>
 
    }

  const addToCart = (id)=>{
        
         setTongleCartIcon(true);

         sum = sum + 1;

         setVoucherSum(sum)
       

   }

  const removeFromCart = (id)=>{
        
        sum = sum - 1;

        setVoucherSum(sum)
        

  } 

  const removeFromBasket = (id)=>{
  
    setTongleCartIcon(false);

   }

   const showVideo = ()=>{
     
         setPlay(true);
    }

    const hideVideo = ()=>{
          
          setPlay(false);
    }


  const reserveTicket = (id)=>{
        
        setTicketVoucer(event);
        setTicketVoucer(curr =>[...curr, event ]);  

  } 

  const createCartItem = (id, voucherSum)=>{
          
        setCart(curr =>[...curr, event ]);

  }

  const openCheckOut =  () => history.push({
     
     pathname: '/checkout'


});

    return (
        <Card className={classes.eventCard}>
          
                          <img src={event.img}
                              className={classes.advertImage} alt="banner2"/>
                                    
                        <CardMedia className={classes.media} />
            
            <CardContent>
                <div className={classes.cardContent}>
              
                    <Typography variant="body1" gutterBottom>
                      <strong>  {event.id}. {event.name}  </strong>
                    </Typography>
                    
                    <Typography variant="body2" color="textSecondary">
                       {event.addresses}
                    </Typography>

                </div>

          
                     
                    
                {/*<div align="center">
                  <RemoveCircle variant="contained" color="inherit" onClick={removeFromCart} tappable align="left" style= {{margin: '5px', borderRadius:'5px', backgroundColor:'#155054', color:'white'}}/>
                    
                          {voucherSum}
                    
                  <AddCircle variant="contained" tappable color="inherit" onClick={addToCart} align="right" style= {{margin: '5px', borderRadius:'5px', backgroundColor:'#155054', color:'white'}}/>
                </div>
                    
                  
                    <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style= {{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=>createCartItem(event.id, voucherSum)}>
                                  Add To Card 
                    </Button>*/}         
                            
                    <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style= {{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=>createCartItem(event.id, voucherSum)}>
                            Details {/*<AddShoppingCartIcon />*/} 
                    </Button>           


            </CardContent>
                  
                  {/*
                          <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{margin:'3px', textTransaform:'none'}} onClick={()=> reserveTicket(event.id)}>   
                                  Add To Cart
                          </Button>
                  */} 
        </Card>
    )
}

export default Event;
