import React, { useEffect,useContext, useState } from 'react';
import {Card, CardMedia, CardContent,  Typography, Grid, Paper, Button, MenuItem} from '@material-ui/core';
import useStyles from '../../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../../Context/CartContext';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';
import Event from '../Events/Event/Event';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export const BuyTicket = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const [ isActive, setIsActive] = useState(false);
       const [ paymentPlatforms, setPaymentPlatforms] = useState([]);
       const [ paymentPlatform, setPaymentPlatform] = useState('');
       const [ isOnEpaynet, setIsOnEpaynet] = useState(true);
       const [ open, setOpen] = useState(false);
       const [ events, setEvents] = useState([]); 
       const [ fullName, setFullname] = useState('');
       const [ contact, setContact] = useState('');
       const [ cellNumberRef, setCellNumberRef] = useState('');
       const [ event, setEvent] = useState('');                                                   


       useEffect(()=> {
          
          getEvents();
          getPaymentPlatforms();
         
        }, [])
 


  const getEvents = async()=>{
      
       const res = axios.post('https://www.digimagesystem.com/iEventPocket/api/getEvents.php' ).then(function (response) {
        
          
          setEvents(response.data);

        });

 } 


  const getPaymentPlatforms = async()=>{
      
       const res = axios.post('https://www.digimagesystem.com/iEventPocket/api/getEpayNetPaymentPlatforms.php' ).then(function (response) {
        
          setPaymentPlatforms(response.data);

        });

 } 


  const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setPaymentPlatform(event.target.value);
      let platformID = event.target.value

}


const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
      setEvent(event.target.value);
       
  }

 

const createPocket = async()=>{

      
  let data = {fullName:fullName, cellNumber:contact, cellNumberRef:cellNumberRef, eventsID:event, ePayNetCompanyID:'4'}

    const res = axios.post('https://www.digimagesystem.com/iEventPocket/api/createEventAccount.php', data ).then(function (response) {

         // getPocketDetails();
            goToDashboard();
  });
         
}



const getPocketDetails = () =>{
    
                  {/*GET THE CREATED POCKET DETAILS*/}
    let myCreds = {eventsID:event, cellNumberRef:cellNumberRef};

    const res = axios.post('https://www.digimagesystem.com/iEventPocket/api/getEventAccountDetails.php', myCreds ).then(function (response) {
             
    });

}


 const goToDashboard = () => history.push({
    
    pathname: '/eventsdashboard'
    
});



  return (

     <main className = {classes.content}>
       <div className={classes.toolbar}/>
       
    <Grid container direction="column" style= {{margin: '5px'}}>

      {/*<Grid item className={classes.sidebar} xs={false} sm={3} md={3} lg={3}></Grid>*/}

         <Grid item container spacing={1} style={{height: "280px" }}>
             
                  <Grid item xs={12} sm={4} md={4} style={{height: "100%" }}>
                       



                        <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>
                
                                 <Grid container spacing={1}>
                                   <Grid item xs={12} align="center">
                                      <Typography align="center" variant="body1">
                                           Create Pocket
                                      </Typography>
                                   </Grid>
                                 </Grid>
                               <hr /> 
                            
                <CardContent> 

                        <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Event" variant="outlined" fullWidth size="small" select
                                  onChange={handleEventChange}
                                  value={event}
                                  > 

                                    {events.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.eventName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>  

                        <Typography variant="h4" gutterBottom>
                                <TextField label="Full Name" variant="outlined" type="text" fullWidth size="small"  placeholder="Full Name" 
                                      onChange={(e)=>setFullname(e.target.value)}
                                      />
                        </Typography>

                          
                        <Typography variant="h4" gutterBottom>
                                <TextField label="Contact" variant="outlined" type="text" fullWidth size="small"  placeholder="Cell Number" 
                                      onChange={(e)=>setContact(e.target.value)}
                                      />
                        </Typography>


                        <Typography variant="h4" gutterBottom>
                                      <TextField label="Ticket Identifier" variant="outlined" type="text" fullWidth size="small" placeholder="Cell Number Reference" 
                                      onChange={(e)=>setCellNumberRef(e.target.value)}
                                      />
                        </Typography>



                        {/*<Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Payment Platform" variant="outlined" fullWidth size="small" select
                                  onChange={handleBankChange}
                                  value={paymentPlatform}
                                  > 

                                    {paymentPlatforms.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.description}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                      { !isOnEpaynet ?   
                          <Typography variant="h4" gutterBottom>
                                <TextField label="Payment Account" variant="outlined" type="text" fullWidth size="small" placeholder="Payment Account" 
                                      />
                          </Typography>
                         :
                          <>
                          </>
                      */}  
      
                <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=> createPocket()}>
                  Submit
                </Button>

          </CardContent>
    
     </Card>










                      
        
        {/*style={{backgroundColor: isActive ? 'salmon' : '',color: isActive ? 'white' : '',}}

                         */}
                  </Grid>
               
                 </Grid>

              </Grid>

  </main>

  );
}

export default BuyTicket;