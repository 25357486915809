import React, { useEffect, useState, useContext } from 'react';
import {Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../Login/styles';  
import BusinessMember from './BusinessMember/BusinessMember';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';   
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
   
export const BusinessMembers = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext); 
       const [ loginDetails, setLoginDetails ] = loginDetailsBag;
       const [open, setOpen] = React.useState(false);
       let appUserID = loginDetails.organisations_id;
       const [ loanHistory, setLoanHistory ] = React.useState([]);
       const [ businessHistory, setBusinessHistory] = useState([]);
       const [ sharedLoanHistory, setSharedLoanHistory ] = React.useState([]);
       let loanHistoryDetails = {};
       let sharedLoanHistoryDetails = {};
       const dataDec  = dataReceiver;
       const checkObjType = history.location.loginState;


  if(typeof checkObjType !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
          setLoginDetails(history.location.loginState);

      }


 useEffect(()=> {

                 setOpen(!open);
                 let userID = history.location.loginState;
                 let data = {companyID:userID.organisations_id};
                 data = JSON.stringify(data);  

                
const resp = axios.post(baseURL+'bridgeGetEpayNetCompanyTeamMembers.php',data ).then(function (response) {
                  
                      response.data = dataDec(response.data);
                      setBusinessHistory(response.data)
                      setOpen(false); 
                                      
          });
     
   }, [])


  return (

    
      <main className = {classes.content}>
            <div className={classes.toolbar}/>

                  <Backdrop className={classes.backdrop} open={open} >
                           <Loader/>
                  </Backdrop>
   
                 <Grid container direction="column" justify="center">

                  <Grid item ></Grid>

                     <Grid container justify="center" spacing={2}>     
                       
                       {businessHistory.map((historys)=> ( 
                        <Grid item xs={12} sm={6} md={4} lg={3} key={'history'+historys.id}>  
                            <BusinessMember historys={historys}/> 
                            <br/>
                        </Grid>
                        ))} 
                                                   
                   </Grid>        
                       
                    <Grid item xs={false}  sm={3}/>

              </Grid>

  </main>

  );
}



export default BusinessMembers;