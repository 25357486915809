import React, { useEffect,useContext, useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Loader from '../Loader/Loader';
//import BarChart from '../Charts/BarChart';
import Backdrop from '@material-ui/core/Backdrop';


export const InvestmentPlan = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext); 
       const [ open, setOpen] = useState(false);
       const [ selectedStatus, setSelectedStatus ] = useState([]); 
       const [ loginDetails, setLoginDetails ] = loginDetailsBag;
       const [ activeStatus, setActiveStatus] = useState([]);  
       const [ consentValue, setConsentValue] = useState('');                     
       const [ activationCategory, setActivationCategory] = useState([{id:'1',name:'ePayNet Business'},
                                                                      {id:'2',name:'Online checkout'},
                                                                      {id:'3',name:'Crowdfunding'}]);
       let appUserID = loginDetails.organisations_id;    
       let selectedConsent = '';
       let status = '';
       let featureID = '';
       let featuresName = '';

       const [ selectedFeature, setSelectedFeature] = useState(''); 
       const [featureName, setFeatureName] = useState('');    
       let mySelectedFeature = '';                                                          
       const consent = [{id:1, name:'Yes',value:'T'},
                        {id:2,name:'No',value:'F'}];
       const dataDec  = dataReceiver;                 
       let eCommerceStatus = loginDetails.isLegalBusiness;
              
       useEffect(()=> {
        
         
        }, [])
 


   const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setSelectedStatus(event.target.value);
          selectedConsent = event.target.value;
        


   }; 


  

  const handleFeatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {


        setSelectedFeature(event.target.value);
        mySelectedFeature = event.target.value;
       

    for (let i = 0; i < activationCategory.length; i++) { 

           featureID = activationCategory[i].id;

          if(featureID == mySelectedFeature){

               featuresName = activationCategory[i].name;
               setFeatureName(featuresName);
    
            }

        }

   }; 



const openBulkTransactions = () => history.push({
     
     pathname: '/businessauthentication'


});


const openCrowFunding = () => history.push({
     
     pathname: '/crowdfunding'


});

const handleSubmit = () => history.push({
     
     pathname: '/recharge'


});

const localPayments = () => history.push({
     
     pathname: '/LocalPayments'


});

const updateePayNetStatus = async()=>{


   let companyID = loginDetails.organisations_id;
   let username = loginDetails.username;
   let action = selectedStatus;
   
   let data = {companyID:companyID, username:username, action:action};

  console.log('Send status update to :'+featuresName)

   const res = await axios.post(baseURL+'/bridgeReceiveEpayNetWebAccountAction.php', data ).then(function (response) {

  
       if(response.data.code == 200){

            setActiveStatus(response.data);

          }else{
              
           setActiveStatus(response.data);              
           
        }   
     });
         
}


  return (

    <main className = {classes.content}>
            <div className={classes.toolbar}/>

       <Grid container direction="column" className={classes.root}>
  
                  <Grid item></Grid>

                     <Grid item container spacing={2}>
                      
                         <Grid item xs={false} sm={6} md={4} lg={4}>
                                  <Card >

                                     <CardContent>

                                       <Typography variant="body1" gutterBottom>
                                          To recharge you account please click on the button below
                                       </Typography>
                                      <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}}>Submit</Button>

                                     </CardContent>
                                  </Card>   
                         </Grid>   
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>

                               <CardContent>

                                <CardHeader title="Funder Details"  align="center" />

                                  <Typography variant="h4" gutterBottom>
                                     <TextField label="Full Name" name="fullName" variant="outlined" type="text" fullWidth size="small"  placeholder=" John Doe"
                                           
                                        
                                      />
                                      </Typography>


                                 <Typography variant="h4" gutterBottom>
                                      <TextField label="National ID" variant="outlined" type="text" fullWidth size="small"  placeholder="National ID"
                                      
                                         
                                      />
                                  </Typography>

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Cell Number" variant="outlined" type="text" fullWidth size="small"  placeholder="Cell Number" 
                                      
                                        
                                      />
                          </Typography>

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Email" variant="outlined" type="text" fullWidth size="small"  placeholder="epaynet@gmail.com" 
                                      
                                        
                                      />
                          </Typography>

                          {/*<Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Region" variant="outlined" fullWidth size="small" select
                                  onChange={handleChange}
                                  value={region}
                                  > 

                                    {regions.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.provinceName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Town" variant="outlined" fullWidth size="small" select
                                  onChange={handleTownChange}
                                  value={town}
                                  > 

                                    {towns.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.townName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>*/}

                                  <Typography variant="h4" gutterBottom>
                                      <TextField label="Physical Address" variant="outlined" type="text" fullWidth size="small"  placeholder=""
                                      
                                         
                                      />
                                   </Typography> 

                      <Typography variant="h4" gutterBottom>
                                  <TextField label="Source Of Income" variant="outlined" type="text" fullWidth size="small"  placeholder=""
                                      
                                      
                                         
                                      />
                      </Typography>

                      <Typography variant="h4" gutterBottom>
                                 <TextField label="Income Amount" variant="outlined" type="text" fullWidth size="small"  placeholder="500.00"
                                      
                                      
                                         
                                      />
                      </Typography> 

                      <Typography variant="h4" gutterBottom>
                                <TextField label="Employer" variant="outlined" type="text" fullWidth size="small"  placeholder=""
                                     
                                         
                                      />
                      </Typography>        


                      <Typography variant="body1" gutterBottom>
                          I hereby consent that the information provided above is correct and accurate. I also acknowledge that it is my responsibility to update this information should there be any changes to the above.
                      </Typography>

                      <Typography variant="h4" gutterBottom>                
                               <TextField label="Consent" variant="outlined"  fullWidth size="small" select
                                 onChange={handleConsentChange}
                                 value={consentValue} >

                                    {consent.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.name} 
                                   </MenuItem>
                                   ))}
                      </TextField></Typography><br></br>

           
                <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="inherit" style ={{margin: '5px', textTransaform:'none', backgroundColor:'#155054', color:'white'}} onClick={()=>handleSubmit()}>Submit</Button>

             

      </CardContent>
 </Card>


                        </Grid>
         
                     <Grid item xs={false} sm={false} md={4} lg={4}/>
                 </Grid>

              </Grid>

 </main>
  );
}

export default InvestmentPlan;